import axios from "axios";
import { 
  getAccessRuleByJobIDAndUserID,
 } from "network/ApiAxios";

import EmployerLayout from "layouts/EmployerLayout";
import React, { useEffect, useState } from "react";

import { Box } from "@mui/material";
import JobApplicationsEmployerV3 from "components/employer/jobApplicationsEmployerV3";
// import JobApplicationsCardsEmployerV2 from "components/employer/jobApplicationsCardsEmployerV2";
import JobApplicationsCardsEmployerV3 from "components/employer/jobApplicationsCardsEmployerV3";

import config from "config";
import {
  DropdownMenu,
  Media,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormText,
  FormFeedback,
  CardText,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Table,
} from "reactstrap";
import Select from "react-select";
import { useTranslation } from "react-i18next";



function OneJobEmployerApplications ({ jobID }) {
  // let jobs_length = config.Jobs_for_outfrSearchPermanentFr.length;
  const [openTab, setOpenTab] = useState(1);
  // const [jobID, setJobID] = useState(config.Default_Job_for_employer_profiles);
  const [profileTypes, setProfileTypes] = useState([]);
  const [profileTypesOptions, setProfileTypesOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visibility, setVisibility] = useState(true);
  const allProfiles =
  {
    jobID: "11111111111111",
    name: "Tous les profils"
  };

  const tab_Nb_for_Sourcing_Pipeline = 1;
  const tab_Nb_for_A_Relancer_Pipeline = 2;
  const tab_Nb_for_A_qualifier_Pipeline = 3;
  const tab_Nb_for_final_decision_Pipeline = 4;
  const tab_Nb_for_A_suivre_Pipeline = 5;
  const tab_Nb_for_Rejected_Pipeline = 6;
  const tab_Nb_for_Valid_Pipeline = 7;
  const tab_Nb_for_Offre_Pipeline = 8;
  const tab_Nb_for_CDI_Pipeline = 9;

  const { t, i18n } = useTranslation();
  
  

  var user;
  if (localStorage.getItem("user")) {
  user = JSON.parse(localStorage.getItem("user"));
   } else {
   user = { role: "none" };
   };

   const instance = axios.create({
    baseURL: config.WS_BASE_URL,
  });
  
  instance.interceptors.request.use(async (config) => {
    const token = localStorage.getItem("token");
    config.headers.Authorization = token ? token : "";
    config.headers['Content-Type'] = "application/json";
    return config;
  });

  const getProfileTypesFunction = () => {
    console.log("aaaaaaaaaaaaaaaaaaaaaaaa: getProfileTypesFunction");
    // var pipeline = "Rejeté";
    // const response = getProfileTypes();
    // const { data } = response;
    // console.log("aaaaaaaaaaaaaaaaaaaaaaaa: getProfileTypesFunction: response: ", response);
    // console.log("aaaaaaaaaaaaaaaaaaaaaaaa: getProfileTypesFunction: response.data: ", response.data);
    
    instance.get(`profiletypes/getAllProfileTypes`).then((resp) => {
      console.log("aaaaaaaaaaaaaaaaaaaaaaaa: getProfileTypesFunction: resp: ", resp);
      setProfileTypes(resp.data);
      let newArray;
      newArray = [allProfiles].concat(resp.data);
      // newArray = resp.data;
      setProfileTypesOptions(newArray.map(item => ({value: item.jobID, label: item.name})));
      // profileTypesOptions = newArray.map(item => ({value: item._id, label: item.name}));

      // const orderItems = req.body.orderItems.map((x) => ({ ...x, product: x._id }));

      // setProfileTypesOptions(newArray);
      // console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxx: getProfileTypesFunction: newArray: ", newArray);

      console.log("aaaaaaaaaaaaaaaaaaaaaaaa: getProfileTypesFunction: resp: ", resp);
      console.log("aaaaaaaaaaaaaaaaaaaaaaaa: getProfileTypesFunction: profileTypes: ", profileTypes);
      console.log("aaaaaaaaaaaaaaaaaaaaaaaa: getProfileTypesFunction: profileTypesOptions: ", profileTypesOptions);
      // console.log("222222222222222222: fetchCandidateCompetencies: step 2:");
      // console.log("222222222222222222: fetchCandidateCompetencies: step 3 : resp: ", resp);
      // console.log("222222222222222222: fetchCandidateCompetencies: step 4 : resp.data: ", resp.data);
      });
    // console.log("aaaaaaaaaaaaaaaaaaaaaaaa: getProfileTypesFunction: success");
    // console.log("aaaaaaaaaaaaaaaaaaaaaaaa: response.data: ", response.data);
    // setVisibility(response.data.visibility);
    // setProfileTypeName(response.data.name);
    // const orderItems = req.body.orderItems.map((x) => ({ ...x, product: x._id }));


  };

  const getAccessRuleFunction = async (profileTypeID) => {
    console.log("bbbbbbbbbbbbbbbbbbbb: getAccessRuleFunction");
    console.log("bbbbbbbbbbbbbbbbbbbb: getAccessRuleFunction: jobID: ", jobID);
    console.log("bbbbbbbbbbbbbbbbbbbb: getAccessRuleFunction: user._id: ", user._id);
    const response = await getAccessRuleByJobIDAndUserID(jobID, user._id);
    console.log("bbbbbbbbbbbbbbbbbbbb: getAccessRuleFunction: response: ", response);
    if (response.data !== null) {
      console.log("bbbbbbbbbbbbbbbbbbbb: getAccessRuleFunction: response.data: response.data !== null");
      console.log("bbbbbbbbbbbbbbbbbbbb: getAccessRuleFunction: response.data: ", response.data);
      console.log("bbbbbbbbbbbbbbbbbbbb: getAccessRuleFunction: response.data.visibility: ", response.data.visibility);

      // setVisibility(response.data.visibility);
      if (response.data.visibility === false)
      {
        console.log("bbbbbbbbbbbbbbbbbbbb: getAccessRuleFunction: response.data.visibility === false: ", response.data.visibility);
        setVisibility(false);
        // return (false); 
      }
      else 
      {
        console.log("bbbbbbbbbbbbbbbbbbbb: getAccessRuleFunction: response.data.visibility === true: ", response.data.visibility);
        setVisibility(true);
        // return (true);
      }
      // return (response.data.visibility);
      // setAccessRules(response.data);
      // console.log("bbbbbbbbbbbbbbbbbbbb: getAccessRuleFunction: response: success");
      // setVisibility(response.data.visibility);
      // setProfileTypeName(response.data.name);
    } else {
      setVisibility(true);
      // return (true);
      // console.log("bbbbbbbbbbbbbbbbbbbbbbbb: failure");
    }
  };




  useEffect(() => {
    getProfileTypesFunction();
    getAccessRuleFunction();

  }, [jobID]);
  

  return (
    <>
      <Container>
        
          <>

              
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Table className="GatTable">
                      <tbody>
                        <tr>
                        <td
                            className={
                              (openTab === tab_Nb_for_Sourcing_Pipeline)
                                ? "tdActive "
                                : "td "
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(tab_Nb_for_Sourcing_Pipeline);}}
                            data-toggle="tab"
                            // href="#link20"
                            role="tablist"
                            //onClick={() => history.push('/admin/edit-profile')}
                            size="sm"
                          >
                            <i className="fa fa-users" aria-hidden="true" />{" "}
                            Sourcing
                        </td>

                        <td
                            className={
                              (openTab === tab_Nb_for_A_Relancer_Pipeline)
                                ? "tdActive "
                                : "td "
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(tab_Nb_for_A_Relancer_Pipeline);}}
                            data-toggle="tab"
                            // href="#link21"
                            role="tablist"
                            //onClick={() => history.push('/admin/edit-profile')}
                            size="sm"
                          >
                            <i className="fa fa-users" aria-hidden="true" />{" "}
                            {t('pipelineStatus1')}
                        </td>

                          <td
                            className={openTab === tab_Nb_for_A_qualifier_Pipeline ? "tdActive " : "td "}
                            //activeClassName={openTab === tab_Nb_for_A_qualifier_Pipeline ? "active" : {}}
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(tab_Nb_for_A_qualifier_Pipeline);
                            }}
                            //data-toggle="tab"
                            // to="#link1"
                            //role="tablist"
                            //onClick={() => history.push('/admin/edit-profile')}
                            // size="sm"
                          >
                            <i className="fa fa-book" aria-hidden="true" />{" "}
                            {t('pipelineStatus2')}
                          </td>
                          
                          
                          <td
                            className={openTab === tab_Nb_for_final_decision_Pipeline ? "tdActive " : "td "}
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(tab_Nb_for_final_decision_Pipeline);
                            }}
                            data-toggle="tab"
                            // href="#link2"
                            role="tablist"
                            //onClick={() => history.push('/admin/edit-profile')}
                            size="sm"
                          >
                            <i className="fa fa-question-circle" aria-hidden="true" />{" "}
                            {t('pipelineStatus3')}
                          </td>
                          <td
                            className={openTab === tab_Nb_for_A_suivre_Pipeline ? "tdActive " : "td "}
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(tab_Nb_for_A_suivre_Pipeline);
                            }}
                            data-toggle="tab"
                            // href="#link2"
                            role="tablist"
                            //onClick={() => history.push('/admin/edit-profile')}
                            size="sm"
                          >
                            <i className="fa fa-question-circle" aria-hidden="true" />{" "}
                            {t('pipelineStatus4')}
                          </td>
                          <td
                            className={
                              (openTab === tab_Nb_for_Rejected_Pipeline)
                                ? "tdActive "
                                : "td "
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(tab_Nb_for_Rejected_Pipeline);}}
                            data-toggle="tab"
                            // href="#link3"
                            role="tablist"
                            //onClick={() => history.push('/admin/edit-profile')}
                            size="sm"
                          >
                            <i className="fa fa-users" aria-hidden="true" />{" "}
                            {t('pipelineStatus5')}
                          </td>

                          <td
                            className={
                              (openTab === tab_Nb_for_Valid_Pipeline)
                                ? "tdActive "
                                : "td "
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(tab_Nb_for_Valid_Pipeline);}}
                            data-toggle="tab"
                            // href="#link4"
                            role="tablist"
                            //onClick={() => history.push('/admin/edit-profile')}
                            size="sm"
                          >
                            <i className="fa fa-users" aria-hidden="true" />{" "}
                            {t('pipelineStatus6')}
                          </td>

                          <td
                            className={
                              (openTab === tab_Nb_for_Offre_Pipeline)
                                ? "tdActive "
                                : "td "
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(tab_Nb_for_Offre_Pipeline);}}
                            data-toggle="tab"
                            // href="#link4"
                            role="tablist"
                            //onClick={() => history.push('/admin/edit-profile')}
                            size="sm"
                          >
                            <i className="fa fa-users" aria-hidden="true" />{" "}
                            {t('Offer')}
                          </td>
                          
                          <td
                            className={
                              (openTab === tab_Nb_for_CDI_Pipeline)
                                ? "tdActive "
                                : "td "
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(tab_Nb_for_CDI_Pipeline);}}
                            data-toggle="tab"
                            // href="#link4"
                            role="tablist"
                            //onClick={() => history.push('/admin/edit-profile')}
                            size="sm"
                          >
                            <i className="fa fa-users" aria-hidden="true" />{" "}
                            CDI
                          </td>



                        </tr>
                      </tbody>
                    </Table>
                  </CardHeader>
                  {openTab === tab_Nb_for_A_qualifier_Pipeline &&
                  <CardBody
                    className={openTab === tab_Nb_for_A_qualifier_Pipeline ? "block" : "hidden"}
                    id={`link${tab_Nb_for_A_qualifier_Pipeline}`}
                  >
                    {/*
                    <JobApplicationsCardsEmployerV2 key={"derrrrfgrgtrgtrgtrgtrgtr"} jobID={"627d34016543d2ab235e8126"} />
                    <JobApplicationsCardsEmployerV2 key={"vsdvfreffrefrefrefre"} jobID={"27ce5096543d2ab235e8112"} />
                  */ }
                  {console.log ("5555555555555555555: jobID: ", jobID)}
                  {jobID !== "11111111111111" &&
                    <JobApplicationsCardsEmployerV3

                    key={`${jobID}_toQualify`}
                    jobID={jobID} 
                    // jobID ="627ce5096543d2ab235e8112"
                    pipeline="A qualifier"
                  />

                  }
                    {jobID === "11111111111111" &&
                        <>
                        {profileTypes.map((profileTypeItem) => (
                          <>
                          <JobApplicationsCardsEmployerV3
                            key={profileTypeItem._id}
                            jobID={profileTypeItem.jobID} 
                            // jobID ="627ce5096543d2ab235e8112"
                            pipeline="A qualifier"
                          />
                          </>
                        ))
                        }
                        </>
                      }
                  
                  </CardBody>
                  }
                  {openTab === tab_Nb_for_final_decision_Pipeline &&
                  <CardBody
                    className={openTab === tab_Nb_for_final_decision_Pipeline ? "block" : "hidden"}
                    id={`link${tab_Nb_for_final_decision_Pipeline}`}
                  >

                    {jobID !== "11111111111111" &&
                      <JobApplicationsCardsEmployerV3
                      // key={`${jobID}_finalOpinion`}
                      jobID={jobID} 
                      pipeline="Avis final"
                    />
                    }
                    {jobID === "11111111111111" &&
                      <>
                      {profileTypes.map((profileTypeItem) => (
                        <>
                        <JobApplicationsCardsEmployerV3
                          key={profileTypeItem._id}
                          jobID={profileTypeItem.jobID} 
                          // jobID ="627ce5096543d2ab235e8112"
                          pipeline="Avis final"
                        />
                        </>
                      ))
                      }
                      </>
                    }


                  </CardBody>
                  }

                {openTab === tab_Nb_for_A_suivre_Pipeline &&
                  <CardBody
                    className={openTab === tab_Nb_for_A_suivre_Pipeline ? "block" : "hidden"}
                    id={`link${tab_Nb_for_A_suivre_Pipeline}`}
                  >

                    {jobID !== "11111111111111" &&
                      <JobApplicationsCardsEmployerV3
                        // key={`${jobID}_ASuivre`}
                        jobID={jobID} 
                        pipeline="A suivre"
                    />
                    }
                    {jobID === "11111111111111" &&
                      <>
                      {profileTypes.map((profileTypeItem) => (
                        <>
                        <JobApplicationsCardsEmployerV3
                          // key={profileTypeItem._id}
                          jobID={profileTypeItem.jobID} 
                          // jobID ="627ce5096543d2ab235e8112"
                          pipeline="A suivre"
                        />
                        </>
                      ))
                      }
                      </>
                    }


                  </CardBody>
                  }

                  {openTab === tab_Nb_for_Rejected_Pipeline &&
                  <CardBody
                    className={openTab === tab_Nb_for_Rejected_Pipeline ? "block" : "hidden"}
                    id={`link${tab_Nb_for_Rejected_Pipeline}`}
                  >

                    {jobID !== "11111111111111" &&
                      <JobApplicationsCardsEmployerV3
                        // key={`${jobID}_Rejected`}
                        jobID={jobID} 
                        pipeline="Rejeté"
                    />
                  }
                  {jobID === "11111111111111" &&
                    <>
                    {profileTypes.map((profileTypeItem) => (
                      <>
                      <JobApplicationsCardsEmployerV3
                        // key={profileTypeItem._id}
                        jobID={profileTypeItem.jobID} 
                        // jobID ="627ce5096543d2ab235e8112"
                        pipeline="Rejeté"
                      />
                      </>
                    ))
                    }
                    </>
                  }



                  </CardBody>
                  }

                  {openTab === tab_Nb_for_Valid_Pipeline &&
                  <CardBody
                    className={openTab === tab_Nb_for_Valid_Pipeline ? "block" : "hidden"}
                    id={`link${tab_Nb_for_Valid_Pipeline}`}
                  >

                    {jobID !== "11111111111111" && 
                      <JobApplicationsCardsEmployerV3
                        // key={`${jobID}_Valid`}
                        jobID={jobID} 
                        pipeline="Validé"
                    />
                  }
                  {jobID === "11111111111111" &&
                    <>
                    {profileTypes.map((profileTypeItem) => (
                      <>
                      <JobApplicationsCardsEmployerV3
                        // key={profileTypeItem._id}
                        jobID={profileTypeItem.jobID} 
                        // jobID ="627ce5096543d2ab235e8112"
                        pipeline="Validé"
                      />
                      </>
                    ))
                    }
                    </>
                  }




                  </CardBody>
                  }

                {openTab === tab_Nb_for_Sourcing_Pipeline &&
                  <CardBody
                    className={openTab === tab_Nb_for_Sourcing_Pipeline ? "block" : "hidden"}
                    id={`link${tab_Nb_for_Sourcing_Pipeline}`}
                  >

                    {jobID !== "11111111111111" && 
                    <>
                      <JobApplicationsCardsEmployerV3
                        //key={`${jobID}_NoPipeline_5a`}
                        jobID={jobID} 
                        pipeline=""
                    />
                      <JobApplicationsCardsEmployerV3
                        // key={`${jobID}_Sourcing_5b`}
                        jobID={jobID} 
                        pipeline="Sourcing"
                    />
                    </>

                  }
                  {jobID === "11111111111111" &&
                    <>
                    {profileTypes.map((profileTypeItem) => (
                      <>
                      <JobApplicationsCardsEmployerV3
                        // key={`${profileTypeItem._id}_NoPipeline_All_jobs_5a`}
                        jobID={profileTypeItem.jobID} 
                        pipeline=""
                      />
                      <JobApplicationsCardsEmployerV3
                        // key={`${profileTypeItem._id}_Sourcing_All_jobs_5b`}
                        jobID={profileTypeItem.jobID} 
                        pipeline="Sourcing"
                      />
                      </>
                    ))
                    }
                    </>
                  }




                  </CardBody>
                  }
                  {openTab === tab_Nb_for_A_Relancer_Pipeline &&
                  <CardBody
                    className={openTab === tab_Nb_for_A_Relancer_Pipeline ? "block" : "hidden"}
                    id={`link${tab_Nb_for_A_Relancer_Pipeline}`}
                  >

                    {jobID !== "11111111111111" && 
                      <JobApplicationsCardsEmployerV3
                        // key={`${jobID}_Valid_2`}
                        jobID={jobID} 
                        pipeline="A relancer"
                    />
                  }
                  {jobID === "11111111111111" &&
                    <>
                    {profileTypes.map((profileTypeItem) => (
                      <>
                      <JobApplicationsCardsEmployerV3
                        // key={profileTypeItem._id}
                        jobID={profileTypeItem.jobID} 
                        // jobID ="627ce5096543d2ab235e8112"
                        pipeline="A relancer"
                      />
                      </>
                    ))
                    }
                    </>
                  }




                  </CardBody>
                  }
                  {openTab === tab_Nb_for_Offre_Pipeline &&
                  <CardBody
                    className={openTab === tab_Nb_for_Offre_Pipeline ? "block" : "hidden"}
                    id={`link${tab_Nb_for_Offre_Pipeline}`}
                  >

                    {jobID !== "11111111111111" && 
                      <JobApplicationsCardsEmployerV3
                        // key={`${jobID}_Offre`}
                        jobID={jobID} 
                        pipeline="Offre"
                    />
                  }
                  {jobID === "11111111111111" &&
                    <>
                    {profileTypes.map((profileTypeItem) => (
                      <>
                      <JobApplicationsCardsEmployerV3
                        // key={profileTypeItem._id}
                        jobID={profileTypeItem.jobID} 
                        // jobID ="627ce5096543d2ab235e8112"
                        pipeline="Offre"
                      />
                      </>
                    ))
                    }
                    </>
                  }




                  </CardBody>
                  }
                  {openTab === tab_Nb_for_CDI_Pipeline &&
                  <CardBody
                    className={openTab === tab_Nb_for_CDI_Pipeline ? "block" : "hidden"}
                    id={`link${tab_Nb_for_CDI_Pipeline}`}
                  >

                    {jobID !== "11111111111111" && 
                      <JobApplicationsCardsEmployerV3
                        // key={`${jobID}_CDI`}
                        jobID={jobID} 
                        pipeline="CDI"
                    />
                  }
                  {jobID === "11111111111111" &&
                    <>
                    {profileTypes.map((profileTypeItem) => (
                      <>
                      <JobApplicationsCardsEmployerV3
                        // key={profileTypeItem._id}
                        jobID={profileTypeItem.jobID} 
                        // jobID ="627ce5096543d2ab235e8112"
                        pipeline="CDI"
                      />
                      </>
                    ))
                    }
                    </>
                  }




                  </CardBody>
                  }
                  </Card>
              
          </>
       
    </Container>
    </>
  );
}
export default OneJobEmployerApplications;