import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  PencilAltIcon,
  LocationMarkerIcon,
  BriefcaseIcon,
  MailIcon,
  PhoneIcon,
  LinkIcon,
  ArchiveIcon,
  OfficeBuildingIcon,
  PaperClipIcon,
} from "@heroicons/react/solid";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  CardFooter,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import { useHistory, Link } from "react-router-dom";
import config from "config";
const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type']= "application/json";
  return config;
});

const Profile = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const history = useHistory();
  const [data1, setData1] = useState([]);
  const [data, setData] = useState([]);
  const fetchMyJobsData = async () => {
    instance
      .put("jobs", { userID: JSON.parse(localStorage.getItem("user"))._id })
      .then((resp) => {
        //setJobs(resp.data);
        const data = resp.data;
        const DATA = [];
        console.log("data", data);
        if (data.length >= 5) {
          for (let i = data.length - 1; i >= data.length - 5; i--) {
            DATA.push(data[i]);
          }
        } else {
          for (let i = data.length - 1; i >= 0; i--) {
            DATA.push(data[i]);
          }
        }
        setData(DATA);
      });
  };
  const fetchMyCompetitionsData = async () => {
    instance
      .put("competitions", {
        userID: JSON.parse(localStorage.getItem("user"))._id,
      })
      .then((resp) => {
        //setCompetitions(resp.data);
        const data = resp.data;
        const DATA = [];
        console.log("data", data);
        if (data.length >= 5) {
          for (let i = data.length - 1; i >= data.length - 5; i--) {
            DATA.push(data[i]);
          }
        } else {
          for (let i = data.length - 1; i >= 0; i--) {
            DATA.push(data[i]);
          }
        }
        setData1(DATA);
      });
  };
  useEffect(() => {
    fetchMyJobsData();
    fetchMyCompetitionsData();
  }, []);
  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" >
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <Card className="card-profile shadow">
              <Row className="justify-content-center">
                <Col className="order-lg-2" lg="3">
                  <div className="card-profile-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    {user.image?<img
                        alt="..."
                        className="rounded-circle"
                        src={`${config.Data_URL}${user.image}`}
                      />:
                      <img
                        alt="..."
                        className="rounded-circle"
                        src={require("assets/img/brand/default.png").default}
                      />}
                    </a>
                  </div>
                </Col>
              </Row>
              <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                <div className="d-flex justify-content-between">
                  {/* <Button
                                        className="mr-4"
                                        color="info"
                                        href="#pablo"
                                        onClick={e => e.preventDefault()}
                                        size="sm"
                                    >
                                        Connect
                                    </Button>
                                    <Button
                                        className="float-right"
                                        color="default"
                                        href="#pablo"
                                        onClick={e => e.preventDefault()}
                                        size="sm"
                                    >
                                        Message
                                    </Button> */}
                </div>
              </CardHeader>
              <CardBody className="pt-0 pt-md-4">
                <Row>
                  <div className="col">
                    <div className="card-profile-stats d-flex justify-content-center mt-md-5"></div>
                  </div>
                </Row>
                <div className="text-center">
                  <h3>{user.company}</h3>
                  <div
                    className="h5 mt-4 col-12 "
                    style={{ display: "inline-flex" }}
                  >
                    <LocationMarkerIcon className="h-6 pr-2" />
                    {user.location}
                  </div>
                  <div
                    className="h5 mt-4 col-12 "
                    style={{ display: "inline-flex" }}
                  >
                    <BriefcaseIcon className="h-6 pr-2" />
                    {user.activity}
                  </div>
                  <div
                    className="h5 mt-4 col-12 "
                    style={{ display: "inline-flex" }}
                  >
                    <MailIcon className="h-6 pr-2" />
                    {user.companyEmail}
                  </div>
                  <div
                    className="h5 mt-4 col-12 "
                    style={{ display: "inline-flex" }}
                  >
                    <PhoneIcon className="h-6 pr-2" />
                    {user.phone}
                  </div>
                  <div
                    className="h5 mt-4 col-12 "
                    style={{ display: "inline-flex" }}
                  >
                    <LinkIcon className="h-6 pr-2" />
                    <a href={user.webSite} target="blank">
                      {user.webSite}
                    </a>
                  </div>
                  <hr className="my-4" />
                  <p>{user.description}</p>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-Card border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0 card-t">Offres</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="bg-white">
              {data.map((job) => (
                  <Row
                    className=" px-2 py-3 mb-3 flex  hover:black hover:border hover:border-black cursor-pointer duration-75 hover:z-20 bg-white shadow-lg rounded-sm"
                    key={job._id}
                    onClick={() =>
                        history.push(`/admin/tab2?jobId=${job._id}`)
                      }
                  >
                    <Col lg="2">
                      <img
                        src={`${config.Data_URL}${job.image}`}
                        onError={(e) => {
                          e.target.src =
                            require("assets/img/brand/500.png").default;
                        }}
                        style={{
                          width: "120x",
                          height: "120px",

                          objectFit: "cover",
                        }}
                        alt="Responsive image"
                      />
                    </Col>
                    <Col lg="8">
                      <div className="flex items-center space-x-3 mb-2">
                        <div>
                          <h3 className="card-t">{job.title}</h3>
                          <span
                            className="text-sm"
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp:
                                "2" /* number of lines to show */,
                              WebkitBoxOrient: "vertical",
                            }}
                          >
                            {job.description}
                          </span>
                        </div>
                      </div>
                      <Row>
                        <Col lg="6">
                          <div style={{ display: "inline-flex" }}>
                            {" "}
                            <ArchiveIcon  className=""
                              style={{ height: "1.2rem" }}
                            /> &nbsp; <span> {job.technologies}</span>{" "}
                          </div>
                        </Col>
                        <Col lg="6">
                          <div style={{ display: "inline-flex" }}>
                            {" "}
                            <PaperClipIcon className="" style={{ height: "1.2rem" }} />{" "}
                            &nbsp; <span> {job.contract}</span>{" "}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <div style={{ display: "inline-flex" }}>
                            {" "}
                            <OfficeBuildingIcon className=""
                              style={{ height: "1.2rem" }}
                            />{" "}
                            &nbsp; <span> {job.entreprise}</span>{" "}
                          </div>
                        </Col>
                        <Col lg="6">
                          <div style={{ display: "inline-flex" }}>
                            {" "}
                            <LocationMarkerIcon className=""
                              style={{ height: "1.2rem" }}
                            />{" "}
                            &nbsp; <span> {job.location}</span>{" "}
                          </div>
                        </Col>
                      </Row>
                      {/* <div>
                            Status:{" "}
                            <span
                              className={`${
                                job.status === "inactive"
                                  ? "text-red-600"
                                  : "text-green-600"
                              }`}
                            >
                              {job.status}
                            </span>
                          </div>
                          <div>
                            Updated on:{" "}
                            {`${job.updatedAt.slice(
                              0,
                              10
                            )} ${job.updatedAt.slice(11, 19)}`}
                          </div> */}
                    </Col>
                    <Col lg="2" className=" flex items-center">
                      <div>
                        <div className="text-center text-sm">
                          {job?.createdAt.slice(0, 10)}
                          
                        </div>
                        <Button
                        //   style={{
                        //     backgroundColor: "#404954",
                        //     color: "white",
                        //   }}
                        //   color="text-white"
                        className="btn-secondary"
                          onClick={() =>
                            history.push(`/admin/tab2?jobId=${job._id}`)
                          }
                          size="sm"
                        >
                          Voir Plus
                        </Button>
                      </div>
                    </Col>
                  </Row>
                ))}
              </CardBody>
            </Card>
            <Card className="bg-secondary shadow mt-4">
              <CardHeader className="bg-Card border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0 card-t">Compétitions</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="bg-white">
              {data1?.map((competition) => (
                  <Row
                    className=" px-2 py-3 mb-3 flex  hover:black hover:border hover:border-black cursor-pointer duration-75 hover:z-20 bg-white shadow-lg rounded-sm"
                    key={competition._id}
                    onClick={() =>
                        history.push(
                          `/admin/tab2comp?competitionId=${competition._id}`
                        )
                      }
                  >
                    <Col lg="2">
                      <img
                        src={`${config.Data_URL}${competition.image}`}
                        onError={(e) => {
                          e.target.src =
                            require("assets/img/brand/500.png").default;
                        }}
                        style={{
                          width: "120x",
                          height: "120px",

                          objectFit: "cover",
                        }}
                        alt="Responsive image"
                      />
                    </Col>
                    <Col lg="8">
                      <div className="flex items-center space-x-3 mb-2">
                        <div>
                          <h3 className="card-t">{competition.title}</h3>
                          <span
                            className="text-sm"
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp:
                                "2" /* number of lines to show */,
                              WebkitBoxOrient: "vertical",
                            }}
                          >
                            {competition.description}
                          </span>
                        </div>
                      </div>
                      {/* <Row>
                            <Col lg="6">
                              <div style={{ display: "inline-flex" }}>
                                {" "}
                                <ArchiveIcon
                                  style={{ height: "1.2rem" }}
                                />{" "}
                                &nbsp; <span> {job.technologies}</span>{" "}
                              </div>
                            </Col>
                            <Col lg="6">
                              <div style={{ display: "inline-flex" }}>
                                {" "}
                                <PaperClipIcon
                                  style={{ height: "1.2rem" }}
                                />{" "}
                                &nbsp; <span> {job.contract}</span>{" "}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <div style={{ display: "inline-flex" }}>
                                {" "}
                                <OfficeBuildingIcon
                                  style={{ height: "1.2rem" }}
                                />{" "}
                                &nbsp; <span> TopTech-Tunisie</span>{" "}
                              </div>
                            </Col>
                            <Col lg="6">
                              <div style={{ display: "inline-flex" }}>
                                {" "}
                                <LocationMarkerIcon
                                  style={{ height: "1.2rem" }}
                                />{" "}
                                &nbsp; <span> {job.location}</span>{" "}
                              </div>
                            </Col>
                          </Row> */}
                      {/* <div>
                            Status:{" "}
                            <span
                              className={`${
                                job.status === "inactive"
                                  ? "text-red-600"
                                  : "text-green-600"
                              }`}
                            >
                              {job.status}
                            </span>
                          </div>
                          <div>
                            Updated on:{" "}
                            {`${job.updatedAt.slice(
                              0,
                              10
                            )} ${job.updatedAt.slice(11, 19)}`}
                          </div> */}
                    </Col>
                    <Col lg="2" className=" flex items-center">
                      <div>
                        <div className="text-center text-sm">
                     {competition?.createdAt.slice(0, 10)}
                                                  
                        </div>
                        <Button
                        //   style={{
                        //     backgroundColor: "#404954",
                        //     color: "white",
                        //   }}
                        className="btn-secondary"
                          color="text-white"
                          onClick={() =>
                            history.push(
                                `/admin/tab2comp?competitionId=${competition._id}`
                              )
                          }
                          size="sm"
                        >
                          Voir Plus
                        </Button>
                      </div>
                    </Col>
                  </Row>
                ))}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
