import React, { useEffect,useState, useRef } from "react";

import axios from "axios";
import config from "config";
const instance = axios.create({
    baseURL: config.WS_BASE_URL,
  });
  
  instance.interceptors.request.use(async (config) => {
    const token = localStorage.getItem("token");
    config.headers.Authorization = token ? token : "";
    config.headers['Content-Type'] = "application/json";
    return config;
  });

const CVPdfDisplayer =({candidatId,jobApplicationUserID,jobApplicationCV,jobApplicationFormCV})=> {
  

  const pdfIframeRef = useRef(null);
  console.log("candidatId", candidatId)

  useEffect(() => {
    const fetchCVPDF = async (url) => {
      try {
        const response = await instance.get(url, {
          responseType: "blob"
        });
  
        const file = new Blob([response.data], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(file);
  
        if (pdfIframeRef.current) {
          pdfIframeRef.current.src = pdfUrl;
        }
      } catch (error) {
        console.log(error);
      }
    };
  
    const fetchJobAppCVPDF = async () => {
      const url = `jobApplication/getJobAppCV/${candidatId}`;
      console.log("fetchJobAppCVPDF","fetchJobAppCVPDF",candidatId)
      console.log('Job application CV PDF fetched successfully!');

      await fetchCVPDF(url);
    };
  
    const fetchUserCVPDF = async () => {
      const url = `getUserCV/${jobApplicationUserID}`;
      await fetchCVPDF(url);
      console.log('User CV PDF fetched successfully!');

      console.log("fetchUserCVPDF","jobApplicationUserID",jobApplicationUserID)
    };
  
    const fetchCVPDFData = async () => {
        console.log('jobApplicationFormCV',jobApplicationFormCV)
       
      
      if (jobApplicationCV !== "") {
        await fetchJobAppCVPDF();
      }  else if (jobApplicationCV === "" && jobApplicationFormCV !== ""){
        await fetchUserCVPDF();
      }else if (jobApplicationCV === "" && jobApplicationFormCV === "") {
        console.log('User does not have a CV.');

    } 

    };
  
    fetchCVPDFData();
  
    return () => {
      // Clean up the iframe and revoke the object URL
      if (pdfIframeRef.current) {
        pdfIframeRef.current.src = "";
      }
    };
  }, [candidatId, jobApplicationUserID, jobApplicationCV, jobApplicationFormCV]);

  return (

   <iframe
      ref={pdfIframeRef}
      id="pdfIframe"
      title="PDF Viewer"
      style={{ width: "100%", height: "100vh", border: "none" }}
    />
     
  );
};

export default CVPdfDisplayer;


 