import React, { useEffect, useState } from "react";
import { options_videosPermission } from 'options';
import PageSpace from "components/styles/pageSpace";
import PageTitleStyle from "components/styles/PageTitleStyle";


// reactstrap components
import {
  Container,
  CardHeader,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Button,
} from "reactstrap";
import CandidateLayoutV3 from "layouts/CandidateLayoutV3";
import { options_Pays } from "options";
import Select from "react-select";

// core components
import { edit } from "../../network/ApiAxios";
import axios from "axios";
import config from "config";

import EmployerLayout from "layouts/EmployerLayout";

// const https = require('https');
//
// const agent = new https.Agent({
//     rejectUnauthorized: false,
// });

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});

const OutFranceCandidateParametersView = (props) => {
  let user = JSON.parse(localStorage.getItem("user"));

  const [name, setName] = useState(user.name);
  const [lastName, setLastName] = useState(user.lastName);
  const [email, setEmail] = useState(user.email);
  const [isTestUser, setIsTestUser] = useState(false);
  const [phone, setPhone] = useState(user.phone);
  const [description, setDescription] = useState(user.description);
  const [location, setLocation] = useState(user.location);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("user")).email === "test@test.com") {
      setIsTestUser(true);
    }
  }, []);

  const editUser = async () => {
    const formData = new FormData();
    formData.append("userID", user._id);
    formData.append("name", name);
    formData.append("lastName", lastName);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("description", description);
    formData.append("location", location);
    const response =  await instance.post("/users/edit",  formData );
    const { data } = response;
    if (data.success) {
      user = {
        ...user,
        name,
        lastName,
        email,
        phone,
        description,
        location
      };
      localStorage.setItem("user", JSON.stringify(data.user[0]));
      window.location.reload();
    }
  };

  return (
    <>
      <CandidateLayoutV3 />
      <Container>
      <PageSpace />
      <PageTitleStyle
        title="Paramètres"
      >
        
      </PageTitleStyle>

      <Row className="justify-content-center border-0 py-2" style={{width:"100%" }}>
          <Col xs="2" >
          </Col>
          <Col xs="10">
            <Card className="bg-secondary shadow">
                <CardBody className="bg-white">
                    <Form>
                    <div className="pl-lg-4">

                        </div>
                    <h6 className="heading-small text-muted mb-4">Information</h6>
                    <div className="pl-lg-4">
                        <Row>
                        <Col lg="3">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-username"
                            >
                                Nom
                            </label>
                            <Input
                                className="form-control-alternative"
                                value={name}
                                id="input-username"
                                placeholder="Username"
                                onChange={(e) => setName(e.target.value)}
                                type="text"
                            />
                            </FormGroup>
                        </Col>
                        <Col lg="3">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-username"
                            >
                                Prénom
                            </label>
                            <Input
                                className="form-control-alternative"
                                value={lastName}
                                id="input-username"
                                placeholder="Username"
                                onChange={(e) => setLastName(e.target.value)}
                                type="text"
                            />
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-email"
                            >
                                Email
                            </label>
                            <Input
                                className="form-control-alternative"
                                id="input-email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                type="email"
                            />
                            </FormGroup>
                        </Col>
                        </Row>
                    </div>
                    <hr className="my-4" />
                    <div className="pl-lg-4">
                        <Row>
                        <Col lg="3">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-phone"
                            >
                                Numéro de téléphone
                            </label>
                            <Input
                                className="form-control-alternative"
                                value={phone}
                                id="input-phone"
                                // placeholder="Username"
                                onChange={(e) => setPhone(e.target.value)}
                                type="number"
                            />
                            </FormGroup>
                        </Col>
                        </Row>
                    </div>

                            <FormGroup xs={12}>
                            <label className="px-lg-3">
                                Pays
                            </label>
                            <Select className="w-full "
                            options={options_Pays}
                            value={location}
                            placeholder={location}
                            onChange={(e) => {
                                setLocation(e.value);
                                }} />
                            </FormGroup>
                            <hr className="my-4" />
                    <hr className="my-4" />
                    <FormGroup xs={12}>
                        <Button
                            color="primary"
                            // href="#pablo"
                            variant="contained"
                            sx={{ mt: "25px" }}    
                            onClick={editUser}
                            //size="sm"
                            disabled={isTestUser}
                            >
                            Sauvegarder
                        </Button>
                    </FormGroup>

                    </Form>
                </CardBody>
                </Card>



          </Col>
    </Row> 
    </Container>
    </>
  );
};

export default OutFranceCandidateParametersView;
