import axios from "axios";
import React, { useEffect, useState } from "react";

//import OneInterview from "components/recruteur/OneInterview";
import { useHistory, useLocation, Link } from "react-router-dom";
import qs from "query-string";
//import ShowCandidat from "./ShowCandidat";
import {
  PencilAltIcon,
  LocationMarkerIcon,
  BriefcaseIcon,
  MailIcon,
  PhoneIcon,
  LinkIcon,
  CodeIcon,
  ArchiveIcon,
  OfficeBuildingIcon,
  PaperClipIcon,
  AcademicCapIcon,
  UserIcon,
  LightBulbIcon,
} from "@heroicons/react/solid";
import { SearchIcon } from "@heroicons/react/outline";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
  CardText,
  CardTitle,
  CardImg,
  Col,
  Row,
} from "reactstrap";
//import {getAll} from "../../network/ApiAxios";
import config from "config";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});
function CompCandidats(participants) {
  const [candidats, setCandidats] = useState([]);

  const [users, setUsers] = useState([]);

  const [job, setJob] = useState({});
  const history = useHistory();
  const location = useLocation();
  const query = qs.parse(location.search);
  const [search, setSearch] = useState("");
  const [candidat, setCandidat] = useState("");
  const [checkbox, setCheckbox] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [candidatID, setCandidatID] = useState("");
  const removeCandidat = (id) => {
    instance
      .delete(`jobs/candidat/${id}`)
      .then((res) => {
        fetchData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchData = () => {
    instance
      .get("jobs/candidats")
      .then((res) => {
        setCandidats(res.data);
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });
  };

  //   useEffect(() => {
  //       console.log("job id:", jobId.jobId )
  //     axios.get(`http://localhost:5100/api/jobs/${jobId.jobId }`).then((resp) => {
  //       setJob(resp.data.job);

  //     });
  //   }, [jobId]);
  console.log("partcicpants", participants);
  return (
    <>
      <div
        className="flex items-center my-3 px-2 py-2 border-2 mx-auto rounded-full border-gray-400 max-w-md"
        style={{ float: "right" }}
      >
        <input
          type="text"
          className="bg-transparent flex-grow text-black px-2"
          placeholder="Search..."
          onChange={(e) => setSearch(e.target.value)}
        />
        <SearchIcon className="h-6 text-blue-500" />
      </div>
      <Card className="bg-transparent w-full">
        <Row>
          {participants.participants
            ?.filter((participant) => {
              if (search === "") {
                return participant;
              } else if (
                participant.candidat.name
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                participant.candidat.lastName
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                participant.candidat.email
                  .toLowerCase()
                  .includes(search.toLowerCase())
              ) {
                return participant;
              }
            })
            .map((participant) => (
              <>
                <Col lg="6" key={participant._id}>
                  <Card>
                    <CardImg
                      variant=""
                      className="rounded-circle"
                      style={{
                        width: "200px",
                        height: "200px",
                        alignSelf: "center",
                        objectFit: "cover",
                      }}
                      alt="Responsive image"
                      src={`${config.Data_URL}${participant?.candidat?.image}`}
                    />
                    <CardBody>
                      <CardTitle className="text-center">
                        {participant?.candidat?.name}{" "}
                        {participant?.candidat?.lastName}
                      </CardTitle>
                      <div className=" " style={{ display: "inline-flex" }}>
                        <MailIcon className="h-6 pr-2" />
                        {participant?.candidat?.email}
                      </div>
                      <hr className="my-2" />
                      <div className=" " style={{ display: "inline-flex" }}>
                        <AcademicCapIcon className="h-6 pr-2" />
                        Diplome: {participant?.candidat?.studyLevel}
                      </div>
                      <hr className="my-2" />
                      <div className=" " style={{ display: "inline-flex" }}>
                        <LightBulbIcon className="h-6 pr-2" />
                        Expérience: {participant?.candidat?.yearsOfExperience}
                      </div>
                      <hr className="my-2" />
                      <div>
                        <Button
                          style={{
                            width: "100%",
                            backgroundColor: "#404954",
                            color: "white",
                          }}
                          color=""
                          href={`${config.Data_URL}${participant?.CV}`}
                          target="blank"
                        >
                          Voir CV
                        </Button>
                      </div>
                      <hr className="my-2" />
                      <div>
                        <Button
                          style={{
                            width: "100%",
                            backgroundColor: "#404954",
                            color: "white",
                          }}
                          color=""
                          href={`${participant?.codeLink}`}
                          target="blank"
                        >
                          Code
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </>
            ))}{" "}
        </Row>
      </Card>
    </>
  );
}

export default CompCandidats;
