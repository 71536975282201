import {
  ViewListIcon,
  ArrowCircleDownIcon,
  PencilAltIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/outline";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import JobApplicationNoteIntroduction from "components/employer/jobApplicationNoteIntroduction";

import { Row, Button, Col, Card, CardImg, FormGroup, Input, InputGroup, InputGroupAddon, CardHeader, Table, CardBody, Form, Label } from "reactstrap";

import "../custom.css";
import config from "config";

// import 'assets/css/react_pdf_css.css';

// import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

// pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;




const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});
function JobApplicationNote(props) {
  const history = useHistory();



  const [jobApplicationNote, setJobApplicationNote] = useState({});
  const [jobID, setJobID] = useState("");
  const [jobApplicationID, setJobApplicationID] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [job, setJob] = useState({});
  const [createdAt, setCreatedAt] = useState("");



const getNote = () => {
    instance.get(`jobApplicationNotes/getJobApplicationNoteByID/${props.jobApplicationNoteID}`).then((resp) => {
      if (resp.data !== null) {
            // console.log("8888888888888888888888: geNote: step 2:");
            // console.log("8888888888888888888888: geNote: step 3 : resp: ", resp);
            // console.log("8888888888888888888888: geNote: step 4 : resp.data: ", resp.data);
            setJobApplicationNote(resp.data);
            setJobID(resp.data.jobID);
            setJobApplicationID(resp.data.jobApplicationID);
            if (resp.data.createdAt)
            {
              setCreatedAt(resp.data.createdAt);

            }
            
          
        
      }
    });
};

const fetchJobData = () => {
  // console.log("ddddddddddddddddddddddddddddddddddd: fetchJobData: step 2:");
  // console.log("ddddddddddddddddddddddddddddddddddd: fetchJobData: step 3 : props.actualJobApplicationID: ", props.actualJobApplicationID);
  // console.log("ddddddddddddddddddddddddddddddddddd: fetchJobData: step 3 : jobApplicationID: ", jobApplicationID);
  if (jobID !=="") {
    instance.get(`jobs/${jobID}`).then((resp) => {
      if (resp.data !== null) {
        if ((resp.data.job !== null)) {
          if ((resp.data.job.title !== null)) {
          setJob(resp.data.job);
          if ((props.actualJobApplicationID !== jobApplicationID) && jobApplicationID !== "" )
          {
            setJobTitle("Offre: " + resp.data.job.title + ": ");

          }
          
          // console.log("5555555555555555555555555555555555: fetchJobData: step 2:");
          // console.log("5555555555555555555555555555555555: fetchJobData: step 3 : resp: ", resp);
          // console.log("5555555555555555555555555555555555: fetchJobData: step 4 : resp.data: ", resp.data);
          // console.log("5555555555555555555555555555555555: fetchJobData: step 4 : resp.data.job.title: ", resp.data.job.title);
          }
        }
      }
    });

  }
};



   useEffect(() => {
    getNote();
    fetchJobData();

  
  }, [jobID, jobApplicationID]);






  return (
    <>
      <Row key={props.jobApplicationNoteID}>
        <Col lg="12">
          <FormGroup row>
          {jobApplicationNote && createdAt !== "" &&
            <>
              <JobApplicationNoteIntroduction
                recruiterUserID={jobApplicationNote.recruiterID}
              />
              <br />

                <Input
                  type="textarea"
                  rows="5"
                  readOnly
                  value={jobApplicationNote.note || ""}
              >
              </Input>

              {/*
                <Label>
                  {jobApplicationNote.note}
                </Label>
            */}

              <br />
              <Label>
                  {jobTitle} {createdAt.slice(0, 10)}
              </Label>
            </>
            }
          {jobApplicationNote && createdAt === "" &&
                <Label 
                className="px-lg-3"
                >
                  {jobTitle} {jobApplicationNote.note}
              </Label>

          }

          <hr className="my-4" />

          </FormGroup>
        </Col>
      </Row>
    </>
  );
}

export default JobApplicationNote;
