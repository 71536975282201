import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { options_FrCandidate_videosPermission } from 'options';
import PageSpace from "components/styles/pageSpace";
import PageTitleStyle from "components/styles/PageTitleStyle";


// reactstrap components
import {
  Container,
  CardHeader,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Button,
  Alert,
} from "reactstrap";
import Select from "react-select";

// core components
import { edit } from "../../network/ApiAxios";
import axios from "axios";
import config from "config";
import InFrCandidateInvitationsAccessRequests from "components/candidat/InFrCandidateInvitationsAccessRequests";
import CandidateLayoutV3 from "layouts/CandidateLayoutV3";


// const https = require('https');
//
// const agent = new https.Agent({
//     rejectUnauthorized: false,
// });

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});

const InFranceCandidatePreferencesView = (props) => {
    const [InFrVideosPermission, setInFrVideosPermission] = useState("");
    const [msg,setMsg] = useState('')
    let user = JSON.parse(localStorage.getItem("user"));



  

  useEffect(() => {
    if (user.InFrVideosPermission)
    {
      setInFrVideosPermission(user.InFrVideosPermission);
    }
  }, []);

  var data = JSON.stringify({
    "userID": user._id,
    "InFrVideosPermission": InFrVideosPermission
  });

    const editCandidate = () => {
      setMsg('')
  
    axios({
        method: "post",
        url: `${config.WS_BASE_URL}users/editUserParameters`,
        data: data,
        headers: { "Content-Type": "application/json" },
      })
    .then((res) => {
        if (res.data.success) {
                user.InFrVideosPermission = InFrVideosPermission;
                localStorage.setItem("user", JSON.stringify(user));
                setMsg('Votre Préference a été sauvegardée avec succès')
        }
    }
        )
        .catch((err) => console.log(err));

  };

  return (
    <>
      <CandidateLayoutV3 />
      <Container>
      <PageSpace />
      <PageTitleStyle
        title="Préferences"
      >
        
      </PageTitleStyle>

      <Row className="justify-content-center border-0 py-2" style={{width:"100%" }}>
          <Col xs="2" >
          </Col>
          <Col xs="10">
            <Card className="bg-secondary shadow">
                <CardBody className="bg-white">
                    <Form>
                    <FormGroup xs={12}>
                    <label className="px-lg-3">Pour que votre candidature soit prise en compte, vous devez passer un entretien vidéo, est ce que vous acceptez que les entreprises en France accèdent à ces vidéos*</label>
                    <Select  
                        name="videosPermission"
                        required
                        className="w-full "
                        options={options_FrCandidate_videosPermission}
                        value = {
                            options_FrCandidate_videosPermission.filter(option => 
                              option.value === InFrVideosPermission)
                         }
                         // placeholder={InFrVideosPermission}
                         placeholder= {
                            options_FrCandidate_videosPermission.filter(option => 
                              option.value === InFrVideosPermission)
                         }
                        //fullWidth 
                        //onBlur={handleBlur} 
                        // onChange={handleChange} 
                        //value={NiveauDiplome}
                        onChange={(e) => { setInFrVideosPermission(e.value) }} 
                        // value={values.NiveauDiplome || ""} 
                        //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                        //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                    />
                    </FormGroup>
                    <hr className="my-4" />
                    <FormGroup xs={12}>
                        <Button
                            color="primary"
                            // href="#pablo"
                            variant="contained"
                            sx={{ mt: "25px" }}    
                            onClick={editCandidate}
                            //size="sm"
                            // disabled={isTestUser}
                            >
                            Sauvegarder
                        </Button>
                    </FormGroup>

                    </Form>
                    {msg && <Alert className='text-center w-50' color='success'>{msg}</Alert>}
                </CardBody>
                </Card>



          </Col>
    </Row>

    {user?.InFrVideosPermission === "No" && <>
      <PageSpace />
      <PageTitleStyle
        title="Les demandes d’accès reçues"
      >
        
      </PageTitleStyle>
      <InFrCandidateInvitationsAccessRequests user={user}/>
    </>}

    </Container>
    </>
  );
};

export default InFranceCandidatePreferencesView;
