import {
  ViewListIcon,
  ArrowCircleDownIcon,
  PencilAltIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/outline";
import JobApplicationTitle from "components/employer/jobApplicationTitle";
import JobApplicationNote from "components/employer/jobApplicationNote";
import JobAppGeneralAnalysis from "components/employer/jobAppGeneralAnalysis";
import JobAppScoreReport from "components/employer/jobAppScoreReport";
import JobAppFormForOutFrProfile from "components/employer/jobAppFormForOutFrProfile";
import ChangeCompetitionSpecifications from "components/recruteur/ChangeCompetitionSpecifications";

import {options_jobApplicationPipeline} from "options";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import VideoPlayer from "react-video-js-player";
import DownloadLink from "react-download-link";
import { ClipLoader } from "react-spinners";
import { 
  Container, 
  Button, 
  Col, 
  Card, 
  CardImg, 
  FormGroup, 
  InputGroup, 
  InputGroupAddon, 
  CardHeader, 
  Table, 
  CardBody, 
  Form,
  Input,
} from "reactstrap";
import Select from "react-select";
import { Grid, TextField } from "@mui/material";

import { UserIcon } from "@heroicons/react/outline";
import "../custom.css";
import Row from "reactstrap/lib/Row";
import config from "config";

import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
// import 'assets/css/react_pdf_css.css';

// import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

// pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;




const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});
function SpecificationsForOneCompetitionEmployer(props) {
  const history = useHistory();
  // console.log("11111111111111111111111111111: ShowApplicationEmployerV4");
  // console.log("11111111111111111111111111111: ShowApplicationEmployerV4: xxx: ", xxx);

  

  
  // const [candidat, setCandidat] = useState("");
  // const [jobApplicationCV, setJobApplicationCV] = useState("");

  // const [jobApplicationForm, setJobApplicationForm] = useState("");
  const [profileFormCV, setProfileFormCV] = useState("");


  
  
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
  const user = JSON.parse(localStorage.getItem("user"));

	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};

	const goToPrevPage = () =>
		setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

	const goToNextPage = () =>
		setPageNumber(
			pageNumber + 1 >= numPages ? numPages : pageNumber + 1,
		);

















   useEffect(() => {
  }, []);








  return (
    <>
      <Container fluid>
        <ChangeCompetitionSpecifications
          competitionID = {props.competitionID}
        />

        {config.Environment === "Dev" &&
            <>
              <Row>
                <label style={{ marginRight: '.5rem' }}>Précédent</label>
                <label style={{ marginLeft: '.5rem' }}>Suivant</label>

              </Row>
              <Row>
                <hr className="my-2" />
              </Row>

              <Row>
              <img
                src={"assets/img/brand/500.png"}
                onError={(e) => {
                  e.target.src =
                    require("assets/img/brand/500.png").default;
                }}
            />
              </Row>

          
            {/*
            <div className="flex justify-center items-center top-0 left-0 z-50 w-full h-full opacity-100">
            <Document file={`assets/pdf/1648553251-CV-Mamadou-Fode-Bailo-DIALLO.pdf`} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} />
          </Document>
          </div>
            */}


          
          </>
        }
        {config.Environment !== "Dev" && props.specificationsPDF &&
          <>
            <Row>
              <nav>
              <Button style={{ marginRight: '.5rem' }} onClick={goToPrevPage}>Précédent</Button>
              <Button style={{ marginLeft: '.5rem' }} onClick={goToNextPage}>Suivant</Button>
              <p>
                Page {pageNumber} of {numPages}
              </p>
            </nav>

            </Row>
            <Row>
                <hr className="my-2" />
            </Row>
            <Row>
              <Document file={`${config.Data_URL}${props.specificationsPDF}`} onLoadSuccess={onDocumentLoadSuccess}>
                  <Page pageNumber={pageNumber} />
              </Document>
              {/*
                <div className="flex justify-center items-center top-0 left-0 z-50 w-full h-full opacity-100">

                </div>

              */}

            </Row>
          </>
        }
          {config.Environment !== "Dev" && !props.specificationsPDF &&
          <>
            <p>Pas de cahier de charge déposé.</p>    
          </>
        }

      

      </Container>
    </>
  );
}

export default SpecificationsForOneCompetitionEmployer;
