import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { 
  Container, 
  Row,
  Button, 
  Col, 
  Card, 
  CardImg, 
  FormGroup, 
  InputGroup, 
  InputGroupAddon, 
  CardHeader, 
  Table, 
  CardBody, 
  Form,
  Input,
} from "reactstrap";
import Select from "react-select";
import { Grid, TextField } from "@mui/material";

import { UserIcon } from "@heroicons/react/outline";
import "../custom.css";
import config from "config";






const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});
function UserAccountInfo( props) {
  const history = useHistory();
  const [candidateInfos, setCandidateInfos] = useState("");

  



  
  
  const user = JSON.parse(localStorage.getItem("user"));




  const fetchCandidateInfo = async () => {
    // console.log("0000000000000000000000000000: fetchCandidateInfo: ");
    // console.log("0000000000000000000000000000: fetchCandidateInfo: props.candidateUserID: ", props.candidateUserID);
    
    instance.get(`/users/getprofilebyuserid/${props.candidateUserID}`).then((resp) => {
      // setJobApplicationForm(resp.data);
      // console.log("1111111111111111111111111111: UserAccountInfo: fetchCandidateInfo: ");
      // console.log("1111111111111111111111111111: UserAccountInfo: fetchCandidateInfo: resp: ", resp);
      // console.log("1111111111111111111111111111: UserAccountInfo: fetchCandidateInfo: resp.data: ", resp.data);
      setCandidateInfos(resp.data);
      });
};





   useEffect(() => {
    fetchCandidateInfo();
  
  }, []);







  return (
    <>
        <Row>

          <Form>
            <h4 className=" mb-4">Fiche</h4>
            
            <p className=" mb-4">Prénom: {candidateInfos.name}</p>
            <p className=" mb-4">Nom: {candidateInfos.lastName}</p>
            <p className=" mb-4">Pays: {candidateInfos.location}</p>
            <p className=" mb-4">Email: {candidateInfos.email}</p>
            
            <p className=" mb-4">Whatsapp: {candidateInfos.whatsappnumber}</p>
            <p className=" mb-4">Linkedin: {candidateInfos.linkedin}</p>
            <p className=" mb-4">Téléphone: {candidateInfos.phone}</p>

            {candidateInfos.linkedin &&
            <>
              <p className=" mb-4">Linkedin: {candidateInfos.linkedin}</p>
              
                <Button
                type="button"
                className="mb-4 shadow-xl text-white"
                style={{backgroundColor:"#11a0ef"}}
                href={candidateInfos.linkedin}
                target="blank"

                // onClick={() => setModalOpen(!modalOpen)}
              >
                URL du profil Linkedin:

              </Button>
            </>
            }

          </Form>
                

 
        </Row>
      
    </>
  );
}

export default UserAccountInfo;
