import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { confirmRegister } from "../../network/ApiAxios";
import { Card, CardBody, Col, Container } from "reactstrap";
import qs from "query-string";
import {useLocation } from "react-router-dom";


const ConfirmEmail = props => {

    const { id } = useParams();
    const [valid, setValid] = useState(true);
    const location = useLocation();
    const query = qs.parse(location.search);
    const {jobId, redirect} = query;
    console.log('jobId', jobId)
    console.log('redirect',redirect)

    useEffect(() => {
        if (!id) {
            setValid(false);
        } else {
            const runAsync = async () => {
                const response = await confirmRegister(id);
                const { data } = response;
                console.log(data);
                if (!data.success) {
                    setValid(false);
                } else {
                    setTimeout(() => {
                        props.history.push(`/home/login?jobId=${jobId}&redirect=${redirect}`);
                    }, 5000); 
                }
            }
            runAsync();
        }
    }, [])

    return (
        <>
            <Container style={{ height: '700px'}} className='d-flex justify-content-center align-items-center' >
                <Col lg="6" md="8">
                    <Card 
                    className="bg-secondary shadow"
                    color="primary"
                    //outline
                    >
                        <CardBody className="px-lg-5 py-lg-5">
                            <div className="text-center mb-4">
                                <h1>{valid ?
                                    `Email confirmed ✅
                            You will be redirected to login ...`
                                    : "Something went wrong!"}</h1>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Container>
        </>
    )
};

export default ConfirmEmail;
