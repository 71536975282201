import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
// reactstrap components
import {
  Label,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { editUserCompany } from "../../network/ApiAxios";
import config from "config";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});
const EditUser = (props) => {
  const { t, i18n } = useTranslation();

  const [user, setUser] = useState();
  const [userID, setUserID] = useState();
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [accessLevel, setAccessLevel] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const history = useHistory();


  const getUser = () => {
    instance.get(`users/getprofilebyuserid/${props.userID}`).then((resp) => {
      // console.log("function editUser : resp: ", resp);
      setUser(resp.data);
      setUserID(resp.data._id);
      setName(resp.data.name);
      setLastName(resp.data.lastName);
      setEmail(resp.data.email);
      if (resp.data.accessLevel) { setAccessLevel(resp.data.accessLevel) }
      else { setAccessLevel("Standard") }
    });
  };

  useEffect(() => {
    getUser();
  }, [props.userID]);

  const editUser = async () => {
    if (!(password && confirmPassword)) {
      setError(
        "Mot de passe nécessaire ! veuillez saisir un nouveau pour cet utilisateur"
      );
      return;
    }

    if (password !== confirmPassword) {
      setError("Les mots de passe ne correspondent pas");
      return;
    }
    await editUserCompany({ userID, name, lastName, password, accessLevel })
      .then(resp => {
        console.log("edit user.js editUserCompany");
        console.log("edit user.js editUserCompany resp: ", resp);
        if (resp.data.success) {
          //window.location.reload();
          props.runAsync()
          props.toggle()
        }
        if (!resp.data.success) {
          setError(resp.data.msg)
        }
      })

    // await instance.post(`editUser/${props.userID}`, {name, email, role, password})
  }


  return (
    <>
      <Col lg="10" md="8" className="mx-auto mb-4 mt-4">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form">
              <FormGroup>
                <Label>{t('firstName')}</Label>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-hat-3" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder={t('firstName')}
                    type="text"
                    value={name || ""}
                    onChange={(e) => setName(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <Label>{t('lastName')}</Label>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-hat-3" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder={t('lastName')}
                    type="text"
                    autoComplete="Nouveau Nom"
                    value={lastName || ""}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <Label>Email</Label>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>

                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    value={email || ""}
                    //onChange={(e) => setEmail(e.target.value)}
                    disabled
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <Label>{t('Role')}</Label>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend"></InputGroupAddon>

                  <Select
                    Value={accessLevel}
                    placeholder={accessLevel}
                    className={`w-full `}
                    options={[
                      { value: "Administrator", label: "Administrator" },
                      { value: "Standard", label: "Standard" },
                    ]}
                    onChange={(e) => {
                      // console.log("eeee", e.value);
                      setAccessLevel(e.value);
                    }}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <Label>{t('password')}</Label>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <Label>{t('PasswordConfirmation')}</Label>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Confirm Password"
                    type="password"
                    autoComplete="new-password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              {error && (
                <div className="text-muted font-italic">
                  <small>
                    <span className="text-red font-weight-700">{error}</span>
                  </small>
                </div>
              )}

            
              <div className="text-center">
                <Button
                  onClick={editUser}
                  className="mt-4"
                  style={{
                    backgroundColor: "#404954",
                    borderColor: "#404954",
                    color: "white",
                  }}
                  type="button"
                >
                  {t('save')}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default EditUser;
