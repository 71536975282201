import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { 
  Container, 
  Row,
  Button, 
  Col, 
  Card, 
  CardImg, 
  FormGroup, 
  InputGroup, 
  InputGroupAddon, 
  CardHeader, 
  Table, 
  CardBody, 
  Form,
  Input,
} from "reactstrap";
import Select from "react-select";
import { Grid, TextField } from "@mui/material";

import { UserIcon } from "@heroicons/react/outline";
import "../custom.css";
import config from "config";
import { useTranslation } from "react-i18next";






const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});
function JobAppFormForOutFrProfile( props) {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  

  const [jobApplicationForm, setJobApplicationForm] = useState("");
  const [jobApplicationFormWhatsappNb, setJobApplicationFormWhatsappNb] = useState("");
  const [jobApplicationFormWhatsappNbFiltered, setJobApplicationFormWhatsappNbFiltered] = useState("");


  
  
  const user = JSON.parse(localStorage.getItem("user"));






  const fetchForm = async () => {
    // console.log("0000000000000000000000000000:");
    // console.log("1111111111111111111111111111: jobApplicationUserID: ", jobApplicationUserID);
    
    instance.get(`/outFrSearchCdiFrProfiles/getprofilebyuserid/${props.jobApplicationUserID}`).then((resp) => {
      setJobApplicationForm(resp.data);
      setJobApplicationFormWhatsappNb(resp.data.whatsappnumber);
      let input = resp.data.whatsappnumber;
      let input_filtered = input.replace(/[|&;$%@"<>()+,]/g, "");
      setJobApplicationFormWhatsappNbFiltered(input_filtered);
      console.log("33333333333333333333: fetchForm: input_filtered", input_filtered);
      // console.log("33333333333333333333: fetchForm: resp: ", resp);
      // console.log("33333333333333333333: fetchForm: resp.data: ", resp.data);
      });
};








   useEffect(() => {
    fetchForm();
  
  }, []);







  return (
    <>
        <Row>

                {jobApplicationForm &&
                  <Form>
                    <h4 className=" mb-4">{t('Form')}</h4>
                    
                    <p className=" mb-4">{t('firstName')}: {jobApplicationForm.firstname}</p>
                    <p className=" mb-4">{t('lastName')}: {jobApplicationForm.lastname}</p>
                    <p className=" mb-4">{t('Gender')}: {jobApplicationForm.gender}</p>
                    <p className=" mb-4">{t('FamilyStatus')}: {jobApplicationForm.familySituation}</p>
                    <p className=" mb-4">Age: {jobApplicationForm.age}</p>
                    <p className=" mb-4">Email: {jobApplicationForm.email}</p>
                    <p className=" mb-4">Whatsapp: {jobApplicationForm.whatsappnumber}</p>
                    <Button
                      type="button"
                      className="mb-4 shadow-xl text-white"
                      style={{backgroundColor:"#11a0ef"}}
                      href={`https://wa.me/${jobApplicationFormWhatsappNbFiltered}`}
                      target="blank"
  
                      // onClick={() => setModalOpen(!modalOpen)}
                    >
                      URL Whatsapp
  
                    </Button>
                    {jobApplicationForm.linkedin &&
                    <>
                      <p className=" mb-4">Linkedin: {jobApplicationForm.linkedin}</p>
                      
                        <Button
                        type="button"
                        className="mb-4 shadow-xl text-white"
                        style={{backgroundColor:"#11a0ef"}}
                        href={jobApplicationForm.linkedin}
                        target="blank"

                        // onClick={() => setModalOpen(!modalOpen)}
                      >
                        URL {t('Profile')} Linkedin:

                      </Button>
                    </>
                    }
                    {!jobApplicationForm.linkedin &&
                      <p>{t('FormMSG1')}</p>
                    }


                    <p className=" mb-4">{t('FormMSG2')}? {jobApplicationForm.bacObtained}</p>
                    <p className=" mb-4">{t('diploma')}: {jobApplicationForm.diploma}</p>
                    <p className=" mb-4">{t('expertise')}: {jobApplicationForm.expertiseField}</p>
                    <p className=" mb-4">{t('FormMSG3')}: {jobApplicationForm.technicalProfileOrNot}</p>
                    <p className=" mb-4">{t('ProfilteTypeTitle')}: {jobApplicationForm.profileType}</p>
                    <p className=" mb-4">{t('FormMSG4')}: {jobApplicationForm.desiredProject}</p>
                    <p className=" mb-4">{t('Experience')}: {jobApplicationForm.experience}</p>
                    <p className=" mb-4">{t('FormMSG5')}: {jobApplicationForm.permanentContractExperience}</p>
                    <p className=" mb-4">Top 3 technologies: {jobApplicationForm.topTechnologies}</p>
                    <p className=" mb-4">Certifications: {jobApplicationForm.certifications}</p>
                    <p className=" mb-4">{t('FormMSG6')}: {jobApplicationForm.certificationLevel}</p>
                    <p className=" mb-4">{t('FormMSG7')}: {jobApplicationForm.actualContract}</p>
                    <p className=" mb-4">{t('Mobility')}: {jobApplicationForm.mobility}</p>
                    <p className=" mb-4">{t('FormMSG8')}: {jobApplicationForm.baf}</p>
                    <p className=" mb-4">{t('Title')}: {jobApplicationForm.title}</p>
                    <p className=" mb-4">{t('LocationCity')}: : {jobApplicationForm.village}</p>
                    <p className=" mb-4">{t('Country')}: {jobApplicationForm.country}</p>
                    <p className=" mb-4">{t('StudyLevel')}: {jobApplicationForm.studyLevel}</p>
                    <p className=" mb-4">{t('university')}: {jobApplicationForm.university}</p>
                    <p className=" mb-4">{t('Phone')}: {jobApplicationForm.phonenumber}</p>
                    <p className=" mb-4">Date: {jobApplicationForm.date}</p>
                  </Form>
                }
                {!jobApplicationForm &&
                  <p>{t('FormMSG9')}</p>
                }
 
        </Row>
      
    </>
  );
}

export default JobAppFormForOutFrProfile;
