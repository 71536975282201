import axios from "axios";
import Questions from "components/recruteur/Questions";
import UpdateForm from "components/UpdateForm";
import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  PencilAltIcon,
  LocationMarkerIcon,
  BriefcaseIcon,
  MailIcon,
  PhoneIcon,
  LinkIcon,
  CodeIcon,
  ArchiveIcon,
  OfficeBuildingIcon,
  PaperClipIcon,
  AcademicCapIcon,
  UserIcon,
  LightBulbIcon,
} from "@heroicons/react/solid";
import {
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormText,
  FormFeedback,
  CardText,
} from "reactstrap";
import Offres from "components/recruteur/jobs";
import config from "config";
import Header from "components/Headers/Header";


import { Box } from "@mui/material";
import CandidateLayoutV2 from "layouts/CandidateLayoutV2";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});
function CandidateHomeViewV2({ jobId }) {
  const history = useHistory();
  const [job, setJob] = useState({});
  const [created, setCreated] = useState("");
  const [endDate, setEndDate] = useState("");
  const [interviewUrl, setInterviewUrl] = useState("");
  const [affiche, setAffiche] = useState({
    update: false,
    questions: false,
  });

  const [openTab, setOpenTab] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalPostul, setModalPostul] = useState(false);
  const [CVinput, setCVinput] = useState(false);
  const [CV, setCV] = useState("");
  const [candidatID, setCandidatID] = useState("");
  const [pathJob, setPathJob] = useState("");
  const [pathComp, setPathComp] = useState("");

  const [data1, setData1] = useState([]);
  const [data, setData] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [competitions, setCompetitions] = useState([]);
  const fetchData = async () => {
    instance.get("jobs").then((resp) => {
      setJobs(resp.data);
      const data = resp.data;
      const DATA = [];
      console.log("data", data);
      if (data.length >= 3) {
        for (let i = data.length - 1; i >= data.length - 3; i--) {
          DATA.push(data[i]);
        }
      } else {
        for (let i = data.length - 1; i >= 0; i--) {
          DATA.push(data[i]);
        }
      }
      setData(DATA);
    });
  };
  const fetchData1 = async () => {
    instance.get("competitions").then((resp) => {
      setCompetitions(resp.data);
      const data = resp.data;
      const DATA = [];
      console.log("data", data);
      if (data.length >= 3) {
        for (let i = data.length - 1; i >= data.length - 3; i--) {
          DATA.push(data[i]);
        }
      } else {
        for (let i = data.length - 1; i >= 0; i--) {
          DATA.push(data[i]);
        }
      }
      setData1(DATA);
    });
  };
  useEffect(() => {
    fetchData();
    fetchData1();
    handlePath();
    
  }, [pathJob, pathComp]);

  const deleteJob = () => {
    instance.delete(`jobs/${jobId}`).then(() => {
      history.push("/admin/tab2");
    });
  };
  const handlePath = () => {
    if (JSON.parse(localStorage.getItem("user"))) {
      switch (JSON.parse(localStorage.getItem("user")).role) {
        case "employer":
          setPathJob("/admin/tab2");     
          setPathComp("/admin/tab2comp");  
          break;    
        case "candidat":
          setPathJob("/infrancecandidate/apply");   
          setPathComp("/candidat/tab2comp");         
      }
    } else {
      setPathJob("/home/jobs");    
      setPathComp("/home/competitions");    
    }
  };

  return (
    <>
      
      {JSON.parse(localStorage.getItem("user")) && <CandidateLayoutV2 />}

      <Box sx={
        { 
          ml: 32,
          p: "30px" 
        }
        }>
        
       
        <Container fluid>
          <Row className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"></Row>
          <Row>
          <Col>
            <div
              className="homePageStyle"
            style={{
                  //backgroundColor: "#f7fafc",
                  //objectFit: "cover",
                  justifyContent:'center',
                  alignItems:'center',
                  width: "100%",
                  height: "75%",
                  paddingRight: 0,
                  paddingLeft: 0,
              }}
            
            
            >
              <h2>Trouvez le job qui vous convient</h2>           
            </div>
            </Col>
            </Row>
          <Row>
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4"></Col>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-Card border-0">
                  <Row className="align-items-center">
                    <h3 className=" mb-2 ml-3 uppercase card-t">
                      Offres d'emploi
                    </h3>
                  </Row>
                </CardHeader>
                <CardBody className="bg-white">
                  {data.map((job) => (
                    <Row
                      className=" px-2 py-3 mb-3 flex  hover:black hover:border hover:border-black cursor-pointer duration-75 hover:z-20 bg-white shadow-lg rounded-sm"
                      key={job._id}
                      onClick={()=> 
                        {history.push(`${pathJob}?jobId=${job._id}`);
                      }}
                    >
                      <Col lg="2">
                        <img
                          src={`${config.Data_URL}${job.image}`}
                          onError={(e) => {
                            e.target.src =
                              require("assets/img/brand/500.png").default;
                          }}
                          style={{
                            width: "120x",
                            height: "120px",

                            objectFit: "cover",
                          }}
                          alt="Responsive image"
                        />
                      </Col>
                      <Col lg="8">
                        <div className="flex items-center space-x-3 mb-2">
                          <div>
                            <h3 className="card-t">{job.title}</h3>
                            <span
                              className="text-sm"
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitLineClamp:
                                  "2" /* number of lines to show */,
                                WebkitBoxOrient: "vertical",
                              }}
                            >
                              {job.description}
                            </span>
                          </div>
                        </div>
                        <Row>
                          <Col lg="6">
                            <div style={{ display: "inline-flex" }}>
                              {" "}
                              <ArchiveIcon
                                className=""
                                style={{ height: "1.2rem" }}
                              />{" "}
                              &nbsp; <span> {job.technologies}</span>{" "}
                            </div>
                          </Col>
                          <Col lg="6">
                            <div style={{ display: "inline-flex" }}>
                              {" "}
                              <PaperClipIcon
                                className=""
                                style={{ height: "1.2rem" }}
                              />{" "}
                              &nbsp; <span> {job.contract}</span>{" "}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <div style={{ display: "inline-flex" }}>
                              {" "}
                              <OfficeBuildingIcon
                                className=""
                                style={{ height: "1.2rem" }}
                              />{" "}
                              &nbsp; <span> {job.entreprise}</span>{" "}
                            </div>
                          </Col>
                          <Col lg="6">
                            <div style={{ display: "inline-flex" }}>
                              {" "}
                              <LocationMarkerIcon
                                className=""
                                style={{ height: "1.2rem" }}
                              />{" "}
                              &nbsp; <span> {job.location}</span>{" "}
                            </div>
                          </Col>
                        </Row>
                        {/* <div>
                              Status:{" "}
                              <span
                                className={`${
                                  job.status === "inactive"
                                    ? "text-red-600"
                                    : "text-green-600"
                                }`}
                              >
                                {job.status}
                              </span>
                            </div>
                            <div>
                              Updated on:{" "}
                              {`${job.updatedAt.slice(
                                0,
                                10
                              )} ${job.updatedAt.slice(11, 19)}`}
                            </div> */}
                      </Col>
                      <Col lg="2" className=" flex items-center">
                        <div>
                          <div className="text-center">
                            {job?.createdAt.slice(0, 10)}
                          </div>
                          <Button type="button" color="primary" onClick={()=> 
                            {history.push(`${pathJob}?jobId=${job._id}`);
                          }}>
                            Postuler
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  ))}
                </CardBody>
                <CardFooter className="align-items-center ">
                  <Row className="align-items-center "
                  
                    style={{
                      //width: "",
                      justifyContent:'center',
                      alignItems:'center',
                    }}

                  >
                    <Button
                      className="mx-auto"
                      //color="danger"
                      color="primary"
                      type="button"
                      to={pathJob}
                      tag={Link}
                    >
                      Voir toutes les offres
                    </Button>
                  </Row>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          {/* <div className="mt-3 mb-3" >
                <img
                alt="..."
                className="shadow-lg"
                  src={require("assets/img/brand/hire.jpg").default}
                  style={{width:"100%"}}
                >
                  
                </img>
              </div> */}
        
        </Container>

      </Box> 

    </>
  );
}

export default CandidateHomeViewV2;
