import axios from "axios";

import Header from "components/Headers/Header";
import CandidatHorsFrLayout from "layouts/CandidatHorsFrLayout";
import { H2, H3 } from "components/Typography";

import Offres from "components/recruteur/jobs";
import CandidatOffres from "components/candidat/CandidatOffres";
import { Input } from "reactstrap";
import Select from "react-select";

// import { Grid } from "@mui/material";
import { Container, Box, Button, Card, Grid, MenuItem, TextField } from "@mui/material";
import * as yup from "yup";
import { useHistory, useLocation } from "react-router-dom";
//import { DropzoneArea } from 'material-ui-dropzone';
//import {useDropzone} from 'react-dropzone';

import { Formik } from "formik";
import {useCallback} from 'react';
import React, { useEffect, useState } from "react";
import config from "config";
import { options_profile_categories, options_CCNP, options_CCNA_NetSec, options_Cisco_NetSec, options_Competency_degree, options_profile_change_SR } from 'options';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import FlexBox from "components/FlexBox";import CandidatOutFrNavbar from "components/Navbars/CandidatOutFrNavbar";
import SidebarCandidatHorsFr from "components/Sidebar/SidebarCandidatHorsFr";
import SidebarCandidatHorsFrV2 from "components/Sidebar/SidebarCandidatHorsFrV2";
import OutFrFreelanceterminateYourProfileBefore from "components/outFrCandidatSearchCdiFr/outFrFreelanceterminateYourProfileBefore";




const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});

const options_niveau_diplome = [
  { value: 'BAC', label: 'BAC' },
  { value: 'BTS', label: 'BTS' },
  { value: 'License', label: 'License' },
  { value: 'Maitrise', label: 'Maitrise' },
  { value: 'Master juste la première année', label: 'Master juste la première année' },
  { value: 'Master deuxième année', label: 'Master deuxième année' },
  { value: 'BAC+5 (Ingénierie)', label: 'BAC+5 (Ingénierie)' },
  { value: 'Doctorat', label: 'Doctorat' },
  { value: 'Autre', label: 'Autre' }
];


function OutFrFreelanceCandidateCompetencies() {
  var user;
  if (localStorage.getItem("user")) {
  user = JSON.parse(localStorage.getItem("user"));
   } else {
   user = { role: "none" };
   };
  
  const history = useHistory();
  const location = useLocation();
  const [competenciesAlreadyPosted, setCompetenciesAlreadyPosted] = useState(false);
  // Réseaux sécurité
  const [ccnp_NetSec, set_CCNP_NetSec] = useState("");
  const [ccnaNetSec, set_CCNA_NetSec] = useState("");
  const [ciscoNetSec, setCiscoNetSec] = useState("");
  const [nexusNetSec, setNexusNetSec] = useState("");
  const [MPLSNetSec, setMPLSNetSec] = useState("");
  const [FortinetNetSec, setFortinetNetSec] = useState("");
  const [PaloAltoNetSec, setPaloAltoNetSec] = useState("");
  const [F5NetSec, setF5NetSec] = useState("");
  const [ACENetSec, setACENetSec] = useState("");
  const [CiscoASANetSec, setCiscoASANetSec] = useState("");
  const [showCompetenciesTable, setShowCompetenciesTable] = useState(false);
  const [profileType, setProfileType] = useState("");
  // Système Windows
  const [Windows_Server_SW, set_Windows_Server_SW] = useState("");
  const [Active_Directory_SW, set_Active_Directory_SW] = useState("");
  const [Office_365_SW, set_Office_365_SW] = useState("");
  const [EXCHANGE_SW, set_EXCHANGE_SW] = useState("");
  const [SHAREPOINT_SW, set_SHAREPOINT_SW] = useState("");
  const [SCCM_SW, set_SCCM_SW] = useState("");
  const [SCOM_SW, set_SCOM_SW] = useState("");
  const [IIS_SW, set_IIS_SW] = useState("");
  const [Powershell_SW, set_Powershell_SW] = useState("");
  const [HyperV_SW, set_HyperV_SW] = useState("");
  const [Azure_SW, set_Azure_SW] = useState("");
  const [AWS_SW, set_AWS_SW] = useState("");
  const [Devops_SW, set_Devops_SW] = useState("");
  const [Docker_SW, set_Docker_SW] = useState("");
  const [Kubernetes_SW, set_Kubernetes_SW] = useState("");
  // Système Linux
  const [LINUX_SL, set_LINUX_SL] = useState("");
  const [Redhat_SL, set_Redhat_SL] = useState("");
  const [Ubuntu_SL, set_Ubuntu_SL] = useState("");
  const [Shell_SL, set_Shell_SL] = useState("");
  const [Apache_SL, set_Apache_SL] = useState("");
  const [Docker_SL, set_Docker_SL] = useState("");
  const [Kubernetes_SL, set_Kubernetes_SL] = useState("");
  const [Ansible_SL, set_Ansible_SL] = useState("");
  const [Terraform_SL, set_Terraform_SL] = useState("");
  const [Puppet_SL, set_Puppet_SL] = useState("");
  const [Jenkins_SL, set_Jenkins_SL] = useState("");
  const [GIT_SL, set_GIT_SL] = useState("");
  const [Openstack_SL, set_Openstack_SL] = useState("");
  const [Azure_SL, set_Azure_SL] = useState("");
  const [AWS_SL, set_AWS_SL] = useState("");
  const [Devops_SL, set_Devops_SL] = useState("");
  // Système Réseaux
  const [profile_Change_SR, set_profile_Change_SR] = useState("");
  const [Windows_Server_SR, set_Windows_Server_SR] = useState("");
  const [Directory_SR, set_Directory_SR] = useState("");
  const [EXCHANGE_SR, set_EXCHANGE_SR] = useState("");
  const [SHAREPOINT_SR, set_SHAREPOINT_SR] = useState("");
  const [SCCM_SR, set_SCCM_SR] = useState("");
  const [SCOM_SR, set_SCOM_SR] = useState("");
  const [IIS_SR, set_IIS_SR] = useState("");
  const [Powershell_SR, set_Powershell_SR] = useState("");
  const [HyperV_SR, set_HyperV_SR] = useState("");
  const [Azure_SR, set_Azure_SR] = useState("");
  const [AWS_SR, set_AWS_SR] = useState("");
  const [Devops_SR, set_Devops_SR] = useState("");
  const [LINUX_SR, set_LINUX_SR] = useState("");
  const [Shell_SR, set_Shell_SR] = useState("");
  const [Redhat_SR, set_Redhat_SR] = useState("");
  const [Ubuntu_SR, set_Ubuntu_SR] = useState("");
  const [Apache_SR, set_Apache_SR] = useState("");
  const [Docker_SR, set_Docker_SR] = useState("");
  const [Ansible_SR, set_Ansible_SR] = useState("");
  const [Terraform_SR, set_Terraform_SR] = useState("");
  const [Puppet_SR, set_Puppet_SR] = useState("");
  const [Jenkins_SR, set_Jenkins_SR] = useState("");
  const [GIT_SR, set_GIT_SR] = useState("");
  const [Openstack_SR, set_Openstack_SR] = useState("");
  const [CCNP_SR, set_CCNP_SR] = useState("");
  const [CCNA_SR, set_CCNA_SR] = useState("");
  const [Cisco_SR, set_Cisco_SR] = useState("");
  const [Nexus_SR, set_Nexus_SR] = useState("");
  const [Fortinet_SR, set_Fortinet_SR] = useState("");
  const [PaloAlto_SR, set_PaloAlto_SR] = useState("");
  const [F5_SR, set_F5_SR] = useState("");
  const [ACE_SR, set_ACE_SR] = useState("");
  const [CiscoASA_SR, set_CiscoASA_SR] = useState("");
  const [Office_365_SR, set_Office_365_SR] = useState("");
// Virtualisation
  const [profile_change_Virt, set_profile_change_Virt] = useState("");
  const [Windows_Server_Virt, set_Windows_Server_Virt] = useState("");
  const [Office_365_Virt, set_Office_365_Virt] = useState("");
  const [LINUX_Virt, set_LINUX_Virt] = useState("");
  const [Redhat_Virt, set_Redhat_Virt] = useState("");
  const [Ubuntu_Virt, set_Ubuntu_Virt] = useState("");
  const [Shell_Virt, set_Shell_Virt] = useState("");
  const [Apache_Virt, set_Apache_Virt] = useState("");
  const [Docker_Virt, set_Docker_Virt] = useState("");
  const [Ansible_Virt, set_Ansible_Virt] = useState("");
  const [Terraform_Virt, set_Terraform_Virt] = useState("");
  const [Cisco_Virt, set_Cisco_Virt] = useState("");
  const [Nexus_Virt, set_Nexus_Virt] = useState("");
  const [Fortinet_Virt, set_Fortinet_Virt] = useState("");
  const [PaloAlto_Virt, set_PaloAlto_Virt] = useState("");
  const [F5_Virt, set_F5_Virt] = useState("");
  const [ACE_Virt, set_ACE_Virt] = useState("");
  const [CiscoASA_Virt, set_CiscoASA_Virt] = useState("");
  // Base de données
  const [MySQL_BDD, set_MySQL_BDD] = useState("");
  const [SQL_Server_BDD, set_SQL_Server_BDD] = useState("");
  const [ORACLE_BDD, set_ORACLE_BDD] = useState("");
  const [POSTGRES_BDD, set_POSTGRES_BDD] = useState("");
  const [Oracle_RAC_BDD, set_Oracle_RAC_BDD] = useState("");
  const [INGRES_BDD, set_INGRES_BDD] = useState("");
  // Développeurs
  const [Python_Dev, set_Python_Dev] = useState("");
  const [PHP_Dev, set_PHP_Dev] = useState("");
  const [Java_Dev, set_Java_Dev] = useState("");
  const [Spring_Dev, set_Spring_Dev] = useState("");
  const [Angular_Dev, set_Angular_Dev] = useState("");
  const [Angular_JS_Dev, set_Angular_JS_Dev] = useState("");
  const [Laravel_Dev, set_Laravel_Dev] = useState("");
  const [Symfony_Dev, set_Symfony_Dev] = useState("");
  const [JavaScript_Dev, set_JavaScript_Dev] = useState("");
  const [React_JS_Dev, set_React_JS_Dev] = useState("");
  const [Vue_JS_Dev, set_Vue_JS_Dev] = useState("");
  const [Node_JS_Dev, set_Node_JS_Dev] = useState("");
  const [C_sharp_Dev, set_C_sharp_Dev] = useState("");
  const [DotNET_Dev, set_DotNET_Dev] = useState("");
  const [Langage_R_Dev, set_Langage_R_Dev] = useState("");
  const [Ruby_Dev, set_Ruby_Dev] = useState("");
  // Bi et Big Data
  const [Hadoop_BigDataBI, set_Hadoop_BigDataBI] = useState("");
  const [Cloudera_BigDataBI, set_Cloudera_BigDataBI] = useState("");
  const [Apache_Hive_BigDataBI, set_Apache_Hive_BigDataBI] = useState("");
  const [Apache_NiFi_BigDataBI, set_Apache_NiFi_BigDataBI] = useState("");
  const [Spark_BigDataBI, set_Spark_BigDataBI] = useState("");
  const [Talend_BigDataBI, set_Talend_BigDataBI] = useState("");
  const [Power_BI_BigDataBI, set_Power_BI_BigDataBI] = useState("");
  // Autres types de profil
  const [ITIL_Autres, set_ITIL_Autres] = useState("");
  const [Management_Autres, set_Management_Autres] = useState("");
  const [SCRUM_Autres, set_SCRUM_Autres] = useState("");
  const [Chefferie_de_projet_Autres, set_Chefferie_de_projet_Autres] = useState("");
  
  

  // const createProfileWithCV = async () => {
  const createCandidateComptencies_NetSec = () => {
    // console.log("we are heree function createProfileWithCV");
    const userID = user._id;
    
    const formData = new FormData();
    // formData.append("user", user._id);

    // console.log("formData: 7777777777777777777777777: ccnp_NetSec: ", ccnp_NetSec);
    // console.log("formData: 7777777777777777777777777: userID: ", userID);

    instance.post(`candidatesCompetencies/createCompetencies`, {
      userID,
      profileType,
      ccnp_NetSec,
      ccnaNetSec,
      ciscoNetSec,
      nexusNetSec,
      MPLSNetSec,
      FortinetNetSec,
      PaloAltoNetSec,
      F5NetSec,
      ACENetSec,
      CiscoASANetSec,
    }).then((resp) => {
      console.log("8888888888888: candidatesCompetencies: step 0: ");
      console.log("8888888888888: candidatesCompetencies: step 1: formData: ", formData);
      console.log("8888888888888: candidatesCompetencies: step 2: formrespData: ", resp);
      console.log("8888888888888: candidatesCompetencies: step 3: ccnp_NetSec: ", ccnp_NetSec);
      // console.log("8888888888888: formData: ", formData);
      // history.push('outfrapplypermanentfr/home');
      // history.push("google.fr")
    });
  };

  const createCandidateComptencies_SW = () => {
    const userID = user._id;
    instance.post(`candidatesCompetencies/createCompetencies`, {
      userID,
      profileType,
      Windows_Server_SW,
      Active_Directory_SW,
      Office_365_SW,
      EXCHANGE_SW,
      SHAREPOINT_SW,
      SCCM_SW,
      SCOM_SW,
      IIS_SW,
      Powershell_SW,
      HyperV_SW,
      Azure_SW,
      AWS_SW,
      Devops_SW,
      Docker_SW,
      Kubernetes_SW,
    }).then((resp) => {
      // console.log("8888888888888: candidatesCompetencies: step 0: ");
      // console.log("8888888888888: candidatesCompetencies: step 2: formrespData: ", resp);
      // console.log("8888888888888: formData: ", formData);
      // history.push('outfrapplypermanentfr/home');
      // history.push("google.fr")
    });
  };

  const createCandidateComptencies_SL = () => {
    const userID = user._id;
    instance.post(`candidatesCompetencies/createCompetencies`, {
      userID,
      profileType,
      LINUX_SL,
      Redhat_SL,
      Ubuntu_SL,
      Shell_SL,
      Apache_SL,
      Docker_SL,
      Kubernetes_SL,
      Ansible_SL,
      Terraform_SL,
      Puppet_SL,
      Jenkins_SL,
      GIT_SL,
      Openstack_SL,
      Azure_SL,
      AWS_SL,
      Devops_SL,    
    }).then((resp) => {
      // console.log("8888888888888: candidatesCompetencies: step 0: ");
      // console.log("8888888888888: candidatesCompetencies: step 2: formrespData: ", resp);
      // console.log("8888888888888: formData: ", formData);
      // history.push('outfrapplypermanentfr/home');
      // history.push("google.fr")
    });
  };

  const createCandidateComptencies_RS = () => {
    const userID = user._id;
    instance.post(`candidatesCompetencies/createCompetencies`, {
      userID,
      profileType,
      profile_Change_SR,
      Windows_Server_SR,
      Directory_SR,
      Office_365_SR,
      EXCHANGE_SR,
      SHAREPOINT_SR,
      SCCM_SR,
      SCOM_SR,
      IIS_SR,
      Powershell_SR,
      HyperV_SR,
      Azure_SR,
      AWS_SR,
      Devops_SR,
      LINUX_SR,
      Shell_SR,
      Redhat_SR,
      Ubuntu_SR,
      Apache_SR,
      Docker_SR,
      Ansible_SR,
      Terraform_SR,
      Puppet_SR,
      Jenkins_SR,
      GIT_SR,
      Openstack_SR,
      CCNP_SR,
      CCNA_SR,
      Cisco_SR,
      Nexus_SR,
      Fortinet_SR,
      PaloAlto_SR,
      F5_SR,
      ACE_SR,
      CiscoASA_SR,    
    }).then((resp) => {
      // console.log("8888888888888: candidatesCompetencies: step 0: ");
      // console.log("8888888888888: candidatesCompetencies: step 2: formrespData: ", resp);
      // console.log("8888888888888: formData: ", formData);
      // history.push('outfrapplypermanentfr/home');
      // history.push("google.fr")
    });
  };
  const createCandidateComptencies_Virt = () => {
    const userID = user._id;
    instance.post(`candidatesCompetencies/createCompetencies`, {
      userID,
      profileType,
      profile_change_Virt,
      Windows_Server_Virt,
      Office_365_Virt,
      LINUX_Virt,
      Redhat_Virt,
      Ubuntu_Virt,
      Shell_Virt,
      Apache_Virt,
      Docker_Virt,
      Ansible_Virt,
      Terraform_Virt,
      Cisco_Virt,
      Nexus_Virt,
      Fortinet_Virt,
      PaloAlto_Virt,
      F5_Virt,
      ACE_Virt,
      CiscoASA_Virt,    
    }).then((resp) => {
      // console.log("8888888888888: candidatesCompetencies: step 0: ");
      // console.log("8888888888888: candidatesCompetencies: step 2: formrespData: ", resp);
      // console.log("8888888888888: formData: ", formData);
      // history.push('outfrapplypermanentfr/home');
      // history.push("google.fr")
    });
  };

  const createCandidateComptencies_BDD = () => {
    const userID = user._id;
    instance.post(`candidatesCompetencies/createCompetencies`, {
      userID,
      profileType,
      MySQL_BDD,
      SQL_Server_BDD,
      ORACLE_BDD,
      POSTGRES_BDD,
      Oracle_RAC_BDD,
      INGRES_BDD,    
    }).then((resp) => {
      // console.log("8888888888888: candidatesCompetencies: step 0: ");
      // console.log("8888888888888: candidatesCompetencies: step 2: formrespData: ", resp);
      // console.log("8888888888888: formData: ", formData);
      // history.push('outfrapplypermanentfr/home');
      // history.push("google.fr")
    });
  };

  const createCandidateComptencies_BigDataBI = () => {
    const userID = user._id;
    instance.post(`candidatesCompetencies/createCompetencies`, {
      userID,
      profileType,
      Hadoop_BigDataBI,
      Cloudera_BigDataBI,
      Apache_Hive_BigDataBI,
      Apache_NiFi_BigDataBI,
      Spark_BigDataBI,
      Talend_BigDataBI,
      Power_BI_BigDataBI,    
    }).then((resp) => {
      // console.log("8888888888888: candidatesCompetencies: step 0: ");
      // console.log("8888888888888: candidatesCompetencies: step 2: formrespData: ", resp);
      // console.log("8888888888888: formData: ", formData);
      // history.push('outfrapplypermanentfr/home');
      // history.push("google.fr")
    });
  };

  const createCandidateComptencies_Dev = () => {
    const userID = user._id;
    instance.post(`candidatesCompetencies/createCompetencies`, {
      userID,
      profileType,
      Python_Dev,
      PHP_Dev,
      Java_Dev,
      Spring_Dev,
      Angular_Dev,
      Angular_JS_Dev,
      Laravel_Dev,
      Symfony_Dev,
      JavaScript_Dev,
      React_JS_Dev,
      Vue_JS_Dev,
      Node_JS_Dev,
      C_sharp_Dev,
      DotNET_Dev,
      Langage_R_Dev,
      Ruby_Dev,    
    }).then((resp) => {
      // console.log("8888888888888: candidatesCompetencies: step 0: ");
      // console.log("8888888888888: candidatesCompetencies: step 2: formrespData: ", resp);
      // console.log("8888888888888: formData: ", formData);
      // history.push('outfrapplypermanentfr/home');
      // history.push("google.fr")
    });
  };
  const createCandidateComptencies_Autres = () => {
    const userID = user._id;
    instance.post(`candidatesCompetencies/createCompetencies`, {
      userID,
      profileType,
      ITIL_Autres,
      Management_Autres,
      SCRUM_Autres,
      Chefferie_de_projet_Autres,
    }).then((resp) => {
      // console.log("8888888888888: candidatesCompetencies: step 0: ");
      // console.log("8888888888888: candidatesCompetencies: step 2: formrespData: ", resp);
      // console.log("8888888888888: formData: ", formData);
      // history.push('outfrapplypermanentfr/home');
      // history.push("google.fr")
    });
  };
  


  const checkoutSchema = yup.object().shape({
    // titre: yup.string().required("required"),
  });



  const fetchProfile = () => {
  

    instance({
      method: "get",
      url: `/outfrsearchcdifrprofiles/getprofilebyuserid/${user._id}`,
      headers: {'Content-Type': 'application/json' },  
    }).then((resp) => {
      // setAlreadyPosted(true);
      // console.log("5555555555555555555555555555 resp.data: ", resp.data);
      });
    };

  const fetchCandidateCompetencies = () => {
  

    instance({
      method: "get",
      url: `/candidatesCompetencies/getCompetenciesByUserID/${user._id}`,
      headers: {'Content-Type': 'application/json' },  
    }).then((resp) => {
      setCompetenciesAlreadyPosted(true);
      // console.log("5555555555555555555555555555 resp.data: ", resp.data);
      });
    };

    useEffect(() => {
  
      fetchCandidateCompetencies();
    
    }, [location]);

  
  return (
    <>

 
      {competenciesAlreadyPosted &&
        <>
        <Box sx={
          { 
            ml: 32,
            p: "30px" 
          }
        }>
        <Grid item xs={12}
        sx={{ 
          mt: "25px",
          mb: "25px"      
        }}
        >
          <label className="px-lg-3">Votre tableau de compétences est déjà réalisé</label>
          </Grid>
          </Box>
          <OutFrFreelanceterminateYourProfileBefore />
        </>
      }

    {!competenciesAlreadyPosted &&
      <Box 
        style={{display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'center'}}
        sx={
          { 
           // ml: 30,
            p: "30px" 
          }
         }>
          <form>

          
          <Grid item xs={12}>
            <label className="px-lg-3">Etape 1: Quel est le profil qui vous corresponde le mieux?</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                // placeholder="profile_categories" 
                className="w-full "
                options={options_profile_categories}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { setProfileType(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Button 
            variant="contained" 
            color="primary" 
            sx={{ mt: "25px" }}
            onClick={() => { 
              // console.log("bouton cliqué 0000000000000000000000 ")
              setShowCompetenciesTable(!showCompetenciesTable);
              // window.location.reload();
            }}
            >
              Suivant
        </Button>
          </form>

        {showCompetenciesTable && profileType === "Réseaux et sécurité" &&
        
                  <form>

        
                  <Grid item xs={12}>
                    <label className="px-lg-3">Est ce que vous avez obtenu la certification CCNP (Cisco) ou HCIP Switching et Routing (Huawei)?</label>
                      <Select 
                        //name="Niveau du diplome" 
                        //label="Niveau du diplome" 
                        name="ccnp"
                        // placeholder="gender" 
                        className="w-full "
                        options={options_CCNP}
                        //fullWidth 
                        //onBlur={handleBlur} 
                        // onChange={handleChange} 
                        //value={NiveauDiplome}
                        onChange={(e) => { set_CCNP_NetSec(e.value) }} 
                        // value={values.NiveauDiplome || ""} 
                        //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                        //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                      />
                   </Grid>
                   <Grid item xs={12}>
                    <label className="px-lg-3">Est ce que vous avez une certification CCNA (Cisco) valide ou pas?</label>
                      <Select 
                        //name="Niveau du diplome" 
                        //label="Niveau du diplome" 
                        name="CCNA_NetSec"
                        // placeholder="gender" 
                        className="w-full "
                        options={options_CCNA_NetSec}
                        //fullWidth 
                        //onBlur={handleBlur} 
                        // onChange={handleChange} 
                        //value={NiveauDiplome}
                        onChange={(e) => { set_CCNA_NetSec(e.value) }} 
                        // value={values.NiveauDiplome || ""} 
                        //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                        //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                      />
                   </Grid>
          
                   <Grid item xs={12}>
                    <label className="px-lg-3">Cisco</label>
                      <Select 
                        //name="Niveau du diplome" 
                        //label="Niveau du diplome" 
                        name="Cisco_NetSec"
                        // placeholder="gender" 
                        className="w-full "
                        options={options_Cisco_NetSec}
                        //fullWidth 
                        //onBlur={handleBlur} 
                        // onChange={handleChange} 
                        //value={NiveauDiplome}
                        onChange={(e) => { setCiscoNetSec(e.value) }} 
                        // value={values.NiveauDiplome || ""} 
                        //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                        //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                      />
                   </Grid>
                   <Grid item xs={12}>
                    <label className="px-lg-3">Nexus</label>
                      <Select 
                        //name="Niveau du diplome" 
                        //label="Niveau du diplome" 
                        name="Nexus_NetSec"
                        // placeholder="gender" 
                        className="w-full "
                        options={options_Competency_degree}
                        //fullWidth 
                        //onBlur={handleBlur} 
                        // onChange={handleChange} 
                        //value={NiveauDiplome}
                        onChange={(e) => { setNexusNetSec(e.value) }} 
                        // value={values.NiveauDiplome || ""} 
                        //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                        //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                      />
                   </Grid>
                   <Grid item xs={12}>
                    <label className="px-lg-3">MPLS</label>
                      <Select 
                        //name="Niveau du diplome" 
                        //label="Niveau du diplome" 
                        name="MPLS_NetSec"
                        // placeholder="gender" 
                        className="w-full "
                        options={options_Competency_degree}
                        //fullWidth 
                        //onBlur={handleBlur} 
                        // onChange={handleChange} 
                        //value={NiveauDiplome}
                        onChange={(e) => { setMPLSNetSec(e.value) }} 
                        // value={values.NiveauDiplome || ""} 
                        //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                        //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                      />
                   </Grid>
                   <Grid item xs={12}>
                    <label className="px-lg-3">MPLS</label>
                      <Select 
                        //name="Niveau du diplome" 
                        //label="Niveau du diplome" 
                        name="Fortinet_NetSec"
                        // placeholder="gender" 
                        className="w-full "
                        options={options_Competency_degree}
                        //fullWidth 
                        //onBlur={handleBlur} 
                        // onChange={handleChange} 
                        //value={NiveauDiplome}
                        onChange={(e) => { setFortinetNetSec(e.value) }} 
                        // value={values.NiveauDiplome || ""} 
                        //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                        //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                      />
                   </Grid>
                   <Grid item xs={12}>
                    <label className="px-lg-3">Palo-Alto</label>
                      <Select 
                        //name="Niveau du diplome" 
                        //label="Niveau du diplome" 
                        name="Palo_Alto_NetSec"
                        // placeholder="gender" 
                        className="w-full "
                        options={options_Competency_degree}
                        //fullWidth 
                        //onBlur={handleBlur} 
                        // onChange={handleChange} 
                        //value={NiveauDiplome}
                        onChange={(e) => { setPaloAltoNetSec(e.value) }} 
                        // value={values.NiveauDiplome || ""} 
                        //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                        //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                      />
                   </Grid>
                   <Grid item xs={12}>
                    <label className="px-lg-3">F5</label>
                      <Select 
                        //name="Niveau du diplome" 
                        //label="Niveau du diplome" 
                        name="F5_NetSec"
                        // placeholder="gender" 
                        className="w-full "
                        options={options_Competency_degree}
                        //fullWidth 
                        //onBlur={handleBlur} 
                        // onChange={handleChange} 
                        //value={NiveauDiplome}
                        onChange={(e) => { setF5NetSec(e.value) }} 
                        // value={values.NiveauDiplome || ""} 
                        //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                        //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                      />
                   </Grid>
                   <Grid item xs={12}>
                    <label className="px-lg-3">ACE</label>
                      <Select 
                        //name="Niveau du diplome" 
                        //label="Niveau du diplome" 
                        name="ACE_NetSec"
                        // placeholder="gender" 
                        className="w-full "
                        options={options_Competency_degree}
                        //fullWidth 
                        //onBlur={handleBlur} 
                        // onChange={handleChange} 
                        //value={NiveauDiplome}
                        onChange={(e) => { setACENetSec(e.value) }} 
                        // value={values.NiveauDiplome || ""} 
                        //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                        //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                      />
                   </Grid>
                   <Grid item xs={12}>
                    <label className="px-lg-3">Cisco-ASA</label>
                      <Select 
                        //name="Niveau du diplome" 
                        //label="Niveau du diplome" 
                        name="ACE_NetSec"
                        // placeholder="gender" 
                        className="w-full "
                        options={options_Competency_degree}
                        //fullWidth 
                        //onBlur={handleBlur} 
                        // onChange={handleChange} 
                        //value={NiveauDiplome}
                        onChange={(e) => { setCiscoASANetSec(e.value) }} 
                        // value={values.NiveauDiplome || ""} 
                        //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                        //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                      />
                   </Grid>
                   <Button 
                    variant="contained" 
                    color="primary" 
                    type="submit" 
                    sx={{ mt: "25px" }}
                    onClick={() => { 
                      // console.log("bouton cliqué 0000000000000000000000 ")
          
                      createCandidateComptencies_NetSec()
                      history.push("/outfrapplypermanentfr/home")
                    }}
                    >
                      Sauvegarder votre tableau de compétences
                  </Button>
          
                </form>


        }
        {showCompetenciesTable && profileType === "Système Windows" &&      
          <form>


          <Grid item xs={12}>
            <label className="px-lg-3">Windows Server</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="menu-Windows-Server-SW"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Windows_Server_SW(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Active-Directory</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Active_Directory_SW"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Active_Directory_SW(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>

          <Grid item xs={12}>
            <label className="px-lg-3">Office-365</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Office_365_S"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Office_365_SW(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">EXCHANGE</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="EXCHANGE_SW"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_EXCHANGE_SW(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">SHAREPOINT</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="SHAREPOINT_SW"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_SHAREPOINT_SW(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">SCCM</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="SCCM_SW"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_SCCM_SW(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">SCOM</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="SCOM_SW"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_SCOM_SW(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">IIS</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="IIS_SW"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_IIS_SW(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Powershell</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Powershell_SW"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Powershell_SW(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Hyper-V</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="HyperV_SW"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_HyperV_SW(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Azure</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Azure_SW"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Azure_SW(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">AWS</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="AWS_SW"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_AWS_SW(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Devops</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Devops_SW"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Devops_SW(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Docker</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Docker_SW"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Docker_SW(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Kubernetes</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Kubernetes_SW"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Kubernetes_SW(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Button 
            variant="contained" 
            color="primary" 
            type="submit" 
            sx={{ mt: "25px" }}
            onClick={() => { 
              // console.log("bouton cliqué 0000000000000000000000 ")

              createCandidateComptencies_SW()
              history.push("/outfrapplypermanentfr/home")
            }}
            >
              Sauvegarder votre tableau de compétences
          </Button>

          </form>
        }

        {showCompetenciesTable && profileType === "Système Linux" &&      
          <form>
          <Grid item xs={12}>
            <label className="px-lg-3">LINUX</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="LINUX_SL"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_LINUX_SL(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Redhat</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Redhat_SL"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Redhat_SL(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>

          <Grid item xs={12}>
            <label className="px-lg-3">Ubuntu</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Ubuntu_SL"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Ubuntu_SL(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Shell</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Shell_SL"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Shell_SL(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Apache</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Apache_SL"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Apache_SL(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Docker</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Docker_SL"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Docker_SL(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Kubernetes</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Kubernetes_SL"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Kubernetes_SL(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Ansible</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Ansible_SL"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Ansible_SL(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Terraform</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Terraform_SL"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Terraform_SL(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Puppet</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Puppet_SL"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Puppet_SL(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Jenkins</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Jenkins_SL"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Jenkins_SL(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">GIT</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="GIT_SL"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_GIT_SL(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Openstack</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Openstack_SL"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Openstack_SL(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Azure</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Azure_SL"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Azure_SL(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">AWS</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="AWS_SL"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_AWS_SL(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Devops</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Devops_SL"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Devops_SL(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          

          <Button 
            variant="contained" 
            color="primary" 
            type="submit" 
            sx={{ mt: "25px" }}
            onClick={() => { 
              // console.log("bouton cliqué 0000000000000000000000 ")

              createCandidateComptencies_SL()
              history.push("/outfrapplypermanentfr/home")
            }}
            >
              Sauvegarder votre tableau de compétences
          </Button>

          </form>
        }

        {showCompetenciesTable && profileType === "Réseaux et système" &&      
          <form>
          <Grid item xs={12}>
            <label className="px-lg-3">Est ce que ça vous arrange d'avancer dans l'un de ces profils?</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="profile_change_SR"
                // placeholder="gender" 
                className="w-full "
                options={options_profile_change_SR}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_profile_Change_SR(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Windows-Server</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Windows_Server_SR"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Windows_Server_SR(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>

          <Grid item xs={12}>
            <label className="px-lg-3">Active-Directory</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Active-Directory_SR"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Directory_SR(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Office-365</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Office-365_SR"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Office_365_SR(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">EXCHANGE</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="EXCHANGE_SR"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_EXCHANGE_SR(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">SHAREPOINT</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="SHAREPOINT_SR"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_SHAREPOINT_SR(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">SCCM</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="SCCM_SR"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_SCCM_SR(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">SCOM</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="SCOM_SR"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_SCOM_SR(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">IIS</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="IIS_SR"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_IIS_SR(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Puppet</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Powershell_SR"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Powershell_SR(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Hyper-V</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="HyperV_SR"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_HyperV_SR(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Azure</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Azure_SR"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Azure_SR(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">AWS</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="AWS_SR"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_AWS_SR(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Devops</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Devops_SR"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Devops_SR(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">LINUX</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="LINUX_SR"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_LINUX_SR(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Redhat</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Redhat_SR"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Redhat_SR(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Ubuntu</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Ubuntu_SR"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Ubuntu_SR(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Shell</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Shell_SR"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Shell_SR(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Apache</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Apache_SR"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Apache_SR(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Docker</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Docker_SR"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Docker_SR(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Ansible</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Ansible_SR"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Ansible_SR(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Terraform</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Terraform_SR"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Terraform_SR(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Puppet</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Puppet_SR"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Puppet_SR(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Jenkins</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Jenkins_SR"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Jenkins_SR(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">GIT</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="GIT_SR"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_GIT_SR(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Openstack</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Openstack_SR"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Openstack_SR(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Est ce que vous avez obtenu la certification CCNP (Cisco) ou HCIP Switching et Routing (Huawei)?</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="CCNP_SR"
                // placeholder="gender" 
                className="w-full "
                options={options_CCNP}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_CCNP_SR(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Est ce que vous avez une certification CCNA (Cisco) valide ou pas?</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="CCNA_SR"
                // placeholder="gender" 
                className="w-full "
                options={options_CCNA_NetSec}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_CCNA_SR(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Cisco</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Cisco_SR"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Cisco_SR(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Nexus</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Nexus_SR"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Nexus_SR(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Fortinet</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Fortinet_SR"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Fortinet_SR(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Palo-Alto</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="PaloAlto_SR"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_PaloAlto_SR(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">F5</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="F5_SR"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_F5_SR(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">ACE</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="ACE_SR"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_ACE_SR(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Cisco-ASA</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="CiscoASA_SR"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_CiscoASA_SR(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>

          

          <Button 
            variant="contained" 
            color="primary" 
            type="submit" 
            sx={{ mt: "25px" }}
            onClick={() => { 
              // console.log("bouton cliqué 0000000000000000000000 ")

              createCandidateComptencies_RS()
              history.push("/outfrapplypermanentfr/home")
            }}
            >
              Sauvegarder votre tableau de compétences
          </Button>

          </form>
        }
        {showCompetenciesTable && profileType === "Virtualisation" &&      
          <form>
          <Grid item xs={12}>
            <label className="px-lg-3">Est ce que ça vous arrange d'avancer dans l'un de ces profils?</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="profile_type_Virt"
                // placeholder="gender" 
                className="w-full "
                options={options_profile_change_SR}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_profile_change_Virt(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Windows-Server</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Windows_Server_Virt"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Windows_Server_Virt(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>

          <Grid item xs={12}>
            <label className="px-lg-3">Office-365</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Office-365_Virt"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Office_365_Virt(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>





          <Grid item xs={12}>
            <label className="px-lg-3">LINUX</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="LINUX_Virt"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_LINUX_Virt(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Redhat</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Redhat_Virt"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Redhat_Virt(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Ubuntu</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Ubuntu_Virt"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Ubuntu_Virt(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Shell</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Shell_Virt"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Shell_Virt(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Apache</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Apache_Virt"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Apache_Virt(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Docker</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Docker_Virt"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Docker_Virt(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Ansible</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Ansible_Virt"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Ansible_Virt(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Terraform</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Terraform_Virt"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Terraform_Virt(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>




          <Grid item xs={12}>
            <label className="px-lg-3">Cisco</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Cisco_Virt"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Cisco_Virt(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Nexus</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Nexus_Virt"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Nexus_Virt(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Fortinet</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Fortinet_Virt"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Fortinet_Virt(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Palo-Alto</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="PaloAlto_Virt"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_PaloAlto_Virt(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">F5</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="F5_Virt"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_F5_Virt(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">ACE</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="ACE_Virt"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_ACE_Virt(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Cisco-ASA</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="CiscoASA_Virt"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_CiscoASA_Virt(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>

          

          <Button 
            variant="contained" 
            color="primary" 
            type="submit" 
            sx={{ mt: "25px" }}
            onClick={() => { 
              // console.log("bouton cliqué 0000000000000000000000 ")

              createCandidateComptencies_Virt()
              history.push("/outfrapplypermanentfr/home")
            }}
            >
              Sauvegarder votre tableau de compétences
          </Button>

          </form>
        }
        {showCompetenciesTable && profileType === "Base de données" &&      
          <form>
            <Grid item xs={12}>
              <label className="px-lg-3">SQL-Server</label>
                <Select 
                  //name="Niveau du diplome" 
                  //label="Niveau du diplome" 
                  name="SQL_Server_BDD"
                  // placeholder="gender" 
                  className="w-full "
                  options={options_Competency_degree}
                  //fullWidth 
                  //onBlur={handleBlur} 
                  // onChange={handleChange} 
                  //value={NiveauDiplome}
                  onChange={(e) => { set_SQL_Server_BDD(e.value) }} 
                  // value={values.NiveauDiplome || ""} 
                  //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                  //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                />
            </Grid>

            <Grid item xs={12}>
              <label className="px-lg-3">MySQL</label>
                <Select 
                  //name="Niveau du diplome" 
                  //label="Niveau du diplome" 
                  name="MySQL_BDD"
                  // placeholder="gender" 
                  className="w-full "
                  options={options_Competency_degree}
                  //fullWidth 
                  //onBlur={handleBlur} 
                  // onChange={handleChange} 
                  //value={NiveauDiplome}
                  onChange={(e) => { set_MySQL_BDD(e.value) }} 
                  // value={values.NiveauDiplome || ""} 
                  //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                  //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                />
            </Grid>





            <Grid item xs={12}>
              <label className="px-lg-3">ORACLE</label>
                <Select 
                  //name="Niveau du diplome" 
                  //label="Niveau du diplome" 
                  name="ORACLE_BDD"
                  // placeholder="gender" 
                  className="w-full "
                  options={options_Competency_degree}
                  //fullWidth 
                  //onBlur={handleBlur} 
                  // onChange={handleChange} 
                  //value={NiveauDiplome}
                  onChange={(e) => { set_ORACLE_BDD(e.value) }} 
                  // value={values.NiveauDiplome || ""} 
                  //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                  //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                />
            </Grid>
            <Grid item xs={12}>
              <label className="px-lg-3">POSTGRES</label>
                <Select 
                  //name="Niveau du diplome" 
                  //label="Niveau du diplome" 
                  name="POSTGRES_BDD"
                  // placeholder="gender" 
                  className="w-full "
                  options={options_Competency_degree}
                  //fullWidth 
                  //onBlur={handleBlur} 
                  // onChange={handleChange} 
                  //value={NiveauDiplome}
                  onChange={(e) => { set_POSTGRES_BDD(e.value) }} 
                  // value={values.NiveauDiplome || ""} 
                  //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                  //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                />
            </Grid>
            <Grid item xs={12}>
              <label className="px-lg-3">Oracle-RAC</label>
                <Select 
                  //name="Niveau du diplome" 
                  //label="Niveau du diplome" 
                  name="Oracle_RAC_BDD"
                  // placeholder="gender" 
                  className="w-full "
                  options={options_Competency_degree}
                  //fullWidth 
                  //onBlur={handleBlur} 
                  // onChange={handleChange} 
                  //value={NiveauDiplome}
                  onChange={(e) => { set_Oracle_RAC_BDD(e.value) }} 
                  // value={values.NiveauDiplome || ""} 
                  //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                  //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                />
            </Grid>
            <Grid item xs={12}>
              <label className="px-lg-3">INGRES</label>
                <Select 
                  //name="Niveau du diplome" 
                  //label="Niveau du diplome" 
                  name="INGRES_BDD"
                  // placeholder="gender" 
                  className="w-full "
                  options={options_Competency_degree}
                  //fullWidth 
                  //onBlur={handleBlur} 
                  // onChange={handleChange} 
                  //value={NiveauDiplome}
                  onChange={(e) => { set_INGRES_BDD(e.value) }} 
                  // value={values.NiveauDiplome || ""} 
                  //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                  //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                />
            </Grid>
            <Button 
            variant="contained" 
            color="primary" 
            type="submit" 
            sx={{ mt: "25px" }}
            onClick={() => { 
              // console.log("bouton cliqué 0000000000000000000000 ")

              createCandidateComptencies_BDD()
              history.push("/outfrapplypermanentfr/home")
            }}
            >
              Sauvegarder votre tableau de compétences
          </Button>

          </form>
        }
        {showCompetenciesTable && profileType.includes("Développeur") &&      
          <form>

          <Grid item xs={12}>
            <label className="px-lg-3">Python</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Python_Dev"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Python_Dev(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>

          <Grid item xs={12}>
            <label className="px-lg-3">PHP</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="PHP_Dev"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_PHP_Dev(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Java</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Java_Dev"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Java_Dev(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Spring</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Spring_Dev"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Spring_Dev(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Angular</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Angular_Dev"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Angular_Dev(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Angular_JS</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Angular_JS_Dev"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Angular_JS_Dev(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Laravel</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Laravel_Dev"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Laravel_Dev(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Symfony</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Symfony_Dev"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Symfony_Dev(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">JavaScript</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="JavaScript_Dev"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_JavaScript_Dev(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">React_JS</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="React_JS_Dev"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_React_JS_Dev(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Vue_JS</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Vue_JS_Dev"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Vue_JS_Dev(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Node_JS</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Node_JS_Dev"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Node_JS_Dev(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">C_sharp</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="C_sharp_Dev"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_C_sharp_Dev(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">DotNET</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="DotNET_Dev"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_DotNET_Dev(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Langage_R</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Langage_R_Dev"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Langage_R_Dev(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Ruby</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Ruby_Dev"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Ruby_Dev(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>



          <Button 
            variant="contained" 
            color="primary" 
            type="submit" 
            sx={{ mt: "25px" }}
            onClick={() => { 
              // console.log("bouton cliqué 0000000000000000000000 ")

              createCandidateComptencies_Dev()
              history.push("/outfrapplypermanentfr/home")
            }}
            >
              Sauvegarder votre tableau de compétences
          </Button>

          </form>
        }
        {showCompetenciesTable && ((profileType === "BI") || (profileType === "Big Data")) &&      
          <form>
                      <Grid item xs={12}>
            <label className="px-lg-3">Hadoop</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Hadoop_BigDataBI"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Hadoop_BigDataBI(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Cloudera_HDFS</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Cloudera_BigDataBI"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Cloudera_BigDataBI(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Apache_Hive</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Apache_Hive_BigDataBI"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Apache_Hive_BigDataBI(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Apache_NiFi</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Apache_NiFi_BigDataBI"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Apache_NiFi_BigDataBI(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Spark</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Spark_BigDataBI"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Spark_BigDataBI(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Talend</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Talend_BigDataBI"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Talend_BigDataBI(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Power_BI</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Power_BI_BigDataBI"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Power_BI_BigDataBI(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Button 
            variant="contained" 
            color="primary" 
            type="submit" 
            sx={{ mt: "25px" }}
            onClick={() => { 
              // console.log("bouton cliqué 0000000000000000000000 ")

              createCandidateComptencies_BigDataBI()
              history.push("/outfrapplypermanentfr/home")
            }}
            >
              Sauvegarder votre tableau de compétences
          </Button>

          </form>
        }
         {showCompetenciesTable && ((profileType === "Autre type de profil") || (profileType === "Chef de projet") || (profileType === "Manager")) &&      
          <form>
                    <Grid item xs={12}>
            <label className="px-lg-3">ITIL</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="ITIL_Autres"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_ITIL_Autres(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Management</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Management_Autres"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Management_Autres(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>

          <Grid item xs={12}>
            <label className="px-lg-3">SCRUM</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="SCRUM_Autres"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_SCRUM_Autres(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Grid item xs={12}>
            <label className="px-lg-3">Chefferie de projet</label>
              <Select 
                //name="Niveau du diplome" 
                //label="Niveau du diplome" 
                name="Chefferie_de_projet_Autres"
                // placeholder="gender" 
                className="w-full "
                options={options_Competency_degree}
                //fullWidth 
                //onBlur={handleBlur} 
                // onChange={handleChange} 
                //value={NiveauDiplome}
                onChange={(e) => { set_Chefferie_de_projet_Autres(e.value) }} 
                // value={values.NiveauDiplome || ""} 
                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
              />
          </Grid>
          <Button 
            variant="contained" 
            color="primary" 
            type="submit" 
            sx={{ mt: "25px" }}
            onClick={() => { 
              // console.log("bouton cliqué 0000000000000000000000 ")

              createCandidateComptencies_Autres()
              history.push("/outfrapplypermanentfr/home")
            }}
            >
              Sauvegarder votre tableau de compétences
          </Button>

          </form>
        }

              
      </Box>
    }
    </>
  );
}

export default OutFrFreelanceCandidateCompetencies;
