import {
  ViewListIcon,
  ArrowCircleDownIcon,
  PencilAltIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/outline";
import JobApplicationTitle from "components/employer/jobApplicationTitle";
import JobApplicationNote from "components/employer/jobApplicationNote";
import JobAppGeneralAnalysis from "components/employer/jobAppGeneralAnalysis";
import JobAppScoreReport from "components/employer/jobAppScoreReport";
import UserCandidateNotes from "components/superAdmin/userCandidateNotes";
import UserManagementNotes from "components/employer/userManagementNotes";

import JobAppFormForOutFrProfile from "components/employer/jobAppFormForOutFrProfile";
import ProfileCompetenciesoutFrCandidate from "components/employer/profileCompetenciesoutFrCandidate";
import CvForOutFrCandidateUser from "components/employer/cvForOutFrCandidateUser";
import UserPipeline from "components/employer/userPipeline";
import UserManagementActions from "components/employer/userManagementActions";
import {options_jobApplicationPipeline} from "options";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import VideoPlayer from "react-video-js-player";
import DownloadLink from "react-download-link";
import { ClipLoader } from "react-spinners";
import { 
  Container, 
  Button, 
  Col, 
  Card, 
  CardImg, 
  FormGroup, 
  InputGroup, 
  InputGroupAddon, 
  CardHeader, 
  Table, 
  CardBody, 
  Form,
  Input,
} from "reactstrap";
import Select from "react-select";
import { Grid, TextField } from "@mui/material";

import { UserIcon } from "@heroicons/react/outline";
import "../custom.css";
import Row from "reactstrap/lib/Row";
import config from "config";

import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
// import 'assets/css/react_pdf_css.css';

// import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

// pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;




const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});
function UserProfile(props) {
  const history = useHistory();
  // console.log("11111111111111111111111111111: ShowApplicationEmployerV4");
  // console.log("11111111111111111111111111111: ShowApplicationEmployerV4: candidatId: ", candidatId);
  const [candidateUser, setCandidateUser] = useState({});
  

  const [videos, setVideos] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState();
  const [currentQuestionType, setCurrentQuestionType] = useState();
  const [currentQCMQuestionOption1, setCurrentQCMQuestionOption1] = useState();
  const [currentQCMQuestionOption2, setCurrentQCMQuestionOption2] = useState();
  const [currentQCMQuestionOption3, setCurrentQCMQuestionOption3] = useState();
  const [currentQCMQuestionOption4, setCurrentQCMQuestionOption4] = useState();
  const [currentQCMQuestionOption5, setCurrentQCMQuestionOption5] = useState();
  const [currentQCMQuestionOption6, setCurrentQCMQuestionOption6] = useState();
  const [currentQCMQuestionSelectedOption, setCurrentQCMQuestionSelectedOption] = useState();
  const [currentQCMQuestionCorrectOption, setCurrentQCMQuestionCorrectOption] = useState();

  


  
  const [candidat, setCandidat] = useState("");
  const [jobApplicationCV, setJobApplicationCV] = useState("");
  const [drive, setDrive] = useState("");
  const [hasDrive, setHasdrive] = useState(false);
  const [motivLttr, setMotivLttr] = useState(false);
  const [applicationPipeline, setApplicationPipeline] = useState("");

  const [openTab, setOpenTab] = useState(1);
  const [jobApplicationForm, setJobApplicationForm] = useState("");
  const [jobApplicationFormCV, setJobApplicationFormCV] = useState("");
  const [jobApplicationFormWhatsappNb, setJobApplicationFormWhatsappNb] = useState("");
  const [jobApplicationFormWhatsappNbFiltered, setJobApplicationFormWhatsappNbFiltered] = useState("");

  const [jobApplicationCompetencies, setJobApplicationCompetencies] = useState("");
  const [profileAnalysis, setProfileAnalysis] = useState("");
  const [candidateCompetenciesAnalysis, setCandidateCompetenciesAnalysis] = useState("");
  const [jobApplicationNotes, setJobApplicationNotes] = useState(null);
  const [otherJobApplicationNotes, setOtherJobApplicationNotes] = useState([]);
  const [addThisNote, setAddThisNote] = useState("");
  const [newAddedNote, setNewAddedNote] = useState(0);
  const [newAddedNoteText, setNewAddedNoteText] = useState("");
  const [allJobApplicationsForThisUser, setAllJobApplicationsForThisUser] = useState([]);
  const [jobID, setJobID] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [job, setJob] = useState({});

  
  
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
  const user = JSON.parse(localStorage.getItem("user"));

	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};

	const goToPrevPage = () =>
		setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

	const goToNextPage = () =>
		setPageNumber(
			pageNumber + 1 >= numPages ? numPages : pageNumber + 1,
		);




  const updatePipeline = async (pipeline) => {
    // var pipeline = "Rejeté";
    const response = await instance.put(`jobApplication/updatePipeline/${props.candidatId}`, {pipeline,});
    // console.log("ccccccccccccccccccccccccc : response: ", response);
    if (response.data.success) {
      // console.log("aaaaaaaaaaaaaaaaaaaaaaaa: success");
      setApplicationPipeline(pipeline);
    } else {
      // console.log("bbbbbbbbbbbbbbbbbbbbbbbb: failure");
    }
  };

  const fetchForm = async () => {
    // console.log("0000000000000000000000000000:");
    // console.log("1111111111111111111111111111: props.candidateUserID: ", props.candidateUserID);
    
    instance.get(`/outFrSearchCdiFrProfiles/getprofilebyuserid/${props.candidateUserID}`).then((resp) => {
      setJobApplicationForm(resp.data);
      setJobApplicationFormCV(resp.data.CV);
      setJobApplicationFormWhatsappNb(resp.data.whatsappnumber);
      let input = resp.data.whatsappnumber;
      let input_filtered = input.replace(/[|&;$%@"<>()+,]/g, "");
      setJobApplicationFormWhatsappNbFiltered(input_filtered);
      console.log("33333333333333333333: fetchForm: input_filtered", input_filtered);
      // console.log("33333333333333333333: fetchForm: resp: ", resp);
      // console.log("33333333333333333333: fetchForm: resp.data: ", resp.data);
      });
};
const fetchCandidateCompetencies = async () => {
  // console.log("222222222222222222: fetchCandidateCompetencies: step 0: ");
  // console.log("222222222222222222: fetchCandidateCompetencies: step 1: fetchCandidateCompetencies: ", props.candidateUserID);
  
  instance.get(`/candidatesCompetencies/getCompetenciesByUserID/${props.candidateUserID}`).then((resp) => {
    setJobApplicationCompetencies(resp.data);
    // console.log("222222222222222222: fetchCandidateCompetencies: step 2:");
    // console.log("222222222222222222: fetchCandidateCompetencies: step 3 : resp: ", resp);
    // console.log("222222222222222222: fetchCandidateCompetencies: step 4 : resp.data: ", resp.data);
    });
};

const fetchJobApplication = async () => {
  instance
  .get(`jobs/candidat/${props.candidatId}`)
  .then((res) => {
    // console.log("ddddddddddddddddddddddddddddddddddd:");
    // console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee: res: ", res);
    setCandidat(res.data);
    setJobApplicationCV(res.data.CV);
    setJobID(res.data.jobID);

    // console.log("fffffffffffffffffffffffffff : res.data: ", res.data);
    setApplicationPipeline(res.data.pipeline);
    if (res.data.drive) {
      setHasdrive(!hasDrive);
    }
    if (res.data.videos.length) {
      setVideos(res.data.videos);
      // if (!res.data.merged) {
      //   startMerge(res.data);
      // }
      // console.log(res.data.videos);
    }
  })
  .catch((err) => {
    console.log(err);
  });
};
const fetchProfileAnalysis = async () => {
  // console.log("333333333333333333: fetchProfileAnalysis: step 0: ");
  // console.log("333333333333333333: fetchProfileAnalysis: step 1: ", props.candidateUserID);
  
  instance.get(`/jobApplicationAnalysis/getGeneralValidationByUserID/${props.candidateUserID}`).then((resp) => {
    setProfileAnalysis(resp.data);
    // console.log("333333333333333333: fetchProfileAnalysis: step 2:");
    // console.log("333333333333333333: fetchProfileAnalysis: step 3 : resp: ", resp);
    // console.log("333333333333333333: fetchProfileAnalysis: step 4 : resp.data: ", resp.data);
    });
};

const fetchCandidateCompetenciesAnalysis = async () => {
  // console.log("333333333333333333: fetchCandidateCompetenciesAnalysis: step 0: ");
  // console.log("333333333333333333: fetchCandidateCompetenciesAnalysis: step 1: ", props.candidateUserID);
  
  instance.get(`/jobApplicationAnalysis/getCompetenciesAnalysisByUserID/${props.candidateUserID}`).then((resp) => {
    setCandidateCompetenciesAnalysis(resp.data);
    // console.log("333333333333333333: fetchCandidateCompetenciesAnalysis: step 2:");
    // console.log("333333333333333333: fetchCandidateCompetenciesAnalysis: step 3 : resp: ", resp);
    // console.log("333333333333333333: fetchCandidateCompetenciesAnalysis: step 4 : resp.data: ", resp.data);
    });
};
const fetchJobApplicationNotes = async () => {
  // console.log("333333333333333333: fetchJobApplicationNotes: step 0: ");
  // console.log("333333333333333333: fetchJobApplicationNotes: step 1: ", candidatId);
  
  instance.get(`/jobApplicationNotes/getNotesByJobApplicationID/${props.candidatId}`).then((resp) => {
    if (resp.data.length) {
      setJobApplicationNotes(resp.data);
    }
    
    // console.log("333333333333333333: fetchJobApplicationNotes: step 2:");
    // console.log("333333333333333333: fetchJobApplicationNotes: step 3 : resp: ", resp);
    // console.log("333333333333333333: fetchJobApplicationNotes: step 4 : resp.data: ", resp.data);
    });
};
const fetchOtherNotesForThisCandidate = async () => {
    console.log("333333333333333333: fetchOtherNotesForThisCandidate v5: step 0: ");
  
  instance.get(`/jobApplicationNotes/getNotesByCandidateID/${props.candidateUserID}`).then((resp) => {
    if ( resp.data && resp.data.length && resp.data.length !== null && resp.data.length !== 0  && resp.data[0]._id && resp.data[0]._id !== null) {
      console.log("333333333333333333: fetchOtherNotesForThisCandidate v5: step 1: resp.data.length:", resp.data.length);
      setOtherJobApplicationNotes(resp.data);
    }
    
    console.log("333333333333333333: fetchOtherNotesForThisCandidate v5: step 2:");
    console.log("333333333333333333: fetchOtherNotesForThisCandidate v5: step 3 : resp: ", resp);
    console.log("333333333333333333: fetchOtherNotesForThisCandidate v5: step 4 : resp.data: ", resp.data);
    });
};



const fetchAllJobApplicationsForThisUser = async () => {
  // console.log("333333333333333333: fetchJobApplicationNotes: step 0: ");
  // console.log("333333333333333333: fetchJobApplicationNotes: step 1: ", candidatId);
  
  instance.get(`/jobApplication/getJobApplicationsByUserID/${props.candidateUserID}`).then((resp) => {
    if (resp.data.length) {
      setAllJobApplicationsForThisUser(resp.data);
    }
    
    // console.log("333333333333333333: fetchJobApplicationNotes: step 2:");
    // console.log("333333333333333333: fetchJobApplicationNotes: step 3 : resp: ", resp);
    // console.log("333333333333333333: fetchJobApplicationNotes: step 4 : resp.data: ", resp.data);
    });
};



const createJobApplicationNote = async () => {
  let jobApplicationID = props.candidatId;
  let note = addThisNote;
  let candidateID = props.candidateUserID;
  let recruiterID = user._id;



  // console.log("444444444444444444: createJobApplicationNote: step 0: ");
  // console.log("444444444444444444: createJobApplicationNote: step 1: ", candidatId);
  // console.log("444444444444444444: createJobApplicationNote: step 5 addThisNote: ", addThisNote);
  // console.log("444444444444444444: createJobApplicationNote: step 6 formData: ", formData);
  // console.log("444444444444444444: createJobApplicationNote: step 7 inputs: ", inputs);
  
  instance.post("/jobApplicationNotes/createNote", 
  {
    jobApplicationID,
    candidateID,
    jobID,
    recruiterID,
    note,
  }
  ).then((resp) => {
    console.log("444444444444444444: createJobApplicationNote: step 2:");
    console.log("444444444444444444: createJobApplicationNote: step 3 : resp: ", resp);
    console.log("444444444444444444: createJobApplicationNote: step 4 : resp.data: ", resp.data);
    });
};

const fetchJobData = () => {
  if (jobID !=="") {
    instance.get(`jobs/${jobID}`).then((resp) => {
      if (resp.data !== null) {
        if ((resp.data.job !== null)) {
          if ((resp.data.job.title !== null)) {
          setJob(resp.data.job);
          setJobTitle(resp.data.job.title);
          // console.log("5555555555555555555555555555555555: fetchJobData: step 2:");
          // console.log("5555555555555555555555555555555555: fetchJobData: step 3 : resp: ", resp);
          // console.log("5555555555555555555555555555555555: fetchJobData: step 4 : resp.data: ", resp.data);
          // console.log("5555555555555555555555555555555555: fetchJobData: step 4 : resp.data.job.title: ", resp.data.job.title);
          }
        }
      }
    });

  }


};

const getCandidateUser = () => {

  instance.get(`users/getprofilebyuserid/${props.candidateUserID}`).then((resp) => {
    // setParticipants(resp.data.job);
    // console.log("00000000000000000000000000 oneUserCardSuperAdmin getCandidate: resp.data : ");
    // console.log("00000000000000000000000000 oneUserCardSuperAdmin getCandidate: resp.data : ", resp.data);
    setCandidateUser(resp.data);
  });


};


   useEffect(() => {
    getCandidateUser();
    fetchJobApplication();
    fetchForm();
    fetchCandidateCompetencies();
    fetchProfileAnalysis();
    fetchCandidateCompetenciesAnalysis();
    fetchJobApplicationNotes();
    fetchAllJobApplicationsForThisUser();
    fetchJobData();
    fetchOtherNotesForThisCandidate();
  
  }, []);

  const videoUrlHandler = (videoUrl, CurrentQType, currentQ) => {
    setVideoUrl(videoUrl);
    setCurrentQuestionType(CurrentQType);
    setCurrentQuestion(currentQ);
  };
  const QCMQuestionVideoUrlHandler = (videoUrl, CurrentQType, currentQ, QCMQuestionOption1, QCMQuestionOption2, QCMQuestionOption3, QCMQuestionOption4, QCMQuestionOption5, QCMQuestionOption6, qcmSelectedOption, QCMCorrectOption) => {
    setVideoUrl(videoUrl);
    setCurrentQuestionType(CurrentQType);
    setCurrentQuestion(currentQ);
    setCurrentQCMQuestionOption1(QCMQuestionOption1);
    setCurrentQCMQuestionOption2(QCMQuestionOption2);
    setCurrentQCMQuestionOption3(QCMQuestionOption3);
    setCurrentQCMQuestionOption4(QCMQuestionOption4);
    setCurrentQCMQuestionOption5(QCMQuestionOption5);
    setCurrentQCMQuestionOption6(QCMQuestionOption6);
    setCurrentQCMQuestionSelectedOption(qcmSelectedOption);
    setCurrentQCMQuestionCorrectOption(QCMCorrectOption);

  };
  const setInstalled = (id) => {
    var installed = true;
    instance.put(`jobs/installed/${id}`, {
      installed,
    });
  };

  const refreshCandidat = () => {
    instance
      .get(`jobs/candidat/${props.candidatId}`)
      .then((res) => {
        setCandidat(res.data);
      });
  };


  return (
    <>
      <div
        className="flex flex-col px-4 space-y-2 bg-white h-1/3 "
        style={{ marginTop: "" }}
      >
        <div className="flex items-center  ">
          <CardImg
            variant=""
            className="rounded-circle"
            style={{
              width: "80px",
              height: "80px",
              alignSelf: "center",
              objectFit: "cover",
            }}
            alt="Responsive image"
            src={"assets/img/brand/500.png"}
            onError={(e) => {
              e.target.src =
                // require("assets/img/brand/500.png").default;
                require("assets/img/brand/500.png").default;
                
            }}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <div className="pt-2 font-bold    ">
            <span
              className="pt-2 left-0 font-bold "
              style={{ display: "inline-flex" }}
            >
              Prénom:&nbsp;<h4> {candidateUser.name}</h4>
            </span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span
              className="pt-2 font-bold   "
              style={{ display: "inline-flex" }}
            >
              Nom:&nbsp; <h4>{candidateUser.lastName} </h4>
            </span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span
              className="pt-2 right-0 font-bold   "
              style={{ display: "inline-flex" }}
            >
              Email:&nbsp; <h4>{candidateUser.email}</h4>
            </span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span
              className="pt-2 right-0 font-bold   "
              style={{ display: "inline-flex" }}
            >
              Pays:&nbsp; <h4>{candidateUser.location}</h4>
            </span>
          </div>
        </div>


        <UserPipeline
          managedUserID = {props.candidateUserID}
        />
        <Row>
          <Col className="order-xl-1">
            <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
                <Table className="GatTable">
                  <tbody>
                    <tr>

                        <td
                          className={openTab === 2 ? "tdActive " : "td "}
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(2);
                          }}
                          data-toggle="tab"
                          href="#link2"
                          role="tablist"
                          //onClick={() => history.push('/admin/edit-profile')}
                          size="sm"
                        >
                          <i className="fa fa-question-circle" aria-hidden="true" />{" "}
                          Formulaire
                        </td>

                        <>
                          <td
                            className={
                              (openTab === 3)
                                ? "tdActive "
                                : "td "
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(3);}}
                            data-toggle="tab"
                            href="#link3"
                            role="tablist"
                            //onClick={() => history.push('/admin/edit-profile')}
                            size="sm"
                          >
                            <i className="fa fa-users" aria-hidden="true" />{" "}
                            CV
                          </td>
                        </>
                        <>
                          <td
                            className={
                              (openTab === 4)
                                ? "tdActive "
                                : "td "
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(4);}}
                            data-toggle="tab"
                            href="#link4"
                            role="tablist"
                            //onClick={() => history.push('/admin/edit-profile')}
                            size="sm"
                          >
                            <i className="fa fa-users" aria-hidden="true" />{" "}
                            Compétences
                          </td>
                        </>
                        <>
                          <td
                            className={
                              (openTab === 5)
                                ? "tdActive "
                                : "td "
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(5);}}
                            data-toggle="tab"
                            href="#link5"
                            role="tablist"
                            //onClick={() => history.push('/admin/edit-profile')}
                            size="sm"
                          >
                            <i className="fa fa-users" aria-hidden="true" />{" "}
                            Analyse
                          </td>
                        </>
                        <>
                          <td
                            className={
                              (openTab === 6)
                                ? "tdActive "
                                : "td "
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(6);}}
                            data-toggle="tab"
                            href="#link6"
                            role="tablist"
                            //onClick={() => history.push('/admin/edit-profile')}
                            size="sm"
                          >
                            <i className="fa fa-users" aria-hidden="true" />{" "}
                            Remarques
                          </td>
                          <td
                            className={
                              (openTab === 7)
                                ? "tdActive "
                                : "td "
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(7);}}
                            data-toggle="tab"
                            href="#link7"
                            role="tablist"
                            //onClick={() => history.push('/admin/edit-profile')}
                            size="sm"
                          >
                            <i className="fa fa-users" aria-hidden="true" />{" "}
                            Entretiens
                          </td>
                        </>
                    </tr>
                  </tbody>
                </Table>
              </CardHeader>

              {openTab === 2 &&
              <CardBody
                className={openTab === 2 ? "block" : "hidden"}
                id="link2"
              >
                <JobAppFormForOutFrProfile
                  jobApplicationUserID = {props.candidateUserID}
                />
              </CardBody>
              }
              {openTab === 3 &&
              <Container fluid>
                <CardBody
                className={openTab === 3 ? "block" : "hidden"}
                id="link3"
              >
              <CvForOutFrCandidateUser
                candidateUserID = {props.candidateUserID}
              />

              </CardBody>

              </Container>

              }
             {openTab === 4 &&
              <CardBody
                className={openTab === 4? "block" : "hidden"}
                id="link4"
              >
                { /* console.log("Compétences") */}
                <ProfileCompetenciesoutFrCandidate
                  candidateUserID = {props.candidateUserID}
                />

              </CardBody>
              }
              {openTab === 5 &&
                <CardBody
                  className={openTab === 5? "block" : "hidden"}
                  id="link5"
                >
                  {/* console.log("77777777777777777: showJobAppForOutFrCandidateEmployerV6: ") */}
                  {/* console.log("77777777777777777: showJobAppForOutFrCandidateEmployerV6: user.employerCampanyID", user.employerCampanyID) */}
                    <JobAppGeneralAnalysis
                      candidatId = {props.candidatId}
                    />



                </CardBody>
              }
              {openTab === 6 &&
              <CardBody
                className={openTab === 6? "block" : "hidden"}
                id="link6"
              >
                <UserManagementNotes
                  candidateUserID = {props.candidateUserID}
                />
                <hr className="my-4" />
                <UserCandidateNotes
                  candidateUserID = {props.candidateUserID}
                />
                <hr className="my-4" />
                <UserManagementActions
                  candidateUserID = {props.candidateUserID}
                />
              </CardBody>
              }
              {openTab === 7 &&
              <CardBody
                className={openTab === 7? "block" : "hidden"}
                id="link7"
              >
                <form>
                  <h4 className=" mb-4">Entretiens </h4>
                  <Grid item xs={12}>
                    <label className="px-lg-3">Les entretiens passés par le candidat sont:</label>

                    </Grid>

                    {allJobApplicationsForThisUser &&
                      allJobApplicationsForThisUser.map((oneJobApplication) => (
                        
                        <JobApplicationTitle
                          oneJobApplicationID={oneJobApplication._id}
                          jobID={oneJobApplication.jobID}
                          key={oneJobApplication._id}
                        />                   

                      ))}
                    {!allJobApplicationsForThisUser &&
                      <p>Ce candidat n'a éalisé aucun entretien</p>
                    }


                </form>


              </CardBody>
              }
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default UserProfile;
