import {
  ViewListIcon,
  ArrowCircleDownIcon,
  PencilAltIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/outline";
import JobApplicationTitle from "components/employer/jobApplicationTitle";
import JobApplicationNote from "components/employer/jobApplicationNote";
import {options_jobApplicationPipeline} from "options";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import VideoPlayer from "react-video-js-player";
import DownloadLink from "react-download-link";
import { ClipLoader } from "react-spinners";
import { 
  Container, 
  Button, 
  Col, 
  Card, 
  CardImg, 
  FormGroup, 
  InputGroup, 
  InputGroupAddon, 
  CardHeader, 
  Table, 
  CardBody, 
  Form,
  Input,
} from "reactstrap";
import Select from "react-select";
import { Grid, TextField } from "@mui/material";

import { UserIcon } from "@heroicons/react/outline";
import "../custom.css";
import Row from "reactstrap/lib/Row";
import config from "config";

import { Document, Page, pdfjs } from "react-pdf";
import { useTranslation } from "react-i18next";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
// import 'assets/css/react_pdf_css.css';

// import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

// pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;




const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});
function JobAppScoreReport(props) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  // console.log("11111111111111111111111111111: ShowApplicationEmployerxx");
  // console.log("11111111111111111111111111111: ShowApplicationEmployerxx: props.candidatId: ", props.candidatId);

  

  const [videos, setVideos] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState();
  const [currentQuestionType, setCurrentQuestionType] = useState();
  const [currentQCMQuestionOption1, setCurrentQCMQuestionOption1] = useState();
  const [currentQCMQuestionOption2, setCurrentQCMQuestionOption2] = useState();
  const [currentQCMQuestionOption3, setCurrentQCMQuestionOption3] = useState();
  const [currentQCMQuestionOption4, setCurrentQCMQuestionOption4] = useState();
  const [currentQCMQuestionOption5, setCurrentQCMQuestionOption5] = useState();
  const [currentQCMQuestionOption6, setCurrentQCMQuestionOption6] = useState();
  const [currentQCMQuestionSelectedOption, setCurrentQCMQuestionSelectedOption] = useState();
  const [currentQCMQuestionCorrectOption, setCurrentQCMQuestionCorrectOption] = useState();

  


  
  const [candidat, setCandidat] = useState("");
  const [jobApplicationCV, setJobApplicationCV] = useState("");
  const [drive, setDrive] = useState("");
  const [hasDrive, setHasdrive] = useState(false);
  const [motivLttr, setMotivLttr] = useState(false);
  const [applicationPipeline, setApplicationPipeline] = useState("");

  const [openTab, setOpenTab] = useState(1);
  const [jobApplicationForm, setJobApplicationForm] = useState("");
  const [jobApplicationFormCV, setJobApplicationFormCV] = useState("");

  const [jobApplicationCompetencies, setJobApplicationCompetencies] = useState("");
  const [jobApplicationUserID, setJobApplicationUserID] = useState("");
  const [profileAnalysis, setProfileAnalysis] = useState("");
  const [candidateCompetenciesAnalysis, setCandidateCompetenciesAnalysis] = useState("");
  const [jobApplicationNotes, setJobApplicationNotes] = useState(null);
  const [otherJobApplicationNotes, setOtherJobApplicationNotes] = useState([]);
  const [addThisNote, setAddThisNote] = useState("");
  const [newAddedNote, setNewAddedNote] = useState(0);
  const [newAddedNoteText, setNewAddedNoteText] = useState("");
  const [allJobApplicationsForThisUser, setAllJobApplicationsForThisUser] = useState([]);
  const [jobID, setJobID] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [job, setJob] = useState({});

  
  
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
  const user = JSON.parse(localStorage.getItem("user"));










  const fetchForm = async () => {
    // console.log("0000000000000000000000000000:");
    // console.log("1111111111111111111111111111: jobApplicationUserID: ", jobApplicationUserID);
    
    instance.get(`/outFrSearchCdiFrProfiles/getprofilebyuserid/${jobApplicationUserID}`).then((resp) => {
      setJobApplicationForm(resp.data);
      setJobApplicationFormCV(resp.data.CV);
      // console.log("33333333333333333333: fetchForm: ");
      // console.log("33333333333333333333: fetchForm: resp: ", resp);
      // console.log("33333333333333333333: fetchForm: resp.data: ", resp.data);
      });
};


const fetchJobApplication = async () => {
  instance
  .get(`jobs/candidat/${props.candidatId}`)
  .then((res) => {
    // console.log("ddddddddddddddddddddddddddddddddddd:");
    // console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee: res: ", res);
    setCandidat(res.data);
    setJobApplicationUserID(res.data.userID);
    setJobApplicationCV(res.data.CV);
    setJobID(res.data.jobID);

    // console.log("fffffffffffffffffffffffffff : res.data: ", res.data);
    setApplicationPipeline(res.data.pipeline);
    if (res.data.drive) {
      setHasdrive(!hasDrive);
    }
    if (res.data.videos.length) {
      setVideos(res.data.videos);
      // if (!res.data.merged) {
      //   startMerge(res.data);
      // }
      // console.log(res.data.videos);
    }
  })
  .catch((err) => {
    console.log(err);
  });
};
const fetchProfileAnalysis = async () => {
  // console.log("333333333333333333: fetchProfileAnalysis: step 0: ");
  // console.log("333333333333333333: fetchProfileAnalysis: step 1: ", jobApplicationUserID);
  
  instance.get(`/jobApplicationAnalysis/getGeneralValidationByUserID/${jobApplicationUserID}`).then((resp) => {
    setProfileAnalysis(resp.data);
    // console.log("333333333333333333: fetchProfileAnalysis: step 2:");
    // console.log("333333333333333333: fetchProfileAnalysis: step 3 : resp: ", resp);
    // console.log("333333333333333333: fetchProfileAnalysis: step 4 : resp.data: ", resp.data);
    });
};

const fetchCandidateCompetenciesAnalysis = async () => {
  // console.log("333333333333333333: fetchCandidateCompetenciesAnalysis: step 0: ");
  // console.log("333333333333333333: fetchCandidateCompetenciesAnalysis: step 1: ", jobApplicationUserID);
  
  instance.get(`/jobApplicationAnalysis/getCompetenciesAnalysisByUserID/${jobApplicationUserID}`).then((resp) => {
    setCandidateCompetenciesAnalysis(resp.data);
    // console.log("333333333333333333: fetchCandidateCompetenciesAnalysis: step 2:");
    // console.log("333333333333333333: fetchCandidateCompetenciesAnalysis: step 3 : resp: ", resp);
    // console.log("333333333333333333: fetchCandidateCompetenciesAnalysis: step 4 : resp.data: ", resp.data);
    });
};











const fetchJobData = () => {
  if (jobID !=="") {
    instance.get(`jobs/${jobID}`).then((resp) => {
      if (resp.data !== null) {
        if ((resp.data.job !== null)) {
          if ((resp.data.job.title !== null)) {
          setJob(resp.data.job);
          setJobTitle(resp.data.job.title);
          // console.log("5555555555555555555555555555555555: fetchJobData: step 2:");
          // console.log("5555555555555555555555555555555555: fetchJobData: step 3 : resp: ", resp);
          // console.log("5555555555555555555555555555555555: fetchJobData: step 4 : resp.data: ", resp.data);
          // console.log("5555555555555555555555555555555555: fetchJobData: step 4 : resp.data.job.title: ", resp.data.job.title);
          }
        }
      }
    });

  }


};




   useEffect(() => {
    fetchJobApplication();
    fetchForm();
    fetchProfileAnalysis();
    fetchCandidateCompetenciesAnalysis();
    fetchJobData();
  
  }, [props.candidatId, jobID, jobApplicationUserID]);







  return (
    <>

        <Row>
          <Col className="order-xl-1">
            <Card className="bg-secondary shadow">

                <Form>
                  <h4 className=" mb-4">{t('AnalysisKey1')} </h4>
                  {(profileAnalysis.Validation_or_not) &&
                    <p className=" mb-4">{t('AnalysisKey2')}: {profileAnalysis.Validation_or_not}</p>
                  }
                  {(profileAnalysis.motif_de_rejet_general) &&
                    <p className=" mb-4">{t('AnalysisKey3')}: {profileAnalysis.motif_de_rejet_general}</p>
                  }
                  <h4 className=" mb-4">{t('AnalysisKey4')} </h4>
                  {(candidateCompetenciesAnalysis.Validation_or_not_by_domain_Validation) &&
                    <p className=" mb-4">{t('AnalysisKey2')}: {candidateCompetenciesAnalysis.Validation_or_not_by_domain_Validation}</p>
                  }
                  {(candidateCompetenciesAnalysis.Validation_or_not_by_domain_Motif) &&
                    <p className=" mb-4">{t('AnalysisKey3')}: {candidateCompetenciesAnalysis.Validation_or_not_by_domain_Motif}</p>
                  }
                </Form>

            </Card>
          </Col>
        </Row>
    </>
  );
}

export default JobAppScoreReport;
