import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { options_videosPermission } from 'options';
import PageSpace from "components/styles/pageSpace";
import PageTitleStyle from "components/styles/PageTitleStyle";


// reactstrap components
import {
  Container,
  CardHeader,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Button,
  Alert,
} from "reactstrap";
import CandidateLayoutV3 from "layouts/CandidateLayoutV3";
import { options_Pays } from "options";
import Select from "react-select";

// core components
import { edit } from "../../network/ApiAxios";
import axios from "axios";
import config from "config";

import EmployerLayout from "layouts/EmployerLayout";

// const https = require('https');
//
// const agent = new https.Agent({
//     rejectUnauthorized: false,
// });

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});

const OutFranceCandidatePreferencesView = (props) => {
  const [msg, setMsg] = useState('')

  const history = useHistory();
  const [outFrVideosPermission, setOutFrVideosPermission] = useState("");
  let user = JSON.parse(localStorage.getItem("user"));




  useEffect(() => {
    if (user.outFrVideosPermission) {
      setOutFrVideosPermission(user.outFrVideosPermission);
    }
  }, []);

  // const editUser = async () => {
  const editUser = () => {
    setMsg('')

    // console.log("outFranceCandidatePreferencesView: editUser: ");
    // console.log("outFranceCandidatePreferencesView: editUser: userID: ", user._id);
    // console.log("outFranceCandidatePreferencesView: editUser: outFrVideosPermission: ", outFrVideosPermission);
    var data = JSON.stringify({
      "userID": user._id,
      "outFrVideosPermission": outFrVideosPermission
    });

    axios({
      method: "post",
      url: `${config.WS_BASE_URL}users/editUserParameters`,
      data: data,
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        console.log("outFranceCandidatePreferencesView: editUser:");
        console.log("outFranceCandidatePreferencesView: editUser: res: ", res);
        console.log("outFranceCandidatePreferencesView: editUser: res.data.success: ", res.data.success);
        if (res.data.success) {
          {/*
                user = {
                ...user,
                outFrVideosPermission
                };
            */}

          user.outFrVideosPermission = outFrVideosPermission;
          localStorage.setItem("user", JSON.stringify(user));
          // localStorage.setItem("user", JSON.stringify(res.user));

          // setOutFrVideosPermission(user.outFrVideosPermission);
          // window.location.reload();
          //history.push( "/outfrapplypermanentfr/home");
          setMsg('Votre Préference a été sauvegardée avec succès')

        }
      }
      )
      .catch((err) => console.log(err));
    {/*
        const { data } = response;
        if (data.success) {
        user = {
            ...user,
            outFrVideosPermission
        };
        localStorage.setItem("user", JSON.stringify(data.user[0]));
        window.location.reload();
        }
    */}

  };

  return (
    <>
      <CandidateLayoutV3 />
      <Container>
        <PageSpace />
        <PageTitleStyle
          title="Préferences"
        >

        </PageTitleStyle>

        <Row className="justify-content-center border-0 py-2" style={{ width: "100%" }}>
          <Col xs="2" >
          </Col>
          <Col xs="10">
            <Card className="bg-secondary shadow">
              <CardBody className="bg-white">
                <Form>
                  <FormGroup xs={12}>
                    <label className="px-lg-3">Pour que votre candidature soit prise en compte, vous devez passer un entretien vidéo, est ce que vous acceptez que les entreprises en France accèdent à ces vidéos*</label>
                    <Select
                      //name="Niveau du diplome" 
                      //label="Niveau du diplome" 
                      name="videosPermission"
                      required
                      // placeholder="gender" 
                      className="w-full "
                      options={options_videosPermission}
                      value={
                        options_videosPermission.filter(option =>
                          option.value === outFrVideosPermission)
                      }
                      // placeholder={outFrVideosPermission}
                      placeholder={
                        options_videosPermission.filter(option =>
                          option.value === outFrVideosPermission)
                      }
                      //fullWidth 
                      //onBlur={handleBlur} 
                      // onChange={handleChange} 
                      //value={NiveauDiplome}
                      onChange={(e) => { setOutFrVideosPermission(e.value) }}
                    // value={values.NiveauDiplome || ""} 
                    //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                    //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                    />
                  </FormGroup>
                  <hr className="my-4" />
                  <FormGroup xs={12}>
                    <Button
                      color="primary"
                      // href="#pablo"
                      variant="contained"
                      sx={{ mt: "25px" }}
                      onClick={editUser}
                    //size="sm"
                    // disabled={isTestUser}
                    >
                      Sauvegarder
                    </Button>
                  </FormGroup>

                </Form>
                {msg && <Alert className='text-center w-50' color='success'>{msg}</Alert>}

              </CardBody>
            </Card>



          </Col>
        </Row>
      </Container>
    </>
  );
};

export default OutFranceCandidatePreferencesView;
