import axios from "axios";
import ReactPaginate from "react-paginate";
//import Card from "components/Card";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import ViewJob from "./ViewJob";
import { SearchIcon } from "@heroicons/react/outline";
import config from "config";
import "../app.css";
import oneJobCard from "./oneJobCard";
import CardJob from "components/Card";
import Header from "components/Headers/Header";
import {
  PencilAltIcon,
  LocationMarkerIcon,
  BriefcaseIcon,
  MailIcon,
  PhoneIcon,
  LinkIcon,
  CodeIcon,
  ArchiveIcon,
  OfficeBuildingIcon,
  PaperClipIcon,
} from "@heroicons/react/solid";
import {
  Button,
  Form,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormText,
  FormFeedback,
  CardText,
} from "reactstrap";
import OneJob from "./oneJob";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});
function Offres() {
  const location = useLocation();
  const [jobs, setJobs] = useState([]);
  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState([]);
  const [perPage] = useState(6);
  const [pageCount, setPageCount] = useState(0);
  
  const history = useHistory();
  const query = qs.parse(location.search);
  const user = JSON.parse(localStorage.getItem("user"));
  const fetchData = async () => {
    instance.get("jobs").then((resp) => {
      setJobs(resp.data);
      const data = resp.data;
      const DATA = [];
      console.log("data", data);
      for (let i = data.length - 1; i >= 0; i--) {
        DATA.push(data[i]);
      }
      setData(DATA.slice(offset, offset + perPage));
      setPageCount(Math.ceil(data.length / perPage));
    });
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    console.log("selectedpage", selectedPage);
    setOffset(selectedPage + selectedPage * 5);
  };

  useEffect(() => {
  
    fetchData();
  }, [location, offset]);

  return (
    <>
    {jobs && !query.jobId && (
      <Container className="container-fluid ">
        <Row>
          <Card className="bg-transparent w-full">
            <CardHeader className="bg-transparent  w-full">
              <Row>
                <Col lg="8">
               
                </Col>
                <Col lg="4">
                  <div
                    className="flex items-center my-3 px-2 py-2 border-2 mx-auto rounded-full border-gray-400 max-w-md"
                    style={{ float: "right" }}
                  >
                    <input
                      type="text"
                      className="bg-transparent flex-grow text-black px-2"
                      placeholder="Search..."
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                    <SearchIcon className="h-6 text-blue-500" />
                  </div>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="grid grid-cols-1 gap-2 mx-2 my-3">
              {search && (
                <>
                  {jobs
                    .filter((job) => {
                      if (search === "") {
                        return job;
                      } else if (
                        job.title.toLowerCase().includes(search.toLowerCase())
                      ) {
                        return job;
                      }
                    })
                    .map((job) => (
                      <Row className=" px-2 py-3 flex  hover:black hover:border hover:border-black cursor-pointer duration-75 hover:z-20 bg-white shadow-lg rounded-sm" 
                       key={job._id}
                       onClick={() => 
                        user?(
                        user.role=="employer"? (history.push(`/admin/tab2?jobId=${job._id}`))
                                             : (history.push(`/candidat/tab2?jobId=${job._id}`))) 
                                             : (history.push(`/home/jobs?jobId=${job._id}`))
                      }>
                        <Col lg="2">
                          <img
                            src={`${config.Data_URL}${job.image}`}
                            onError={(e) => {
                              e.target.src =
                                require("assets/img/brand/500.png").default;
                            }}
                            style={{
                              width: "120x",
                              height: "120px",

                              objectFit: "cover",
                            }}
                            alt="Responsive image"
                          />
                        </Col>
                        <Col lg="8">
                          <div className="flex items-center space-x-3 mb-2">
                            <div>
                              <h3 className="card-t">{job.title}</h3>
                              <span
                                className="text-sm"
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  display: "-webkit-box",
                                  WebkitLineClamp:
                                    "2" /* number of lines to show */,
                                  WebkitBoxOrient: "vertical",
                                }}
                              >
                                {job.description}
                              </span>
                            </div>
                          </div>
                          <Row>
                            <Col lg="6">
                              <div style={{ display: "inline-flex" }}>
                                {" "}
                                <ArchiveIcon
                                  style={{ height: "1.2rem" }}
                                />{" "}
                                &nbsp; <span> {job.technologies}</span>{" "}
                              </div>
                            </Col>
                            <Col lg="6">
                              <div style={{ display: "inline-flex" }}>
                                {" "}
                                <PaperClipIcon
                                  style={{ height: "1.2rem" }}
                                />{" "}
                                &nbsp; <span> {job.contract}</span>{" "}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <div style={{ display: "inline-flex" }}>
                                {" "}
                                <OfficeBuildingIcon
                                  style={{ height: "1.2rem" }}
                                />{" "}
                                &nbsp; <span>{job.entreprise}</span>{" "}
                              </div>
                            </Col>
                            <Col lg="6">
                              <div style={{ display: "inline-flex" }}>
                                {" "}
                                <LocationMarkerIcon
                                  style={{ height: "1.2rem" }}
                                />{" "}
                                &nbsp; <span> {job.location}</span>{" "}
                              </div>
                            </Col>
                          </Row>
                          {/* <div>
                            Status:{" "}
                            <span
                              className={`${
                                job.status === "inactive"
                                  ? "text-red-600"
                                  : "text-green-600"
                              }`}
                            >
                              {job.status}
                            </span>
                          </div>
                          <div>
                            Updated on:{" "}
                            {`${job.updatedAt.slice(
                              0,
                              10
                            )} ${job.updatedAt.slice(11, 19)}`}
                          </div> */}
                        </Col>
                        <Col lg="2" className=" flex items-center">
                          <div>
                            <div className="text-center">
                              {job.createdAt.slice(0, 10)}
                            </div>
                            <Button
                             className="btn-secondary"
                              onClick={() => 
                                user?(
                                user.role=="employer"? (history.push(`/admin/tab2?jobId=${job._id}`))
                                                     : (history.push(`/candidat/tab2?jobId=${job._id}`))) 
                                                     : (history.push(`/home/jobs?jobId=${job._id}`))
                              }
                            >
                              Voir Plus
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    ))}
                </>
              )}
               {!search && (
                <>
                  {data
                    .filter((job) => {
                      if (search === "") {
                        return job;
                      } else if (
                        job.title.toLowerCase().includes(search.toLowerCase())
                      ) {
                        return job;
                      }
                    })
                    .map((job) => (
                      <Row className=" px-2 py-3 flex  hover:black hover:border hover:border-black cursor-pointer duration-75 hover:z-20 bg-white shadow-lg rounded-sm"
                        key={job._id}
                        onClick={() => 
                          user?(
                          user.role=="employer"? (history.push(`/admin/tab2?jobId=${job._id}`))
                                               : (history.push(`/candidat/tab2?jobId=${job._id}`))) 
                                               : (history.push(`/home/jobs?jobId=${job._id}`))
                        }>
                        <Col lg="2">
                          <img
                            src={`${config.Data_URL}${job.image}`}
                            onError={(e) => {
                              e.target.src =
                                require("assets/img/brand/500.png").default;
                            }}
                            style={{
                              width: "120x",
                              height: "120px",

                              objectFit: "cover",
                            }}
                            alt="Responsive image"
                          />
                        </Col>
                        <Col lg="8">
                          <div className="flex items-center space-x-3 mb-2">
                            <div>
                              <h3 className="card-t">{job.title}</h3>
                              <span
                                className="text-sm"
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  display: "-webkit-box",
                                  WebkitLineClamp:
                                    "2" /* number of lines to show */,
                                  WebkitBoxOrient: "vertical",
                                }}
                              >
                                {job.description}
                              </span>
                            </div>
                          </div>
                          <Row>
                            <Col lg="6">
                              <div style={{ display: "inline-flex" }}>
                                {" "}
                                <ArchiveIcon
                                  style={{ height: "1.2rem" }}
                                />{" "}
                                &nbsp; <span> {job.technologies}</span>{" "}
                              </div>
                            </Col>
                            <Col lg="6">
                              <div style={{ display: "inline-flex" }}>
                                {" "}
                                <PaperClipIcon
                                  style={{ height: "1.2rem" }}
                                />{" "}
                                &nbsp; <span> {job.contract}</span>{" "}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <div style={{ display: "inline-flex" }}>
                                {" "}
                                <OfficeBuildingIcon
                                  style={{ height: "1.2rem" }}
                                />{" "}
                                &nbsp; <span> {job.entreprise}</span>{" "}
                              </div>
                            </Col>
                            <Col lg="6">
                              <div style={{ display: "inline-flex" }}>
                                {" "}
                                <LocationMarkerIcon
                                  style={{ height: "1.2rem" }}
                                />{" "}
                                &nbsp; <span> {job.location}</span>{" "}
                              </div>
                            </Col>
                          </Row>
                          {/* <div>
                            Status:{" "}
                            <span
                              className={`${
                                job.status === "inactive"
                                  ? "text-red-600"
                                  : "text-green-600"
                              }`}
                            >
                              {job.status}
                            </span>
                          </div>
                          <div>
                            Updated on:{" "}
                            {`${job.updatedAt.slice(
                              0,
                              10
                            )} ${job.updatedAt.slice(11, 19)}`}
                          </div> */}
                        </Col>
                        <Col lg="2" className=" flex items-center">
                          <div>
                            <div className="text-center">
                              {job.createdAt.slice(0, 10)}
                            </div>
                            <Button
                             type="button"
                             color="primary"
                              onClick={() => 
                                user?(
                                  user.role=="employer"? (history.push(`/admin/tab2?jobId=${job._id}`))
                                                       : (history.push(`/candidat/tab2?jobId=${job._id}`))) 
                                                       : (history.push(`/home/jobs?jobId=${job._id}`))
                              }
                            >
                              Postuler
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    ))}
                </>
              )}
            </CardBody>
            <CardFooter className="bg-transparent  w-full">
              <ReactPaginate
                previousLabel={"<<"}
                nextLabel={">>"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={3}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </CardFooter>
          </Card>
        </Row>
      </Container>
    )}
      {query.jobId && <OneJob jobId={query.jobId} />}
    </>
  );
}

export default Offres;
