import React, {useState } from 'react'
import {Card, CardBody, CardImg, Col, Container, Row, Tooltip } from 'reactstrap'
import CandidateLayoutV3 from 'layouts/CandidateLayoutV3'
import TrainingImg from '../../assets/img/brand/training.avif';

const TrainingForCandidatesV1 = () => {

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);


    return (
        <>
            <CandidateLayoutV3 />
            <Container>
                <Row className='mt-5'>
                    <Col xs="2">
                    </Col>
                    <Col xs="10" className="d-flex flex-column justify-content-center align-items-center">
                        <Container>
                            <a
                                href='https://admin.topdevacademy.com/auth/login'
                                target='_blank'
                                rel="noreferrer"
                                id="TooltipExample"
                            >
                                <Card className="my-2 text-center p-4 shadow mb-5 bg-white rounded cursor-pointer"
                                    color="primary"
                                    outline>
                                    <CardBody className='d-flex justify-content-center'>
                                        <CardImg
                                            variant=""
                                            //className="rounded-circle"
                                            style={{
                                                width: "50%",
                                                height: "50%",
                                                alignSelf: "center",
                                                objectFit: "cover",
                                            }}
                                            alt="Responsive image 1"
                                            src={TrainingImg}
                                        />

                                    </CardBody>
                                </Card>
                            </a>
                            <Tooltip
                                isOpen={tooltipOpen}
                                target="TooltipExample"
                                toggle={toggle}
                            >
                                Cliquez sur l'mage pour plus de détails
                            </Tooltip>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default TrainingForCandidatesV1