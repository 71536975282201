import React, { useEffect, useState } from "react";
import axios from "axios";
import { optionsThink, optionsTimes } from "options";
import {
  ClockIcon,
  VideoCameraIcon,
  PlusCircleIcon,
  DuplicateIcon,
  ChatAltIcon,
  PencilAltIcon,
} from "@heroicons/react/solid";
import { ClipLoader } from "react-spinners";

// reactstrap components
import {
  Container,
  Label,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  CardImg,
} from "reactstrap";
import config from "config";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});
function EditJobAcceptanceCriteria(props) {
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [description, setDescription] = useState(null);
  const [data, setData] = useState(null);

  const getAcceptanceCriteriaData = () => {
    instance
      .get(`JobAcceptanceCriteria/getAcceptanceCriteriaByID/${props.AcceptanceCriteriaId}`)
      .then((res) => {
        console.log('props.AcceptanceCriteriaId', props.AcceptanceCriteriaId);
        setDescription(res.data.AcceptanceCriteria.description);
        console.log('setDescription', res.data.AcceptanceCriteria.description);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const updateAcceptanceCriteria = () => {
    instance
      .put(`JobAcceptanceCriteria/UpdateAcceptanceCritera/${props.AcceptanceCriteriaId}`, { description })
      .then((res) => {
        console.log('props.AcceptanceCriteriaId', props.AcceptanceCriteriaId);
        console.log('setDescription', description);
        console.log('ress', res.data);
        setData(description);
        setLoading(false);
        window.location.reload(); // Reload the page

      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAcceptanceCriteriaData();
  }, []);
 
  return (
    <>    
      <Container>
      {loading && (
            <div className="absolute flex justify-center items-center top-0 left-0 z-50 bg-gray-800 w-full h-full opacity-80">
              <ClipLoader color="#f00" />
            </div>
      )}
      {!loading && (
        <>
         <Row className="mb-4 mt-4">
                <Col lg="5" className="d-flex align-items-center">
                    <h3>{t('OfferDesKey4')} {t('AcceptanceCriteria')} </h3>
                </Col>
                <Col lg="5" className="d-flex align-items-center">
              <Input value={description} onChange={e => setDescription(e.target.value)} />
            </Col>
            </Row>
        <Row>
     
        </Row>
        
        <Row className="justify-content-center">
          <Button
            onClick={updateAcceptanceCriteria}
            className="mt-4"
            style={{
              backgroundColor: "#404954",
              borderColor: "#404954",
              color: "white",
            }}
            type="button"
          >
            {t('OfferDesKey4')}
          </Button>
        </Row>
        </>
      )
      }
      </Container>
    </>
  );
};

export default EditJobAcceptanceCriteria;
