import axios from "axios";
import React, { useEffect, useState } from "react";
// import ShowCandidat from "components/recruteur/ShowCandidat";
import ShowJobAppForOneJob from "components/employer/showJobAppForOneJob";
import JobApplicationCardEmployer from "components/employer/jobApplicationCardEmployer";
//import OneInterview from "components/recruteur/OneInterview";
import { useHistory, useLocation, Link } from "react-router-dom";
import qs from "query-string";
//import ShowCandidat from "./ShowCandidat";

import {
  DropdownMenu,
  Media,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormText,
  FormFeedback,
  CardText,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Table,
  ModalHeader,
} from "reactstrap";
//import {getAll} from "../../network/ApiAxios";
import config from "config";
import { set } from "react-hook-form";
import { useTranslation } from "react-i18next";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});
function JobApplicationsCardsEmployerV3( props ) {
  const [candidats, setCandidats] = useState([]);
  
  const [users, setUsers] = useState([]);
  const [participants, setParticipants] = useState();
  const [job, setJob] = useState({});
  const [jobApplications, setJobApplications] = useState([]);
  const history = useHistory();
  const location = useLocation();
  // const query = qs.parse(location.search);
  const [search, setSearch] = useState("");
  const [jobAppDate, setJobAppDate] = useState("");
  const [checkbox, setCheckbox] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalDelOpen, setModalDelOpen] = useState(false);
  const [candidatID, setCandidatID] = useState("");
  const [participantId, setParticipantId] = useState("");
  const [candidatModal, setCandidatModal] = useState();
  const [check, setCheck] = useState(1);

  const [createdAt, setCreatedAt] = useState("");
  const [reloadWindow, setReloadWindow] = useState(false);  
  const [jobApplicationNotes, setJobApplicationNotes] = useState([]);

  const job_ID = props.jobID;
  // const job_ID = "627d34086543d2ab235e814a";

  const { t, i18n } = useTranslation();
  

  const getJob = () => {
    instance.get(`jobs/${job_ID}`).then((resp) => {
      // setParticipants(resp.data.job);
      // console.log("00000000000000000000000000 JobApplicationsEmployerV3 getJob: resp.data : ", resp.data);
      setJob(resp.data.job);
    });
  };

  const getJobApplications = () => {
    // console.log("1111111111111111111111111111 JobApplicationsEmployerV3");
    // console.log("1111111111111111111111111111 JobApplicationsEmployerV3 jobID", jobID);

    // console.log("1111111111111111111111111111 JobApplicationsEmployerV3");
    // console.log("1111111111111111111111111111 JobApplicationsEmployerV3 job_ID", job_ID);
    instance.get(`jobApplication/getJobApplicationsByJobID/${job_ID}`).then((resp) => {
      // console.log("getJobApplication: ", resp);
      // setCreatedAt(resp.data.createdAt);
      // console.log("1111111111111111111111111111 JobApplicationsEmployerV3: createdAt", createdAt);
      setJobApplications(resp.data);
      // console.log("1111111111111111111111111111 JobApplicationsEmployerV3: resp : ", resp);
      // console.log("1111111111111111111111111111 JobApplicationsEmployerV3: getJobApplications: resp.data : ", resp.data);
      
    });
  };

  const fetchJobApplicationNotes = async () => {
    
    instance.get(`/jobApplicationNotes/getNotesByJobApplicationID/${props.candidatId}`).then((resp) => {
      if (resp.data.length) {
        setJobApplicationNotes(resp.data);
        console.log('notes in card',resp.data)
      }
      });
  };

  useEffect(() => {
    getJobApplications();
    fetchJobApplicationNotes()
    getJob();
  }, [reloadWindow, props.jobID]);

  return (
    <>
    <Container>
    
        {jobApplications &&
        <>
          { /* console.log("00000000000000000 JobApplicationsEmployerV3 participants: ", jobApplications) */}
          {jobApplications

            .map((participant) => (
              <>
                  {participant && participant.pipeline !== "A suivre" && participant.pipeline !== "Offre" && participant.pipeline !== "CDI" && participant.pipeline !== "Validé" && participant.pipeline !== "Rejeté" && participant.pipeline !== "A relancer" && participant.pipeline !== "Sourcing" && participant.pipeline !== "A qualifier" && participant.pipeline !== "Avis final" && props.pipeline === "" && (
                    <Row
                    className="mb-3 flex w-full hover:black hover:border hover:border-black cursor-pointer duration-75 hover:z-20 bg-white shadow-lg rounded-sm"
                    key={participant._id}
                    // key={`"${participant._id}_No_Pipeline"`}

                    >


                        <Col lg="8">
                          <JobApplicationCardEmployer
                            candidatId={participant.userID}
                            userIDImage={participant.userID.image}
                            prenom={participant.prenom}
                            nom={participant.nom}
                            email={participant.email}
                            Jobtitle={job.title}
                            jobAPplicationCreatedAt={participant.createdAt}
                            jobApplicationVideosLength={participant?.videos?.length}
                          />
  

                        </Col>
                        <Col lg="4" className="flex items-center">
                          <Container>
                          <Row>
                          <Col lg="12" className="mt-4">
                          <div
                          className="text-sm"
                          style={{ display: "inline-flex" }}
                        >
                            <Button
                              color="primary"
                              size="sm"

                              // href={`http://localhost:5100/${participant.CV}`}
                              //   target="blank"
                              onClick={() => {
                                setCandidatID(participant?._id);
                                setModalOpen(!modalOpen);
                                setCandidatModal(participant);
                                setJobAppDate(participant?.createdAt)
                              }}
                            >
                              {t('jobAppKEY1')}
                            </Button>
                            </div>
                            </Col>
                            <Col lg="12" className="mt-4">
                            <div
                          className="text-sm"
                          style={{ display: "inline-flex" }}
                        >
                            

                            <Button
                              color="primary"
                              size="sm"
                              target="_blank"
                              href={`/jobApplication/oneJobApplication/${participant?._id}`}
                            >
                              {t('jobAppKEY2')}
                            </Button>
                            </div>
                            </Col>
                        </Row>

                          </Container>


                        </Col>
                    </Row>
                  )}
                  {participant && participant.pipeline === "Validé" && props.pipeline === "Validé" && (
                    <Row
                    className="mb-3 flex w-full hover:black hover:border hover:border-black cursor-pointer duration-75 hover:z-20 bg-white shadow-lg rounded-sm"
                    key={participant._id}

                    >


                        <Col lg="8">
                          <JobApplicationCardEmployer
                            // key={participant._id}
                            candidatId={participant.userID}
                            userIDImage={participant.userID.image}
                            prenom={participant.prenom}
                            nom={participant.nom}
                            email={participant.email}
                            Jobtitle={job.title}
                            jobAPplicationCreatedAt={participant.createdAt}
                            jobApplicationVideosLength={participant?.videos?.length}
                          />
  

                        </Col>
                        <Col lg="4" className="flex items-center">
                          <Container>
                          <Row>
                          <Col lg="12" className="mt-4">
                          <div
                          className="text-sm"
                          style={{ display: "inline-flex" }}
                        >
                            <Button
                              color="primary"
                              size="sm"

                              // href={`http://localhost:5100/${participant.CV}`}
                              //   target="blank"
                              onClick={() => {
                                setCandidatID(participant?._id);
                                setModalOpen(!modalOpen);
                                setCandidatModal(participant);
                                setJobAppDate(participant?.createdAt)
                              }}
                            >
                              {t('jobAppKEY1')}
                            </Button>
                            </div>
                            </Col>
                            <Col lg="12" className="mt-4">
                            <div
                          className="text-sm"
                          style={{ display: "inline-flex" }}
                        >
                            

                            <Button
                              color="primary"
                              size="sm"
                              target="_blank"
                              href={`/jobApplication/oneJobApplication/${participant?._id}`}
                            >
                              {t('jobAppKEY2')}
                            </Button>
                            </div>
                            </Col>
                        </Row>

                          </Container>


                        </Col>
                    </Row>
                  )}
                  {participant && participant.pipeline === "Rejeté" && props.pipeline === "Rejeté" && (
                    <Row
                    className="mb-3 flex w-full hover:black hover:border hover:border-black cursor-pointer duration-75 hover:z-20 bg-white shadow-lg rounded-sm"
                    key={participant._id}

                    >


                        <Col lg="8">
                          <JobApplicationCardEmployer
                            // key={participant._id}
                            candidatId={participant.userID}
                            userIDImage={participant.userID.image}
                            prenom={participant.prenom}
                            nom={participant.nom}
                            email={participant.email}
                            Jobtitle={job.title}
                            jobAPplicationCreatedAt={participant.createdAt}
                            jobApplicationVideosLength={participant?.videos?.length}
                          />
  

                        </Col>
                        <Col lg="4" className="flex items-center">
                          <Container>
                          <Row>
                          <Col lg="12" className="mt-4">
                          <div
                          className="text-sm"
                          style={{ display: "inline-flex" }}
                        >
                            <Button
                              color="primary"
                              size="sm"

                              // href={`http://localhost:5100/${participant.CV}`}
                              //   target="blank"
                              onClick={() => {
                                setCandidatID(participant?._id);
                                setModalOpen(!modalOpen);
                                setCandidatModal(participant);
                                setJobAppDate(participant?.createdAt)
                              }}
                            >
                              {t('jobAppKEY1')}
                            </Button>
                            </div>
                            </Col>
                            <Col lg="12" className="mt-4">
                            <div
                          className="text-sm"
                          style={{ display: "inline-flex" }}
                        >
                            

                            <Button
                              color="primary"
                              size="sm"
                              target="_blank"
                              href={`/jobApplication/oneJobApplication/${participant?._id}`}
                            >
                              {t('jobAppKEY2')}
                            </Button>
                            </div>
                            </Col>
                        </Row>

                          </Container>


                        </Col>
                    </Row>
                  )}
                  {participant && participant.pipeline === "Sourcing" && props.pipeline === "Sourcing" && (
                    <Row
                    className="mb-3 flex w-full hover:black hover:border hover:border-black cursor-pointer duration-75 hover:z-20 bg-white shadow-lg rounded-sm"
                    key={participant._id}
                    // key={`"${participant._id}_Sourcing"`}

                    >


                        <Col lg="8">
                          <JobApplicationCardEmployer
                            // key={participant._id}
                            candidatId={participant.userID}
                            userIDImage={participant.userID.image}
                            prenom={participant.prenom}
                            nom={participant.nom}
                            email={participant.email}
                            Jobtitle={job.title}
                            jobAPplicationCreatedAt={participant.createdAt}
                            jobApplicationVideosLength={participant?.videos?.length}
                          />
  

                        </Col>
                        <Col lg="4" className="flex items-center">
                          <Container>
                          <Row>
                          <Col lg="12" className="mt-4">
                          <div
                          className="text-sm"
                          style={{ display: "inline-flex" }}
                        >
                            <Button
                              color="primary"
                              size="sm"

                              // href={`http://localhost:5100/${participant.CV}`}
                              //   target="blank"
                              onClick={() => {
                                setCandidatID(participant?._id);
                                setModalOpen(!modalOpen);
                                setCandidatModal(participant);
                                setJobAppDate(participant?.createdAt)
                                //console.log('candidature date', jobAppDate);
                              }}
                            >
                              {t('jobAppKEY1')}
                            </Button>
                            </div>
                            </Col>
                            <Col lg="12" className="mt-4">
                            <div
                          className="text-sm"
                          style={{ display: "inline-flex" }}
                        >
                            

                            <Button
                              color="primary"
                              size="sm"
                              target="_blank"
                              href={`/jobApplication/oneJobApplication/${participant?._id}`}
                            >
                              {t('jobAppKEY2')}
                            </Button>
                            </div>
                            </Col>
                        </Row>

                          </Container>


                        </Col>
                    </Row>
                  )}
                  {participant && participant.pipeline === "A relancer" && props.pipeline === "A relancer" && (
                    <Row
                    className="mb-3 flex w-full hover:black hover:border hover:border-black cursor-pointer duration-75 hover:z-20 bg-white shadow-lg rounded-sm"
                    key={participant._id}
                    // key={`"${participant._id}_A_relancer"`}

                    >


                        <Col lg="8">
                          <JobApplicationCardEmployer
                            // key={participant._id}
                            candidatId={participant.userID}
                            userIDImage={participant.userID.image}
                            prenom={participant.prenom}
                            nom={participant.nom}
                            email={participant.email}
                            Jobtitle={job.title}
                            jobAPplicationCreatedAt={participant.createdAt}
                            jobApplicationVideosLength={participant?.videos?.length}
                          />
 

                        </Col>
                        <Col lg="4" className="flex items-center">
                          <Container>
                          <Row>
                          <Col lg="12" className="mt-4">
                          <div
                          className="text-sm"
                          style={{ display: "inline-flex" }}
                        >
                            <Button
                              color="primary"
                              size="sm"

                              // href={`http://localhost:5100/${participant.CV}`}
                              //   target="blank"
                              onClick={() => {
                                setCandidatID(participant?._id);
                                setModalOpen(!modalOpen);
                                setCandidatModal(participant);
                                setJobAppDate(participant?.createdAt)
                              }}
                            >
                              {t('jobAppKEY1')}
                            </Button>
                            </div>
                            </Col>
                            <Col lg="12" className="mt-4">
                            <div
                          className="text-sm"
                          style={{ display: "inline-flex" }}
                        >
                            

                            <Button
                              color="primary"
                              size="sm"
                              target="_blank"
                              href={`/jobApplication/oneJobApplication/${participant?._id}`}
                            >
                              {t('jobAppKEY2')}
                            </Button>
                            </div>
                            </Col>
                        </Row>

                          </Container>


                        </Col>
                      </Row>
                  )}
                  {participant && participant.pipeline === "A qualifier" && props.pipeline === "A qualifier" && (
                    <Row
                    className="mb-3 flex w-full hover:black hover:border hover:border-black cursor-pointer duration-75 hover:z-20 bg-white shadow-lg rounded-sm"
                    key={participant._id}

                    >


                        <Col lg="8">
                          <JobApplicationCardEmployer
                            // key={participant._id}
                            candidatId={participant.userID}
                            userIDImage={participant.userID.image}
                            prenom={participant.prenom}
                            nom={participant.nom}
                            email={participant.email}
                            Jobtitle={job.title}
                            jobAPplicationCreatedAt={participant.createdAt}
                            jobApplicationVideosLength={participant?.videos?.length}
                          />
  

                        </Col>
                        <Col lg="4" className="flex items-center">
                          <Container>
                          <Row>
                          <Col lg="12" className="mt-4">
                          <div
                          className="text-sm"
                          style={{ display: "inline-flex" }}
                        >
                            <Button
                              color="primary"
                              size="sm"

                              // href={`http://localhost:5100/${participant.CV}`}
                              //   target="blank"
                              onClick={() => {
                                setCandidatID(participant?._id);
                                setModalOpen(!modalOpen);
                                setCandidatModal(participant);
                                setJobAppDate(participant?.createdAt)
                              }}
                            >
                              {t('jobAppKEY1')}
                            </Button>
                            </div>
                            </Col>
                            <Col lg="12" className="mt-4">
                            <div
                          className="text-sm"
                          style={{ display: "inline-flex" }}
                        >
                            

                            <Button
                              color="primary"
                              size="sm"
                              target="_blank"
                              href={`/jobApplication/oneJobApplication/${participant?._id}`}
                            >
                              {t('jobAppKEY2')}
                            </Button>
                            </div>
                            </Col>
                        </Row>

                          </Container>


                        </Col>
                    </Row>
                  )}
                  {participant && participant.pipeline === "Avis final" && props.pipeline === "Avis final" && (
                    <Row
                    className="mb-3 flex w-full hover:black hover:border hover:border-black cursor-pointer duration-75 hover:z-20 bg-white shadow-lg rounded-sm"
                    key={participant._id}

                    >


                        <Col lg="8">
                          <JobApplicationCardEmployer
                            // key={participant._id}
                            candidatId={participant.userID}
                            userIDImage={participant.userID.image}
                            prenom={participant.prenom}
                            nom={participant.nom}
                            email={participant.email}
                            Jobtitle={job.title}
                            jobAPplicationCreatedAt={participant.createdAt}
                            jobApplicationVideosLength={participant?.videos?.length}
                          />
  

                        </Col>
                        <Col lg="4" className="flex items-center">
                          <Container>
                          <Row>
                          <Col lg="12" className="mt-4">
                          <div
                          className="text-sm"
                          style={{ display: "inline-flex" }}
                        >
                            <Button
                              color="primary"
                              size="sm"

                              // href={`http://localhost:5100/${participant.CV}`}
                              //   target="blank"
                              onClick={() => {
                                setCandidatID(participant?._id);
                                setModalOpen(!modalOpen);
                                setCandidatModal(participant);
                                setJobAppDate(participant?.createdAt)
                              }}
                            >
                              {t('jobAppKEY1')}
                            </Button>
                            </div>
                            </Col>
                            <Col lg="12" className="mt-4">
                            <div
                          className="text-sm"
                          style={{ display: "inline-flex" }}
                        >
                            

                            <Button
                              color="primary"
                              size="sm"
                              target="_blank"
                              href={`/jobApplication/oneJobApplication/${participant?._id}`}
                            >
                              {t('jobAppKEY2')}
                            </Button>
                            </div>
                            </Col>
                        </Row>

                          </Container>


                        </Col>
                    </Row>
                  )}
                  {participant && participant.pipeline === "A suivre" && props.pipeline === "A suivre" && (
                    <Row
                    className="mb-3 flex w-full hover:black hover:border hover:border-black cursor-pointer duration-75 hover:z-20 bg-white shadow-lg rounded-sm"
                    key={participant._id}

                    >


                        <Col lg="8">
                          <JobApplicationCardEmployer
                            // key={participant._id}
                            candidatId={participant.userID}
                            userIDImage={participant.userID.image}
                            prenom={participant.prenom}
                            nom={participant.nom}
                            email={participant.email}
                            Jobtitle={job.title}
                            jobAPplicationCreatedAt={participant.createdAt}
                            jobApplicationVideosLength={participant?.videos?.length}
                          />
  

                        </Col>
                        <Col lg="4" className="flex items-center">
                          <Container>
                          <Row>
                          <Col lg="12" className="mt-4">
                          <div
                          className="text-sm"
                          style={{ display: "inline-flex" }}
                        >
                            <Button
                              color="primary"
                              size="sm"

                              // href={`http://localhost:5100/${participant.CV}`}
                              //   target="blank"
                              onClick={() => {
                                setCandidatID(participant?._id);
                                setModalOpen(!modalOpen);
                                setCandidatModal(participant);
                                setJobAppDate(participant?.createdAt)
                              }}
                            >
                              {t('jobAppKEY1')}
                            </Button>
                            </div>
                            </Col>
                            <Col lg="12" className="mt-4">
                            <div
                          className="text-sm"
                          style={{ display: "inline-flex" }}
                        >
                            

                            <Button
                              color="primary"
                              size="sm"
                              target="_blank"
                              href={`/jobApplication/oneJobApplication/${participant?._id}`}
                            >
                              {t('jobAppKEY2')}
                            </Button>
                            </div>
                            </Col>
                        </Row>

                          </Container>


                        </Col>
                    </Row>
                  )}
                  {participant && participant.pipeline === "Offre" && props.pipeline === "Offre" && (
                    <Row
                    className="mb-3 flex w-full hover:black hover:border hover:border-black cursor-pointer duration-75 hover:z-20 bg-white shadow-lg rounded-sm"
                    key={participant._id}

                    >


                        <Col lg="8">
                          <JobApplicationCardEmployer
                            // key={participant._id}
                            candidatId={participant.userID}
                            userIDImage={participant.userID.image}
                            prenom={participant.prenom}
                            nom={participant.nom}
                            email={participant.email}
                            Jobtitle={job.title}
                            jobAPplicationCreatedAt={participant.createdAt}
                            jobApplicationVideosLength={participant?.videos?.length}
                          />
  

                        </Col>
                        <Col lg="4" className="flex items-center">
                          <Container>
                          <Row>
                          <Col lg="12" className="mt-4">
                          <div
                          className="text-sm"
                          style={{ display: "inline-flex" }}
                        >
                            <Button
                              color="primary"
                              size="sm"

                              // href={`http://localhost:5100/${participant.CV}`}
                              //   target="blank"
                              onClick={() => {
                                setCandidatID(participant?._id);
                                setModalOpen(!modalOpen);
                                setCandidatModal(participant);
                                setJobAppDate(participant?.createdAt)
                              }}
                            >
                              {t('jobAppKEY1')}
                            </Button>
                            </div>
                            </Col>
                            <Col lg="12" className="mt-4">
                            <div
                          className="text-sm"
                          style={{ display: "inline-flex" }}
                        >
                            

                            <Button
                              color="primary"
                              size="sm"
                              target="_blank"
                              href={`/jobApplication/oneJobApplication/${participant?._id}`}
                            >
                              {t('jobAppKEY2')}
                            </Button>
                            </div>
                            </Col>
                        </Row>

                          </Container>


                        </Col>
                    </Row>
                  )}
                  {participant && participant.pipeline === "CDI" && props.pipeline === "CDI" && (
                    <Row
                    className="mb-3 flex w-full hover:black hover:border hover:border-black cursor-pointer duration-75 hover:z-20 bg-white shadow-lg rounded-sm"
                    key={participant._id}
                    >


                        <Col lg="8">
                          <JobApplicationCardEmployer
                            // key={participant._id}
                            candidatId={participant.userID}
                            userIDImage={participant.userID.image}
                            prenom={participant.prenom}
                            nom={participant.nom}
                            email={participant.email}
                            Jobtitle={job.title}
                            jobAPplicationCreatedAt={participant.createdAt}
                            jobApplicationVideosLength={participant?.videos?.length}
                          />
  

                        </Col>
                        <Col lg="4" className="flex items-center">
                          <Container>
                          <Row>
                          <Col lg="12" className="mt-4">
                          <div
                          className="text-sm"
                          style={{ display: "inline-flex" }}
                        >
                            <Button
                              color="primary"
                              size="sm"

                              // href={`http://localhost:5100/${participant.CV}`}
                              //   target="blank"
                              onClick={() => {
                                setCandidatID(participant?._id);
                                setModalOpen(!modalOpen);
                                setCandidatModal(participant);
                                setJobAppDate(participant?.createdAt)
                              }}
                            >
                              {t('jobAppKEY1')}
                            </Button>
                            </div>
                            </Col>
                            <Col lg="12" className="mt-4">
                            <div
                          className="text-sm"
                          style={{ display: "inline-flex" }}
                        >
                            

                            <Button
                              color="primary"
                              size="sm"
                              target="_blank"
                              href={`/jobApplication/oneJobApplication/${participant?._id}`}
                            >
                              {t('jobAppKEY2')}
                            </Button>
                            </div>
                            </Col>
                        </Row>

                          </Container>


                        </Col>
                    </Row>
                  )}
              </>   
  
            ))
            
          }
          <Modal
            key={candidatID}
            toggle={() => setModalOpen(!modalOpen)}
            isOpen={modalOpen}
            size="lg"
            style={{ maxWidth: "1100px", width: "80%" }}
          >
            <div className=" modal-header">
              {job && job.title &&
                <h4 className=" modal-title" id="exampleModalLabel">
                  {job.title}{" "}
                </h4>

              }

              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => setModalOpen(!modalOpen)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalHeader>
              <Button
                color="danger"
                type="button"
                style={{
                  position: "fixed",
                  right: 20,
                }}
                onClick={() => 
                  {
                    setModalOpen(!modalOpen);
                    setReloadWindow(!reloadWindow);
                  }
                }
                size="sm"
              >
                {t('Close')}
              </Button>
            </ModalHeader>
            <ModalBody>
              <ShowJobAppForOneJob
                candidatId={candidatID}
                jobAppDate={jobAppDate}
                
              />
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                type="button"
                onClick={() => 
                  {
                    setModalOpen(!modalOpen);
                    setReloadWindow(!reloadWindow);
                  }
                }
                size="sm"
              >
                {t('Close')}
              </Button>
            </ModalFooter>
          </Modal>


      
      </>
      }
      </Container>
    </>
  );
}

export default JobApplicationsCardsEmployerV3;
