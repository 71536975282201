import React, { useState, useEffect } from "react";
import { ArrowDownIcon, BriefcaseIcon } from "@heroicons/react/outline";
import { useLocation, useHistory } from "react-router-dom";
import { createOnePlaybook } from "network/ApiAxios";
import qs from "query-string";
import {
  Button,
  Form,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,
  Card,
  FormText,
  FormFeedback,
} from "reactstrap";
import axios from "axios";
import Select from "react-select";
import config from "config";
import Header from "components/Headers/Header.js";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});

function DisplayOneLearningPath (props) {
  const locationQuery = useLocation();
  const history = useHistory();
  const query = qs.parse(locationQuery.search);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [learningPath, setLearningPath] = useState({});

  

  const [userID, setUserID] = useState();
  const [creatorID, setCreatorID] = useState();
  
  const [step_1_Description, set_step_1_Description] = useState("");
  const [step_1_URL, set_step_1_URL] = useState("");
  const [step_2_Description, set_step_2_Description] = useState("");
  const [step_2_URL, set_step_2_URL] = useState("");
  const [step_3_Description, set_step_3_Description] = useState("");
  const [step_3_URL, set_step_3_URL] = useState("");

  const fetchLearningPathData = () => {
    instance.get(`playbooks/getOnePlaybookByID/${props.learningPathID}`).then((resp) => {
      setLearningPath(resp.data.PlaybooksItem);
      setTitle(resp.data.PlaybooksItem.title);
      setDescription(resp.data.PlaybooksItem.description);
      // setEndDate(resp.data.job.endDate.slice(0, 10));
     
    });

  };


  useEffect(() => {
    setUserID(JSON.parse(localStorage.getItem("user"))._id);
    setCreatorID(JSON.parse(localStorage.getItem("user"))._id);
    fetchLearningPathData();
  }, []);










  return (
    <>
 
        <Container fluid className="mt-4">
          <Card className="p-4 shadow-sm p-3 mb-5 bg-white rounded">
            <Form>
              <div className="form-group">
                <Label>Titre du parcours de formation*</Label>
                <Input
                  value={title}
                  onChange={(e) => {
                    if (!e.target.value) {

                    } else {
                      
                    }
                    setTitle(e.target.value);
                  }}
                  type="text"

                  // placeholder="Example: accountant"
                />
                
              </div>


              <div className="form-group">
                <Label>Description*</Label>
                <p>Une description détaillée </p>
                <textarea
                  value={description}

                  rows="5"
                  onChange={(e) => {
                    if (!e.target.value) {

                    } else {
                      
                    }
                    
                  }}
                />

              </div>


              <div className="form-group">
                <Label>Etape 1: Description</Label>
                <Input
                  value={step_1_Description}
                  onChange={(e) => {
                    set_step_1_Description(e.target.value);
                  }}
                  type="textarea"
                  rows="5"
                  // placeholder="Example: accountant"
                />
              </div>
              <div className="form-group">
                <Label>Etape 1: URL</Label>
                <Input
                  value={step_1_URL}
                  onChange={(e) => {
                    set_step_1_URL(e.target.value);
                  }}
                  type="text"
                  // placeholder="Example: accountant"
                />
              </div>

              <div className="form-group">
                <Label>Etape 2: Description</Label>
                <Input
                  value={step_2_Description}
                  onChange={(e) => {
                    set_step_2_Description(e.target.value);
                  }}
                  type="textarea"
                  rows="5"
                  // placeholder="Example: accountant"
                />
              </div>
              <div className="form-group">
                <Label>Etape 2: URL</Label>
                <Input
                  value={step_2_URL}
                  onChange={(e) => {
                    set_step_2_URL(e.target.value);
                  }}
                  type="text"
                  // placeholder="Example: accountant"
                />
              </div>


              <div className="form-group">
                <Label>Etape 3: Description</Label>
                <Input
                  value={step_3_Description}
                  onChange={(e) => {
                    set_step_3_Description(e.target.value);
                  }}
                  type="textarea"
                  rows="5"
                  // placeholder="Example: accountant"
                />
              </div>
              <div className="form-group">
                <Label>Etape 3: URL</Label>
                <Input
                  value={step_3_URL}
                  onChange={(e) => {
                    set_step_3_URL(e.target.value);
                  }}
                  type="text"
                  // placeholder="Example: accountant"
                />
              </div>
            </Form>


            <div className="flex justify-center mt-4 space-x-2">
              <Button
                type="button"
                
                className="px-5 py-2  bg-green-400 text-white  hover:border-green-500 hover:border-[1px]"
                color="primary"
              >
                Créer le parcours de formation
              </Button>
            </div>
          </Card>
        </Container>


    
    </>
  );
}

export default DisplayOneLearningPath;
