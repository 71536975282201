
import JobApplicationTitle from "components/employer/jobApplicationTitle";
import JobApplicationSummary from "components/employer/jobApplicationSummary";
import JobApplicationNote from "components/employer/jobApplicationNote";
import JobAppGeneralAnalysis from "components/employer/jobAppGeneralAnalysis";
import JobAppScoreReport from "components/employer/jobAppScoreReport";
import JobAppVideos from "components/employer/jobAppVideos";
import UserCandidateNotes from "components/superAdmin/userCandidateNotes";
import OutFrContractUserManagementNotes from "components/employer/outFrContractUserManagementNotes";

import JobAppFormForOutFrProfile from "components/employer/jobAppFormForOutFrProfile";
import ProfileCompetenciesoutFrCandidate from "components/employer/profileCompetenciesoutFrCandidate";
import CvForOutFrCandidateUser from "components/employer/cvForOutFrCandidateUser";
import UserPipeline from "components/employer/userPipeline";
import UserManagementActions from "components/employer/userManagementActions";
import {options_jobApplicationPipeline} from "options";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import VideoPlayer from "react-video-js-player";
import DownloadLink from "react-download-link";
import { ClipLoader } from "react-spinners";
import Select from "react-select";
import { 
  Container, 
  Row,
  Button, 
  Col, 
  Label,
  Card, 
  CardImg, 
  FormGroup, 
  InputGroup, 
  InputGroupAddon, 
  CardHeader, 
  Table, 
  CardBody, 
  Form,
  Input,
} from "reactstrap";
import { Grid, TextField } from "@mui/material";

import { UserIcon } from "@heroicons/react/outline";
import "../custom.css";
import config from "config";





const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});



function RoleInterviewPassedForUserProfiles(props) {
  const history = useHistory();
  const [roleInterviewPassed, setRoleInterviewPassed] = useState(false);
  // console.log("11111111111111111111111111111: ShowApplicationEmployerV4");
  // console.log("11111111111111111111111111111: ShowApplicationEmployerV4: candidatId: ", candidatId);
  const [candidateUser, setCandidateUser] = useState({});
  const [allJobAppsIDsForThisUser, setAllJobIDsForThisUser] = useState([]);
  const [allJobAppsTitlesForThisUser, setAllJobAppsTitlesForThisUser] = useState([]);
  const [allJobsOptionsForThisUser, setAllJobsOptionsForThisUser] = useState([]);
  const [selectedJobIDForInterview, setSelectedJobIDForInterview] = useState("");
  const [selectedJobAppForInterview, setSelectedJobAppForInterview] = useState({});

  const [videos, setVideos] = useState(null);
  const [allJobApplicationsForThisUser, setAllJobApplicationsForThisUser] = useState([]);
  


  



  
  
  const user = JSON.parse(localStorage.getItem("user"));


  // function getJobTitle (jobIDParam){
    const getJobTitle = (jobIDParam) => {


    // let jobTitleOutput = "0000000000000";
    let jobTitleOutput;
    console.log("getJobTitle: jobIDParam: ", jobIDParam);
  
    if (jobIDParam !== "") {
            
      instance.get(`jobs/${jobIDParam}`).then((respForJobTitle) => {
        console.log("getJobTitle: respForJobTitle: ", respForJobTitle);
  
        if ((respForJobTitle.data !== null) && (respForJobTitle.data.job !== null) && (respForJobTitle.data.job.title !== null)){
              jobTitleOutput = respForJobTitle.data.job.title;
              console.log("getJobTitle: respForJobTitle.data.job.title: ", respForJobTitle.data.job.title);
              // return respForJobTitle.data.job.title;
              return jobTitleOutput;
        };
      });
  
    }
    console.log("getJobTitle: jobTitheOutput", jobTitleOutput)
    // return jobTitleOutput;
  }
  const getSelectedJobApp = async (jobIDParameter) => {
      var jobIdToFoundItem;
      var newArray;
    
      // console.log("getSelectedJobApp: step 0: ");
      // console.log("getSelectedJobApp: step 1: ", candidatId);
          
      instance.get(`/jobApplication/getJobApplicationsByUserID/${props.candidateUserID}`).then((resp) => {
        if (resp.data.length) {
          
          newArray = resp.data;
          console.log("getSelectedJobApp: newArray: ", newArray);
          
          
          for (var i = 0; i < newArray.length; i++) {
    
            jobIdToFoundItem = newArray[i].jobID;
            if (jobIdToFoundItem === jobIDParameter) {
              console.log("getSelectedJobApp: newArray[i] jobApp found: ", newArray[i]);
              setSelectedJobAppForInterview(newArray[i]);
    
      
          
            }
            
          }
    
          
            }
            
    
        
        });
    
      
  };

  





const fetchAllJobApplicationsForThisUser = async () => {
  // console.log("333333333333333333: fetchJobApplicationNotes: step 0: ");
  // console.log("333333333333333333: fetchJobApplicationNotes: step 1: ", candidatId);
  instance.get(`/jobApplication/getJobApplicationsByUserID/${props.candidateUserID}`).then((resp) => {
    if (resp.data.length) {
      setAllJobApplicationsForThisUser(resp.data);

        }
        

    
    // console.log("333333333333333333: fetchJobApplicationNotes: step 2:");
    // console.log("333333333333333333: fetchJobApplicationNotes: step 3 : resp: ", resp);
    // console.log("333333333333333333: fetchJobApplicationNotes: step 4 : resp.data: ", resp.data);
    });


    // setAllJobAppsIDsForThisUser(newArray.map(item => ({value: item._id, label: item.nom})));
};






const fetchAllJobOptionsForThisUserV4 = async () => {
  var jobIdItem;
  var jabAppIDItem;
  var jobTitleItem;
  var newArray;

  // console.log("333333333333333333: fetchJobApplicationNotes: step 0: ");
  // console.log("333333333333333333: fetchJobApplicationNotes: step 1: ", candidatId);
      
  instance.get(`/jobApplication/getJobApplicationsByUserID/${props.candidateUserID}`).then((resp) => {
    if (resp.data.length) {
      
      newArray = resp.data;
      console.log("fetchAllJobOptionsForThisUser: newArray: ", newArray);
      console.log("fetchAllJobOptionsForThisUser: newArray[0]: ", newArray[0]);
      
      for (var i = 0; i < newArray.length; i++) {

        jabAppIDItem = newArray[i]._id;
        console.log("fetchAllJobOptionsForThisUser: for jabAppIDItem:  ", jabAppIDItem);
        console.log("fetchAllJobOptionsForThisUser: for looop: newArray : ", newArray);
        console.log("fetchAllJobOptionsForThisUser: for looop:: i: ", i);
        console.log("fetchAllJobOptionsForThisUser: for jobAppID: (newArray[i])._id: ", (newArray[i])._id);
        jobIdItem = (newArray[i]).jobID;
        console.log("fetchAllJobOptionsForThisUser: jobIdItem: ", jobIdItem);
        if (jobIdItem !== "") {
          
          instance.get(`jobs/${jobIdItem}`).then((respForJobOption) => {

            



            console.log("fetchAllJobOptionsForThisUser: respForJobOption: ", respForJobOption);
            if ((respForJobOption.data !== null) && (respForJobOption.data.job !== null) && (respForJobOption.data.job.title !== null)){
                  console.log("fetchAllJobOptionsForThisUser: newArray[i]: ", newArray[i]);
                  // (newArray[i]).nom = respForJobTitle.data.job.title;
                  // setAllJobAppsTitlesForThisUser([...allJobAppsTitlesForThisUser, respForJobTitle.data.job.title]);
                  console.log("fetchAllJobOptionsForThisUser xxxx: for jobAppID: newArray[i]: ", newArray[i]);
                  jobTitleItem = respForJobOption.data.job.title;
                  console.log("fetchAllJobOptionsForThisUser xxxx: for jobTitleItem: ", jobTitleItem);
                  console.log("fetchAllJobOptionsForThisUser: respForJobOption.data.job.title: ", respForJobOption.data.job.title);
                  setAllJobsOptionsForThisUser(allJobsOptionsForThisUser => [...allJobsOptionsForThisUser, {value: respForJobOption.data.job._id, label: respForJobOption.data.job.title}]);
            };
          });

          jobTitleItem = getJobTitle(jobIdItem);
          console.log("fetchAllJobOptionsForThisUser xxxx: for jobTitleItem: ", jobTitleItem);
          // setAllJobsOptionsForThisUser(allJobsOptionsForThisUser => [...allJobsOptionsForThisUser, {value: jabAppIDItem, label: jobTitleItem}]);
  
      
        }
        // setAllJobsOptionsForThisUser(allJobsOptionsForThisUser => [...allJobsOptionsForThisUser, {value: (newArray[i])._id, label: respForJobOption.data.job.title}]);
        
      }
      console.log("fetchAllJobApplicationsForThisUser: after for looop: newArray : ", newArray);

      
        }
        

    
    // console.log("333333333333333333: fetchJobApplicationNotes: step 2:");
    // console.log("333333333333333333: fetchJobApplicationNotes: step 3 : resp: ", resp);
    // console.log("333333333333333333: fetchJobApplicationNotes: step 4 : resp.data: ", resp.data);
    });

  
};


const checkRoleInterviewProgress = () => {

  instance.get(`users/checkRoleInterviewProgress/${props.candidateUserID}`).then((resp) => {
    // console.log("00000000000000000000000000 oneUserCardSuperAdmin getCandidate: resp.data : ");
    // console.log("00000000000000000000000000 oneUserCardSuperAdmin getCandidate: resp.data : ", resp.data);
    
    setRoleInterviewPassed(resp.data.roleInterviewPassed);
  });


};





   useEffect(() => {
    checkRoleInterviewProgress();
    // fetchAllJobApplicationsForThisUser();
    // fetchAllJobOptionsForThisUserV4();
  }, []);




  return (
    <>


        <Row>
          <Col className="order-xl-1">
            <Card className="bg-secondary shadow">
            {roleInterviewPassed &&
              <Label
                color="secondary"
                size="sm"

              >
                Ce candidat a bien passé un interview de type Role
              </Label>
            }
            {!roleInterviewPassed &&
              <Label
                color="secondary"
                size="sm"

              >
                Ce candidat n'a pas passé un interview de type Role
              </Label>
            }







              
            </Card>
          </Col>
        </Row>
    </>
  );
}

export default RoleInterviewPassedForUserProfiles;
