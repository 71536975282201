import React, { useEffect, useState } from "react";
import {
  PencilAltIcon,
  LocationMarkerIcon,
  BriefcaseIcon,
  MailIcon,
  PhoneIcon,
  LinkIcon,
} from "@heroicons/react/solid";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
// core components
import { useHistory } from "react-router-dom";
import UserHeaderCandidat from "components/Headers/UserHeaderCandidat";
import axios from "axios";
import config from "config";
// const https = require('https');
//
// const agent = new https.Agent({
//     rejectUnauthorized: false,
// });

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});

const ProfileCandidat = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const [CV, setCV] = useState(user.CV);

  const addCV = async () => {
    const formData = new FormData();
    formData.append("userID", user._id);
    formData.append("CV", CV);

    const response = await instance.post("/users/addCV", formData);
    const { data } = response;
    if (data.success) {
      
      localStorage.setItem("user", JSON.stringify(data.user[0]));
      history.push("/candidat/user-profile");
    }
  };
  return (
    <>
      <UserHeaderCandidat />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <Card className="card-profile shadow">
              <Row className="justify-content-center">
                <Col className="order-lg-2" lg="3">
                  <div className="card-profile-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      {user.image?<img
                        alt="..."
                        className="rounded-circle"
                        src={`${config.Data_URL}${user.image}`}
                      />:
                      <img
                        alt="..."
                        className="rounded-circle"
                        src={require("assets/img/brand/default.png").default}
                      />}
                    </a>
                  </div>
                </Col>
              </Row>
              <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                <div className="d-flex justify-content-between">
                  {/* <Button
                                        className="mr-4"
                                        color="info"
                                        href="#pablo"
                                        onClick={e => e.preventDefault()}
                                        size="sm"
                                    >
                                        Connect
                                    </Button>
                                    <Button
                                        className="float-right"
                                        color="default"
                                        href="#pablo"
                                        onClick={e => e.preventDefault()}
                                        size="sm"
                                    >
                                        Message
                                    </Button> */}
                </div>
              </CardHeader>
              <CardBody className="pt-0 pt-md-4">
                <Row>
                  <div className="col">
                    <div className="card-profile-stats d-flex justify-content-center mt-md-5"></div>
                  </div>
                </Row>
                <div className="text-center">
                  <h3>
                    {user.name} {user.lastName}
                  </h3>

                  <div
                    className="h5 mt-4 col-12 "
                    style={{ display: "inline-flex" }}
                  >
                    <MailIcon className="h-6 pr-2" />
                    {user.email}
                  </div>
                  <div
                    className="h5 mt-4 col-12 "
                    style={{ display: "inline-flex" }}
                  >
                    <PhoneIcon className="h-6 pr-2" />
                    {user.studyLevel}
                  </div>
                  <div
                    className="h5 mt-4 col-12 "
                    style={{ display: "inline-flex" }}
                  >
                    <LinkIcon className="h-6 pr-2" />
                    Années d'experience{user.yearsOfExperience}
                  </div>
                  <div
                    className="h5 mt-4 col-12 "
                    style={{ display: "inline-flex" }}
                  >
                    <LocationMarkerIcon className="h-6 pr-2" />
                    {user.adress}
                  </div>
                  <hr className="my-4" />
                  {user.CV && (
                    <Row>
                      <Col lg="6" md="50">
                        <Button
                          style={{
                            backgroundColor: "#404954",
                            color: "white",
                          }}
                          href={`${config.Data_URL}${user.CV}`}
                            target="blank"
                        >
                         
                            Voir mon CV
                         
                        </Button>
                      </Col>
                      <Col lg="6" md="50">
                        <Button
                          style={{
                            backgroundColor: "#404954",
                            color: "white",
                          }}
                          onClick={() => setModalOpen(!modalOpen)}
                        >
                          Mettre à jour CV
                        </Button>
                      </Col>
                    </Row>
                  )}
                  {!user.CV && (
                    <Row>
                      <Col lg="7" md="50">
                        <Button
                          color="info"
                          onClick={() => setModalOpen(!modalOpen)}
                        >
                          Ajouter un CV
                        </Button>
                      </Col>
                    </Row>
                  )}
                </div>
                <Modal
                  toggle={() => setModalOpen(!modalOpen)}
                  isOpen={modalOpen}
                >
                  <div className=" modal-header">
                    <h4 className=" modal-title" id="exampleModalLabel">
                     veuillez télécharger votre cv en format pdf 
                    </h4>
                    <button
                      aria-label="Close"
                      className=" close"
                      type="button"
                      onClick={() => setModalOpen(!modalOpen)}
                    >
                      <span aria-hidden={true}>×</span>
                    </button>
                  </div>
                    <ModalBody>

                    <FormGroup>
                         
                          <Input 
                          className="form-control-alternative" 
                          type="file" 
                          accept= '.pdf'
                          onChange={(e)=>setCV(e.target.files[0])} />

                        </FormGroup>
                    </ModalBody>
                  <ModalFooter>
                    <Button
                      color="danger"
                      type="button"
                      onClick={() => setModalOpen(!modalOpen)}
                    >
                      ANNULER
                    </Button>
                    <Button
                      color="secondary"
                      type="button"
                      onClick={() => {
                        addCV();
                        setModalOpen(!modalOpen);
                      }}
                    >
                      TÉLÉCHARGER
                    </Button>
                  </ModalFooter>
                </Modal>
              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Mon Profil</h3>
                  </Col>
                  <Col className="text-right" xs="2">
                    <Button
                      style={{
                        backgroundColor: "#404954",
                        color: "white",
                      }}
                      href="#pablo"
                      //onClick={() => history.push('/admin/edit-profile')}
                      size="sm"
                    >
                      Offres d'emploi
                    </Button>
                  </Col>
                  <Col className="text-right" xs="2">
                    <Button
                      style={{
                        backgroundColor: "#404954",
                        color: "white",
                      }}
                      href="#pablo"
                      //onClick={() => history.push('/admin/edit-profile')}
                      size="sm"
                    >
                      Compétitions
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">Information</h6>
                  <div className="pl-lg-4">
                    <Row>
                     
                    </Row>
                    {/*<Row>*/}
                    {/*    <Col lg="6">*/}
                    {/*        <FormGroup>*/}
                    {/*            <label*/}
                    {/*                className="form-control-label"*/}
                    {/*                htmlFor="input-first-name"*/}
                    {/*            >*/}
                    {/*                First name*/}
                    {/*            </label>*/}
                    {/*            <Input*/}
                    {/*                className="form-control-alternative"*/}
                    {/*                defaultValue="Lucky"*/}
                    {/*                id="input-first-name"*/}
                    {/*                placeholder="First name"*/}
                    {/*                type="text"*/}
                    {/*            />*/}
                    {/*        </FormGroup>*/}
                    {/*    </Col>*/}
                    {/*    <Col lg="6">*/}
                    {/*        <FormGroup>*/}
                    {/*            <label*/}
                    {/*                className="form-control-label"*/}
                    {/*                htmlFor="input-last-name"*/}
                    {/*            >*/}
                    {/*                Last name*/}
                    {/*            </label>*/}
                    {/*            <Input*/}
                    {/*                className="form-control-alternative"*/}
                    {/*                defaultValue="Jesse"*/}
                    {/*                id="input-last-name"*/}
                    {/*                placeholder="Last name"*/}
                    {/*                type="text"*/}
                    {/*            />*/}
                    {/*        </FormGroup>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                  </div>
                  <hr className="my-4" />
                  {/* Address */}

                  <div className="pl-lg-4">
                    <Row>
                     
                    </Row>
                    <Row>
                      
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Description */}
                  {/* <h6 className="heading-small text-muted mb-4">
                    About me (placeholder)
                  </h6>
                  <div className="pl-lg-4">
                    <FormGroup>
                      <label>About Me</label>
                      <Input
                        className="form-control-alternative"
                        placeholder="A few words about you ..."
                        rows="4"
                        defaultValue="A beautiful Dashboard for Bootstrap 4. It is Free and
                          Open Source."
                        type="textarea"
                        disabled
                      />
                    </FormGroup>
                  </div> */}
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ProfileCandidat;
