import {
  ViewListIcon,
  ArrowCircleDownIcon,
  PencilAltIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/outline";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { 
  Container, 
  Button, 
  Col, 
  Card, 
  CardImg, 
  FormGroup, 
  InputGroup, 
  InputGroupAddon, 
  CardHeader, 
  Table, 
  CardBody, 
  Form,
  Input,
} from "reactstrap";
import "../custom.css";
import Row from "reactstrap/lib/Row";
import config from "config";
import { useTranslation } from "react-i18next";




const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});
function JobAppGeneralAnalysis(props) {
  const { t, i18n } = useTranslation();
  // console.log("11111111111111111111111111111: ShowApplicationEmployerV4");
  // console.log("11111111111111111111111111111: ShowApplicationEmployerV4: props.candidatId: ", props.candidatId);


  const [qcmNumberOfPoints, setQcmNumberOfPoints] = useState("");
  const [qcmCorrectPoints, setQcmCorrectPoints] = useState("");
  const [qcmFalsePoints, setQcmFalsePoints] = useState("");
  const [qcmScorePercentage, setQcmScorePercentage] = useState("");


  
  const user = JSON.parse(localStorage.getItem("user"));





const fetchjobApplicationScore = async () => {
  // console.log("333333333333333333: fetchProfileAnalysis: step 0: ");
  // console.log("333333333333333333: fetchProfileAnalysis: step 1: ", jobApplicationUserID);
  
  instance.get(`/jobApplication/calculateJobApplicationScoreByJobAppID/${props.candidatId}`).then((resp) => {
    setQcmNumberOfPoints(resp.data.qcmNumberOfPoints);
    setQcmCorrectPoints(resp.data.qcmCorrectPoints);
    setQcmFalsePoints(resp.data.qcmFalsePoints);
    setQcmScorePercentage(resp.data.qcmScorePercentage);

  
    console.log("666666666666: jobAppScoreReport: fetchjobApplicationScore:");
    console.log("666666666666: jobAppScoreReport: fetchjobApplicationScore: resp.data:", resp.data);
    console.log("666666666666: jobAppScoreReport: fetchjobApplicationScore: resp.data.qcmNumberOfPoints ", resp.data.qcmNumberOfPoints);
    // console.log("666666666666: jobAppScoreReport: step 4 : resp.data: ", resp.data);
    });
};


const fetchjobApplicationScore_V2 = async () => {
  // console.log("333333333333333333: fetchProfileAnalysis: step 0: ");
  // console.log("333333333333333333: fetchProfileAnalysis: step 1: ", jobApplicationUserID);
  
  instance.get(`/jobApplication/calculateJobApplicationScoreByJobAppID_v2/${props.candidatId}`).then((resp) => {
    setQcmNumberOfPoints(resp.data.qcmNumberOfPoints);
    setQcmCorrectPoints(resp.data.qcmCorrectPoints);
    setQcmFalsePoints(resp.data.qcmFalsePoints);
    setQcmScorePercentage(resp.data.qcmScorePercentage);

  
    console.log("666666666666: jobAppScoreReport: fetchjobApplicationScore:");
    console.log("666666666666: jobAppScoreReport: fetchjobApplicationScore: resp.data:", resp.data);
    console.log("666666666666: jobAppScoreReport: fetchjobApplicationScore: resp.data.qcmNumberOfPoints ", resp.data.qcmNumberOfPoints);
    // console.log("666666666666: jobAppScoreReport: step 4 : resp.data: ", resp.data);
    });
};













   useEffect(() => {
    // fetchjobApplicationScore();
    fetchjobApplicationScore_V2();
  }, [props.candidatId]);







  return (
    <>

        <Row>
          <Col className="order-xl-1">
            <Card className="bg-secondary shadow">

                <Form>
                  <h4 className=" mb-4">Score - {t('Report')} </h4>
                  {(qcmNumberOfPoints) &&
                    <p className=" mb-4">{t('AnalysisKey6')}: {qcmNumberOfPoints}</p>
                  }
                  {(qcmCorrectPoints) &&
                    <p className=" mb-4">{t('AnalysisKey7')}: {qcmCorrectPoints}</p>
                  }
                  {(qcmFalsePoints) &&
                    <p className=" mb-4">{t('AnalysisKey8')}: {qcmFalsePoints}</p>
                  }
                  {(qcmScorePercentage) &&
                    <p className=" mb-4">{t('AnalysisKey9')}: {qcmScorePercentage}</p>
                  }
                </Form>

            </Card>
          </Col>
        </Row>
    </>
  );
}

export default JobAppGeneralAnalysis;
