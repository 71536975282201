import SharedProfileCandidateComp from 'components/candidat/SharefProfileCandidateComp'
import PageSpace from 'components/styles/pageSpace'
import HomepageLayout from 'layouts/HomepageLayout'
import React from 'react'

const FrSharedProfileV1 = ({match}) => {
    const {id} = match.params
    return (
      <>
      <HomepageLayout />
      <PageSpace />
      <SharedProfileCandidateComp id={id} location={"France"} isInterviewNotShown />
      </>
    )
}

export default FrSharedProfileV1