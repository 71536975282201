import axios from "axios";
import React, { useEffect, useState } from "react";
import FrUserProfileForRecruters from "components/recruteur/frUserProfileForRecruters";

import qs from "query-string";
import {
  MailIcon,
  LightBulbIcon,
} from "@heroicons/react/solid";
import {
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";
//import {getAll} from "../../network/ApiAxios";
import config from "config";
import { set } from "react-hook-form";
import { useTranslation } from "react-i18next";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});
function OneCardForFrCandidateRecruters( props ) {
  const { t, i18n } = useTranslation();

  const [candidateUser, setCandidateUser] = useState({});
  
  const [modalOpen, setModalOpen] = useState(false);
  const [candidatID, setCandidatID] = useState("");
  
  const [createdAt, setCreatedAt] = useState("");






  const getCandidateUser = () => {

      instance.get(`users/getprofilebyuserid/${props.candidateUserID}`).then((resp) => {
        // setParticipants(resp.data.job);
        // console.log("00000000000000000000000000 oneUserCardSuperAdmin getCandidate: resp.data : ");
        // console.log("00000000000000000000000000 oneUserCardSuperAdmin getCandidate: resp.data : ", resp.data);
        setCandidateUser(resp.data);
        setCreatedAt(resp.data.date);
      });
    

  };





  useEffect(() => {
    getCandidateUser();
    // getJobApplication();
    // getJob();
    // getJobApplicationUser();
  }, []);

  return (
    <>
    <Container>
    
        {candidateUser &&
        <>
          { /* console.log("00000000000000000 oneJobApplicationCardSuperAdmin jobApplication: ", jobApplication) */}
          { /* console.log("00000000000000000 oneJobApplicationCardSuperAdmin jobID: ", jobID) */}

              <>
                  {candidateUser && (

                    <Row
                    className="mb-3 flex w-full hover:black hover:border hover:border-black cursor-pointer duration-75 hover:z-20 bg-white shadow-lg rounded-sm"
                    key={candidateUser._id}
                      onClick={() => {
                        // setCandidatID(jobApplication?._id);
                        setModalOpen(!modalOpen);
                        // setCandidatModal(jobApplication);
                      }}
                  >
                    <Col lg="2">
                      <img
                        src={`assets/img/brand/500.png`}
                        className="rounded-circle"
                        onError={(e) => {
                          e.target.src =
                            require("assets/img/brand/500.png").default;
                        }}
                        style={{
                          width: "100%",
                          height: "100%",

                          //objectFit: "cover",
                        }}
                        //alt="Responsive image"
                      />
                    </Col>
                    <Col lg="10">
                      <Col lg="12" className="mb-2">
                        <div>
                          <span className="card-t">
                            {candidateUser.name}{" "}
                            {candidateUser.lastName}{" "}
                          </span>
                        </div>
                      </Col>
                      <Row>
                        
                        <Col lg="8">
                          <Col lg="12">
                            <div
                              className="text-sm"
                              style={{ display: "inline-flex" }}
                            >
                              {" "}
                              <MailIcon
                                className=""
                                style={{ height: "1.2rem" }}
                              />{" "}
                              &nbsp; <span>
                                        {candidateUser.email}
                                        {" - "}
                                        {"Pays: "}
                                        {candidateUser.location}

                              </span>{" "}
                            </div>
                          </Col>

                          <Col lg="12">
                            <div
                              className="text-sm"
                              style={{ display: "inline-flex" }}
                            >
                              {" "}
                              <LightBulbIcon
                                className=""
                                style={{ height: "1.2rem" }}
                              />{" "}
                              &nbsp;{" "}
                              <span>
                                {" "}
                                Date:{createdAt.slice(0, 10)}
                              </span>{" "}
                            </div>
                          </Col>


                        </Col>
                        
                        <Col lg="4" className=" flex items-center">
                          <div>
                            <Button
                              color="primary"
                              size="sm"

                              // href={`http://localhost:5100/${participant.CV}`}
                              //   target="blank"
                            >
                              {t('SeeProfile')}
                            </Button>
                            {/*
                            <Button
                              color="primary"
                              size="sm"
                              target="_blank"
                              // href={`/jobApplication/oneJobApplication/${participant?._id}`}
                            >
                              Ouvrir à côté
                            </Button>
                          */}

                          </div>
                        </Col>
                      </Row>
                    </Col>
                    </Row>
                  )}

              </>   
  

            
          
          <Modal
            toggle={() => setModalOpen(!modalOpen)}
            isOpen={modalOpen}
            size="lg"
            style={{ maxWidth: "1100px", width: "80%" }}
          >
            <div className=" modal-header">

              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => setModalOpen(!modalOpen)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
              <FrUserProfileForRecruters
                candidateUserID = {props.candidateUserID}
                candidateEmail={props.candidateEmail}
              />

            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                type="button"
                onClick={() => setModalOpen(!modalOpen)}
                size="sm"
              >
                Fermer
              </Button>
            </ModalFooter>
          </Modal>


      
      </>
      }
      </Container>
    </>
  );
}

export default OneCardForFrCandidateRecruters;
