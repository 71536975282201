import React, { useEffect, useState } from "react";
import axios from "axios";
import { optionsThink, optionsTimes } from "options";
import {
    ClockIcon,
    VideoCameraIcon,
    PlusCircleIcon,
    DuplicateIcon,
    ChatAltIcon,
    PencilAltIcon,
} from "@heroicons/react/solid";
import { ClipLoader } from "react-spinners";

// reactstrap components
import {
    Container,
    Label,
    Button,
    Card,
    CardHeader,
    DropdownMenu,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Table,
    Modal,
    ModalBody,
    ModalFooter,
    CardImg,
    DropdownToggle,
    Dropdown,
    DropdownItem,
} from "reactstrap";
import config from "config";
import Select from "react-select";

const instance = axios.create({
    baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
    const token = localStorage.getItem("token");
    config.headers.Authorization = token ? token : "";
    config.headers['Content-Type'] = "application/json";
    return config;
});
function EditJobAcceptanceCriteriaResponse(props) {
    const { updateResponseText, onUpdate } = props;





    console.log('propsprops', props)
    const [loading, setLoading] = useState(true);
    const [description, setDescription] = useState(null);
    const [data, setData] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [lastClicked, setLastClicked] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState('')


    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleItemClick = (action) => {


        console.log("action", action, props)


        instance.post("JobAcceptanceCriteria/createORUpdateResponseAcceptanceCriteria", {
            JobAppId: props.candidatId,
            AcceptanceCriteriaID: props.AcceptanceCriteriaId._id,
            status: action,
        })
            .then((response) => {
                console.log(response);
                updateResponseText(
                    props.AcceptanceCriteriaId._id,
                    (action === true) ? 'oui' : 'non'
                );
            });
        setSelectedOption(action);
        onUpdate()


        // setLastClicked(action);
    };

    const GetPreselectedAcceptanceCriteriaByjobID = async () => {

        instance
            .get(`JobAcceptanceCriteria/getResponseAcceptancesCriteria/${props.candidatId}`)
            .then((response) => {

                props.setPreselectedData(response.data.acceptanceCriteriaResponse)


                console.log("setPreselectedData", response.data.acceptanceCriteriaResponse
                );
            })
            .catch((error) => {
                console.log("Error:", error);
            });
    }

    const getAcceptanceCriteriaData = () => {
        instance
            .get(`JobAcceptanceCriteria/getAcceptanceCriteriaByID/${props.AcceptanceCriteriaId._id}`)
            .then((res) => {
                setDescription(res.data.AcceptanceCriteria.description);
                setLoading(false);
            })
            .catch((err) => console.log(err));
    };

    const Updating = async () => {
        updateAcceptanceCriteria()
        GetPreselectedAcceptanceCriteriaByjobID()
    }



    const updateAcceptanceCriteria = async () => {
        instance
            .put(`JobAcceptanceCriteria/UpdateAcceptanceCritera/${props.AcceptanceCriteriaId}`, { description })
            .then((res) => {
                console.log('props.AcceptanceCriteriaId', props.AcceptanceCriteriaId);
                console.log('setDescription', description);
                console.log('ress', res.data);
                setData(description);
                setLoading(false);
                props.setModalOpen(false);
                //  window.location.reload(); // Reload the page

            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        getAcceptanceCriteriaData();
    }, []);

    return (
        <>
            <Container>

                {loading && (
                    <div className="absolute flex justify-center items-center top-0 left-0 z-50 bg-gray-800 w-full h-full opacity-80">
                        <ClipLoader color="#f00" />
                    </div>
                )}
                {!loading && (
                    <>
                        <Row className="mb-4 mt-4">
                            <Col lg="5" className="d-flex align-items-center">
                                <h3>{props.AcceptanceCriteriaId.description}</h3>
                            </Col>
                            <Col lg="5" className="d-flex align-items-center">
                                {/* <Input value={description} onChange={e => setDescription(e.target.value)} /> */}

                                {/*               
      Container with overflow: hidden.
      <br /> 
      Last clicked: {lastClicked}*/}
                                <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
                                    <DropdownToggle caret>
                                        {selectedOption !== null ? selectedOption : "Status de validation"}
                                    </DropdownToggle>
                                    <DropdownMenu container="body">
                                        <DropdownItem onClick={() => handleItemClick('true')}>
                                            Critère accepté        </DropdownItem>
                                        <DropdownItem onClick={() => handleItemClick('false')}>
                                            Critère non accepté          </DropdownItem>

                                    </DropdownMenu>
                                </Dropdown>
                            </Col>
                        </Row>
                        <Row>

                        </Row>

                        <Row className="justify-content-center">
                            {/* <Button
            onClick={updateAcceptanceCriteria}
            
            className="mt-4"
            style={{
              backgroundColor: "#404954",
              borderColor: "#404954",
              color: "white",
            }}
            type="submit"
            >
            Fermer
          </Button> */}
                        </Row>
                    </>
                )
                }
            </Container>
        </>
    );
};

export default EditJobAcceptanceCriteriaResponse;
