import axios from "axios";
import ReactPaginate from "react-paginate";
//import Card from "components/Card";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import ViewJob from "components/recruteur/ViewJob";
import { SearchIcon } from "@heroicons/react/outline";
import config from "config";
import "../app.css";
import oneJobCard from "components/recruteur/oneJobCard";
import CardJob from "components/Card";
import Header from "components/Headers/Header";
import {
  PencilAltIcon,
  LocationMarkerIcon,
  BriefcaseIcon,
  MailIcon,
  PhoneIcon,
  LinkIcon,
  CodeIcon,
  ArchiveIcon,
  OfficeBuildingIcon,
  PaperClipIcon,
} from "@heroicons/react/solid";
import {
  Button,
  Form,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormText,
  FormFeedback,
  CardText,
} from "reactstrap";
import OneJob from "components/recruteur/oneJob";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});
function OffresOFCF( {jobid_param} ) {
  const location = useLocation();
  const [jobs, setJobs] = useState([]);
  const [jobId, setJobId] = useState();
  var [job, setJob] = useState({});
  //let job = {};
  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState([]);
  const [perPage] = useState(6);
  const [pageCount, setPageCount] = useState(0);
  
  const history = useHistory();
  const query = qs.parse(location.search);
  const user = JSON.parse(localStorage.getItem("user"));



  const fetchJobV2 = () => {
    //instance.get(`/jobs/${id}`).then((resp) => {
      instance.get(`/jobs/getjobbyid/${jobid_param}`).then((resp) => {

    // console.log("aaaaaaaaaaaaaa function fetchJobs fichier offres OFCF: resp.data eq : ", resp.data);
      // jobs_array.concat(resp.data);
      // setJobs(jobs.concat(resp.data));
      //setJobs(jobs => jobs.concat(resp.data));
      //setData(data  => data.concat(resp.data));
    // console.log(resp.data);
    setJob(resp.data);
    //job = resp.data.job;
    // console.log(job);
    });
  };





  useEffect(() => {
    fetchJobV2();
  }, []); //empty array as second argument.
  


  return (
    <>
      <Row 
        className=" px-2 py-3 flex  hover:black hover:border hover:border-black cursor-pointer duration-75 hover:z-20 bg-white shadow-lg rounded-sm"
        onClick={() => 
          history.push(`/outfrapplypermanentfr/apply?jobId=${jobid_param}`)
        }
      >
        {/* console.log("dddddddddddd: job: ", job) */}

      <Col lg="2">
        <img
          src={`${config.Data_URL}${job.image}`}
          onError={(e) => {
            e.target.src =
              require("assets/img/brand/job_offer_HL_300_225.jpg").default;
          }}
          style={{
            width: "120x",
            height: "120px",

            objectFit: "cover",
          }}
          alt="Responsive image"
      />
      </Col>
      <Col lg="8">
                          <div className="flex items-center space-x-3 mb-2">
                            <div>
                              <h3 className="card-t">{job.title}</h3>
                              <span
                                className="text-sm"
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  display: "-webkit-box",
                                  WebkitLineClamp:
                                    "2" /* number of lines to show */,
                                  WebkitBoxOrient: "vertical",
                                }}
                              >
                                {job.description}
                              </span>
                            </div>
                          </div>
                          <Row>
                            <Col lg="6">
                              <div style={{ display: "inline-flex" }}>
                                {" "}
                                <ArchiveIcon
                                  style={{ height: "1.2rem" }}
                                />{" "}
                                &nbsp; <span> {job.technologies}</span>{" "}
                              </div>
                            </Col>
                            <Col lg="6">
                              <div style={{ display: "inline-flex" }}>
                                {" "}
                                <PaperClipIcon
                                  style={{ height: "1.2rem" }}
                                />{" "}
                                &nbsp; <span> {job.contract}</span>{" "}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <div style={{ display: "inline-flex" }}>
                                {" "}
                                <OfficeBuildingIcon
                                  style={{ height: "1.2rem" }}
                                />{" "}
                                &nbsp; <span> {job.entreprise}</span>{" "}
                              </div>
                            </Col>
                            <Col lg="6">
                              <div style={{ display: "inline-flex" }}>
                                {" "}
                                <LocationMarkerIcon
                                  style={{ height: "1.2rem" }}
                                />{" "}
                                &nbsp; <span> {job.location}</span>{" "}
                              </div>
                            </Col>
                          </Row>
                          {/* <div>
                            Status:{" "}
                            <span
                              className={`${
                                job.status === "inactive"
                                  ? "text-red-600"
                                  : "text-green-600"
                              }`}
                            >
                              {job.status}
                            </span>
                          </div>
                          <div>
                            Updated on:{" "}
                            {`${job.updatedAt.slice(
                              0,
                              10
                            )} ${job.updatedAt.slice(11, 19)}`}
                          </div> */}
      </Col>

      <Col lg="2" className=" flex items-center">
                          <div>
                            <Button
                             type="button"
                             color="primary"
                            >
                              Postuler
                            </Button>
                          </div>
      </Col>

      </Row>


    </>
  );
}

export default OffresOFCF;
