import {
  ViewListIcon,
  ArrowCircleDownIcon,
  PencilAltIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/outline";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import VideoPlayer from "react-video-js-player";
import DownloadLink from "react-download-link";
import { ClipLoader } from "react-spinners";
import { Button, Col, CardImg } from "reactstrap";
import { UserIcon } from "@heroicons/react/outline";
import "../custom.css";
import Row from "reactstrap/lib/Row";
import config from "config";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});
function ShowCandidat({ candidatId, candidatModal }) {
  const [videos, setVideos] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState();
  const [candidat, setCandidat] = useState("");
  const [drive, setDrive] = useState("");
  const [hasDrive, setHasdrive] = useState(false);
  const [motivLttr, setMotivLttr] = useState(false);
  const startMerge = (data) => {
    var buffer = [];
    console.log("data", data);
    for (let i = 0; i < data.videos.length; i++) {
      buffer.push(
        `./videos/${data.prenom}--${data.videos[i].question._id}..mp4`
      );
    }
    instance.put(`merges/${candidat._id}`, { buffer });
  };
  useEffect(() => {
    instance
      .get(`jobs/candidat/${candidatId}`)
      .then((res) => {
        setCandidat(res.data);
        if (res.data.drive) {
          setHasdrive(!hasDrive);
        }
        if (res.data.videos.length) {
          setVideos(res.data.videos);
          // if (!res.data.merged) {
          //   startMerge(res.data);
          // }
          console.log(res.data.videos);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const videoUrlHandler = (videoUrl, currentQ) => {
    setVideoUrl(videoUrl);
    setCurrentQuestion(currentQ);
  };
  const setInstalled = (id) => {
    var installed = true;
    instance.put(`jobs/installed/${id}`, {
      installed,
    });
  };
  const setDriveLink = async (id) => {
    if (drive) {
      await instance.put(`jobs/drive/${id}`, {
        drive,
      });
    }
  };
  const refreshCandidat = () => {
    instance
      .get(`jobs/candidat/${candidatId}`)
      .then((res) => {
        setCandidat(res.data);
      });
  };
  const downloadEmployeeData = () => {
    fetch(
      `${config.Data_URL}videos/${candidat.nom}-${candidat.prenom}-FullInterview.mp4`
    ).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = `${candidat.nom}-${candidat.prenom}-FullInterview.mp4`;
        a.click();
      });
      //window.location.href = response.url;
      setInstalled(candidat._id);
    });
  };
  console.log("jooob", candidatModal);
  return (
    <>
      <div
        className="flex flex-col px-4 space-y-2 bg-white h-1/3 "
        style={{ marginTop: "" }}
      >
        <div className="flex items-center  ">
          <CardImg
            variant=""
            className="rounded-circle"
            style={{
              width: "80px",
              height: "80px",
              alignSelf: "center",
              objectFit: "cover",
            }}
            alt="Responsive image"
            src={`${config.Data_URL}${candidatModal.candidat.image}`}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <div className="pt-2 font-bold    ">
            <span
              className="pt-2 left-0 font-bold "
              style={{ display: "inline-flex" }}
            >
              Prénom:&nbsp;<h4> {candidatModal.candidat.lastName}</h4>
            </span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span
              className="pt-2 font-bold   "
              style={{ display: "inline-flex" }}
            >
              Nom:&nbsp; <h4>{candidatModal.candidat.name} </h4>
            </span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span
              className="pt-2 right-0 font-bold   "
              style={{ display: "inline-flex" }}
            >
              Email:&nbsp; <h4>{candidatModal.candidat.email}</h4>
            </span>
          </div>
        </div>

        <Row>
          
          
            <Button
              style={{
                width: "",
                backgroundColor: "#404954",
                color: "white",
                display: "inline-flex"
              }}
              color=""
              onClick={()=> setMotivLttr(!motivLttr)}
              // href={`${config.Data_URL}${candidatModal.CV}`}
              // target="blank"
            >
              Lettre de candidature {motivLttr?<ChevronUpIcon className="text-right" style={{ height: "1.2rem" }}/>:<ChevronDownIcon className="text-right" style={{ height: "1.2rem" }}/>}
            </Button>
            
          
            <Button
              style={{
                width: "",
                backgroundColor: "#404954",
                color: "white",
              }}
              color=""
              href={`${config.Data_URL}${candidatModal.CV}`}
              target="blank"
            >
              Voir CV
            </Button>
         
        </Row>
        {motivLttr&&
        <Col xl="10">
          <p>{candidatModal.motivLttr}</p>
        </Col>}
      </div>
      <hr className="my-4" />
      {!videos && <h3>Aucune vidéo trouvée pour ce candidat!</h3>}
      <div className="flex  space-x-2 py-3 justify-between">
        <ul className="w-1/2 py-3 list-decimal list-inside bg-white  shadow-xl h-1/2 rounded-md">
          {/* <h3 className="flex items-center justify-center py-3 bg-blue-600 text-white rounded-t-md">
           
        <button onClick={downloadEmployeeData}>Download Full interview</button>
               </h3> */}
          <h3 className="flex px-3 py-3 bg-white text-black font-bold ">
            Questions d'Interview
            <span style={{ marginLeft: "auto" }}>
              {candidat.merged && (
                <ArrowCircleDownIcon
                  className="h-6 pr-2 cursor-pointer "
                  onClick={downloadEmployeeData}
                />
              )}
              {!candidat.merged && (
                <div className=" flex justify-center items-center top-0 left-0 z-50  w-full h-full opacity-100">
                  <ClipLoader color="#000" />
                </div>
              )}
            </span>
          </h3>
          {videos &&
            videos.map((video) => (
              <li
                className="border-b question border-gray-600 text-sm py-2 px-2 text-left last:border-b-0 text-black cursor-pointer transition-all duration-100 transform hover:bg-gray-400  hover:border-none"
                key={video._id}
                onClick={videoUrlHandler.bind(
                  this,
                  video.video,
                  video.question.question
                )}
              >
                {video?.question?.question}
              </li>
            ))}
        </ul>
        <div className="w-1/2 py-3 list-decimal list-inside bg-white  shadow-xl h-1/2 rounded-md">
          {videoUrl && (
            <>
              <div className=" question  text-sm py-2 px-2 text-left last:border-b-0 text-black">
                {currentQuestion}
              </div>
              <video
                src={`${config.Video_URL}${videoUrl}`} type="video/webm"
                controls
                className="px-4 py-4"
              />
              {/* <VideoPlayer
                src={`${config.Data_URL}${videoUrl}`}
                controls={true}
              /> */}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default ShowCandidat;
