import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Label,
} from "reactstrap";
import Toast from "react-bootstrap/Toast";
import { register, registerCand, registerEmployer } from "../../network/ApiAxios";
import RegisterEmployer from "components/other/RegisterEmployer";
import config from "../../config";
import { PencilAltIcon } from "@heroicons/react/outline";
import Select from "react-select";
import { options_Pays } from "options";
import LoginPostul from "components/loginPostul";
import axios from "axios";
import { useTranslation } from "react-i18next";
const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

const RegisterCandidateV3 = (props) => {
  console.log('222 proppps in register', props);
    //shared
  const [name, setName] = useState("");
  const [CV, setCV] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
    //Candidat
  const [email, setEmail] = useState("");
  const [studyLevel, setStudyLevel] = useState("");  
  const [yearsOfExperience, setYearsOfExperience] = useState("");
  const [adress, setAdress] = useState("");
  const [projets, setProjets] = useState("");
  //const [projet_France_CDI, set_Projet_France_CDI] = useState("");
  //const [projet_Etranger_hors_France_CDI, set_projet_Etranger_hors_France_CDI] = useState("");
    //admin
  const [company, setCompany] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [webSite, setWebSite] = useState("");
  const [activity, setActivity] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");

  const [newAccountCreated, setNewAccountCreated] = useState(false);
  const [newEmployerAccountCreated, setNewEmployerAccountCreated] = useState(false);

  const [whatsappnumber, setWhatsappnumber] = useState("");
  const [linkedin, setLinkedin] = useState("");
  
  const [profileType, setProfileType] = useState("");


  const [role, setRole] = useState("candidat");
  const [checkbox, setCheckbox] = useState(false);
  const [acceptNewsletterReception, setAcceptNewsletterReception] = useState(false);
  const [profileTypesOptions, setProfileTypesOptions] = useState([]);
  const [error, setError] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(
    "Email sent! Check it to reset your password."
  );
  const [userID, setUserID] = useState(null);

  const { t, i18n } = useTranslation();



  const handleCandidatValidation = () => {
    let isValid = true;


    if (!location) {
      isValid = false;
      setError("impossible de procéder avec un pays vide ");
    }
    if (!email) {
      isValid = false;
     setError("impossible de procéder avec un Email vide ");

      if (typeof email !== "undefined") {
        let lastAtPos = email.lastIndexOf("@");
        let lastDotPos = email.lastIndexOf(".");

        if (
          !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            email.indexOf("@@") == -1 &&
           lastDotPos > 2 &&
           email.length - lastDotPos > 2
          )
        ) {
          isValid = false;
          setError("L'Email n'est pas valide");
        }
      }

      console.log(error);
     
    }
    if (!lastName) {
      isValid = false;
      setError("impossible de procéder avec un prénom vide ");
    }
    if (!name) {
      isValid = false;
      setError("impossible de procéder avec un nom vide ");
    }
    if (!setProfileType) {
      isValid = false;
      setError("impossible de procéder avec un type de profil vide ");
    }
       
    return isValid;
  };

  const handleOutFrCandidatValidation = () => {
    let isValid = true;

    if (!name) {
      isValid = false;
      setError("impossible de procéder avec un prénom vide ");
      return isValid;
    }
    if (!lastName) {
      isValid = false;
      setError("impossible de procéder avec un nom vide ");
      return isValid;
    }
    if (!profileType) {
      isValid = false;
      setError("impossible de procéder avec un type de profil vide ");
      return isValid;
    }

    if (!location) {
      isValid = false;
      setError("impossible de procéder avec un pays vide ");
      return isValid;
    }

    if (!whatsappnumber) {
      isValid = false;
      setError("impossible de procéder avec un numéro Whatsapp vide ");
      return isValid;
    }

    if (!linkedin) {
      isValid = false;
      setError("impossible de procéder avec Linkedin vide ");
      return isValid;
    }


    if (!CV) {
      isValid = false;
      setError("impossible de procéder sans CV au format PDF ");
      return isValid;
    }

    if (!email) {

      if (typeof email !== "undefined") {
        let lastAtPos = email.lastIndexOf("@");
        let lastDotPos = email.lastIndexOf(".");

        if (
          !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            email.indexOf("@@") == -1 &&
           lastDotPos > 2 &&
           email.length - lastDotPos > 2
          )
        ) {
          isValid = false;
          setError("L'Email n'est pas valide");
          return isValid;
        }
      }

      console.log(error);
     
    }

    if (!password) {
      isValid = false;
      setError("impossible de procéder avec un mot de passe vide ");
      return isValid;
    }
    if (!confirmPassword) {
      isValid = false;
      setError("impossible de procéder sans confirmer le mot de passe ");
      return isValid;
    }

    if (password !== confirmPassword) {
      isValid = false;
      setError("Merci de de bien confirmer le mot de passe: Les mots de passe ne correspondent pas !");
      // setError("Les mots de passe ne correspondent pas");
      return isValid;
    }



       
    return isValid;
  };

  const handleFrCandidatValidation = () => {
    let isValid = true;

    if (!name) {
      isValid = false;
      setError("impossible de procéder avec un prénom vide ");
      return isValid;
    }
    if (!lastName) {
      isValid = false;
      setError("impossible de procéder avec un nom vide ");
      return isValid;
    }
    if (!profileType) {
      isValid = false;
      setError("impossible de procéder avec un type de profil vide ");
      return isValid;
    }

    if (!location) {
      isValid = false;
      setError("impossible de procéder avec un pays vide ");
      return isValid;
    }


    if (!CV) {
      isValid = false;
      setError("impossible de procéder sans CV au format PDF ");
      return isValid;
    }

    if (!email) {

      if (typeof email !== "undefined") {
        let lastAtPos = email.lastIndexOf("@");
        let lastDotPos = email.lastIndexOf(".");

        if (
          !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            email.indexOf("@@") == -1 &&
           lastDotPos > 2 &&
           email.length - lastDotPos > 2
          )
        ) {
          isValid = false;
          setError("L'Email n'est pas valide");
          return isValid;
        }
      }

      console.log(error);
     
    }

    if (!password) {
      isValid = false;
      setError("impossible de procéder avec un mot de passe vide ");
      return isValid;
    }
    if (!confirmPassword) {
      isValid = false;
      setError("impossible de procéder sans confirmer le mot de passe ");
      return isValid;
    }

    if (password !== confirmPassword) {
      isValid = false;
      setError("Merci de de bien confirmer le mot de passe: Les mots de passe ne correspondent pas !");
      // setError("Les mots de passe ne correspondent pas");
      return isValid;
    }



       
    return isValid;
  };

  const registerOutFrCandidate = async () => {
    // if (!(name && email && password && confirmPassword && checkbox)) {
    //     setError("Make sure to fill all the inputs and agree to the privacy policy");
    //     return;
    // }
    console.log("formisvalid", handleOutFrCandidatValidation());

    if (handleOutFrCandidatValidation()) {

      
      const formData = new FormData();

      formData.append("name", name);
      formData.append("lastName", lastName);
      formData.append("email", email);
      formData.append("password", password);
      formData.append("profileType", profileType);
      formData.append("location", location);
      formData.append("whatsappnumber", whatsappnumber);
      formData.append("linkedin", linkedin);
      formData.append("acceptNewsletterReception", acceptNewsletterReception);
      formData.append("CV", CV);
      

      

  
      instance.post(`users/registerNewCandidateUserWithCV?id=${props.id}&redirect=${props.redirect}`, formData).then((resp) => {
        console.log("resgister v4: registerOutFrCandidate: ", resp)
        if (!resp.data.success) {
          setError(resp.data.msg);
          return;
        }
        if (config.DEMO) {
          setToastMessage(
            "Votre compte a été créé avec succès ! Un email vous a été envoyé pour la confirmation"
          );
          
          setUserID(resp.data.userID);
     
        }
        setNewAccountCreated(true);
        setError("");
        setName("");
        setLastName("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setCheckbox(false);
        setShowToast(true);
        setProfileType("");
        setLocation("");
        // setProjets("");
        setAcceptNewsletterReception(false);
        setWhatsappnumber("");
        setLinkedin("");
        setCV("");
      });






  };
};

  const registerFrCandidate = async () => {
    // if (!(name && email && password && confirmPassword && checkbox)) {
    //     setError("Make sure to fill all the inputs and agree to the privacy policy");
    //     return;
    // }
    console.log("formisvalid", handleFrCandidatValidation());

    if (handleFrCandidatValidation()) {

      
      const formData = new FormData();

      formData.append("name", name);
      formData.append("lastName", lastName);
      formData.append("email", email);
      formData.append("password", password);
      formData.append("profileType", profileType);
      formData.append("location", location);

      formData.append("whatsappnumber", "");
      formData.append("linkedin", "");

      formData.append("acceptNewsletterReception", acceptNewsletterReception);
      formData.append("CV", CV);
      

      


      instance.post(`users/registerNewCandidateUserWithCV?id=${props.id}&redirect=${props.redirect}`, formData).then((resp) => {
        console.log("resgister v4: registerOutFrCandidate: ", resp)
        if (!resp.data.success) {
          setError(resp.data.msg);
          return;
        }
        if (config.DEMO) {
          setToastMessage(
            "Votre compte a été créé avec succès ! Un email vous a été envoyé pour la confirmation"
          );
          
          setUserID(resp.data.userID);
    
        }


        setError("");
        setName("");
        setLastName("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setCheckbox(false);
        setShowToast(true);
        setProfileType("");
        setLocation("");
        // setProjets("");
        setAcceptNewsletterReception(false);
        // setWhatsappnumber("");
        // setLinkedin("");
        setCV("");
      });






  };
  };

  const getProfileTypesFunction = () => {
    // console.log("5555555555555555555555555555: getProfileTypesFunction");
    // var pipeline = "Rejeté";
    // const response = getProfileTypes();
    // const { data } = response;
    // console.log("aaaaaaaaaaaaaaaaaaaaaaaa: getProfileTypesFunction: response: ", response);
    // console.log("aaaaaaaaaaaaaaaaaaaaaaaa: getProfileTypesFunction: response.data: ", response.data);
    
    instance.get(`profiletypes/getAllActiveProfileTypes`).then((resp) => {
      // console.log("5555555555555555555555555555: etrangerCDIenFranceGeneralInterviewsView getProfileTypesFunction: resp: ", resp);
      // console.log("5555555555555555555555555555: etrangerCDIenFranceGeneralInterviewsView getProfileTypesFunction: resp.data: ", resp.data);
      let newArray;
      newArray = resp.data;
      // newArray = resp.data;
      setProfileTypesOptions(newArray.map(item => ({value: item.jobID, label: item.name})));


      // const orderItems = req.body.orderItems.map((x) => ({ ...x, product: x._id }));

      // setProfileTypesOptions(newArray);
      // console.log("5555555555555555555555555555: getProfileTypesFunction: newArray: ", newArray);

      // console.log("5555555555555555555555555555: getProfileTypesFunction: resp: ", resp);
      // console.log("5555555555555555555555555555: getProfileTypesFunction: profileTypes: ", profileTypes);
      // console.log("5555555555555555555555555555: getProfileTypesFunction: profileTypesOptions: ", profileTypesOptions);
      
      // console.log("5555555555555555555555555555: fetchCandidateCompetencies: step 2:");
      // console.log("5555555555555555555555555555: fetchCandidateCompetencies: step 3 : resp: ", resp);
      // console.log("5555555555555555555555555555: fetchCandidateCompetencies: step 4 : resp.data: ", resp.data);
      });
    // console.log("5555555555555555555555555555: getProfileTypesFunction: success");
    // console.log("5555555555555555555555555555: response.data: ", response.data);
    // setVisibility(response.data.visibility);
    // setProfileTypeName(response.data.name);
    // const orderItems = req.body.orderItems.map((x) => ({ ...x, product: x._id }));


  };

  useEffect(() => {
  
    getProfileTypesFunction();
  
  }, []);

  return (
    <>
      <div
        aria-live="polite"
        aria-atomic="true"
        style={{
          position: "fixed",
          minHeight: "100px",
          width: "35%",
          right: 10,
          bottom: 80,
          zIndex: 50,
        }}
      >
        <Toast
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            backgroundColor: "white",
            padding: 10,
            borderRadius: 10,
          }}
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={10000}
          autohide={!config.DEMO}
        >
          <Toast.Header>
            {/* <img
              style={{ height: "30px", width: "100px" }}
              src={require("assets/img/brand/argon-react.png").default}
              alt="..."
            /> */}
          </Toast.Header>
          <Toast.Body>
            {toastMessage} <br/>
            {/*(config.DEMO && (props.redirect !== "job")) ? (
              <a href={config.DOMAIN_NAME + "/home/login"}>
                se connecter ?
              </a>
            ) : null*/}
          </Toast.Body>
        </Toast>
      </div>
            
      <>
          <h3 className="text-center"> {t("NavBarLink2")} {t("RegisterKey3")} {t("RegisterKey2")}</h3>
          <Form role="form">
          <FormGroup>
              <InputGroup className="input-group-alternative mb-3">
              <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                  <PencilAltIcon />
                  </InputGroupText>
              </InputGroupAddon>
              <Input
                  placeholder={t("firstName")}
                  type="text"
                  value={name}
                  required
                  onChange={(e) => setName(e.target.value)}
              />
              </InputGroup>
          </FormGroup>
          <FormGroup>
              <InputGroup className="input-group-alternative mb-3">
              <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                  <PencilAltIcon />
                  </InputGroupText>
              </InputGroupAddon>
              <Input
                  placeholder={t("lastName")}
                  type="text"
                  value={lastName}
                  required
                  onChange={(e) => setLastName(e.target.value)}
              />
              </InputGroup>
          </FormGroup>
          <FormGroup>
          <InputGroup className="input-group-alternative mb-3">
              <InputGroupAddon addonType="prepend">
                  
              </InputGroupAddon>
              <label className="px-lg-3">{t("ProfilteTypeTitle")}</label>
                <Select 
                  //name="Niveau du diplome" 
                  //label="Niveau du diplome" 
                  name="jobid_select"
                  // placeholder="gender" 
                  className="w-full "
                  // options={job_IDs_for_recruters}
                  options={profileTypesOptions}
                  //fullWidth 
                  //onBlur={handleBlur} 
                  // onChange={handleChange} 
                  //value={NiveauDiplome}
                  onChange={(e) => { 
                    // console.log("0000000000000000000: e.value: ", e.value)
                    setProfileType(e.value)
                    // setLoading(false);
                  }} 
                  // value={values.NiveauDiplome || ""} 
                  //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                  //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                />
                </InputGroup>
            </FormGroup>

          <FormGroup>
              <InputGroup className="input-group-alternative mb-3">
              <InputGroupAddon addonType="prepend">
                  
              </InputGroupAddon>
              <label className="px-lg-3">{t('Country')}</label>
              <Select className="w-full "
                      options={options_Pays}
                      
                      onChange={(e) => {
                          setLocation(e.value);
                          }} />
              </InputGroup>
          </FormGroup>
          {(location !== "France" && location !== "") &&
            <>
              <FormGroup xs={12}>
              <label className="px-lg-3">Whatsapp</label>
              <Input
                  name="Whatsapp"
                  // label="Nom" 
                  // placeholder="Whatsapp" 
                  className="w-full " 
                  // onBlur={handleBlur} 
                  // value={whatsappnumber}
                  // onChange={handleChange}
                  onChange={e => setWhatsappnumber(e.target.value)} 

                  //onChange={e => setPhonenumber(e.target.value)}

                  
                  // value={values.titre || ""} 
                  // error={!!touched.titre && !!errors.titre} 
                  // helperText={touched.titre && errors.titre} 
                  />
              </FormGroup>

              <FormGroup xs={12}>
              <label className="px-lg-3">Linkedin ({t('UrlRequest')})</label>
                <Input 
                name="Linkedin"
                // label="Nom" 
                // placeholder="Linkedin" 
                className="w-full " 
                // onBlur={handleBlur} 
                // value={linkedin}
                // onChange={handleChange} 
                onChange={e => setLinkedin(e.target.value)}
                // value={values.titre || ""} 
                // error={!!touched.titre && !!errors.titre} 
                // helperText={touched.titre && errors.titre} 
                />
              </FormGroup>


            </>
          }


          <FormGroup>
              <InputGroup className="input-group-alternative mb-3">
              <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                  <PencilAltIcon />
                  </InputGroupText>
              </InputGroupAddon>
              <Input
                  placeholder="Email"
                  type="email"
                  autoComplete="new-email"
                  value={email}
                  required
                  onChange={(e) => setEmail(e.target.value)}
              />
              </InputGroup>
          </FormGroup>
          
          <FormGroup>
              <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                  <PencilAltIcon />
                  </InputGroupText>
              </InputGroupAddon>
              <Input
                  placeholder={t("password")}
                  type="password"
                  autoComplete="new-password"
                  value={password}
                  required
                  onChange={(e) => setPassword(e.target.value)}
              />
              </InputGroup>
          </FormGroup>
          <FormGroup>
              <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                  <PencilAltIcon />
                  </InputGroupText>
              </InputGroupAddon>
              <Input
                  placeholder={t('PasswordConfirmation')}
                  type="password"
                  autoComplete="new-password"
                  value={confirmPassword}
                  required
                  onChange={(e) => setConfirmPassword(e.target.value)}
              />
              </InputGroup>
          </FormGroup>
          <FormGroup>
                <Label
                  className="form-control-label"
                  htmlFor="input-email"
                >
                  {t('CvPdf')}
                </Label>
                <Input
                className="form-control-alternative"
                type="file"
                accept=".pdf"
                onChange={(e) =>
                  setCV(e.target.files[0])
                }
                // CVinput
              />
            </FormGroup>
          {error ? (
              <div className="text-muted font-italic">
              <small>
                  error:{" "}
                  <span className="text-red font-weight-700">{error}</span>
              </small>
              </div>
          ) : null}
          <Row className="my-4">
              <Col xs="12">
                  <input
                  id="customCheckRegister"
                  type="checkbox"
                  checked={checkbox}
                  onChange={() => setCheckbox(!checkbox)}
                  />
                  <label>
                  <span className="text-muted">
                      {t('AcceptationCGU')} {" "}
                      <a 
                        href="/home/generalConditions" 
                        // onClick={(e) => e.preventDefault()}
                        target="blank"
                      >
                      CGU
                      </a>
                  </span>
                  </label>
              </Col>
          </Row>
          <Row className="my-4">
              <Col xs="12">
                  <input
                  // className="custom-control-input"
                  id="customCheckNewsletterCandidate"
                  type="checkbox"
                  checked={acceptNewsletterReception}
                  onChange={() => setAcceptNewsletterReception(!acceptNewsletterReception)}
                  />
                  <label
                  // className="custom-control-label"
                  // htmlFor="customCheckRegister"
                  >
                  <span className="text-muted">
                      {t('Inscription')}
                  </span>
                  </label>
              </Col>
          </Row>

            <div className="text-center">
              <Button
              style={{
              
              width: "100%",
              }}
              onClick={()=>{
                  {location === "France" &&
                    // registerCandidat();
                    registerFrCandidate();
                  }
                  {location !== "France" &&
                  registerOutFrCandidate();
                }

                  }}
              className="mt-4"
              color="primary"
              type="button"
              >
              {t('accountCreation')}
              </Button>
        </div>

          </Form>
      </>
    </>
  );
};

export default RegisterCandidateV3;
