import React, { useEffect, useState } from "react";

import {
  Button,
  Form,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,
  Card,
  FormText,
  FormFeedback,
} from "reactstrap";

import { useForm } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import Header from "components/Headers/Header.js";
import config from "../../config";
import axios from "axios";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});

const ChangeCompetitionSpecifications = (props) => {
  const [NewSpecs, setNewSpecs] = useState("");
  console.log(props);
  const history = useHistory();
  const {
    handleSubmit,
    formState: { errors },
    watch,
    register,
    setValue,
  } = useForm();

  const changeSpecsFile = () => {
    console.log("ChangeCompetitionSpecifications: changeSpecsFile: ");
    console.log("ChangeCompetitionSpecifications: changeSpecsFile: props.competitionID: ", props.competitionID);
    console.log("ChangeCompetitionSpecifications: changeSpecsFile: NewSpecs: ", NewSpecs);

    const formData = new FormData();

    formData.append("id", props.competitionID);
    formData.append("specs", NewSpecs);

    instance.post("competitions/changeCompetitionSpecs", formData).then((resp) => {
      history.push("/employer/Competitions/EmployerCompetitions");
      // console.log("8888888888888: formData: ", formData);
      // history.push('outfrapplypermanentfr/home');
      // history.push("google.fr")
    });
  };
  const handleChange = (e) => {
    setValue("eventImage", e.target.files[0]);
  };
  const handleChangePDF = (e) => {
    setValue("eventPDF", e.target.files[0]);
  };
  return (
    <>
      <Container fluid className="mt-4">
      <Row>
        <Col lg="2">
        </Col>
        <Col lg="10">
        <Card className="p-4 shadow-sm p-3 mb-5 bg-white rounded">
          <Form>


            <FormGroup>
              <Label>Cahier de charge (Il faut mettre un fichier PDF)*</Label>
              <Input
                className="form-control-alternative"
                
                type="file"
                accept=".pdf"
                onChange={(e) =>
                  setNewSpecs(e.target.files[0])
                  }
              />
            </FormGroup>


            <Button 
            variant="contained" 
            color="primary" 
            // type="submit" 
            sx={{ mt: "25px" }}
            onClick={() => { 
              // console.log("bouton cliqué 0000000000000000000000 ")

              changeSpecsFile()
            }}
            >
              Changer le cahier de charge
            </Button>
          </Form>
        </Card>
        </Col>
        </Row>
      </Container>
    </>
  );
};

export default ChangeCompetitionSpecifications;