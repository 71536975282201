import axios from "axios";
import {
  getAccessRuleByJobIDAndUserID,
} from "network/ApiAxios";

import EmployerLayout from "layouts/EmployerLayout";
import React, { useEffect, useState } from "react";
import ShowApplicationEmployerV4 from "components/employer/showApplicationEmployerV4";
import ShowJobAppForOneJob from "components/employer/showJobAppForOneJob";
import { useHistory, useLocation } from "react-router-dom";


import config from "config";
import {

  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import qs from "query-string";
import { getUserById } from "network/ApiAxios";



function OneJobApplicationView({ match }) {
  const location = useLocation();
  const history = useHistory();
  const [jobAppDate, setJobAppDate] = useState("");
  const [isloading, setIsLoading] = useState(false);

  const query = qs.parse(location.search);
  var jobApplicationId = (query.jobApplicationId);
  
  var user;
  if ((localStorage.getItem("user"))) {
    user = JSON.parse(localStorage.getItem("user"));
  } else {
    user = { role: "none" };
  };

  const instance = axios.create({
    baseURL: config.WS_BASE_URL,
  });

  instance.interceptors.request.use(async (config) => {
    const token = localStorage.getItem("token");
    config.headers.Authorization = token ? token : "";
    config.headers['Content-Type'] = "application/json";
    return config;
  });

  const HandleRecruiterNotCreatorForTheOffer = async (userId) => {
    try {
      const { data } = await getUserById(userId);
      if (data?.employerCampanyID !== user?.employerCampanyID) {
        history.push('/employer/home');
      };
    } catch (error) {
      console.log(error)
    }
  }

  const getOneJobAppByID = async () => {
    try {
      setIsLoading(true)
      const { data } = await instance.get(`/jobApplication/getJobApplicationById/${match.params.jobApplicationId}`)
      data && setJobAppDate(data?.createdAt)
      //console.log("data jobAppDate", data)
      const resp = await instance.get(`jobs/${data.jobID}`)
      //console.log('pff', resp.data)
      await HandleRecruiterNotCreatorForTheOffer(resp.data.job.userID)

    } catch (error) {
      console.log(error)
    }
    finally {
      setIsLoading(false)
    }
  }



  useEffect(() => {
    getOneJobAppByID()
  }, [])




  return (
    <>
      <EmployerLayout />
      <Container>
        <Row>
          <Col lg="2">
          </Col>
          <Col lg="10">
            {isloading ?
              <div className="d-flex justify-content-center align-items-center">
                <Spinner color="primary">
                  Loading...
                </Spinner>
              </div>
              : <ShowJobAppForOneJob
                candidatId={match.params.jobApplicationId}
                jobAppDate={jobAppDate}
              />}

          </Col>
        </Row>

      </Container>










    </>
  );
}

export default OneJobApplicationView;