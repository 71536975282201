import EmployerLayout from "layouts/EmployerLayout";
import React, { useEffect, useState } from "react";
import PageTitleStyle from "components/styles/PageTitleStyle";
import PageSpace from "components/styles/pageSpace";
import OneJob from "components/recruteur/oneJob";
// import OneJobEmployer from "components/employer/oneJobEmployer";
import OneJobEmployerV2 from "components/employer/oneJobEmployerV2";
import {
  Container,
  Row,
  Col,
} from "reactstrap";

import axios from "axios";
import config from "config";
import { useHistory, useLocation,  } from "react-router-dom";
import qs from "query-string";
import { Box } from "@mui/material";

const instance = axios.create({
    baseURL: config.WS_BASE_URL,
  });
  
  instance.interceptors.request.use(async (config) => {
    const token = localStorage.getItem("token");
    config.headers.Authorization = token ? token : "";
    config.headers['Content-Type'] = "application/json";
    return config;
  });
  

function OneJobEmployerView({ match }) {
    const location = useLocation();
    const query = qs.parse(location.search);
    const user = JSON.parse(localStorage.getItem("user"));
    let jobID = match.params.jobId;
    const [job, setJob] = useState({});
    const [jobTitle, setJobTitle] = useState("");
    const fetchJobData = () => {
      instance.get(`jobs/${jobID}`).then((resp) => {
        setJob(resp.data.job);
        setJobTitle(resp.data.job.title);
        // setEndDate(resp.data.job.endDate.slice(0, 10));
       
      });
  
    };
    useEffect(() => {
      fetchJobData();
    }, [jobID]);

  return (
    <>
      <EmployerLayout />
      <PageSpace />
      <PageTitleStyle
        title={job.title}
      >     
      </PageTitleStyle>
      <Container>
      <Row>
        <Col lg="2">
        </Col>
        <Col lg="10">
          <OneJobEmployerV2 jobId={jobID} />
        </Col>
      </Row>
  </Container>
    </>
  );
}

export default OneJobEmployerView;