
import axios from "axios";
import { optionsThink, optionsTimes } from "options";
import React, { useEffect, useState, Component } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Badge,
  Label,
  FormGroup,
} from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import "reactjs-popup/dist/index.css";
import config from "config";
import AddJobQuestion from "components/employer/addJobQuestion";
import AddJobQuestionCatalog from "components/employer/addJobQuestionCatalog";
import AddCodingQuestion from "./AddCodingQuestion";
import { useTranslation } from "react-i18next";
import QuestionsLists from "./QuestionsLists";
import AddJobQuestionsFromDB from "./AddJobQuestionsFromDB";
import { UpdatJobOneField } from "network/ApiAxios";
import { GenerateIAQuestionsFomJobDescription } from "network/ApiAxios";
import AlertMessage from "components/styles/AlertMessage";



const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});

function QuestionsV2({ jobId, fct }) {
  const { t, i18n } = useTranslation();

  const [job, setJob] = useState(null);
  const [questions, setQuestions] = useState(null);
  const [programed_Bloc_Questions_1, set_programed_Bloc_Questions_1] = useState("");
  const [programed_Bloc_Questions_Job_1, set_programed_Bloc_Questions_Job_1] = useState({});
  const [programed_Bloc_Questions_JobTitle_1, set_programed_Bloc_Questions_JobTitle_1] = useState("");
  const [programed_Bloc_Questions_2, set_programed_Bloc_Questions_2] = useState("");
  const [programed_Bloc_Questions_Job_2, set_programed_Bloc_Questions_Job_2] = useState({});
  const [programed_Bloc_Questions_JobTitle_2, set_programed_Bloc_Questions_JobTitle_2] = useState("");
  const [programed_Bloc_Questions_3, set_programed_Bloc_Questions_3] = useState("");
  const [programed_Bloc_Questions_Job_3, set_programed_Bloc_Questions_Job_3] = useState({});
  const [programed_Bloc_Questions_JobTitle_3, set_programed_Bloc_Questions_JobTitle_3] = useState("");
  const [programed_Bloc_Questions_4, set_programed_Bloc_Questions_4] = useState("");
  const [programed_Bloc_Questions_JobTitle_4, set_programed_Bloc_Questions_JobTitle_4] = useState("");
  const [programed_Bloc_Questions_5, set_programed_Bloc_Questions_5] = useState("");
  const [programed_Bloc_Questions_JobTitle_5, set_programed_Bloc_Questions_JobTitle_5] = useState("");
  const [programed_Bloc_Questions_6, set_programed_Bloc_Questions_6] = useState("");
  const [programed_Bloc_Questions_JobTitle_6, set_programed_Bloc_Questions_JobTitle_6] = useState("");
  const [programed_Bloc_Questions_7, set_programed_Bloc_Questions_7] = useState("");
  const [programed_Bloc_Questions_JobTitle_7, set_programed_Bloc_Questions_JobTitle_7] = useState("");




  const history = useHistory();
  const location = useLocation();

  const [questionAddingModalOpen, setQuestionAddingModalOpen] = useState(false);
  const [codingQuestionModalOpen, setCodingQuestionModalOpen] = useState(false)
  const [isQuestionChargedFromDB, setIsQuestionChargedFromDB] = useState(null);
  const [option1, setOption1] = useState(false);
  const [option2, setOption2] = useState(false);
  const [option3, setOption3] = useState(false);
  const [questionsNb, setQuestionsNb] = useState(null);
  const [questionsNb1, setQuestionsNb1] = useState(null);
  const [msg, setMsg] = useState(null);
  const [color, setColor] = useState('success');

  const user = JSON.parse(localStorage.getItem('user'));

  const toggleCQModal = () => setCodingQuestionModalOpen(!codingQuestionModalOpen)

  // console.log("aaaaaaa",ThisQuestion)



  const fetchData = () => {
    instance.get(`jobs/${jobId}`)
      .then((resp) => {
        setJob(resp.data.job)
        setOption1(resp?.data?.job?.Add_IAquestions_from_jobDescription)
        setOption2(resp?.data?.job?.Add_IAquestions_from_CV_Candidate)
        setOption3(resp?.data?.job?.Add_FollowUp_IACode_Questions)
        setQuestions(resp.data.job.questions);
        if (resp.data.job.questionCatalog1) {
          set_programed_Bloc_Questions_1(resp.data.job.questionCatalog1);
          instance.get(`jobs/${resp.data.job.questionCatalog1}`)
            .then((resp) => {
              set_programed_Bloc_Questions_Job_1(resp.data.job);
              set_programed_Bloc_Questions_JobTitle_1(resp.data.job.title);
              // console.log("pppppppppppppppp: QuestionsV2: resp.data.job.title: ", resp.data.job.title);
            })
            .catch((err) => console.log(err));
        };
        if (resp.data.job.questionCatalog2) {
          set_programed_Bloc_Questions_2(resp.data.job.questionCatalog2);
          instance.get(`jobs/${resp.data.job.questionCatalog2}`)
            .then((resp) => {
              set_programed_Bloc_Questions_Job_2(resp.data.job);
              set_programed_Bloc_Questions_JobTitle_2(resp.data.job.title);
              // console.log("pppppppppppppppp: QuestionsV2: resp.data.job.title: ", resp.data.job.title);
            })
            .catch((err) => console.log(err));
        };
        if (resp.data.job.questionCatalog3) {
          set_programed_Bloc_Questions_3(resp.data.job.questionCatalog3);
          instance.get(`jobs/${resp.data.job.questionCatalog3}`)
            .then((resp) => {
              set_programed_Bloc_Questions_Job_3(resp.data.job);
              set_programed_Bloc_Questions_JobTitle_3(resp.data.job.title);
              // console.log("pppppppppppppppp: QuestionsV2: resp.data.job.title: ", resp.data.job.title);
            })
            .catch((err) => console.log(err));
        };

        if (resp.data.job.questionCatalog4) {
          set_programed_Bloc_Questions_4(resp.data.job.questionCatalog4);
          instance.get(`jobs/${resp.data.job.questionCatalog4}`)
            .then((resp) => {
              set_programed_Bloc_Questions_JobTitle_4(resp.data.job.title);
            })
            .catch((err) => console.log(err));
        };

        if (resp.data.job.questionCatalog5) {
          set_programed_Bloc_Questions_5(resp.data.job.questionCatalog5);
          instance.get(`jobs/${resp.data.job.questionCatalog5}`)
            .then((resp) => {
              set_programed_Bloc_Questions_JobTitle_5(resp.data.job.title);
            })
            .catch((err) => console.log(err));
        };

        if (resp.data.job.questionCatalog6) {
          set_programed_Bloc_Questions_6(resp.data.job.questionCatalog6);
          instance.get(`jobs/${resp.data.job.questionCatalog6}`)
            .then((resp) => {
              set_programed_Bloc_Questions_JobTitle_6(resp.data.job.title);
            })
            .catch((err) => console.log(err));
        };

        if (resp.data.job.questionCatalog7) {
          set_programed_Bloc_Questions_7(resp.data.job.questionCatalog7);
          instance.get(`jobs/${resp.data.job.questionCatalog7}`)
            .then((resp) => {
              set_programed_Bloc_Questions_JobTitle_7(resp.data.job.title);
            })
            .catch((err) => console.log(err));
        };

        // setEndDate(resp.data.job.endDate.slice(0, 10));

      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    fetchData();
    // fetchQuestionsCatalogData();
  }, []);

  const ToggleOption = async (e, statee, setter) => {
    try {
      setter(!statee);
      await UpdatJobOneField(jobId, e.target.name, { "fieldValue": !statee })
      //fetchData()
    } catch (error) {
      console.log(error)
    }
  }

  const ChangeIaQuestionNbForCV= async()=>{
    try {
      const {data} = await UpdatJobOneField(jobId, "IAquestions_from_CV_CandidateCount", { "fieldValue": questionsNb1 })
      if(data.success){
        setMsg(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const HandleChangeInput = (e, setter) => {
    setter(+e.target.value)
  }

  const GenerateQuestions = async () => {
    try {
      const { data } = await GenerateIAQuestionsFomJobDescription(jobId, questionsNb);
      setMsg(data?.msg);
      fetchData()
    } catch (error) {
      setMsg(error?.response?.data?.msg)
      setColor('danger')
    }
  }


  return (
    <Container>
      <Row className="mb-2 justify-content-center">
          <Button
            style={{
              backgroundColor: "#404954",
              borderColor: "#404954",
              color: "white",
            }}
            className=""
            color=""
            type="button"
            size="lg"
            onClick={() => {
              setQuestionAddingModalOpen(!questionAddingModalOpen);
            }}
          >
            {t('AddQuestion')}
          </Button>

        {/* <Col sm='6'>
          <Button
            style={{
              backgroundColor: "#404954",
              borderColor: "#404954",
              color: "white",
            }}
            className="ml-5"
            color=""
            type="button"
            size="lg"
            onClick={() => {
              setCodingQuestionModalOpen(!codingQuestionModalOpen);
            }}
          >
            Ajouter une question de type code (β)
          </Button>
        </Col> */}

      </Row>

      <Row>
        <hr />
      </Row>
      <Row className="mb-2">
        {!questions && (
          <h3>{t('QuestionAddKey1')}!</h3>
        )}
        <ul className="list-decimal list-inside">
          <h3>{t('QuestionAddKey2')}</h3>
          <QuestionsLists
            questions={questions}
            jobId={jobId}
            callback={fetchData}
          />

        </ul>
      </Row>

      <Row className='mb-1'>
        <h3>Blocs de questions</h3>
      </Row>

      <AddJobQuestionCatalog
        jobID={jobId}
      />

      <Row>
        <hr />
      </Row>
      <Row>
        <h3>{t('QuestionAddKey5')}</h3>
      </Row>
      {msg &&
                <Row className='mt-2 mb-2'>
                  <AlertMessage message={msg} color={color} />
                </Row>}

      <Row className="mb-2">
        <>
          <div className="w-full">
            <FormGroup check className='mb-4'>
              <Input
                checked={option1}
                type="checkbox"
                name="Add_IAquestions_from_jobDescription"
                onChange={(e) => ToggleOption(e, option1, setOption1)} />
              <Label check>
                Questions générées à partir de description de l'offre par l'IA
              </Label>
            </FormGroup>
            {option1 && <div>
              <div className="d-flex mt-2 mb-4">
                <Input
                  className='w-50'
                  hidden={job?.IAquestions_from_jobDescription?.length}
                  type='number'
                  placeholder='Ajouter ou modifier le nombre de questions à générer'
                  onChange={(e) => HandleChangeInput(e, setQuestionsNb)}

                />
                <Button
                  className='ml-2'
                  hidden={job?.IAquestions_from_jobDescription?.length}
                  onClick={GenerateQuestions}
                >
                  Générer des questions
                </Button>
              </div>

            </div>
            }

          </div>

          <div className="w-full">
            <FormGroup check className='mb-4'>
              <Input
                checked={option2}
                type="checkbox"
                name="Add_IAquestions_from_CV_Candidate"
                onChange={(e) => ToggleOption(e, option2, setOption2)} />
              <Label check>
                Questions générées à partir de CV du candidat par l'IA
                <br />
                Une fois l'option est activée, le candidat répond sur des questions générales orales générées par ChatGPT.
              </Label>
            </FormGroup>
            {option2 && <div className="d-flex mb-4">
              <Input
                className='w-50'
                type='number'
                defaultValue={job?.IAquestions_from_CV_CandidateCount}
                placeholder='Ajouter ou modifier le nombre de questions à générer'
                onChange={(e) => HandleChangeInput(e, setQuestionsNb1)}
              />
              <Button
                  className='ml-2'
                  onClick={ChangeIaQuestionNbForCV}
                >
                  Modifier
                </Button>
            </div>
            }
          </div>

          {/* <FormGroup check>
            <Input
              checked={option3}
              type="checkbox"
              name="Add_FollowUp_IACode_Questions"
              onChange={(e) => ToggleOption(e, option3, setOption3)} />
            <Label check>
              Questions de suivi (β)
              <br />
              Les candidats répondront à des questions de suivi après les exercices de code. Ces questions sont générées par ChatGPT et vous permettent de vérifier que les candidats comprennet le code su'ils ont fourni
            </Label>
          </FormGroup> */}
        </>


      </Row>

      <Modal
        toggle={() => setQuestionAddingModalOpen(!questionAddingModalOpen)}
        isOpen={questionAddingModalOpen}
        size="lg">
        <ModalBody>
          <Row className='justify-content-center'>
            <Col>
              <input
                name="radio1"
                type="radio"
                onChange={() => setIsQuestionChargedFromDB(true)}
                checked={isQuestionChargedFromDB == true}

              />
              <Label>Charger des questions de la base de données</Label>
            </Col>
            <Col>
              <input
                name="radio1"
                type="radio"
                onChange={() => setIsQuestionChargedFromDB(false)}
                checked={isQuestionChargedFromDB == false}
              />
              <Label>Créer une question</Label>
            </Col>
          </Row>
          <hr />
          {isQuestionChargedFromDB === false && <AddJobQuestion
            jobID={jobId}
            fct={fetchData}
            toggle={() => setQuestionAddingModalOpen(!questionAddingModalOpen)}
          />}
          {isQuestionChargedFromDB === true && <AddJobQuestionsFromDB
            jobID={jobId}
            questions={questions}
            fct={fetchData}
            toggle={() => setQuestionAddingModalOpen(!questionAddingModalOpen)}
          />}
        </ModalBody>

      </Modal>
      {/*  <Modal 
      toggle={() => setQuestionCatalog1AddingModalOpen(!questionCatalog1AddingModalOpen)} 
      isOpen={questionCatalog1AddingModalOpen}
      size="lg"
    >
      <ModalBody>
        <AddJobQuestionCatalog 
        jobID={jobId}
        callback={fetchData}
        toggle={() => setQuestionCatalog1AddingModalOpen(!questionCatalog1AddingModalOpen)}
        />
      </ModalBody>               
      
    </Modal> */}
      <Modal
        toggle={toggleCQModal}
        isOpen={codingQuestionModalOpen}
        size="lg"
      >
        <ModalBody>
          <AddCodingQuestion
            toggle={toggleCQModal}
            jobID={jobId}
            fct={fetchData}
            questions={questions}
          />
        </ModalBody>

      </Modal>



    </Container>

  );
}

export default QuestionsV2;
