import axios from "axios";
import { 
  getAccessRuleByJobIDAndUserID,
 } from "network/ApiAxios";

import EmployerLayout from "layouts/EmployerLayout";
import React, { useEffect, useState } from "react";
import ShowJobAppForOutFrCandidateEmployerV6 from "components/employer/showJobAppForOutFrCandidateEmployerV6";

import { useHistory, useLocation } from "react-router-dom";


import config from "config";
import {

  Container,
  Row,
  Col,
} from "reactstrap";
import qs from "query-string";



function OutFrContractOneJobAppView ({ match }) {
  const location = useLocation();

  const query = qs.parse(location.search);
  var jobApplicationId = (query.jobApplicationId);
  

  var user;
  if (localStorage.getItem("user")) {
  user = JSON.parse(localStorage.getItem("user"));
   } else {
   user = { role: "none" };
   };

   const instance = axios.create({
    baseURL: config.WS_BASE_URL,
  });
  
  instance.interceptors.request.use(async (config) => {
    const token = localStorage.getItem("token");
    config.headers.Authorization = token ? token : "";
    config.headers['Content-Type'] = "application/json";
    return config;
  });



  

  return (
    <>
      <EmployerLayout />
      <Container>
        <Row>
        <Col lg="2">
        </Col>
        <Col lg="10">
          <ShowJobAppForOutFrCandidateEmployerV6
                candidatId={match.params.jobApplicationId}
              />

        </Col>
        </Row>

        </Container>




       
      

      

      
    </>
  );
}

export default OutFrContractOneJobAppView;