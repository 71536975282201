import axios from "axios";
import React, { useEffect, useState } from "react";
// import ShowCandidat from "components/recruteur/ShowCandidat";


//import OneInterview from "components/recruteur/OneInterview";
import { useHistory, useLocation, Link } from "react-router-dom";
import qs from "query-string";
//import ShowCandidat from "./ShowCandidat";
import {
  PencilAltIcon,
  LocationMarkerIcon,
  BriefcaseIcon,
  MailIcon,
  PhoneIcon,
  LinkIcon,
  CodeIcon,
  ArchiveIcon,
  OfficeBuildingIcon,
  PaperClipIcon,
  AcademicCapIcon,
  UserIcon,
  LightBulbIcon,
  VideoCameraIcon,
  EyeIcon
} from "@heroicons/react/solid";
import { SearchIcon } from "@heroicons/react/outline";
import {
  DropdownMenu,
  Media,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormText,
  FormFeedback,
  CardText,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Table,
} from "reactstrap";
//import {getAll} from "../../network/ApiAxios";
import config from "config";
import { set } from "react-hook-form";
import { useTranslation } from "react-i18next";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});


function JobApplicationCardEmployer( props ) {
  const { t, i18n } = useTranslation();

  const [jobApplicationNotes, setJobApplicationNotes] = useState([]);

  const fetchCandidateNotes = async () => {
    
    instance.get(`/jobApplicationNotes/getNotesByCandidateID/${props.candidatId}`).then((resp) => {
      if (resp.data.length) {
        setJobApplicationNotes(resp.data);
        console.log('notes in card',resp.data)
      }
      });
  };

  useEffect(()=>{
    fetchCandidateNotes()
  },[props.candidatId])

  const ModifiedDate = jobApplicationNotes.length && jobApplicationNotes[jobApplicationNotes.length - 1]?.createdAt;
  console.log('pff mil modification jobNote', jobApplicationNotes )


  return (
    <>
    <Container>
    
    <Row>
    <Col lg="3">
      <img
        src={`${config.Data_URL}${props.userIDImage}`}
        className="rounded-circle"
        onError={(e) => {
          e.target.src =
            require("assets/img/brand/profile-icon-3.png").default;
        }}
        style={{
          width: "100%",
          height: "100%",

          //objectFit: "cover",
        }}
        //alt="Responsive image"
      />
    </Col>
    <Col lg="9">
      <Col lg="12" className="mb-2">
        <div>
          <span className="card-t">
            {props.prenom}{" "}
            {props.nom}{" "}
          </span>
        </div>
      </Col>
      <Row>
          <Col lg="12">
            <div
              className="text-sm"
              style={{ display: "inline-flex" }}
            >
              {" "}
              <MailIcon
                className=""
                style={{ height: "1.2rem" }}
              />{" "}
              &nbsp; <span>{props.email}</span>{" "}
            </div>
          </Col>
          <Col lg="12">
            <div
              className="text-sm"
              style={{ display: "inline-flex" }}
            >
              {" "}
              <BriefcaseIcon
                className=""
                style={{ height: "1.2rem" }}
              />{" "}
              &nbsp; <span>{props.Jobtitle}</span>{" "}
            </div>
          </Col>
          <Col lg="12">
            <div
              className="text-sm"
              style={{ display: "inline-flex" }}
            >
              {" "}
              <LightBulbIcon
                className=""
                style={{ height: "1.2rem" }}
              />{" "}
              &nbsp;{" "}
              <span>
                {" "}
                Date de création :{props.jobAPplicationCreatedAt.slice(0, 10)}
              </span>{" "}
            </div>
          </Col>
          {ModifiedDate ? <Col lg="12">
            <div
              className="text-sm"
              style={{ display: "inline-flex" }}
            >
              {" "}
              <EyeIcon
                className=""
                style={{ height: "1.2rem" }}
              />{" "}
              &nbsp;{" "}
              <span>
                Date de modification : {ModifiedDate.slice(0, 10)} 
              </span>
            </div>
          </Col> : null}
          <Col lg="12">
            <div
              className="text-sm"
              style={{ display: "inline-flex" }}
            >
              {" "}
              <VideoCameraIcon
                className=""
                style={{ height: "1.2rem" }}
              />{" "}
              &nbsp;{" "}
              <span>
                {" "}
                {/* console.log("55555555555555555555555555 jobApplicationsEmployerV3") */}
                {/* console.log("55555555555555555555555555 jobApplicationsEmployerV3: participant?.interviewCandidat", participant?.interviewCandidat?.videos?.length) */}
                {t('jobAppKEY3')}: {props.jobApplicationVideosLength}
              </span>{" "}
            </div>
          </Col>

      </Row>
    </Col>
    </Row>

      
      </Container>
    </>
  );
}

export default JobApplicationCardEmployer;
