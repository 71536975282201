import {
  ViewListIcon,
  ArrowCircleDownIcon,
  PencilAltIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/outline";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";

import { Row, Button, Col, Card, CardImg, FormGroup, InputGroup, InputGroupAddon, CardHeader, Table, CardBody, Form } from "reactstrap";

import "../custom.css";
import config from "config";

// import 'assets/css/react_pdf_css.css';

// import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

// pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;




const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});
function JobApplicationTitleForOutFrContract(props) {
  const history = useHistory();


  const [jobApplication, setJobApplication] = useState({});
  const [otherJob, setOtherJob] = useState({});

  const getJobApplication = () => {

    // console.log("1111111111111111111111111111 oneJobApplicationCardSuperAdminV2 getJobApplication");
    // console.log("1111111111111111111111111111 oneJobApplicationCardSuperAdminV2 getJobApplication job_Application_ID", job_Application_ID);
    instance.get(`jobApplication/getJobApplicationById/${props.oneJobApplicationID}`).then((resp) => {
      // console.log("getJobApplication: ", resp);
      // setCreatedAt(resp.data.createdAt);
      // console.log("1111111111111111111111111111 JobApplicationsEmployerV3: createdAt", createdAt);
      setJobApplication(resp.data);
      // console.log("1111111111111111111111111111 resp.data: ", resp.data);
      // console.log("1111111111111111111111111111 jobApplication: ", jobApplication);
      // console.log("1111111111111111111111111111 jobID: ", jobID);
  
      // console.log("1111111111111111111111111111 JobApplicationsEmployerV3: resp : ", resp);
      // console.log("1111111111111111111111111111 oneJobApplicationCardSuperAdmin: getJobApplication: resp.data : ", resp.data);
      
    });
  };

const getOtherJobData = () => {
  if (props.jobID !=="") {
    instance.get(`jobs/${props.jobID}`).then((resp) => {
      if (resp.data !== null) {
        if ((resp.data.job !== null)) {
          if ((resp.data.job.title !== null)) {
            console.log("777777777777777777777777777777: getOtherJobData: step 2:");
            console.log("777777777777777777777777777777: getOtherJobData: step 3 : resp: ", resp);
            console.log("777777777777777777777777777777: getOtherJobData: step 4 : resp.data: ", resp.data);
            console.log("777777777777777777777777777777: getOtherJobData: step 4 : resp.data.job.title: ", resp.data.job.title);
            setOtherJob(resp.data.job);
          }
        }
      }
    });

  }


};


   useEffect(() => {
    getJobApplication();
    getOtherJobData();

  
  }, []);






  return (
    <>
        <Button
        className="text-light"
        tag={Link}
        target="blank"
        to={`/jobApplication/oneJobApplication/${props.oneJobApplicationID}`}
        // onClick={() => props.history.push("xxxxxxxxxxxxxxxxxxxxxxxxxxx/auth/reset-password")}

        // onClick={() => history.push(`/jobApplication/oneJobApplication/${oneJobApplication._id}`)}
        // <small>{otherJob.title}</small>
        
      >
        <small>{otherJob.title} - {jobApplication.pipeline}</small>
      </Button>

        
              


    </>
  );
}

export default JobApplicationTitleForOutFrContract;
