import {
  ViewListIcon,
  ArrowCircleDownIcon,
  PencilAltIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/outline";
import JobApplicationTitle from "components/employer/jobApplicationTitle";
import JobApplicationNote from "components/employer/jobApplicationNote";
import JobAppGeneralAnalysis from "components/employer/jobAppGeneralAnalysis";
import JobAppScoreReport from "components/employer/jobAppScoreReport";
import JobAppNotes from "components/employer/jobAppNotes";
import JobAppFormForOutFrProfile from "components/employer/jobAppFormForOutFrProfile";


import {options_jobApplicationPipeline} from "options";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import VideoPlayer from "react-video-js-player";
import DownloadLink from "react-download-link";
import { ClipLoader } from "react-spinners";
import { 
  Container, 
  Button, 
  Col, 
  Card, 
  CardImg, 
  FormGroup, 
  InputGroup, 
  InputGroupAddon, 
  CardHeader, 
  Table, 
  CardBody, 
  Form,
  Input,
} from "reactstrap";
import Select from "react-select";
import { Grid, TextField } from "@mui/material";

import "../custom.css";
import Row from "reactstrap/lib/Row";
import config from "config";





const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});
function ProfileCompetenciesoutFrCandidate(props) {
  const history = useHistory();
  // console.log("11111111111111111111111111111: ShowApplicationEmployerV4");
  // console.log("11111111111111111111111111111: ShowApplicationEmployerV4: candidatId: ", candidatId);

  const [candidateCompetenciesAnalysis, setCandidateCompetenciesAnalysis] = useState("");
  const [jobApplicationCompetencies, setJobApplicationCompetencies] = useState("");
  const [jobApplicationUserID, setJobApplicationUserID] = useState("");

  
  const user = JSON.parse(localStorage.getItem("user"));








const fetchCandidateCompetencies = async () => {
  // console.log("222222222222222222: fetchCandidateCompetencies: step 0: ");
  // console.log("222222222222222222: fetchCandidateCompetencies: step 1: fetchCandidateCompetencies: ", jobApplicationUserID);
  
  instance.get(`/candidatesCompetencies/getCompetenciesByUserID/${props.candidateUserID}`).then((resp) => {
    setJobApplicationCompetencies(resp.data);
    // console.log("222222222222222222: fetchCandidateCompetencies: step 2:");
    // console.log("222222222222222222: fetchCandidateCompetencies: step 3 : resp: ", resp);
    // console.log("222222222222222222: fetchCandidateCompetencies: step 4 : resp.data: ", resp.data);
    });
};




const fetchCandidateCompetenciesAnalysis = async () => {
  // console.log("333333333333333333: fetchCandidateCompetenciesAnalysis: step 0: ");
  // console.log("333333333333333333: fetchCandidateCompetenciesAnalysis: step 1: ", jobApplicationUserID);
  
  instance.get(`/jobApplicationAnalysis/getCompetenciesAnalysisByUserID/${props.candidateUserID}`).then((resp) => {
    setCandidateCompetenciesAnalysis(resp.data);
    // console.log("333333333333333333: fetchCandidateCompetenciesAnalysis: step 2:");
    // console.log("333333333333333333: fetchCandidateCompetenciesAnalysis: step 3 : resp: ", resp);
    // console.log("333333333333333333: fetchCandidateCompetenciesAnalysis: step 4 : resp.data: ", resp.data);
    });
};








   useEffect(() => {
    fetchCandidateCompetencies();
    // fetchCandidateCompetenciesAnalysis();
  
  }, []);







  return (
    <>
    <Row>
      <h4 className=" mb-4">Compétences </h4>
    </Row>
    <Row>

      {jobApplicationCompetencies &&
            <Form>
              {(jobApplicationCompetencies.profileType) &&
                <p className=" mb-4">Type du profil: {jobApplicationCompetencies.profileType}</p>
              }
              {(jobApplicationCompetencies.ccnaNetSec) &&
                <p className=" mb-4">CCNA: {jobApplicationCompetencies.ccnaNetSec}</p>
              }
              {(jobApplicationCompetencies.ccnp_NetSec) &&
                <p className=" mb-4">CCNP: {jobApplicationCompetencies.ccnp_NetSec}</p>
              }
              {(jobApplicationCompetencies.ciscoNetSec) &&
                <p className=" mb-4">cisco: {jobApplicationCompetencies.ciscoNetSec}</p>
              }
              {(jobApplicationCompetencies.nexusNetSec) &&
                <p className=" mb-4">Nexus: {jobApplicationCompetencies.nexusNetSec}</p>
              }
              {(jobApplicationCompetencies.MPLSNetSec) &&
                <p className=" mb-4">MPLS: {jobApplicationCompetencies.MPLSNetSec}</p>
              }
              {(jobApplicationCompetencies.FortinetNetSec) &&
                <p className=" mb-4">Fortinet: {jobApplicationCompetencies.FortinetNetSec}</p>
              }
              {(jobApplicationCompetencies.PaloAltoNetSec) &&
                <p className=" mb-4">PaloAlto: {jobApplicationCompetencies.PaloAltoNetSec}</p>
              }
              {(jobApplicationCompetencies.F5NetSec) &&
                <p className=" mb-4">F5: {jobApplicationCompetencies.F5NetSec}</p>
              } 
              {(jobApplicationCompetencies.ACENetSec) &&
                <p className=" mb-4">ACE: {jobApplicationCompetencies.ACENetSec}</p>
              }     
              {(jobApplicationCompetencies.CiscoASANetSec) &&
                <p className=" mb-4">Cisco ASA: {jobApplicationCompetencies.CiscoASANetSec}</p>
              }
              {(jobApplicationCompetencies.Windows_Server_SW) &&
                <p className=" mb-4">Windows_Server: {jobApplicationCompetencies.Windows_Server_SW}</p>
              }
              {(jobApplicationCompetencies.Active_Directory_SW) &&
                <p className=" mb-4">Active Directory: {jobApplicationCompetencies.Active_Directory_SW}</p>
              }
              {(jobApplicationCompetencies.Office_365_SW) &&
                <p className=" mb-4">Office 365: {jobApplicationCompetencies.Office_365_SW}</p>
              }
              {(jobApplicationCompetencies.EXCHANGE_SW) &&
                <p className=" mb-4">EXCHANGE: {jobApplicationCompetencies.EXCHANGE_SW}</p>
              }
              {(jobApplicationCompetencies.SHAREPOINT_SW) &&
                <p className=" mb-4">SHAREPOINT: {jobApplicationCompetencies.SHAREPOINT_SW}</p>
              }
              {(jobApplicationCompetencies.SCCM_SW) &&
                <p className=" mb-4">SCCM: {jobApplicationCompetencies.SCCM_SW}</p>
              }
              {(jobApplicationCompetencies.SCOM_SW) &&
                <p className=" mb-4">SCOM: {jobApplicationCompetencies.SCOM_SW}</p>
              } 
              {(jobApplicationCompetencies.IIS_SW) &&
                <p className=" mb-4">IIS: {jobApplicationCompetencies.IIS_SW}</p>
              }     
              {(jobApplicationCompetencies.Powershell_SW) &&
                <p className=" mb-4">Powershell: {jobApplicationCompetencies.Powershell_SW}</p>
              }
              {(jobApplicationCompetencies.HyperV_SW) &&
                <p className=" mb-4">HyperV: {jobApplicationCompetencies.HyperV_SW}</p>
              }
              {(jobApplicationCompetencies.Azure_SW) &&
                <p className=" mb-4">Azure: {jobApplicationCompetencies.Azure_SW}</p>
              }
              {(jobApplicationCompetencies.AWS_SW) &&
                <p className=" mb-4">AWS: {jobApplicationCompetencies.AWS_SW}</p>
              }
              {(jobApplicationCompetencies.Devops_SW) &&
                <p className=" mb-4">Devops: {jobApplicationCompetencies.Devops_SW}</p>
              }
              {(jobApplicationCompetencies.Docker_SW) &&
                <p className=" mb-4">Docker: {jobApplicationCompetencies.Docker_SW}</p>
              }
              {(jobApplicationCompetencies.Kubernetes_SW) &&
                <p className=" mb-4">Kubernetes: {jobApplicationCompetencies.Kubernetes_SW}</p>
              }
              {(jobApplicationCompetencies.LINUX_SL) &&
                <p className=" mb-4">LINUX: {jobApplicationCompetencies.LINUX_SL}</p>
              } 
              {(jobApplicationCompetencies.Redhat_SL) &&
                <p className=" mb-4">Redhat: {jobApplicationCompetencies.Redhat_SL}</p>
              }     
              {(jobApplicationCompetencies.Ubuntu_SL) &&
                <p className=" mb-4">Ubuntu: {jobApplicationCompetencies.Ubuntu_SL}</p>
              }
              {(jobApplicationCompetencies.Shell_SL) &&
                <p className=" mb-4">Shell: {jobApplicationCompetencies.Shell_SL}</p>
              }
              {(jobApplicationCompetencies.Apache_SL) &&
                <p className=" mb-4">Apache: {jobApplicationCompetencies.Apache_SL}</p>
              }
              {(jobApplicationCompetencies.Docker_SL) &&
                <p className=" mb-4">Docker: {jobApplicationCompetencies.Docker_SL}</p>
              }
              {(jobApplicationCompetencies.Kubernetes_SL) &&
                <p className=" mb-4">Kubernetes: {jobApplicationCompetencies.Kubernetes_SL}</p>
              }
              {(jobApplicationCompetencies.Ansible_SL) &&
                <p className=" mb-4">Ansible: {jobApplicationCompetencies.Ansible_SL}</p>
              }
              {(jobApplicationCompetencies.Terraform_SL) &&
                <p className=" mb-4">Terraform: {jobApplicationCompetencies.Terraform_SL}</p>
              }
              {(jobApplicationCompetencies.Puppet_SL) &&
                <p className=" mb-4">Puppet: {jobApplicationCompetencies.Puppet_SL}</p>
              } 
              {(jobApplicationCompetencies.Jenkins_SL) &&
                <p className=" mb-4">Jenkins: {jobApplicationCompetencies.Jenkins_SL}</p>
              }     
              {(jobApplicationCompetencies.GIT_SL) &&
                <p className=" mb-4">GIT: {jobApplicationCompetencies.GIT_SL}</p>
              }
              {(jobApplicationCompetencies.Openstack_SL) &&
                <p className=" mb-4">Openstack: {jobApplicationCompetencies.Openstack_SL}</p>
              }
              {(jobApplicationCompetencies.Azure_SL) &&
                <p className=" mb-4">Azure: {jobApplicationCompetencies.Azure_SL}</p>
              }
              {(jobApplicationCompetencies.AWS_SL) &&
                <p className=" mb-4">AWS: {jobApplicationCompetencies.AWS_SL}</p>
              }
              {(jobApplicationCompetencies.Devops_SL) &&
                <p className=" mb-4">Devops: {jobApplicationCompetencies.Devops_SL}</p>
              }
              {(jobApplicationCompetencies.profile_Change_SR) &&
                <p className=" mb-4">Ouvert pour un changement de profil: {jobApplicationCompetencies.profile_Change_SR}</p>
              }
              {(jobApplicationCompetencies.Windows_Server_SR) &&
                <p className=" mb-4">Windows_Server: {jobApplicationCompetencies.Windows_Server_SR}</p>
              }
              {(jobApplicationCompetencies.Directory_SR) &&
                <p className=" mb-4">Active Directory: {jobApplicationCompetencies.Directory_SR}</p>
              } 
              {(jobApplicationCompetencies.Office_365_SR) &&
                <p className=" mb-4">Office 365: {jobApplicationCompetencies.Office_365_SR}</p>
              }     
              {(jobApplicationCompetencies.EXCHANGE_SR) &&
                <p className=" mb-4">EXCHANGE: {jobApplicationCompetencies.EXCHANGE_SR}</p>
              }
              {(jobApplicationCompetencies.SHAREPOINT_SR) &&
                <p className=" mb-4">SHAREPOINT: {jobApplicationCompetencies.SHAREPOINT_SR}</p>
              }
              {(jobApplicationCompetencies.SCCM_SR) &&
                <p className=" mb-4">SCCM: {jobApplicationCompetencies.SCCM_SR}</p>
              }
              {(jobApplicationCompetencies.SCOM_SR) &&
                <p className=" mb-4">SCOM: {jobApplicationCompetencies.SCOM_SR}</p>
              }
              {(jobApplicationCompetencies.IIS_SR) &&
                <p className=" mb-4">IIS: {jobApplicationCompetencies.IIS_SR}</p>
              }
              {(jobApplicationCompetencies.Powershell_SR) &&
                <p className=" mb-4">Powershell: {jobApplicationCompetencies.Powershell_SR}</p>
              }
              {(jobApplicationCompetencies.HyperV_SR) &&
                <p className=" mb-4">HyperV: {jobApplicationCompetencies.HyperV_SR}</p>
              }
              {(jobApplicationCompetencies.Azure_SR) &&
                <p className=" mb-4">Azure: {jobApplicationCompetencies.Azure_SR}</p>
              } 
              {(jobApplicationCompetencies.AWS_SR) &&
                <p className=" mb-4">AWS: {jobApplicationCompetencies.AWS_SR}</p>
              }     
              {(jobApplicationCompetencies.Devops_SR) &&
                <p className=" mb-4">Devops: {jobApplicationCompetencies.Devops_SR}</p>
              }
              {(jobApplicationCompetencies.LINUX_SR) &&
                <p className=" mb-4">LINUX: {jobApplicationCompetencies.LINUX_SR}</p>
              }
              {(jobApplicationCompetencies.Shell_SR) &&
                <p className=" mb-4">Shell: {jobApplicationCompetencies.Shell_SR}</p>
              }
              {(jobApplicationCompetencies.Redhat_SR) &&
                <p className=" mb-4">Redhat: {jobApplicationCompetencies.Redhat_SR}</p>
              }
              {(jobApplicationCompetencies.Ubuntu_SR) &&
                <p className=" mb-4">Ubuntu: {jobApplicationCompetencies.Ubuntu_SR}</p>
              }
              {(jobApplicationCompetencies.Apache_SR) &&
                <p className=" mb-4">Apache: {jobApplicationCompetencies.Apache_SR}</p>
              }
              {(jobApplicationCompetencies.Docker_SR) &&
                <p className=" mb-4">Docker: {jobApplicationCompetencies.Docker_SR}</p>
              }
              {(jobApplicationCompetencies.Ansible_SR) &&
                <p className=" mb-4">Ansible: {jobApplicationCompetencies.Ansible_SR}</p>
              } 
              {(jobApplicationCompetencies.Terraform_SR) &&
                <p className=" mb-4">Terraform: {jobApplicationCompetencies.Terraform_SR}</p>
              }     
              {(jobApplicationCompetencies.Puppet_SR) &&
                <p className=" mb-4">Puppet: {jobApplicationCompetencies.Puppet_SR}</p>
              }
              {(jobApplicationCompetencies.Jenkins_SR) &&
                <p className=" mb-4">Jenkins: {jobApplicationCompetencies.Jenkins_SR}</p>
              }
              {(jobApplicationCompetencies.GIT_SR) &&
                <p className=" mb-4">GIT: {jobApplicationCompetencies.GIT_SR}</p>
              }
              {(jobApplicationCompetencies.Openstack_SR) &&
                <p className=" mb-4">Openstack: {jobApplicationCompetencies.Openstack_SR}</p>
              }
              {(jobApplicationCompetencies.CCNP_SR) &&
                <p className=" mb-4">CCNP: {jobApplicationCompetencies.CCNP_SR}</p>
              }
              {(jobApplicationCompetencies.CCNA_SR) &&
                <p className=" mb-4">CCNA: {jobApplicationCompetencies.CCNA_SR}</p>
              }
              {(jobApplicationCompetencies.Cisco_SR) &&
                <p className=" mb-4">Cisco: {jobApplicationCompetencies.Cisco_SR}</p>
              }
              {(jobApplicationCompetencies.Nexus_SR) &&
                <p className=" mb-4">Nexus: {jobApplicationCompetencies.Nexus_SR}</p>
              } 
              {(jobApplicationCompetencies.Fortinet_SR) &&
                <p className=" mb-4">Fortinet: {jobApplicationCompetencies.Fortinet_SR}</p>
              }     
              {(jobApplicationCompetencies.PaloAlto_SR) &&
                <p className=" mb-4">PaloAlto: {jobApplicationCompetencies.PaloAlto_SR}</p>
              }
              {(jobApplicationCompetencies.F5_SR) &&
                <p className=" mb-4">F5: {jobApplicationCompetencies.F5_SR}</p>
              }
              {(jobApplicationCompetencies.ACE_SR) &&
                <p className=" mb-4">ACE: {jobApplicationCompetencies.ACE_SR}</p>
              }
              {(jobApplicationCompetencies.CiscoASA_SR) &&
                <p className=" mb-4">Cisco ASA: {jobApplicationCompetencies.CiscoASA_SR}</p>
              }
              {(jobApplicationCompetencies.profile_change_Virt) &&
                <p className=" mb-4">profile change: {jobApplicationCompetencies.profile_change_Virt}</p>
              }
              {(jobApplicationCompetencies.Windows_Server_Virt) &&
                <p className=" mb-4">Windows Server: {jobApplicationCompetencies.Windows_Server_Virt}</p>
              }
              {(jobApplicationCompetencies.Office_365_Virt) &&
                <p className=" mb-4">Office 365: {jobApplicationCompetencies.Office_365_Virt}</p>
              }
              {(jobApplicationCompetencies.LINUX_Virt) &&
                <p className=" mb-4">LINUX: {jobApplicationCompetencies.LINUX_Virt}</p>
              } 
              {(jobApplicationCompetencies.Redhat_Virt) &&
                <p className=" mb-4">Redhat: {jobApplicationCompetencies.Redhat_Virt}</p>
              }     
              {(jobApplicationCompetencies.Ubuntu_Virt) &&
                <p className=" mb-4">Ubuntu: {jobApplicationCompetencies.Ubuntu_Virt}</p>
              }
              {(jobApplicationCompetencies.Shell_Virt) &&
                <p className=" mb-4">Shell: {jobApplicationCompetencies.Shell_Virt}</p>
              }
              {(jobApplicationCompetencies.Apache_Virt) &&
                <p className=" mb-4">Apache: {jobApplicationCompetencies.Apache_Virt}</p>
              }
              {(jobApplicationCompetencies.Docker_Virt) &&
                <p className=" mb-4">Docker: {jobApplicationCompetencies.Docker_Virt}</p>
              }
              {(jobApplicationCompetencies.Ansible_Virt) &&
                <p className=" mb-4">Ansible: {jobApplicationCompetencies.Ansible_Virt}</p>
              }
              {(jobApplicationCompetencies.Terraform_Virt) &&
                <p className=" mb-4">Terraform: {jobApplicationCompetencies.Terraform_Virt}</p>
              }
              {(jobApplicationCompetencies.Cisco_Virt) &&
                <p className=" mb-4">Cisco: {jobApplicationCompetencies.Cisco_Virt}</p>
              }
              {(jobApplicationCompetencies.Nexus_Virt) &&
                <p className=" mb-4">Nexus: {jobApplicationCompetencies.Nexus_Virt}</p>
              } 
              {(jobApplicationCompetencies.Fortinet_Virt) &&
                <p className=" mb-4">Fortinet: {jobApplicationCompetencies.Fortinet_Virt}</p>
              }     
              {(jobApplicationCompetencies.PaloAlto_Virt) &&
                <p className=" mb-4">PaloAlto: {jobApplicationCompetencies.PaloAlto_Virt}</p>
              }
              {(jobApplicationCompetencies.F5_Virt) &&
                <p className=" mb-4">F5: {jobApplicationCompetencies.F5_Virt}</p>
              }
              {(jobApplicationCompetencies.ACE_Virt) &&
                <p className=" mb-4">ACE: {jobApplicationCompetencies.ACE_Virt}</p>
              }
              {(jobApplicationCompetencies.CiscoASA_Virt) &&
                <p className=" mb-4">Cisco ASA: {jobApplicationCompetencies.CiscoASA_Virt}</p>
              }
              {(jobApplicationCompetencies.MySQL_BDD) &&
                <p className=" mb-4">MySQL: {jobApplicationCompetencies.MySQL_BDD}</p>
              }
              {(jobApplicationCompetencies.SQL_Server_BDD) &&
                <p className=" mb-4">SQL_Server: {jobApplicationCompetencies.SQL_Server_BDD}</p>
              }
              {(jobApplicationCompetencies.ORACLE_BDD) &&
                <p className=" mb-4">ORACLE: {jobApplicationCompetencies.ORACLE_BDD}</p>
              }
              {(jobApplicationCompetencies.POSTGRES_BDD) &&
                <p className=" mb-4">POSTGRES: {jobApplicationCompetencies.POSTGRES_BDD}</p>
              }
              {(jobApplicationCompetencies.Oracle_RAC_BDD) &&
                <p className=" mb-4">Oracle RAC: {jobApplicationCompetencies.Oracle_RAC_BDD}</p>
              }
              {(jobApplicationCompetencies.INGRES_BDD) &&
                <p className=" mb-4">INGRES: {jobApplicationCompetencies.INGRES_BDD}</p>
              }
              {(jobApplicationCompetencies.Hadoop_BigDataBI) &&
                <p className=" mb-4">Hadoop: {jobApplicationCompetencies.Hadoop_BigDataBI}</p>
              } 
              {(jobApplicationCompetencies.Cloudera_BigDataBI) &&
                <p className=" mb-4">Cloudera: {jobApplicationCompetencies.Cloudera_BigDataBI}</p>
              }     
              {(jobApplicationCompetencies.Apache_Hive_BigDataBI) &&
                <p className=" mb-4">Apache Hive_: {jobApplicationCompetencies.Apache_Hive_BigDataBI}</p>
              }
              {(jobApplicationCompetencies.Apache_NiFi_BigDataBI) &&
                <p className=" mb-4">Apache NiFi: {jobApplicationCompetencies.Apache_NiFi_BigDataBI}</p>
              }
              {(jobApplicationCompetencies.Spark_BigDataBI) &&
                <p className=" mb-4">Spark: {jobApplicationCompetencies.Spark_BigDataBI}</p>
              }
              {(jobApplicationCompetencies.Talend_BigDataBI) &&
                <p className=" mb-4">Talend: {jobApplicationCompetencies.Talend_BigDataBI}</p>
              }
              {(jobApplicationCompetencies.Power_BI_BigDataBI) &&
                <p className=" mb-4">Power BI: {jobApplicationCompetencies.Power_BI_BigDataBI}</p>
              }
              {(jobApplicationCompetencies.Python_Dev) &&
                <p className=" mb-4">Python: {jobApplicationCompetencies.Python_Dev}</p>
              }
              {(jobApplicationCompetencies.PHP_Dev) &&
                <p className=" mb-4">PHP: {jobApplicationCompetencies.PHP_Dev}</p>
              }
              {(jobApplicationCompetencies.Java_Dev) &&
                <p className=" mb-4">Java: {jobApplicationCompetencies.Java_Dev}</p>
              } 
              {(jobApplicationCompetencies.Spring_Dev) &&
                <p className=" mb-4">Spring: {jobApplicationCompetencies.Spring_Dev}</p>
              }     
              {(jobApplicationCompetencies.Angular_Dev) &&
                <p className=" mb-4">Angular: {jobApplicationCompetencies.Angular_Dev}</p>
              }
              {(jobApplicationCompetencies.Angular_JS_Dev) &&
                <p className=" mb-4">Angular JS: {jobApplicationCompetencies.Angular_JS_Dev}</p>
              }
              {(jobApplicationCompetencies.Laravel_Dev) &&
                <p className=" mb-4">Laravel: {jobApplicationCompetencies.Laravel_Dev}</p>
              }
              {(jobApplicationCompetencies.Symfony_Dev) &&
                <p className=" mb-4">Symfony: {jobApplicationCompetencies.Symfony_Dev}</p>
              }
              {(jobApplicationCompetencies.JavaScript_Dev) &&
                <p className=" mb-4">JavaScript: {jobApplicationCompetencies.JavaScript_Dev}</p>
              }
              {(jobApplicationCompetencies.React_JS_Dev) &&
                <p className=" mb-4">React JS: {jobApplicationCompetencies.React_JS_Dev}</p>
              }
              {(jobApplicationCompetencies.Vue_JS_Dev) &&
                <p className=" mb-4">Vue JS: {jobApplicationCompetencies.Vue_JS_Dev}</p>
              }
              {(jobApplicationCompetencies.Node_JS_Dev) &&
                <p className=" mb-4">Node JS: {jobApplicationCompetencies.Node_JS_Dev}</p>
              } 
              {(jobApplicationCompetencies.C_sharp_Dev) &&
                <p className=" mb-4">C sharp: {jobApplicationCompetencies.C_sharp_Dev}</p>
              }     
              {(jobApplicationCompetencies.DotNET_Dev) &&
                <p className=" mb-4">DotNET: {jobApplicationCompetencies.DotNET_Dev}</p>
              }
              {(jobApplicationCompetencies.Langage_R_Dev) &&
                <p className=" mb-4">Langage R: {jobApplicationCompetencies.Langage_R_Dev}</p>
              }
              {(jobApplicationCompetencies.Ruby_Dev) &&
                <p className=" mb-4">Ruby: {jobApplicationCompetencies.Ruby_Dev}</p>
              }
              {(jobApplicationCompetencies.ITIL_Autres) &&
                <p className=" mb-4">ITIL: {jobApplicationCompetencies.ITIL_Autres}</p>
              }
              {(jobApplicationCompetencies.Management_Autres) &&
                <p className=" mb-4">Management: {jobApplicationCompetencies.Management_Autres}</p>
              }
              {(jobApplicationCompetencies.SCRUM_Autres) &&
                <p className=" mb-4">SCRUM: {jobApplicationCompetencies.SCRUM_Autres}</p>
              }
              {(jobApplicationCompetencies.Chefferie_de_projet_Autres) &&
                <p className=" mb-4">Chefferie de projet: {jobApplicationCompetencies.Chefferie_de_projet_Autres}</p>
              }


            </Form>
      }  
          
      {!jobApplicationCompetencies &&
        <p>Ce candidat n'a pas rempli le tableau des compétences</p>
      }
        
      
    </Row>
    </>
  );
}

export default ProfileCompetenciesoutFrCandidate;
