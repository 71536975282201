import axios from "axios";
import Questions from "components/recruteur/Questions";
import UpdateForm from "components/UpdateForm";
import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  PencilAltIcon,
  LocationMarkerIcon,
  BriefcaseIcon,
  MailIcon,
  PhoneIcon,
  LinkIcon,
  CodeIcon,
  ArchiveIcon,
  OfficeBuildingIcon,
  PaperClipIcon,
  AcademicCapIcon,
  UserIcon,
  LightBulbIcon,
} from "@heroicons/react/solid";
import {
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormText,
  FormFeedback,
  CardText,
} from "reactstrap";
import CompCandidats from "components/recruteur/CompCandidats";
import SpecificationsForOneCompetition from "components/candidat/SpecificationsForOneCompetition";
import config from "config";
import LoginPostul from "components/loginPostul";
import CandidateLayoutV3 from "layouts/CandidateLayoutV3";
const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});

function CandidateOneCompetition({ match }) {
  
  let competitionId = match.params.competitionId;
  const history = useHistory();
  const [competition, setCompetition] = useState({});
  const [created, setCreated] = useState("");
  const [endDate, setEndDate] = useState("");
  const [interviewUrl, setInterviewUrl] = useState("");
  const [affiche, setAffiche] = useState({
    update: false,
    questions: false,
  });

  const [openTab, setOpenTab] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalPostul, setModalPostul] = useState(false);
  const [CVinput, setCVinput] = useState(false);
  const [CV, setCV] = useState("");
  const [candidatID, setCandidatID] = useState("");
  const [codeDescription, setCodeDescription] = useState("");
  const [codeLink, setCodeLink] = useState("");
  const [loginPostul, setLoginPostul] = useState(false);
  const [applied, setApplied] = useState(false);
  const [success, setSuccess] = useState(false);
  var user;
  if (localStorage.getItem("user")) {
    user = JSON.parse(localStorage.getItem("user"));
  } else {
    user = { role: "none" };
  }
  useEffect(() => {
    instance.get(`competitions/getOneByID/${competitionId}`).then((resp) => {
      console.log("CandidateOneCompetition: resp");
      setCompetition(resp.data.Item);
      setCreated(resp.data.Item.createdAt);
      // setInterviewUrl(resp.data.job.interview.url);
      setEndDate(resp.data.Item.endDate.slice(0, 10));
      resp.data.Item.participations.forEach((e) => {
        if (localStorage.getItem("user")) {
          if (JSON.parse(localStorage.getItem("user"))._id == e.candidat._id) {
            setApplied(true);
          }
        }
      });
    });
  }, [competitionId]);

  const deleteCompetition = () => {
    instance.delete(`competitions/${competitionId}`).then(() => {
      history.push("/admin/tab2comp");
    });
  };
  const participate = () => {
    const formData = new FormData();
    formData.append("CV", CV);
    formData.append("candidatID", user._id);
    formData.append("codeLink", codeLink);
    formData.append("codeDescription", codeDescription);
    instance
      .put(`jobs/participateComp/${competitionId}`, formData)
      .then((resp) => {
        
        if (resp.data.success){
          setSuccess(true);
          setApplied(true);}
      });
  };

  return (
    <>
    <CandidateLayoutV3 />
      <Container>
        
        <Row>
        <Col lg="2">
        </Col>
        <Col className="order-xl-1" xl="10">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Table className="GatTable">
                <tbody>
                  <tr>
                    <td
                      className={openTab === 1 ? "tdActive " : "td "}
                      //activeClassName={openTab === 1 ? "active" : {}}
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(1);
                      }}
                      //data-toggle="tab"
                      to="#link1"
                      //role="tablist"
                      //onClick={() => history.push('/admin/edit-profile')}
                      // size="sm"
                    >
                      <i className="fa fa-book" aria-hidden="true" />{" "}
                      Description
                    </td>
                    <td
                      className={openTab === 2 ? "tdActive " : "td "}
                      //activeClassName={openTab === 1 ? "active" : {}}
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(2);
                      }}
                      //data-toggle="tab"
                      to="#link2"
                      //role="tablist"
                      //onClick={() => history.push('/admin/edit-profile')}
                      // size="sm"
                    >
                      <i className="fa fa-book" aria-hidden="true" />{" "}
                      Cahier de charge
                    </td> 

                    {/* {user.role == "employer" && (
                  <Col className="text-right" xs="2">
                    <Button
                      style={
                        openTab === 2
                          ? {
                              color: "#455266",
                              backgroundColor: "#cecece",
                              borderColor: "#455266",
                            }
                          : { color: "white", backgroundColor: "#455266" }
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(2);
                      }}
                      data-toggle="tab"
                      href="#link2"
                      role="tablist"
                      //onClick={() => history.push('/admin/edit-profile')}
                      size="sm"
                    >
                      Questions
                    </Button>
                  </Col>
                )} */}


                    {user.role != "employer" && (
                      <>
                        <td style={{ width: "100%" }} />
                        <td
                          className="text-right"
                          style={{ float: "right" }}
                          lg="8"
                        >
                          {!applied && (
                            <Button
                              color="primary"
                              type="button"
                              onClick={() => {
                                setModalPostul(!modalPostul);
                              }}
                              //size="sm"
                            >
                              Participer
                            </Button>
                          )}
                          {applied && (
                            <Button
                              className="btn-secondary"
                              type="button"
                              disabled
                              //size="sm"
                            >
                              Vous avez participé avec succès
                            </Button>
                          )}
                          {user.role == "candidat" && (
                            <Modal
                              toggle={() => setModalPostul(!modalPostul)}
                              isOpen={modalPostul}
                              size="lg"
                            >
                              <div className=" modal-header">
                                <h4
                                  className=" modal-title"
                                  id="exampleModalLabel"
                                >
                                  {competition.title}
                                </h4>
                                <button
                                  aria-label="Close"
                                  className=" close"
                                  type="button"
                                  onClick={() => setModalPostul(!modalPostul)}
                                >
                                  <span aria-hidden={true}>×</span>
                                </button>
                              </div>
                              <ModalBody>
                                {success? 
                                <h3 className="text-center text-green"> Vous avez participé avec succès <i className="ni ni-like-2" /></h3>:
                                <Form>
                                  <Row>
                                    <Col lg="6">
                                      <FormGroup>
                                        <label
                                          className="form-control-label"
                                          htmlFor="input-username"
                                        >
                                          Nom
                                        </label>
                                        <Input
                                          className="form-control-alternative"
                                          value={user.name}
                                          id="input-username"
                                          placeholder="Username"
                                          // onChange={(e) => setName(e.target.value)}
                                          type="text"
                                          readOnly
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                      <FormGroup>
                                        <label
                                          className="form-control-label"
                                          htmlFor="input-username"
                                        >
                                          Prénom
                                        </label>
                                        <Input
                                          className="form-control-alternative"
                                          value={user.lastName}
                                          id="input-username"
                                          placeholder="Username"
                                          // onChange={(e) => setLastName(e.target.value)}
                                          type="text"
                                          readOnly
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="6">
                                      <FormGroup>
                                        <label
                                          className="form-control-label"
                                          htmlFor="input-email"
                                        >
                                          Email
                                        </label>
                                        <Input
                                          className="form-control-alternative"
                                          id="input-email"
                                          value={user.email}
                                          // onChange={(e) => setAdress(e.target.value)}
                                          type="email"
                                          readOnly
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                      <FormGroup>
                                        <label
                                          className="form-control-label"
                                          htmlFor="input-email"
                                        >
                                          Merci de mettre votre travail dans un fichier PDF
                                        </label>
                                        <Input
                                          className="form-control-alternative"
                                          type="file"
                                          accept=".pdf"
                                          onChange={(e) =>
                                            setCV(e.target.files[0])
                                          }
                                          CVinput
                                        />
                                        {/* </div> */}
                                      </FormGroup>
                                    </Col>
                                  </Row>

                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-email"
                                    >
                                      Si votre travail contient du code, mettez le lien vers le code (Github ou Gitlab).
                                      Mettez votre code en public pour qu'il soit accessible.
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      //id="input-email"
                                      placeholder="https://gitlab.com/nom-prénom/titre-de-la-competition"
                                      onChange={(e) =>
                                        setCodeLink(e.target.value)
                                      }
                                      type="text"
                                    />
                                  </FormGroup>
                                  <Row>
                                    <Col lg="12">
                                      <FormGroup>
                                        <label
                                          className="form-control-label"
                                          htmlFor="input-email"
                                        >
                                          Description de votre travail
                                        </label>
                                        <Input
                                          className="form-control-alternative"
                                          //id="input-email"

                                          onChange={(e) =>
                                            setCodeDescription(e.target.value)
                                          }
                                          type="textarea"
                                          style={{ height: "150px" }}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </Form>}
                              </ModalBody>
                              <ModalFooter>
                                <Button
                                  color="secondary"
                                  type="button"
                                  onClick={() => setModalPostul(!modalPostul)}
                                  size="sm"
                                >
                                  {success?"Fermer" :"Annuler"}
                                </Button>
                                {!success&& <Button
                                  color="primary"
                                  type="button"
                                  size="sm"
                                  onClick={() => {
                                    participate();
                                    //setModalPostul(!modalPostul);
                                  }}
                                >
                                  Pariciper
                                </Button>}
                              </ModalFooter>
                            </Modal>
                          )}
                          {user.role == "none" && (
                            <Modal
                              toggle={() => {
                                setModalPostul(!modalPostul);
                                setLoginPostul(false);
                              }}
                              isOpen={modalPostul}
                              size="lg"
                            >
                              <div className=" modal-header">
                                <h4
                                  className=" modal-title"
                                  id="exampleModalLabel"
                                >
                                  {competition.title}
                                </h4>
                                <button
                                  aria-label="Close"
                                  className=" close"
                                  type="button"
                                  onClick={() => {
                                    setModalPostul(!modalPostul);
                                    setLoginPostul(false);
                                  }}
                                >
                                  <span aria-hidden={true}>×</span>
                                </button>
                              </div>
                              <ModalBody>
                                {!loginPostul && (
                                  <Row>
                                    <Col lg="12">
                                      <h4 className="text-center">
                                        {" "}
                                        Vous devez être connecté pour pouvoir
                                        participer !
                                      </h4>
                                    </Col>
                                  </Row>
                                )}
                                {loginPostul && (
                                  <LoginPostul
                                    id={competitionId}
                                    redirect={"competition"}
                                  />
                                )}
                              </ModalBody>
                              {!loginPostul && (
                                <ModalFooter>
                                  <Col lg="6">
                                    <Button
                                      style={{
                                        width: "100%",
                                      }}
                                      className="btn-secondary"
                                      color="text-white"
                                      onClick={() => setLoginPostul(true)}
                                    >
                                      vous avez déjà un compte,
                                      connectez-vous!
                                    </Button>
                                  </Col>
                                  <Col lg="6">
                                    <Button
                                      style={{
                                        width: "100%",
                                      }}
                                      color="primary"
                                      to="/home/register"
                                      tag={Link}
                                    >
                                      Créez un nouveau compte!
                                    </Button>
                                  </Col>
                                </ModalFooter>
                              )}
                            </Modal>
                          )}
                        </td>
                      </>
                    )}
                  </tr>
                </tbody>
              </Table>
            </CardHeader>
            <CardBody
              className={openTab === 1 ? "block" : "hidden"}
              id="link1"
            >
              <Form>
                <h4 className=" mb-4">Description</h4>
                <div className="pl-lg-4">
                  <Row>
                    <div className="text-sm">{competition.description}</div>
                  </Row>
                </div>
                <hr className="my-4" />
                {/* Address */}
                <h4 className=" mb-4">Données supplémentaires</h4>
                <div className="pl-lg-4">
                  <Row>
                    {/*
                                          <Col lg="6">
                      <div
                        className="h5 mt-4 col-12 "
                        style={{ display: "inline-flex" }}
                      >
                        <Button
                          className="btn-secondary"
                          href={`${config.Data_URL}${competition.specs}`}
                          target="blank"
                          size="sm"
                        >
                          Cahier de charge
                        </Button>
                      </div>
                    </Col>
                    */}

                    {/*
                                          <Col lg="6">
                      <div
                        className="h5 mt-4 col-12 "
                        style={{ display: "inline-flex" }}
                      >
                        Date d'expiration: {endDate}
                      </div>
                    </Col>
                    */}

                  </Row>
                </div>
              </Form>
            </CardBody>
            <CardBody
              className={openTab === 2 ? "block" : "hidden"}
              id="link2"
            >
              <SpecificationsForOneCompetition
                  specificationsPDF={`${competition.specs}`}
              />
            </CardBody>

          </Card>
        </Col>
        </Row>
      </Container>
    </>
  );
}

export default CandidateOneCompetition;
