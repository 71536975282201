import JobApplicationTitle from "components/employer/jobApplicationTitle";
import JobApplicationTitleForOutFrContract from "components/employer/JobApplicationTitleForOutFrContract";

import CandidateAllInterviewsVideosSelectOneJobAPP from "components/employer/candidateAllInterviewsVideosSelectOneJobAPP";

import axios from "axios";
import React, { useState, useEffect } from "react";


import { 
  Container, 
  Button, 
  Col, 
  Card, 
  CardBody, 
  Form,
} from "reactstrap";

import "../custom.css";
import Row from "reactstrap/lib/Row";
import config from "config";





const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});
function OutFrCandidateOtherJobApps( props ) {

  const [allJobApplicationsForThisUser, setAllJobApplicationsForThisUser] = useState([]);

  
  
  const user = JSON.parse(localStorage.getItem("user"));



const fetchAllJobApplicationsForThisUser = async () => {
  // console.log("333333333333333333: fetchJobApplicationNotes: step 0: ");
  // console.log("333333333333333333: fetchJobApplicationNotes: step 1: ", candidatId);
  
  instance.get(`/jobApplication/getProfileTypesJobAppsByUserID/${props.jobApplicationUserID}`).then((resp) => {
    if (resp.data.length) {
      setAllJobApplicationsForThisUser(resp.data);
    }
    
    // console.log("333333333333333333: fetchJobApplicationNotes: step 2:");
    // console.log("333333333333333333: fetchJobApplicationNotes: step 3 : resp: ", resp);
    // console.log("333333333333333333: fetchJobApplicationNotes: step 4 : resp.data: ", resp.data);
    });
};







   useEffect(() => {
    fetchAllJobApplicationsForThisUser();
  
  }, []);







  return (
    <>
        <Container>
          <h4 className=" mb-4">Entretiens 222</h4>
          <Row item xs={12}>
            <label className="px-lg-3">Les entretiens passés par le candidat sont:</label>

            </Row>
            <Row>
            {allJobApplicationsForThisUser &&
              allJobApplicationsForThisUser.map((oneJobApplication) => (
                
                <JobApplicationTitleForOutFrContract
                  oneJobApplicationID={oneJobApplication._id}
                  jobID={oneJobApplication.jobID}
                  key={oneJobApplication._id}
                />                   

              ))}
              </Row>
              <Row>
                <hr className="my-4" />
              </Row>
              <CandidateAllInterviewsVideosSelectOneJobAPP
                  candidateUserID = {props.jobApplicationUserID}
              />
        </Container>
    </>
  );
}

export default OutFrCandidateOtherJobApps;
