import axios from "axios";
import React, { useEffect, useState } from "react";
import OneInterview from "components/recruteur/OneInterview";
import config from "config";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});
function Interviews() {
  const [candidats, setCandidats] = useState([]);

  const fetchData = () => {
    instance
      .get("jobs/candidats")
      .then((res) => {
        setCandidats(res.data);
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {!candidats && <h3>No candidats found!</h3>}
      {candidats && (
        <OneInterview candidats={candidats} fetchData={fetchData} />
      )}
    </>
  );
}

export default Interviews;
