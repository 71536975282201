import axios from "axios";
import React, { useEffect, useState } from "react";
import ShowCandidat from "components/recruteur/ShowCandidat";
//import OneInterview from "components/recruteur/OneInterview";
import { useHistory, useLocation, Link } from "react-router-dom";
import qs from "query-string";
//import ShowCandidat from "./ShowCandidat";
import {
  PencilAltIcon,
  LocationMarkerIcon,
  BriefcaseIcon,
  MailIcon,
  PhoneIcon,
  LinkIcon,
  CodeIcon,
  ArchiveIcon,
  OfficeBuildingIcon,
  PaperClipIcon,
  AcademicCapIcon,
  UserIcon,
  LightBulbIcon,
} from "@heroicons/react/solid";
import { SearchIcon } from "@heroicons/react/outline";
import {
  DropdownMenu,
  Media,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormText,
  FormFeedback,
  CardText,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Table,
} from "reactstrap";
//import {getAll} from "../../network/ApiAxios";
import config from "config";
import { set } from "react-hook-form";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});
function LandingPageTerminatedCandidatures(candidates) {
  const [candidats, setCandidats] = useState([]);
  
  const [users, setUsers] = useState([]);
  const [participants, setParticipants] = useState();
  const [job, setJob] = useState({});
  const history = useHistory();
  const location = useLocation();
  const query = qs.parse(location.search);
  const [search, setSearch] = useState("");
  const [candidat, setCandidat] = useState("");
  const [checkbox, setCheckbox] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalDelOpen, setModalDelOpen] = useState(false);
  const [candidatID, setCandidatID] = useState("");
  const [participantId, setParticipantId] = useState("");
  const [candidatModal, setCandidatModal] = useState();
  const [check, setCheck] = useState(1);
  const removeCandidat = () => {
    instance
      .delete(`jobs/candidat/${candidatID}/${participantId}`)
      .then((res) => {
        getJob(participants._id);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getJob = (id) => {
    instance.get(`jobs/${id}`).then((resp) => {
      setParticipants(resp.data.job);
    });
  };

  useEffect(() => {
    if (candidates.participants._id) {
      getJob(candidates.participants._id);
    }
  }, [candidates]);

  return (
    <>
      <Card className="bg-transparent w-full">
        <Row>
          {participants?.participations
            ?.filter((participant) => {
             
              if (search === "") {
                return participant;
              } else if (
                participant.candidat.name
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                participant.candidat.lastName
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                participant.candidat.email
                  .toLowerCase()
                  .includes(search.toLowerCase())
              ) {
                return participant;
              }
            })
            .map((participant) =>
                <>
                {participant.completed &&
                  <Row
                    className="  mb-3 flex w-full hover:black hover:border hover:border-black cursor-pointer duration-75 hover:z-20 bg-white shadow-lg rounded-sm"
                    key={participant._id}
                  >
                    <Col lg="2">
                      <img
                        src={`${config.Data_URL}${participant.candidat.image}`}
                        className="rounded-circle"
                        onError={(e) => {
                          e.target.src =
                            require("assets/img/brand/500.png").default;
                        }}
                        style={{
                          width: "100%",
                          height: "100%",

                          //objectFit: "cover",
                        }}
                        //alt="Responsive image"
                      />
                    </Col>
                    <Col lg="10">
                      <Col lg="12" className="mb-2">
                        <div>
                          <span className="card-t">
                            {participant.candidat.name}{" "}
                            {participant.candidat.lastName}
                          </span>
                          <button
                            aria-label="Close"
                            className=" close"
                            type="button"
                            onClick={() => {
                              setCandidatID(
                                participant?.interviewCandidat?._id
                              );
                              setParticipantId(participant?._id);
                              setModalDelOpen(!modalDelOpen);
                            }}
                          >
                            <span aria-hidden={true}>×</span>
                          </button>
                        </div>
                      </Col>
                      <Row>
                        <Col lg="8">
                          <Col lg="12">
                            <div
                              className="text-sm"
                              style={{ display: "inline-flex" }}
                            >
                              {" "}
                              <MailIcon
                                className=""
                                style={{ height: "1.2rem" }}
                              />{" "}
                              &nbsp; <span>{participant.candidat.email}</span>{" "}
                            </div>
                          </Col>
                          <Col lg="12">
                            <div
                              className="text-sm"
                              style={{ display: "inline-flex" }}
                            >
                              {" "}
                              <AcademicCapIcon
                                className=""
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  display: "-webkit-box",
                                  WebkitLineClamp:
                                    "1" /* number of lines to show */,
                                  WebkitBoxOrient: "vertical",
                                  height: "1.2rem",
                                }}
                              />{" "}
                              &nbsp;{" "}
                              <span>
                                Diplome: {participant.candidat.studyLevel}
                              </span>{" "}
                            </div>
                          </Col>

                          <Col lg="12">
                            <div
                              className="text-sm"
                              style={{ display: "inline-flex" }}
                            >
                              {" "}
                              <LightBulbIcon
                                className=""
                                style={{ height: "1.2rem" }}
                              />{" "}
                              &nbsp;{" "}
                              <span>
                                {" "}
                                Expérience:{" "}
                                {participant.candidat.yearsOfExperience}
                              </span>{" "}
                            </div>
                          </Col>
                          <Col lg="12">
                            <div
                              className="text-sm"
                              style={{ display: "inline-flex" }}
                            >
                              {" "}
                              <LocationMarkerIcon
                                className=""
                                style={{ height: "1.2rem" }}
                              />{" "}
                              &nbsp; <span> aaaaaa</span>{" "}
                            </div>
                          </Col>

                          {/* <div>
                            Status:{" "}
                            <span
                              className={`${
                                job.status === "inactive"
                                  ? "text-red-600"
                                  : "text-green-600"
                              }`}
                            >
                              {job.status}
                            </span>
                          </div>
                          <div>
                            Updated on:{" "}
                            {`${job.updatedAt.slice(
                              0,
                              10
                            )} ${job.updatedAt.slice(11, 19)}`}
                          </div> */}
                        </Col>
                        <Col lg="4" className=" flex items-center">
                          <div>
                            <Button
                              color="primary"
                              size="sm"
                              onClick={() => {
                                setModalOpen(!modalOpen);
                                setCandidatModal(participant);
                                history.push(
                                  `/lp/?jobId=${participants._id}&candidat=${participant?.interviewCandidat?._id}`
                                  //`/admin/tab2?jobId=${participants._id}&candidat=${participant?.interviewCandidat?._id}`
                                );
                              }}
                              // href={`http://localhost:5100/${participant.CV}`}
                              //   target="blank"
                            >
                              Voir la Candidature
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>}
                </>
            )}{" "}
        </Row>

        {query.candidat && (
          <Modal
            toggle={() => setModalOpen(!modalOpen)}
            isOpen={modalOpen}
            size="lg"
            style={{ maxWidth: "1100px", width: "80%" }}
          >
            <div className=" modal-header">
              <h4 className=" modal-title" id="exampleModalLabel">
                {participants?.title}
              </h4>
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => setModalOpen(!modalOpen)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
              <ShowCandidat
                candidatId={query.candidat}
                candidatModal={candidatModal}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                type="button"
                onClick={() => setModalOpen(!modalOpen)}
                size="sm"
              >
                Fermer
              </Button>
            </ModalFooter>
          </Modal>
        )}
        <Modal
          toggle={() => setModalDelOpen(!modalDelOpen)}
          isOpen={modalDelOpen}
        >
          {candidatID && (
            <div className=" modal-header">
              <h4 className=" modal-title" id="exampleModalLabel">
                Êtes-vous sûr de vouloir supprimer cette interview ?
              </h4>
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => setModalDelOpen(!modalDelOpen)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
          )}
          {!candidatID && (
            <div className=" modal-header">
              <h4 className=" modal-title" id="exampleModalLabel">
                Ce candidat n'a pas encore passé l'entretien!
              </h4>
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => setModalDelOpen(!modalDelOpen)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
          )}

          <ModalFooter>
            <Button
              color="secondary"
              type="button"
              size="sm"
              onClick={() => setModalDelOpen(!modalDelOpen)}
            >
              ANNULER
            </Button>
            {candidatID && (
              <Button
                color="danger"
                type="button"
                size="sm"
                onClick={() => {
                  removeCandidat();
                  setModalDelOpen(!modalDelOpen);
                }}
              >
                SUPPRIMER
              </Button>
            )}
          </ModalFooter>
        </Modal>
      </Card>
    </>
  );
}

export default LandingPageTerminatedCandidatures;
