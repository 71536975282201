
import { options_jobApplicationPipeline } from "options";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  Container,
  Button,
  Col,
} from "reactstrap";
import Select from "react-select";

import "../custom.css";
import Row from "reactstrap/lib/Row";
import config from "config";
import { useTranslation } from "react-i18next";





const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});
function JobAppPipeline(props) {
  const history = useHistory();
  const { t, i18n } = useTranslation();



  const [applicationPipeline, setApplicationPipeline] = useState("");
  const [oldApplicationPipeline, setOldApplicationPipeline] = useState("");
  const [newApplicationPipeline, setNewApplicationPipeline] = useState("");

  const [jobApplicationUserID, setJobApplicationUserID] = useState("");
  const [jobID, setJobID] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));






  const updatePipeline = async (pipeline) => {
    // var pipeline = "Rejeté";
    const response = await instance.put(`jobApplication/updatePipeline/${props.jobAppID}`, { pipeline, });
    // console.log("ccccccccccccccccccccccccc : response: ", response);
    if (response.data.success) {
      // console.log("aaaaaaaaaaaaaaaaaaaaaaaa: success");
      setApplicationPipeline(pipeline);
      if (response.data.success) {
        let candidateUserID = jobApplicationUserID;
        let recruiterUserID = user._id;
        let actionType = "Update job Application Pipeline";
        let oldPipeline = oldApplicationPipeline;
        let newPipeline = newApplicationPipeline;


        const response_jobApp_action = await instance.post(`userManagementActions/createAction/`,
          {
            candidateUserID,
            recruiterUserID,
            actionType,
            oldPipeline,
            newPipeline,
          });
      }
    } else {
      // console.log("bbbbbbbbbbbbbbbbbbbbbbbb: failure");
    }
  };




  const fetchJobApplication = async () => {
    instance
      .get(`jobs/candidat/${props.jobAppID}`)
      .then((res) => {
        // console.log("ddddddddddddddddddddddddddddddddddd:");
        // console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee: res: ", res);
        // setCandidat(res.data);
        setJobApplicationUserID(res.data.userID);
        setJobID(res.data.jobID);

        // console.log("fffffffffffffffffffffffffff : res.data: ", res.data);
        setApplicationPipeline(res.data.pipeline);
        setOldApplicationPipeline(res.data.pipeline);

      })
      .catch((err) => {
        console.log(err);
      });
  };




  useEffect(() => {
    fetchJobApplication();

  }, []);



  return (
    <>


      <Row className='mt-2 mb-2'>
        <h4 className="px-lg-3">Pipeline</h4>
      </Row>
      <Row className='mb-5'>

        <Col sm='7'>
          <Select
            options={options_jobApplicationPipeline}
            // value={applicationPipeline} 

            value={
              (options_jobApplicationPipeline).filter(option =>
                option.label === applicationPipeline)
            }


            onChange={(e) => {
              setApplicationPipeline(e.value);
              setNewApplicationPipeline(e.value);
            }} />
        </Col>
        <Col sm='5'>
          <Button
            /* style={{
           
              width: "100%",
            }} */
            onClick={() => {

              updatePipeline(applicationPipeline);
            }}
            //className="mt-4"
            color="primary"
            type="button"
          >
            Sauvegarder
          </Button>
        </Col>
      </Row>

    </>
  );
}

export default JobAppPipeline;
