import {
  ViewListIcon,
  ArrowCircleDownIcon,
  PencilAltIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/outline";
import JobApplicationNote from "components/employer/jobApplicationNote";
import SendEmailInvitForOneJobApp from "components/employer/sendEmailInvitForOneJobApp";
import SendEmailForOneJobApp from "components/employer/sendEmailForOneJobApp";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { 
  Container, 
  Button, 
  Col, 
  Card, 
  CardBody, 
  Input,
} from "reactstrap";
import Select from "react-select";
import { Grid, TextField } from "@mui/material";

import "../custom.css";
import Row from "reactstrap/lib/Row";
import config from "config";
import { useTranslation } from "react-i18next";


const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});
function JobAppNotes(props) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  // console.log("11111111111111111111111111111: ShowApplicationEmployerV4");
  // console.log("11111111111111111111111111111: ShowApplicationEmployerV4: candidatId: ", candidatId);
  
  const [candidat, setCandidat] = useState("");
  const [hasDrive, setHasdrive] = useState(false);

  const [jobApplicationUserID, setJobApplicationUserID] = useState("");
  const [jobApplicationNotes, setJobApplicationNotes] = useState(null);
  const [otherJobApplicationNotes, setOtherJobApplicationNotes] = useState([]);
  const [addThisNote, setAddThisNote] = useState("");
  const [newAddedNote, setNewAddedNote] = useState(0);
  const [newAddedNoteText, setNewAddedNoteText] = useState("");
  const [allJobApplicationsForThisUser, setAllJobApplicationsForThisUser] = useState([]);
  const [jobID, setJobID] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [job, setJob] = useState({});

  
  
  const user = JSON.parse(localStorage.getItem("user"));


const fetchJobApplication = async () => {
  instance
  .get(`jobs/candidat/${props.candidatId}`)
  .then((res) => {
    // console.log("ddddddddddddddddddddddddddddddddddd:");
    // console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee: res: ", res);
    setCandidat(res.data);
    setJobApplicationUserID(res.data.userID);
    setJobID(res.data.jobID);

    // console.log("fffffffffffffffffffffffffff : res.data: ", res.data);


  })
  .catch((err) => {
    // console.log(err);
  });
};

const fetchJobApplicationNotes = async () => {
  // console.log("333333333333333333: fetchJobApplicationNotes: step 0: ");
  // console.log("333333333333333333: fetchJobApplicationNotes: step 1: ", candidatId);
  
  instance.get(`/jobApplicationNotes/getNotesByJobApplicationID/${props.candidatId}`).then((resp) => {
    if (resp.data.length) {
      setJobApplicationNotes(resp.data);
    }
    
    // console.log("333333333333333333: fetchJobApplicationNotes: step 2:");
    // console.log("333333333333333333: fetchJobApplicationNotes: step 3 : resp: ", resp);
    // console.log("333333333333333333: fetchJobApplicationNotes: step 4 : resp.data: ", resp.data);
    });
};
const fetchOtherNotesForThisCandidate = async () => {
    console.log("333333333333333333: jobAppNotes v5: step 0: ");
  
  instance.get(`/jobApplicationNotes/getNotesByCandidateID/${jobApplicationUserID}`).then((resp) => {
    if ( resp.data && resp.data.length && resp.data.length !== null && resp.data.length !== 0  && resp.data[0]._id && resp.data[0]._id !== null) {
      // console.log("333333333333333333: jobAppNotes v5: step 1: resp.data.length:", resp.data.length);
      setOtherJobApplicationNotes(resp.data);
    }
    
    // console.log("333333333333333333: jobAppNotes: step 2:");
    // console.log("333333333333333333: jobAppNotes: step 3 : resp: ", resp);
    // console.log("333333333333333333: jobAppNotes: step 4 : resp.data: ", resp.data);
    });
};

const fetchAllJobApplicationsForThisUser = async () => {
  // console.log("333333333333333333: fetchJobApplicationNotes: step 0: ");
  // console.log("333333333333333333: fetchJobApplicationNotes: step 1: ", candidatId);
  
  instance.get(`/jobApplication/getJobApplicationsByUserID/${jobApplicationUserID}`).then((resp) => {
    if (resp.data.length) {
      setAllJobApplicationsForThisUser(resp.data);
    }
    
    // console.log("333333333333333333: fetchJobApplicationNotes: step 2:");
    // console.log("333333333333333333: fetchJobApplicationNotes: step 3 : resp: ", resp);
    // console.log("333333333333333333: fetchJobApplicationNotes: step 4 : resp.data: ", resp.data);
    });
};

const createJobApplicationNote = async () => {
  let jobApplicationID = props.candidatId;
  let note = addThisNote;
  let candidateID = jobApplicationUserID;
  let recruiterID = user._id;



  // console.log("444444444444444444: createJobApplicationNote: step 0: ");
  // console.log("444444444444444444: createJobApplicationNote: step 1: ", candidatId);
  // console.log("444444444444444444: createJobApplicationNote: step 5 addThisNote: ", addThisNote);
  // console.log("444444444444444444: createJobApplicationNote: step 6 formData: ", formData);
  // console.log("444444444444444444: createJobApplicationNote: step 7 inputs: ", inputs);
  
  instance.post("/jobApplicationNotes/createNote", 
  {
    jobApplicationID,
    candidateID,
    jobID,
    recruiterID,
    note,
  }
  ).then((resp) => {
    // console.log("444444444444444444: createJobApplicationNote: step 2:");
    // console.log("444444444444444444: createJobApplicationNote: step 3 : resp: ", resp);
    // console.log("444444444444444444: createJobApplicationNote: step 4 : resp.data: ", resp.data);
    });
};

const fetchJobData = () => {
  if (jobID !=="") {
    instance.get(`jobs/${jobID}`).then((resp) => {
      if (resp.data !== null) {
        if ((resp.data.job !== null)) {
          if ((resp.data.job.title !== null)) {
          setJob(resp.data.job);
          setJobTitle(resp.data.job.title);
          // console.log("5555555555555555555555555555555555: fetchJobData: step 2:");
          // console.log("5555555555555555555555555555555555: fetchJobData: step 3 : resp: ", resp);
          // console.log("5555555555555555555555555555555555: fetchJobData: step 4 : resp.data: ", resp.data);
          // console.log("5555555555555555555555555555555555: fetchJobData: step 4 : resp.data.job.title: ", resp.data.job.title);
          }
        }
      }
    });

  }
};
   useEffect(() => {
    fetchJobApplication();
    fetchJobApplicationNotes();
    fetchAllJobApplicationsForThisUser();
    fetchJobData();
    fetchOtherNotesForThisCandidate();
  
  }, [props.candidatId, jobID, jobApplicationUserID, newAddedNote]);



  return (
    <>

          <Row className="pl-lg-4">
            <Col>
              <h4>{t('SendEmailInvit')}:</h4>
              {jobID &&
              <SendEmailInvitForOneJobApp
                jobAppID = {props.candidatId}
                jobID = {jobID}
                jobApplicationUserID = {jobApplicationUserID}
              />
            }
            </Col>
        </Row>
        <Row className="pl-lg-4">
            <Col>
              {jobID &&
              <SendEmailForOneJobApp
                jobAppID = {props.candidatId}
                jobID = {jobID}
                jobApplicationUserID = {jobApplicationUserID}
              />
            }
            </Col>
        </Row>
        
        <Row>
          <Col className="order-xl-1">
            <Card className="bg-secondary shadow">
              <CardBody>
                <form>
                  <Grid item xs={12}>
                    <label className="px-lg-3">{t('NoteKEY1')}</label>
                    <Input
                        name="addThisNote"
                        // label="Nom" 
                        // placeholder="Email" 
                        className="w-full " 
                        type="textarea"
                        rows="5"
                        // onBlur={handleBlur} 
                        // value={email}
                        // onChange={handleChange} 
                        onChange={e => setAddThisNote(e.target.value)}
                        value={addThisNote || ""} 
                        // error={!!touched.titre && !!errors.titre} 
                        // helperText={touched.titre && errors.titre} 
                    >
                    </Input>
                    </Grid>
                  <Button 
                    className="mt-2"
                    variant="contained" 
                    color="primary" 
                    // type="submit" 
                    // sx={{ mt: "25px" }}
                    onClick={() => { 
                      // console.log("bouton cliqué 0000000000000000000000 ")
        
                      createJobApplicationNote();
                      setNewAddedNote(newAddedNote + 1 );
                      setAddThisNote("");
                    }}
                    >
                      {t('save')}
                  </Button>

                </form>
                <h4 className="mt-4 mb-4">{t('NoteKEY2')}:</h4>

                {jobApplicationNotes &&
                      jobApplicationNotes
                      .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
                      .map((jobApplicationNote) => (
                        <Grid item xs={12} key={jobApplicationNote._id}>
                          {!jobApplicationNote.candidateID &&
                              <label 
                              className="px-lg-3"
                              
                            >{t('Remarks')} v1: {jobApplicationNote.note}</label>

                          }

                </Grid>

                      ))}


                {otherJobApplicationNotes && otherJobApplicationNotes.length !== 0 && (
                <>
                    {otherJobApplicationNotes
                    .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
                    .map((otherJobApplicationNotesItem) => (
          
                      (otherJobApplicationNotesItem.jobApplicationID === props.candidatId) && (

                        <JobApplicationNote
                          jobApplicationNoteID = {otherJobApplicationNotesItem._id}
                          actualJobApplicationID = {props.candidatId}
                          key={otherJobApplicationNotesItem._id}
                        />
                      )
 
                    ))}
                </>
                )}


                <h4 className="mt-4 mb-4">{t('NoteKEY4')}:</h4>

                {otherJobApplicationNotes && otherJobApplicationNotes.length !== 0 && (
                <>
                    {otherJobApplicationNotes
                    .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
                    .map((otherJobApplicationNotesItem) => (
          
                      (otherJobApplicationNotesItem.jobApplicationID !== props.candidatId) && (

                        <JobApplicationNote
                          jobApplicationNoteID = {otherJobApplicationNotesItem._id}
                          actualJobApplicationID = {props.candidatId}
                          key={otherJobApplicationNotesItem._id}
                        />
                      )
 
                    ))}
                </>
                )}

              </CardBody>
            </Card>
          </Col>
        </Row>
     
    </>
  );
}

export default JobAppNotes;
