import React, { useState, useEffect } from "react";
import { ArrowDownIcon, BriefcaseIcon } from "@heroicons/react/outline";
import { useLocation, useHistory } from "react-router-dom";
import qs from "query-string";
import {
  Button,
  Form,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,
  Card,
  FormText,
  FormFeedback,
} from "reactstrap";
import axios from "axios";
import Select from "react-select";
import config from "config";
import Header from "components/Headers/Header.js";

import { options_job_visibility, options_gender, options_job_contract, options_Pays, options_france_departments } from 'options';
import EmployerLayout from "layouts/EmployerLayout";
import { Box } from "@mui/material";
import { getActiveContracTypes } from "network/ApiAxios";
import Datetime from 'react-datetime';
import { useTranslation } from "react-i18next";


// const https = require('https');
//
// const agent = new https.Agent({
//     rejectUnauthorized: false,
// });

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});

function CreateJobView() {
  const { t, i18n } = useTranslation();
  const locationQuery = useLocation();
  const history = useHistory();
  const query = qs.parse(locationQuery.search);
  const optionsDate = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
};


  const [title, setTitle] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDiscription] = useState("");
  const [studyLevel, setStudyLevel] = useState("");
  const [contract, setContract] = useState("");
  const [yearsOfExperience, setYearsOfExperience] = useState("");
  const [pay, setPay] = useState(null);
  const [gender, setGender] = useState("");
  const [language, setLanguage] = useState("");
  const [technologies, setTechnologies] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState("");

  const [visibility, setVisibility] = useState("");
  const [country, setCountry] = useState("");
  const [department, setDepartment] = useState("");




  const [active, setActive] = useState("");
  const [interview, setInterview] = useState("");
  const [errors, setErrors] = useState({
    title: "",
    location: "",
    description: "",
    status: "",
    interview: "",
    studyLevel: "",
    contract: "",
    yearsOfExperience: "",
    gender: "",
    technologies: "",
    startDate: "",
    endDate: "",
    pay: "",
    language: "",
  });
  const [image, setImage] = useState();
  const [userID, setUserID] = useState();
  const [activeContracTypes, setActiveContacTypes] = useState([])
  const [TJM, setTJM] = useState(0)

  const [error, setError] = useState();
  
  const getAllActiveContracTypes = async () => {
    try {
      const { data } = await getActiveContracTypes()
      data && setActiveContacTypes(data.map(el => el = { label: el.name, value: el._id }))
      //console.log(data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setUserID(JSON.parse(localStorage.getItem("user"))._id);
    if (JSON.parse(localStorage.getItem("user")).image) {
      setImage(JSON.parse(localStorage.getItem("user")).image);
    }
    getAllActiveContracTypes()
  }, []);


  const handleJobValidation = () => {
    let isValid = true;
    if (!active) {
      isValid = false;
      setError("Veuillez fournir un statut (Active ou Inactive) ");
    }

    if (!description) {
      isValid = false;
      setError("Veuillez fournir une Description pour l'offre ");
    }

    if (!technologies) {
      isValid = false;
      setError("impossible de procéder avec Tehnologies vide ");
    }

    if (!language) {
      isValid = false;
      setError("impossible de procéder avec Langue vide ");
    }


    if (!contract) {
      isValid = false;
      setError("impossible de procéder avec le Type de contrat vide ");
    }

    if (!location) {
      isValid = false;
      setError("impossible de procéder avec un Emplacement vide ");
    }

    if (!title) {
      isValid = false;
      setError("impossible de procéder avec un Titre de l'offre vide ");
    }

    return isValid;
  };
  const createJob = async () => {
    let newJob;
    /Freelance/i.test(contract) ? newJob = {
      title,
      location,
      description,
      visibility,
      status: active,
      studyLevel,
      contract,
      yearsOfExperience,
      department,
      country,
      gender,
      technologies,
      startDate,
      endDate,
      TJM,
      language,
      image,
      userID,
      entreprise: JSON.parse(localStorage.getItem("user")).company,
    } :
      newJob = {
        title,
        location,
        description,
        visibility,
        status: active,
        studyLevel,
        contract,
        yearsOfExperience,
        department,
        country,
        gender,
        technologies,
        startDate,
        endDate,
        pay,
        language,
        image,
        userID,
        entreprise: JSON.parse(localStorage.getItem("user")).company,
      }
    //console.log("newJob", newJob)
    if (handleJobValidation()) {
      const jobCreated = await instance.post("/jobs", newJob);
      console.log("jobCreated",jobCreated)
      await instance.post(`/jobs/${jobCreated.data.job._id}/interview`, {
        url: `${title}-${jobCreated.data.job._id.toString().slice(0, 4)}`,
      });
      history.push("/employer/jobs");
    }
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    if (title !== "" && description !== "" && location !== "") {
      history.push(`${locationQuery.pathname}?status=true`);
    }
  };

  const addInterview = (e) => {
    e.preventDefault();
    if (interview !== "") {
      instance.post(`jobs/${query.job}/interview`, {
        url: interview,
      });
      history.push("/admin/tab2");
    }
  };

  const resetForm = (e) => {
    e.preventDefault();
    setTitle("");
    setLocation("");
    setDiscription("");
  };

  return (
    <>
      <EmployerLayout />
      <Box
        sx={
          {
            ml: 32,
            p: "30px",
            mb: 10
          }
        }
        display="flex"
      >

        {Object.keys(query).length === 0 && (
          <Container fluid className="mt-4">
            <Card className="p-4 shadow-sm p-3 mb-5 bg-white rounded">
              <Form onSubmit={onSubmitForm}>
                <div className="form-group">
                  <Label>{t('OfferTitle')}*</Label>
                  <p>
                    {t('CreatedJobKey1')}
                  </p>
                  <Input
                    value={title}
                    onChange={(e) => {
                      if (!e.target.value) {
                        setErrors({
                          ...errors,
                          title: t('CreatedJobKey2'),
                        });
                      } else {
                        setErrors({ ...errors, title: "" });
                      }
                      setTitle(e.target.value);
                    }}
                    type="text"
                    className={`form-control ${errors.title ? "border-danger" : null
                      }`}
                    placeholder="Example: accountant"
                  />
                  {errors.title && <p className="text-red">{errors.title}</p>}
                </div>

                <div className="form-group">
                  <Label>{t('Country')}*</Label>
                  <Select
                    className={`w-full  ${errors.pays ? "border-danger" : null
                      }`}
                    options={options_Pays}
                    onChange={(e) => {

                      // console.log("eeee",e.value)
                      if (!e.value) {
                        setErrors({
                          ...errors,
                        });
                      } else {
                        setErrors({ ...errors, pays: "" });
                      }
                      setCountry(e.value);

                    }}
                  />
                  {errors.country && <p className="text-red">{errors.country}</p>}
                </div>
                {country && country === "France" &&
                  <div className="form-group">
                    <Label>{t('CreatedJobKey3')}*</Label>
                    <Select
                      className={`w-full  ${errors.pays ? "border-danger" : null
                        }`}
                      options={options_france_departments}
                      onChange={(e) => {

                        // console.log("eeee",e.value)
                        if (!e.value) {
                          setErrors({
                            ...errors,
                          });
                        } else {
                          setErrors({ ...errors, department: "" });
                        }
                        setDepartment(e.value);

                      }}
                    />
                    {errors.department && <p className="text-red">{errors.department}</p>}
                  </div>
                }




                <div className="form-group">
                  <Label>{t('Location')}*</Label>
                  <p>{t('LocationCity')}</p>
                  <Input
                    value={location}
                    onChange={(e) => {
                      if (!e.target.value) {
                        setErrors({
                          ...errors,
                          location: t('CreatedJobKey4'),
                        });
                      } else {
                        setErrors({ ...errors, location: "" });
                      }
                      setLocation(e.target.value);
                    }}
                    type="text"
                    className={`form-control ${errors.location ? "border-danger" : null
                      }`}
                    placeholder="City, State, Country"
                  />
                  {errors.location && (
                    <p className="text-red">{errors.location}</p>
                  )}
                </div>



                <div className="form-group">
                  <Label>{t('CreatedJobKey5')}*</Label>
                  <Select
                    className={`w-full  ${errors.contract ? "border-danger" : null
                      }`}
                    options={activeContracTypes}
                    onChange={(e) => {

                      setContract(e.label);

                    }}
                  />
                  {errors.contract && <p className="text-red">{errors.contract}</p>}
                </div>

                {contract && <div className="form-group">
                  <Label>{/Freelance/i.test(contract) ? "TJM (€)" : `${t('Pay')} (K €)`}</Label>
                  <Input
                    type="number"
                    onChange={(e) => contract && /Freelance/i.test(contract) ? setTJM(+e.target.value) : setPay(+e.target.value)}
                  />
                </div>}


                <div className="form-group">
                  <Label>{t('Visibility')}*</Label>
                  <Select
                    className={`w-full  ${errors.gender ? "border-danger" : null
                      }`}
                    options={options_job_visibility}
                    onChange={(e) => {

                      // console.log("eeee",e.value)
                      if (!e.value) {
                        setErrors({
                          ...errors,
                        });
                      } else {
                        setErrors({ ...errors, gender: "" });
                      }
                      setVisibility(e.value);

                    }}
                  />
                  {errors.visibilty && <p className="text-red">{errors.visibility}</p>}
                </div>
                <div className="form-group">
                  <Label>{t('Language')}*</Label>

                  <Input
                    value={language}
                    onChange={(e) => {
                      setLanguage(e.target.value);
                    }}
                    type="text"
                    className={`form-control ${errors.language ? "border-danger" : null
                      }`}
                    placeholder={t('CreatedJobKey6')}
                  />
                  {errors.language && (
                    <p className="text-red">{errors.language}</p>
                  )}
                </div>
                <div className="form-group">
                  <Label>Technologies*</Label>

                  <Input
                    value={technologies}
                    onChange={(e) => {
                      if (!e.target.value) {
                        setErrors({
                          ...errors,
                          technologies: t('CreatedJobKey7'),
                        });
                      } else {
                        setErrors({ ...errors, technologies: "" });
                      }
                      setTechnologies(e.target.value);
                    }}
                    type="text"
                    className={`form-control ${errors.technologies ? "border-danger" : null
                      }`}
                    placeholder="Example: Full stack MEAN"
                  />
                  {errors.technologies && (
                    <p className="text-red">{errors.technologies}</p>
                  )}
                </div>
                <div className="form-group">
                  <Label>Description*</Label>
                  <p>{t('CreatedJobKey8')} </p>
                  <textarea
                    value={description}
                    className={`form-control ${errors.description ? "border-danger" : null
                      }`}
                    rows="5"
                    onChange={(e) => {
                      if (!e.target.value) {
                        setErrors({
                          ...errors,
                          description: "¨Please provide a description",
                        });
                      } else {
                        setErrors({ ...errors, description: "" });
                      }
                      setDiscription(e.target.value);
                    }}
                  />
                  {errors.description && (
                    <p className="text-red">{errors.description}</p>
                  )}
                </div>
                {/* <div className="form-group">
              <Button type="submit" className="btn text-white float-right" style={{backgroundColor:"#455266"}}>
                Continue
              </Button>
              <Button onClick={resetForm} className="btn">
                Cancel
              </Button>
            </div> */}
                <div className="form-group">
                  <Label>{t('StudyLevel')}</Label>
                  <Input
                    value={studyLevel}
                    type='text'
                    onChange={(e) => setStudyLevel(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <Label>{t('Experience')}</Label>
                  <Input
                    value={yearsOfExperience}
                    type='number'
                    onChange={(e) => setYearsOfExperience(+e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <Label>{t('Gender')}</Label>
                  <Input
                    value={gender}
                    type='text'
                    onChange={(e) => setGender(e.target.value)}
                  />
                </div>
                <div className="row mb-5">
                  <Col sm='6'>
                    <Label>{t('CreatedJobKey9')}</Label>
                    <Datetime
                      value={new Date(startDate).toLocaleDateString('en-GB', optionsDate)}
                      onChange={(e)=> {
                        setStartDate(e._isValid ? e._d : e)
                      }}
                      closeOnSelect
                      dateFormat="DD-MM-YYYY"
                      timeFormat={false}
                    />
                  </Col>
                  <Col sm='6'>
                    <Label>{t('CreatedJobKey10')}</Label>
                  <Datetime
                      value={new Date(endDate).toLocaleDateString('en-GB', optionsDate)}
                      onChange={(e)=> setEndDate(e._isValid ? e._d : e)}
                      closeOnSelect
                      dateFormat="DD-MM-YYYY"
                      timeFormat={false}
                    />
                  </Col>

                </div>
              </Form>
              <div className="flex justify-center">
                <div className="flex flex-col justify-between ml-4 px-2 py-2 w-1/3 bg-white shadow-lg h-52">
                  <div className="flex items-center space-x-2">
                    <ArrowDownIcon className="h-8 text-green-500" />
                    <h3 className="text-green-500">Active</h3>
                  </div>
                  <p>
                    {t('CreatedJobKey11')}.
                  </p>
                  <div className="flex items-center">
                    <input
                      type="radio"
                      value="active"
                      name="status"
                      onChange={(e) => setActive(e.target.value)}
                    />
                    <label htmlFor="active" className="ml-2">
                      {t('CreatedJobKey12')}
                    </label>
                  </div>
                </div>
                <div className="flex flex-col justify-between ml-4 px-2 py-2 w-1/3 bg-white shadow-lg h-52">
                  <div className="flex items-center space-x-2">
                    <ArrowDownIcon className="h-8 text-red-500" />
                    <h3 className="text-red-500">Inactive</h3>
                  </div>
                  <p>
                    {t('CreatedJobKey13')}.
                  </p>
                  <div className="flex items-center">
                    <input
                      type="radio"
                      value="inactive"
                      name="status"
                      onChange={(e) => setActive(e.target.value)}
                    />
                    <label htmlFor="active" className="ml-2">
                      {t('CreatedJobKey14')}
                    </label>
                  </div>
                </div>
              </div>
              {errors.active && <p className="text-red">{errors.active}</p>}
              {error ? (
                <div className="text-muted font-italic">
                  <small>
                    error:{" "}
                    <span className="text-red font-weight-700">{error}</span>
                  </small>
                </div>
              ) : null}
              <div className="flex justify-center mt-4 space-x-2">
                <Button
                  onClick={createJob}
                  className="px-5 py-2  bg-green-400 text-white  hover:border-green-500 hover:border-[1px]"
                  color="primary"
                >
                  {t('CreatedJobKey15')}
                </Button>
              </div>
            </Card>
          </Container>
        )}
        {query.status && (
          <div>
            <h2 className=" bg-gray-400 text-xl text-white p-5">
              Create New Job
            </h2>
            <div className="flex justify-center">
              <div className="flex flex-col justify-between ml-4 px-2 py-2 w-1/3 bg-white shadow-lg h-52">
                <div className="flex items-center space-x-2">
                  <ArrowDownIcon className="h-8 text-green-500" />
                  <h3 className="text-green-500">Active</h3>
                </div>
                <p>
                  Set the job to active so you can start sending interview
                  invitations
                </p>
                <div className="flex items-center">
                  <input
                    type="radio"
                    value="active"
                    name="status"
                    onChange={(e) => setActive(e.target.value)}
                  />
                  <label htmlFor="active" className="ml-2">
                    Set to active
                  </label>
                </div>
              </div>
              <div className="flex flex-col justify-between ml-4 px-2 py-2 w-1/3 bg-white shadow-lg h-52">
                <div className="flex items-center space-x-2">
                  <ArrowDownIcon className="h-8 text-red-500" />
                  <h3 className="text-red-500">Inactive</h3>
                </div>
                <p>Save the job as inactive if you want to keep it as a draft</p>
                <div className="flex items-center">
                  <input
                    type="radio"
                    value="inactive"
                    name="status"
                    onChange={(e) => setActive(e.target.value)}
                  />
                  <label htmlFor="active" className="ml-2">
                    Set to inactive
                  </label>
                </div>
              </div>
            </div>
            {errors.active && <p className="text-red">{errors.active}</p>}
            <div className="flex justify-center mt-4 space-x-2">
              <button
                onClick={createJob}
                className="px-5 py-2 bg-green-400 text-white  hover:border-green-500 hover:border-[1px]
            "
              >
                Create job
              </button>
            </div>
          </div>
        )}
      </Box>
    </>
  );
}

export default CreateJobView;
