import React, { useEffect, useState } from "react";
import axios from "axios";
import { optionsThink, optionsTimes, options_include_in_score_caldulation } from "options";
import {
  ClockIcon,
  VideoCameraIcon,
  PlusCircleIcon,
  DuplicateIcon,
  ChatAltIcon,
  PencilAltIcon,
} from "@heroicons/react/solid";
import { ClipLoader } from "react-spinners";

// reactstrap components
import {
  Container,
  Label,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  CardImg,
} from "reactstrap";
import config from "config";
import Select from "react-select";
import { options_videosPermission } from "options";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});
function EditJobQuestion(props) {
  const [loading, setLoading] = useState(true);
  const [question, setQuestion] = useState(null);
  const [newQuestionStatement, setNewQuestionStatement] = useState("");
  const [originQuestionStatementImage, setOriginQuestionStatementImage] = useState("");
  const [newQuestionStatementImage, setNewQuestionStatementImage] = useState("");

  const [newIncludeToScoreCalculation, setNewIncludeToScoreCalculation] = useState("");
  const [timeLimit, setTimeLimit] = useState({ value: "1", label: "1" });
  const [isTime, setIsTime] = useState(false);
  const [thinkTime, setThinkTime] = useState({ value: "1", label: "1" });
  const [isThink, setIsThink] = useState(false);
  const [isNewQuestion, setIsNewQuestion] = useState(false);
  const [newQuestion, setNewQuestion] = useState("");
  const [newQuestionType, setNewQuestionType] = useState("");
  const [selectQuestionType, setSelectQuestionType] = useState(null);
  const [newQuestionQCMDisplay, setNewQuestionQCMDisplay] = useState(false);
  const [newQuestionQCMOption1, setNewQuestionQCMDOption1] = useState("");
  const [newQuestionQCMOption2, setNewQuestionQCMDOption2] = useState("");
  const [newQuestionQCMOption3, setNewQuestionQCMDOption3] = useState("");
  const [newQuestionQCMOption4, setNewQuestionQCMDOption4] = useState("");
  const [newQuestionQCMOption5, setNewQuestionQCMDOption5] = useState("");
  const [newQuestionQCMOption6, setNewQuestionQCMDOption6] = useState("");
  const [newQuestionQCMCorrectAnswer, setNewQuestionQCMCorrectAnswer] = useState("");
  //const [isFollowUpQuestion, setIsFollowUpQuestion] = useState(null);




  const getQuestionData = () => {
    // console.log("WWWWWWWWWWWWWWWWWW: editQuestion: getQuestionData: ");
    instance
      .get(`questions/getQuestionById/${props.questionID}`)
      .then((res) => {
        // console.log("WWWWWWWWWWWWWWWWWW: editQuestion: getQuestionData: res", res);
        setQuestion(res.data);
        setNewQuestionType(res.data.type);
        setNewQuestionStatement(res.data.question);
        if (res.data.questionStatementImage) {
          setOriginQuestionStatementImage(res.data.questionStatementImage);
        }
        setTimeLimit({
          value: res.data.timeLimit,
          label: res.data.timeLimit,
        });
        setThinkTime({
          value: res.data.thinkTime,
          label: res.data.thinkTime,
        });



        if ((res.data.type === "QCM with only one corerct answer") || (res.data.type === "QCM with only one correct answer")) {
          // console.log("555555555555555555: editQuestion: getQuestionData: ");
          // console.log("555555555555555555: editQuestion: getQuestionData: res.data.type.QCMOption1: ", res.data.type.QCMOption1);
          setNewQuestionQCMDisplay(true);
          setNewQuestionQCMDOption1(res.data.QCMOption1);
          setNewQuestionQCMDOption2(res.data.QCMOption2);
          setNewQuestionQCMDOption3(res.data.QCMOption3);
          setNewQuestionQCMDOption4(res.data.QCMOption4);
          setNewQuestionQCMDOption5(res.data.QCMOption5);
          setNewQuestionQCMDOption6(res.data.QCMOption6);
          setNewQuestionQCMCorrectAnswer(res.data.QCMCorrectOption);
          setNewIncludeToScoreCalculation(res.data.included_in_the_score_calculation);
        }
        setLoading(false);
      }
      )
      .catch((err) => console.log(err));
  };

  const updateQuestion = (qs) => {

    if (question && newQuestionType === "Video") {

      const formDataVideo = new FormData();
      formDataVideo.append("timeLimit", timeLimit.value);
      formDataVideo.append("thinkTime", thinkTime.value);

      formDataVideo.append("type", newQuestionType);
      formDataVideo.append("question", newQuestionStatement);
      if (!newQuestionStatementImage) {
        formDataVideo.append("originQuestionStatementImage", originQuestionStatementImage);

      }
      else {
        formDataVideo.append("questionStatementImage", newQuestionStatementImage);
      }

      /* if(isFollowUpQuestion === true || isFollowUpQuestion === false ){
        formDataVideo.append("isFollowUpQuestion", isFollowUpQuestion);
      } */

      if (props.appendFromDB) {
        axios({
          method: "post",
          url: `${config.WS_BASE_URL}jobs/createQuestionV2/${props.jobID}/question`,
          data: formDataVideo,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then((res) => {

            //window.location.reload();
            //setWaitingForAddQuestionRequest(false);
            props.fct()
            props.toggle()
            // res.statusText.toLowerCase() === "ok" && fetchData();
            setQuestion("");
          })
          .catch((err) => console.log(err));
      }
      else {
        axios({
          method: "post",
          url: `${config.WS_BASE_URL}jobs/updateQuestionV2/${props.questionID}/${props.jobID}`,
          data: formDataVideo,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then((res) => {
            window.location.reload();
            // res.statusText.toLowerCase() === "ok" && fetchData();
            // setQuestion("");
          })
          .catch((err) => console.log(err));

      }

    }
    if (question && newQuestionType === "Short Answer") {
      const formDataShortAnswer = new FormData();

      axios({
        method: "post",
        url: `${config.WS_BASE_URL}jobs/updateQuestionV2/${props.questionID}/${props.jobID}`,
        data: formDataShortAnswer,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          window.location.reload();
          // res.statusText.toLowerCase() === "ok" && fetchData();
          // setQuestion("");
        })
        .catch((err) => console.log(err));
    }
    if (question && (newQuestionType === "QCM with only one corerct answer") || (newQuestionType === "QCM with only one correct answer")) {
      console.log("iiiiiiiiiiiiiiiiiiiiiiiiiii: QuestionsV2: addQuestion avec options: ");

      const formData = new FormData();
      formData.append("timeLimit", timeLimit.value);
      formData.append("thinkTime", thinkTime.value);

      formData.append("type", newQuestionType);
      formData.append("question", newQuestionStatement);
      formData.append("included_in_the_score_calculation", newIncludeToScoreCalculation);
      if (!newQuestionStatementImage) {
        formData.append("originQuestionStatementImage", originQuestionStatementImage);

      }
      else {
        formData.append("questionStatementImage", newQuestionStatementImage);
      }

      formData.append("QCMOption1", newQuestionQCMOption1);
      formData.append("QCMOption2", newQuestionQCMOption2);
      formData.append("QCMOption3", newQuestionQCMOption3);
      formData.append("QCMOption4", newQuestionQCMOption4);
      formData.append("QCMOption5", newQuestionQCMOption5);
      formData.append("QCMOption6", newQuestionQCMOption6);
      formData.append("QCMCorrectOption", newQuestionQCMCorrectAnswer);

      if (props.appendFromDB) {
        axios({
          method: "post",
          url: `${config.WS_BASE_URL}jobs/createQuestionV2/${props.jobID}/question`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then((res) => {

            //window.location.reload();
            //setWaitingForAddQuestionRequest(false);
            props.fct()
            props.toggle()
            // res.statusText.toLowerCase() === "ok" && fetchData();
            setQuestion("");
          })
          .catch((err) => console.log(err));
      }
      else {
        axios({
          method: "post",
          url: `${config.WS_BASE_URL}jobs/updateQuestionV2/${props.questionID}/${props.jobID}`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then((res) => {
            window.location.reload();
            // res.statusText.toLowerCase() === "ok" && fetchData();
            // setQuestion("");
          })
          .catch((err) => console.log(err));

      }
    }
  };



  useEffect(() => {
    getQuestionData();

  }, []);


  return (
    <>
      <Container>
        {loading && (
          <div className="absolute flex justify-center items-center top-0 left-0 z-50 bg-gray-800 w-full h-full opacity-80">
            <ClipLoader color="#f00" />
          </div>
        )}
        {!loading && (
          <>
            <Row>
              <Col lg="6">
                <Input
                  // type="text"
                  type="textarea"
                  rows="5"
                  className="border-[.8px] px-2 border-gray-300 flex-grow py-[1.7px] text-sm h-6"
                  onChange={(e) => setNewQuestionStatement(e.target.value)}
                  placeholder="Mettre l'énoncé de la question ici"
                  value={newQuestionStatement}
                />
              </Col>
              <Col lg="4">
                <Select
                  className="h-6 mb-1"
                  options={config.question_types}
                  placeholder="Sélectionner le type de la question"
                  // value = {selectQuestionType || ''}


                  value={
                    (config.question_types).filter(option =>
                      option.value === newQuestionType)
                  }



                  onChange={(e) => {
                    setNewQuestionType(e.value)
                    setSelectQuestionType(e.value)
                    if ((e.value === "QCM with only one corerct answer") || (e.value === "QCM with only one correct answer")) {
                      setNewQuestionQCMDisplay(true)
                    }
                    if ((e.value !== "QCM with only one corerct answer") && (e.value !== "QCM with only one correct answer")) {
                      setNewQuestionQCMDisplay(false)
                    }
                    console.log("iiiiiiiiiiiiiiiiiiiiiiiiiii: QuestionsV2: Select newQuestionType: ", newQuestionType);
                    console.log("iiiiiiiiiiiiiiiiiiiiiiiiiii: QuestionsV2: Select e.value: ", e.value);

                  }}
                />

                {/*question && newQuestionType === "Video" && <>
                  <Label className='mt-4'>Modifier l'option question de suivi</Label>
                  <Select
                    options={options_videosPermission}
                    placeholder={options_videosPermission.find(option =>
                      option.value === question?.isFollowUpQuestion)?.label}
                    onChange={opt=> setIsFollowUpQuestion(opt.value) }
                  />
                </>*/}
              </Col>
            </Row>
            <Row>
              <Col lg="10">
                {question.LiveQuestion &&
                  <>

                    <h4 className="font-semibold text-lg text-black mt-4">Vidéo/Audio de recruteur actuel:</h4>

                    {question.LiveQuestion && !question.LiveQuestion.includes("mp3") && (
                      <>

                        <video
                          src={`${config.Video_URL}${question.LiveQuestion}`}
                          controls
                          className="px-4 py-4"
                          controlsList="nodownload"
                        />
                      </>

                    )}

                    {question.LiveQuestion && question.LiveQuestion.includes("mp3") && (
                      <>
                        <audio
                          src={`${config.Video_URL}${question.LiveQuestion}`}
                          controls
                          controlsList="nodownload"
                        />
                      </>
                    )}


                  </>
                }
                <h4 className="font-semibold text-lg text-black mt-4">Image pour l'énoncé de la question:</h4>
                <Label>Changer d'image:</Label>
                <Input
                  className="form-control-alternative"
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  onChange={(e) => {
                    setNewQuestionStatementImage(e.target.files[0])
                    // console.log("iiiiiiiiiiiiiiiiiiiiiiiiiii: QuestionsV2: e.target.files[0]: ", e.target.files[0])
                  }
                  }
                />

                {question.questionStatementImage &&
                  <>

                    <Label className='mt-4'>Image actuelle:</Label>

                    <CardImg
                      src={`${config.Data_URL}${question.questionStatementImage}`}
                      onError={(e) => {
                        e.target.src =
                          require("assets/img/brand/500.png").default;
                      }}
                    />


                  </>
                }

                <h4 className="font-semibold text-lg text-black mt-4">Paramètres:</h4>
                <div className="flex space-x-2 items-center pb-2 border-b border-gray-500">
                  <ClockIcon className="h-6" />
                  <h4>Durée</h4>
                </div>
                <div className="flex space-x-2 items-center pb-2 border-b border-gray-500">
                  <div style={{ width: "100px" }}>
                    <Select
                      options={optionsTimes}
                      defaultValue={{
                        label: question.timeLimit,
                        value: question.timeLimit,
                      }}
                      onChange={(e) => {
                        setTimeLimit(e);
                        setIsTime(true);
                        if (!isThink) {
                          setThinkTime({
                            value: question.thinkTime,
                            label: question.thinkTime,
                          });
                        }
                        if (!isNewQuestion) {
                          setNewQuestion(question.question);
                        }
                      }}
                    />
                  </div>
                  <h5>minutes</h5>
                </div>
                <div className="flex space-x-2 items-center pb-2 border-b border-gray-500">
                  <ChatAltIcon className="h-6" />
                  <h4>Temps de réflexion</h4>
                </div>
                <div className="flex space-x-2 items-center pb-2 border-b border-gray-500">
                  <div style={{ width: "100px" }}>
                    <Select
                      options={optionsThink}
                      defaultValue={{
                        label: question.thinkTime,
                        value: question.thinkTime,
                      }}
                      onChange={(e) => {
                        setThinkTime(e);
                        setIsThink(true);
                        if (!isTime) {
                          setTimeLimit({
                            value: question.timeLimit,
                            label: question.timeLimit,
                          });
                        }

                        if (!isNewQuestion) {
                          setNewQuestion(question.question);
                        }
                      }}
                    />
                  </div>
                  <h5>minutes</h5>
                </div>


                {newQuestionQCMDisplay && (
                  <>
                    <h3 className="font-semibold text-lg text-black">Options</h3>


                    <Input
                      placeholder="Option 1"
                      type="text"
                      className="border-[.8px] px-2 border-gray-300 flex-grow py-[1.7px] text-sm h-6"
                      value={newQuestionQCMOption1}
                      onChange={(e) => setNewQuestionQCMDOption1(e.target.value)}


                    >

                    </Input>
                    <Input
                      type="text"
                      className="border-[.8px] px-2 border-gray-300 flex-grow py-[1.7px] text-sm h-6"
                      onChange={(e) => setNewQuestionQCMDOption2(e.target.value)}
                      value={newQuestionQCMOption2}
                      placeholder="Option 2"
                    >

                    </Input>
                    <Input
                      type="text"
                      className="border-[.8px] px-2 border-gray-300 flex-grow py-[1.7px] text-sm h-6"
                      onChange={(e) => setNewQuestionQCMDOption3(e.target.value)}
                      value={newQuestionQCMOption3}
                      placeholder="Option 3"
                    >

                    </Input>
                    <Input
                      type="text"
                      className="border-[.8px] px-2 border-gray-300 flex-grow py-[1.7px] text-sm h-6"
                      onChange={(e) => setNewQuestionQCMDOption4(e.target.value)}
                      value={newQuestionQCMOption4}
                      placeholder="Option 4"
                    >

                    </Input>
                    <Input
                      type="text"
                      className="border-[.8px] px-2 border-gray-300 flex-grow py-[1.7px] text-sm h-6"
                      onChange={(e) => setNewQuestionQCMDOption5(e.target.value)}
                      value={newQuestionQCMOption5}
                      placeholder="Option 5"
                    >

                    </Input>
                    <Input
                      type="text"
                      className="border-[.8px] px-2 border-gray-300 flex-grow py-[1.7px] text-sm h-6"
                      onChange={(e) => setNewQuestionQCMDOption6(e.target.value)}
                      value={newQuestionQCMOption6}
                      placeholder="Option 6"
                    >

                    </Input>
                    <Select
                      className="border-[.8px] px-2 border-gray-300 flex-grow py-[1.7px] text-sm h-6"
                      options={config.question_QCM_options_for_recruter}
                      onChange={(e) => setNewQuestionQCMCorrectAnswer(e.value)}
                      value={
                        (config.question_QCM_options_for_recruter).filter(option =>
                          option.value === newQuestionQCMCorrectAnswer)
                      }
                      placeholder="Sélectionner l'option correcte"
                    >


                    </Select>

                    <Col lg="6" className="pt-4">
                      <h4>Voulez-vous inclure cette question dans le calcul du score?</h4>
                      <Select
                        // className="h-6"
                        options={options_include_in_score_caldulation}
                        // placeholder="Voulez-vous inclure cette question dans le calcul du score?"
                        // value = {selectQuestionType || ''}


                        value={
                          (options_include_in_score_caldulation).filter(option =>
                            option.value === newIncludeToScoreCalculation)
                        }



                        onChange={(e) => {
                          setNewIncludeToScoreCalculation(e.value)

                        }}
                      />
                    </Col>

                  </>
                )
                }



              </Col>

            </Row>
            <Row>
              <Button
                onClick={updateQuestion}
                className="mt-4"
                style={{
                  backgroundColor: "#404954",
                  borderColor: "#404954",
                  color: "white",
                }}
                type="button"
              >
                Sauvegarder
              </Button>
            </Row>
          </>
        )
        }
      </Container>
    </>
  );
};

export default EditJobQuestion;
