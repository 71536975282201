import axios from "axios";
import ReactPaginate from "react-paginate";
//import Card from "components/Card";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import config from "config";
import "../app.css";
import Select from 'react-select'

import OneJobCardCandidate from "components/candidat/oneJobCardCandidate";

import {
    Button,
    Form,
    FormGroup,
    Container,
    Label,
    Input,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    FormText,
    FormFeedback,
    CardText,
    Alert,
    Spinner,
    InputGroup,
} from "reactstrap";
import OneJob from "components/recruteur/oneJob";
import { useTranslation } from "react-i18next";
import { getProfileProgressForFrCandidate } from "network/ApiAxios";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { getProfileFrUserByID } from "network/ApiAxios";
import ChecklistForApply from "./MyProfile/CheckListForApply";
import { options_Pays } from "options";

const instance = axios.create({
    baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
    const token = localStorage.getItem("token");
    config.headers.Authorization = token ? token : "";
    config.headers['Content-Type'] = "application/json";
    return config;
});







function JobCardsInFranceCandidateV1() {

    const location = useLocation();
    const [jobs, setJobs] = useState([]);
    const [search, setSearch] = useState("");
    const [searchLaunched, setSearchLaunched] = useState(false);
    const [updateSearch, setUpdateSearch] = useState(false);

    const [perPage] = useState(6);
    const [pageCount, setPageCount] = useState(0);
    const [pageNb, setPageNb] = useState(1);
    const [
        candidateProgressProfile,
        setCandidateProgressProfile,
    ] = useState(null);


    const history = useHistory();
    const query = qs.parse(location.search);
    const user = JSON.parse(localStorage.getItem("user"));
    const locationn = user.location;
    // const [totalJobsNumber, setTotalJobsNumber] = useState("");
    const [totalJobsNumber, setTotalJobsNumber] = useState(0);
    const [totalJobsNumberWithoutSearching, setTotalJobsNumberWithoutSearching] = useState(0);
    const [pageCountWithoutSearching, setPageCountWithoutSearching] = useState(0);
    const [pageNbWithoutSearching, setPageNbWithoutSearching] = useState(1);
    const [profileCV, setProfileCV] = useState(null)
    const [isAppliedToGeneralPresentation, setIsAppliedToGeneralPresentation] = useState(null)
    const [country, setCountry] = useState(user.location);

    // var totalJobsNumber;
    var pageNumber = (query.pageNumber) || 1;
    // console.log("pageNumber: ", pageNumber);
    const { t, i18n } = useTranslation();




    const fetchJobsData = () => {
        if (!searchLaunched) {
            instance.get(`jobs/getActiveJobsForCandidatesNumber/${country}`).then((resp) => {
                setTotalJobsNumberWithoutSearching(resp.data.cnt);
                setPageCountWithoutSearching(Math.ceil(resp.data.cnt / perPage));

            });

            instance.get(`jobs/getActiveJobsForCandidatesPerPage/${country}/${pageNbWithoutSearching}/${perPage}`).then((resp) => {
                setJobs(resp.data);

            });
        }
        else {
            instance.get(`jobs/searchActiveJobsForCandidatesJobsByKeywordsNumber/NotFreelance/${search}/${country}`).then((resp) => {
                setTotalJobsNumber(resp.data.cnt);
                setPageCount(Math.ceil(resp.data.cnt / perPage));

            });

            instance.get(`jobs/searchActiveJobsForCandidatesByKeywords/NotFreelance/${search}/${country}/${pageNb}/${perPage}`).then((resp) => {
                setJobs(resp.data);

            });
        }
    };



    const handlePageClickWithoutSearching = (e) => {
        setSearchLaunched(false);
        setPageNbWithoutSearching(e.selected + 1);

    };

    const handlePageClickForSearching = (e) => {
        setSearchLaunched(true);
        setPageNb(e.selected + 1);
    };



    const launchTheSearchFunction = () => {
        if (search !== "") {
            setSearchLaunched(true);
        }
        setUpdateSearch(!updateSearch);
    };

    const getCVProfileFrCandidate = async () => {
        try {
            const { data } = await getProfileFrUserByID(user?._id)
            data?.CV && setProfileCV(data?.CV)
        } catch (error) {
            console.log(error);
        }
    }

    const CheckProgressForFrCandidate = async () => {
        try {
            const { data } = await getProfileProgressForFrCandidate(user?._id)
            data && setCandidateProgressProfile(((user?.CV || (data.profileExistence && profileCV)) && 50) + (data.IsAppliedToGeneralPresentation && 50))
            setIsAppliedToGeneralPresentation(data.IsAppliedToGeneralPresentation)
            //console.log(((user?.CV || data.profileExistence) && 50) + (data.IsAppliedToGeneralPresentation && 50))
            //console.log('progress', data)
        } catch (error) {
            console.log(error);
        }
    }

    
    useEffect(()=>{
        getCVProfileFrCandidate()
        CheckProgressForFrCandidate()
    },[])

    useEffect(() => {
        fetchJobsData();
    }, [pageNb, updateSearch,pageCountWithoutSearching, pageNbWithoutSearching]);

    
    return (
        <>
            <Container className="container-fluid ">
                <Row>
                    <Col lg="2">
                    </Col>
                    <Col lg="10">


                        <Card className="bg-transparent w-full">
                            <CardHeader>
                                {/*(candidateProgressProfile !== 100 && candidateProgressProfile !== null) && <Card className='mt-1 mb-4'>
                                    <CardHeader>
                                        <ChecklistForApply
                                            profileCV={profileCV}
                                            userCV={user?.CV}
                                            IsAppliedToGeneralPresentation={isAppliedToGeneralPresentation}
                                            isOpen
                                        />
                                    </CardHeader>
                                </Card>*/}
                                <Row>
                                    <Col sm='1'></Col>

                                    <Col lg="4">
                                        <div
                                            className="homePageStyle"
                                            style={{
                                                //backgroundColor: "#f7fafc",
                                                //objectFit: "cover",
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: "100%",
                                                height: "75%",
                                                paddingRight: 0,
                                                paddingLeft: 0,
                                            }}


                                        >
                                            <FormGroup>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-Recherche"
                                                    placeholder="Rechercher"
                                                    onChange={(e) =>
                                                        setSearch(e.target.value)
                                                    }
                                                    type="text"
                                                />
                                            </FormGroup>

                                        </div>
                                    </Col>

                                    <Col sm='3' className='mt-1'>
                                        <FormGroup>
                                            <InputGroup className="input-group-alternative mb-3">
                                                <Select
                                                    name='location'
                                                    className="w-full "
                                                    options={options_Pays}
                                                    placeholder={options_Pays.find(opt => opt.value === country)?.value}
                                                    //value={options_Pays.filter(opt => opt.value === country)}
                                                    onChange={(opt) => setCountry(opt.value)}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>


                                    <Col lg="4">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            // sx={{ mt: "25px" }}
                                            onClick={
                                                launchTheSearchFunction
                                            }
                                        >
                                            Lancez la recherche
                                        </Button>
                                    </Col>
                                </Row>
                            </CardHeader>

                            {searchLaunched && (
                                <>
                                    <CardBody>
                                        {jobs
                                            .map((job) => (
                                                <OneJobCardCandidate
                                                    key={job._id}
                                                    id={job._id}
                                                    title={job.title}
                                                    description={job.description}
                                                    technologies={job.technologies}
                                                    contract={job.contract}
                                                    entreprise={job.entreprise}
                                                    location={job.location}
                                                    createdAt={job.createdAt}
                                                    candidateProgressProfile={candidateProgressProfile}
                                                />

                                            ))}
                                    </CardBody>
                                    <CardFooter className="bg-transparent w-full d-flex justify-content-center">
                                        <ReactPaginate
                                            previousLabel={"<<"}
                                            nextLabel={">>"}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={pageCount}
                                            marginPagesDisplayed={3}
                                            pageRangeDisplayed={5}
                                            onPageChange={handlePageClickForSearching}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"}
                                        />
                                    </CardFooter>
                                </>
                            )}
                            {!searchLaunched && (
                                <>
                                    <CardBody>
                                        {jobs
                                            .map((job) => (
                                                <OneJobCardCandidate
                                                    key={job._id}
                                                    id={job._id}
                                                    title={job.title}
                                                    description={job.description}
                                                    technologies={job.technologies}
                                                    contract={job.contract}
                                                    entreprise={job.entreprise}
                                                    location={job.location}
                                                    createdAt={job.createdAt}
                                                    candidateProgressProfile={candidateProgressProfile}
                                                />
                                            ))}
                                    </CardBody>
                                    <CardFooter className="bg-transparent w-full d-flex justify-content-center">
                                        <ReactPaginate
                                            previousLabel={"<<"}
                                            nextLabel={">>"}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={pageCountWithoutSearching}
                                            marginPagesDisplayed={3}
                                            pageRangeDisplayed={5}
                                            onPageChange={handlePageClickWithoutSearching}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"}
                                        />
                                    </CardFooter>
                                </>
                            )}
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>

    );
}

export default JobCardsInFranceCandidateV1;
