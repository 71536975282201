import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";
import config from "config";
import "./globals.css";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import "react-datetime/css/react-datetime.css";


import CandidatLayout from "layouts/Candidat.js"
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import AuthRoutes from "./components/PrivateRoute/AuthRoutes";
import Interview from "components/Interview";
import PrivateRouteCandidat from "./components/PrivateRoute/PrivateRouteCandidat";

import HomepageView from "views/homepage/homepageView";
import HomepageViewV2 from "views/homepage/homepageViewV2";

import Home from "views/home";
import HomeHeader from "components/Headers/HomeHeader";
import HomeLayout from "layouts/HomeLayout";
import JobsHomepage from "components/homepage/jobsHomepage";
import HomepageJobsView from "views/homepage/HomepageJobsView";
import RegisterV4View from "views/other/RegisterV4View";
import ResetPassword from "views/examples/ResetPassword";
import ConfirmPassword from "./views/examples/ConfirmPassword";
import ResetPasswordSuccess from "views/examples/ResetPasswordSuccess";
import GeneralConditions from "views/other/GeneralConditions";



import Landing_Page_Layout_One from "layouts/Landing_Page_Layout_One";


import CandidatHorsFrLayout from "layouts/CandidatHorsFrLayout";
import EtrangerCDIenFranceHome from "views/candidat/etrangerCDIenFranceHome";
import EtrangerCDIenFranceHomeViewV2 from "views/outFranceCandidate/etrangerCDIenFranceHomeViewV2";
import EtrangerCDIenFranceGeneralInterviewsView from "views/candidat/etrangerCDIenFranceGeneralInterviewsView";
import EtrangerCDIenFranceMyProfile from "views/candidat/etrangerCDIenFranceMyProfile";
import EtrangerCDIenFranceCreateProfileV2 from "views/candidat/etrangerCDIenFranceCreateProfileV2";
import OutFrProjectsView from "views/candidat/outFrProjectsView";
import ApplyJobOFCD from "views/candidat/applyJobOFCD";
import OutFrContractHomeView from "views/outFranceCandidate/outFrContractHomeView";
import EtrangerCDIenFranceCompetenciesV2 from "views/candidat/etrangerCDIenFranceCompetenciesV2"
import OutFranceCandidateParametersView from "views/candidat/outFranceCandidateParametersView";
import OutFranceCandidatePreferencesView from "views/candidat/outFranceCandidatePreferencesView";
import PermanentOpportunitiesView from "views/outFranceCandidate/permanentOpportunitiesView";

import FreelanceMissionsView from "views/outFranceCandidate/freelanceMissionsView";
import FreelanceProfileOutFrCandidateView from "views/outFranceCandidate/freelanceProfileOutFrCandidateView";
import OutFrFreelanceCompetenciesView from "views/outFranceCandidate/outFrFreelanceCompetenciesView";
import OutFrFreelanceGeneralInterviewsView from "views/outFranceCandidate/outFrFreelanceGeneralInterviewsView";


import InFranceProfilesView from "views/superAdmin/inFranceProfilesView";
import InFranceProfilesV2View from "views/superAdmin/inFranceProfilesV2View";

import AllProfilesV2View from "views/superAdmin/allProfilesV2View";
import HomeSuperAdmin from "views/superAdmin/homeSuperAdmin";
import ProfileTypesSuperAdminView from "views/superAdmin/profileTypesSuperAdminView";
import TrainingsTrackingSuperAdminView from "views/superAdmin/trainingsTrackingSuperAdminView";


import CompetitionForm from "components/recruteur/CompetitionForm";
import Competitions from "components/recruteur/competitions";
import OneCompetitionV2 from "components/recruteur/OneCompetitionV2";
import Tab2CompEmployer from "views/tab2CompEmployer";
import CompetitionsEmployer from "components/recruteur/competitionsEmployer";
import EmployerOneCompetition from "components/recruteur/EmployerOneCompetition";
import CandidateOneCompetition from "components/candidat/CandidateOneCompetition";
import CandidateCompetitions from "components/candidat/CandidateCompetitions";



import HomeEmployer from "views/employer/homeEmployer";
import UserManagement from "views/employer/userManagement";
import Jobs from "views/employer/jobs";
import TalentsProfiles from "views/employer/talentsProfiles";
import ParametersEmployer from "views/employer/parametersEmployer";
import OneJobEmployerView from "views/employer/oneJobEmployerView";
import CreateJobView from "views/employer/createJobView";
import RecruiteContractOutFranceView from "views/employer/recruiteContractOutFranceView";
import RecruiteContractOutFranceWithListsView from "views/employer/recruiteContractOutFranceWithListsView";
import RecruiteContractOutFranceSourcingView from "views/employer/recruiteContractOutFranceSourcingView";
import RecruiterTrainingsTrackingView from "views/employer/recruiterTrainingsTrackingView";
import UserManagementForTraningsTracking from "views/employer/userManagementForTraningsTracking";
import CreateLearningPathView from "views/employer/createLearningPathView";
import YourLearningPathsView from "views/employer/yourLearningPathsView";
import DisplayOneLearningPathView from "views/employer/displayOneLearningPathView";
import UsersView from "views/superAdmin/usersView";
import OutFrContractusersView from "views/superAdmin/outFrContractusersView";
import FrUsersView from "views/superAdmin/frUsersView";

import OutFrContractOneJobAppView from "views/employer/outFrContractOneJobAppView";
import FrUsersForRecrutersView from "views/employer/frUsersForRecrutersView";
import OutFrUsersForRecrutersView from "views/employer/outFrUsersForRecrutersView";
import OutFrFreelanceUsersForRecrutersView from "views/employer/OutFrFreelanceUsersForRecrutersView";





import CandidateMyProfile from "views/candidat/candidateMyProfile";
import CandidateJobsView from "views/candidat/candidateJobsView";
import CandidateHomeViewV2 from "views/candidat/candidateHomeViewV2";
import ProfileCandidat from "views/examples/ProfileCandidat";
import CandidateParametersView from "views/candidat/candidateParametersView";


// import InFranceCandidateMyProfile from "views/inFranceCandidate/inFranceCandidateMyProfile";
import InFranceCandidateCompetencies from "views/candidat/InFranceCandidateCompetencies";
import InFranceCreateProfile from "views/inFranceCandidate/InFranceCreateProfile";
// import InFranceCandidateHomeViewV3 from "views/inFranceCandidate/inFranceCandidateHomeViewV3";
import InFranceCandidateHomeViewV4 from "views/inFranceCandidate/inFranceCandidateHomeViewV4";
import CandidateJobOffersView from "views/candidat/CandidateJobOffersView";
import InFranceCandidateApplyJob from "views/inFranceCandidate/inFranceCandidateApplyJob";
import CandidateFreelanceMissionsView from "views/candidat/CandidateFreelanceMissionsView";
import InFranceGeneralInterviewsView from "views/inFranceCandidate/inFranceGeneralInterviewsView";
import OneJobApplicationView from "views/jobApplication/oneJobApplicationView";
import InFranceCandidatePassPresentationInterview from "views/inFranceCandidate/inFranceCandidatePassPresentationInterview";

import ApplyToOneJob from "views/jobs/applyToOneJob";
import Qualify from "views/employer/Qualify";
import InFranceCandidatePreferencesView from "views/candidat/InFrCandidatePreferencesView";
import MyAcceptedAccessRequests from "views/employer/MyAcceptedAccessRequests";
import OpenCandidatesProfiles from "views/employer/OpenCandidatesProfiles";
import MyOfferSubscription from "views/employer/MyOfferSubscription";
import SubsciptionSuccess from "views/employer/SubsciptionSuccess";
import OutFrCandidateProfile from "views/outFranceCandidate/OutFrCandidateProfile";
import DisplayCVCandidate from "components/candidat/DisplayCVCandidate";
import InFrCandidateProfile from "views/inFranceCandidate/InFranceCandidateProfile";
import "./i18n";
import EtrangerCDIenFranceGeneralInterviewsViewV3 from "views/candidat/EtrangerCDIenFranceGeneralInterviewsViewV3";
import OutFrSharedProfile from "./views/candidat/SharefProfile/OutFrSharedProfile";
import FrSharedProfile from "views/candidat/SharefProfile/FrSharedProfile";
import OpenOutFrCandidatesProfiles from "views/employer/OpenedOutFrCandidateProfilesForRecruiters";
import InFranceCandidateJobsWithPayGreaterThen50kView from "views/inFranceCandidate/InFrCandidateJobsWithPaygreaterthen50k";
import InFranceCandidateJobsWithTJMGreaterThen500View from "views/inFranceCandidate/InFranceCandidateJobsWithTJMGreaterThen500View";
import JobOffersWithPayMoreThen50KView from "views/other/LandingPage/JobOffersWithPay50K+View";
import JobOffersWithTJMGreaterThen500View from "views/other/LandingPage/JobOffersWithTJM500+View";
import InternShipOffersForInFrCandidateView from "views/inFranceCandidate/InternShipOffersForInFrCandidateView";
import InFreelanceProfileView from "views/candidat/InFrFreelanceProfileView";
import OutFreelanceProfile from "views/outFranceCandidate/OutFreelanceProfile";
import SearchTalentCandidate from "views/other/LandingPage/SearchTalentCandidate";
import TalentedCandidatesList from "components/homepage/TalentedCandidatesList";
import TrainingCandidates from "views/candidat/TrainingCandidates";
import TrainingForCandidatesDetails from "views/candidat/TrainingForCandidatesDetails";
import SearchCandidatesByCompanyView from "views/employer/SearchCandidatesByCompanyView";
import OutFrCDIProfileView from "views/candidat/OutFrCDIProfileView";
import TalentedCandidatesListV1 from "components/homepage/TalentedCandidatesListV1";
import SearchTalentCandidateV1 from "views/other/LandingPage/SearchTalententCandidateV1";
import CandidateProfileView from "views/candidat/CandidateProfileView";
import SignUpEmployerView from "views/other/SignUpEmployerView";
import SignUpCandidateView from "views/other/SignUpCandidateView";
import CandidateParametersViewV1 from "views/candidat/CandidateParametersViewV1";
import ChangeCountryParametersView from "views/candidat/OutFrCDIProfileView";
import PaymentSuccess from "views/employer/PaymentSuccess";
import WelcomeCandidateView from "views/candidat/WelcomeCandidateView";
import TrainingSubscriptionsView from "views/candidat/TrainingSubscriptionsView";
import TrainingOverView from "views/candidat/TrainingOverView";
import TalentedCandidatesIframeV1 from "components/homepage/TalentedCandidatesIframeV1";
import TrainingForCandidatesV1 from "views/candidat/TrainingForCandidatesV1";
import ConfirmEmail from "views/examples/ConfirmEmail";
import FrSharedProfileV1 from "views/candidat/SharedProfileWithoutInterview/FrSharedProfileV1";
import OutFrSharedProfileV1 from "views/candidat/SharedProfileWithoutInterview/OutFrShareProfileV1";
import TalentedCandidatesListV2 from "components/homepage/TalentedCandidatesListV2";


var user;
var homeRoute = "/";
if (localStorage.getItem("user")) {
user = JSON.parse(localStorage.getItem("user"));
 } else {
 user = { role: "none" };
 };

 if (user.role === "non")
 {
   homeRoute="/home/login";

 }

 if (user.role === "employer")
 {
   homeRoute="/employer/home";

 }

 if ((user.role === "candidat"))
 {
    homeRoute="/candidate/home";
 }
 






ReactDOM.render(
  <BrowserRouter>
    <Switch>
      { /* <PrivateRoute path="/admin" component={AdminLayout} /> */ }
      { /* <PrivateRouteCandidat path="/candidat" component={CandidatLayout} /> */ }
      
      {/* sharedProfile candidate */}
      <Route path='/sharedOutFrProfile/:id' component={OutFrSharedProfile} />
      <Route path='/sharedFrProfile/:id' component={FrSharedProfile} />
      
      {/* sharedProfile candidate without InterView*/ }
      <Route path='/sharedOutFrProfileV1/:id' component={OutFrSharedProfileV1} />
      <Route path='/sharedFrProfileV1/:id' component={FrSharedProfileV1} />


      
      <Route path="/auth/reset-password" component={ResetPassword} />
      <Route path="/auth/confirm-password/:token" component={ConfirmPassword} />
      <Route path="/auth/reset-success" component={ResetPasswordSuccess} />

      

      <Route path="/interview/:id" component={Interview} />

      <Route exact path="/" component={HomepageViewV2} />

      <Route path="/home/generalConditions" component={GeneralConditions} />

      
      {/*
      <Route path="/home" component={HomeLayout} />
      */}
      

      
      <Route path="/homepage/register" component={RegisterV4View} />
      <Route path="/homepage/jobs" component={HomepageJobsView} />
      <Route path="/homepage/jobs/page/:pageNumber" component={HomepageJobsView} />
      <Route path="/homepage/signup-employer" component={SignUpEmployerView} />
      <Route path="/homepage/signup-candidate" component={SignUpCandidateView} />
      <Route path="/auth/confirm-email/:id" component={ConfirmEmail} />


      {/*
      <Route path="/homepage/jobs" component={JobsHomepage} />
      <Route path="/homepage/jobs/page/:pageNumber" component={JobsHomepage} />
*/}
      {/* <Route path="/candidatetranger/horsfrancecdienfrance" component={EtrangerCDIenFrance} /> */}
      {/* <Route path="/candidatetranger/horsfrancecdienfranceoffres" component={EtrangerCDIenFranceOffres} /> */}
      {/* <Route path="/candidatetranger/horsfrancecdienfrancemyprofile" component={EtrangerCDIenFranceMyProfile} /> */}


      {/* <PrivateRouteCandidat path="/outfrapplypermanentfr" component={CandidatHorsFrLayout} /> */}
      <Route path="/outFrCandidate/Competitions/allCompetitions" component={CandidateCompetitions} />
      <Route path="/outFrCandidate/Competitions/oneCompetition/:competitionId" component={CandidateOneCompetition} />
      


      {/* <Route path="/outfrapplypermanentfr/home" component={EtrangerCDIenFranceHomeViewV2} /> */}    
{/*       <Route path="/outfrapplypermanentfr/parameters" component={OutFranceCandidateParametersView} />
 */}      <Route path="/outfrapplypermanentfr/preferences" component={OutFranceCandidatePreferencesView} />
      <Route path="/outfrapplypermanentfr/myprojects" component={OutFrProjectsView} />

      

      <PrivateRoute path="/outfrapplypermanentfr/contract/home" component={OutFrContractHomeView} />
      <PrivateRouteCandidat path="/outfrapplypermanentfr/createprofile" component={EtrangerCDIenFranceCreateProfileV2} />
      <Route path="/outfrapplypermanentfr/contract/generalinterviews" component={EtrangerCDIenFranceGeneralInterviewsViewV3} />
      {/* <Route path="/outfrapplypermanentfr/myprofile" component={EtrangerCDIenFranceMyProfile} /> */}
      <Route path="/outfrapplypermanentfr/mycompetencies" component={EtrangerCDIenFranceCompetenciesV2} />
      <Route path="/outfrapplypermanentfr/apply" component={ApplyJobOFCD} />
      <Route path="/outfrapplypermanentfr/permanentopportunities" component={PermanentOpportunitiesView} />
      <Route path="/outfrapplypermanentfr/freelancemissions" component={FreelanceMissionsView} />
      <Route path="/outfrapplypermanentfr/freelance/createprofile" component={FreelanceProfileOutFrCandidateView} />
      <Route path="/outfrapplypermanentfr/freelance/mycompetencies" component={OutFrFreelanceCompetenciesView} />
      <Route path="/outfrapplypermanentfr/freelance/generalinterviews" component={OutFrFreelanceGeneralInterviewsView} />
      {/* <Route path='/outfrapplypermanentfr/MyProfile/:id' component={OutFrCandidateProfile} /> */}
      <Route path="/outfrapplypermanentfr/FreelanceProfile/:id" component={OutFreelanceProfile} />
      <Route path='/CvCandidate/:idCandidat/uploads/:pathnameCV' component={DisplayCVCandidate} />

      
      

      <PrivateRoute path="/employer/home" component={HomeEmployer} />
      <PrivateRoute path="/employer/team" component={UserManagement} />
      <PrivateRoute path="/employer/jobs" component={Jobs} />
      <PrivateRoute path="/employer/talentsprofiles" component={TalentsProfiles} />
      <PrivateRoute path="/employer/recruit/contractprofiles" component={RecruiteContractOutFranceView} />
      <PrivateRoute path="/employer/outFrContractProfiles/jobApplication/oneJobApplication/:jobApplicationId" component={OutFrContractOneJobAppView} />      
      
      <PrivateRoute path="/employer/trainings/RecruiterTrainingsTracking" component={RecruiterTrainingsTrackingView} />
      <PrivateRoute path="/employer/trainings/UserManagement" component={UserManagementForTraningsTracking} />
      <PrivateRoute path="/employer/trainings/CreateLearningPathView" component={CreateLearningPathView} />
      <PrivateRoute path="/employer/trainings/YourLearningPathsView" component={YourLearningPathsView} />
      <PrivateRoute path="/employer/trainings/DisplayOneLearningPathView/:learningPathID" component={DisplayOneLearningPathView} />
      
      <PrivateRoute path="/employer/suivi/UsersView" component={UsersView} />
      <PrivateRoute path="/employer/suivi/UsersView/page/:pageNumber" component={UsersView} />
      <PrivateRoute path="/employer/suivi/OutFrContractusersView/page/:pageNumber" component={OutFrContractusersView} />
      <PrivateRoute path="/employer/suivi/FrUsersView" component={FrUsersView} />
      {/* <PrivateRoute path="/employer/suivi/FrUsersView/page/:pageNumber" component={FrUsersView} /> */}
      

      
      




      <PrivateRoute path="/employer/recruiters/FrUsersForRecrutersView" component={FrUsersForRecrutersView} />
      <PrivateRoute path="/employer/recruiters/OutFrUsersForRecrutersView" component={OutFrUsersForRecrutersView} />
      <PrivateRoute path="/employer/recruiters/OutFrFreelanceUsersForRecrutersView" component={OutFrFreelanceUsersForRecrutersView} />

      

      <PrivateRoute path="/employer/parameters" component={ParametersEmployer} />
      <PrivateRoute path="/employer/createjob" component={CreateJobView} />
      <PrivateRoute path="/employer/onejob/:jobId" component={OneJobEmployerView} />
      {/*
        <Route path="/employer/:jobId" component={OneJobEmployerView} />
      */}
      <PrivateRoute path="/employer/Competitions/CompetitionForm" component={CompetitionForm} />
      <PrivateRoute path="/employer/Competitions/EmployerCompetitions" component={CompetitionsEmployer} />
      <PrivateRoute path="/employer/Competitions/oneCompetition/:competitionId" component={EmployerOneCompetition} />
      <PrivateRoute path="/employer/recruiters/Qualify" component={Qualify} />
      <PrivateRoute path="/employer/recruiters/AccessRequestsAccepted" component={MyAcceptedAccessRequests} />
      <PrivateRoute path='/employer/recruiters/OpenedAndQualifiedProfiles' component={OpenCandidatesProfiles} />
      <PrivateRoute path='/employer/Subscription' component={MyOfferSubscription} />
      <PrivateRoute path='/employer/SuccessSubscription' component={SubsciptionSuccess} />
      <PrivateRoute path='/employer/recruiters/OpenedOutFrProfiles' component={OpenOutFrCandidatesProfiles} />
      <PrivateRoute path='/employer/candidatesView' component={SearchCandidatesByCompanyView} />

      <Route path="/superadmin/home" component={HomeSuperAdmin} />
      <Route path="/superadmin/profiles" component={InFranceProfilesView} />
      <Route path="/superadmin/profiles/page/:pageNumber" component={InFranceProfilesView} />  
      <Route path="/superadmin/profiletypes" component={ProfileTypesSuperAdminView} />
      <Route path="/superadmin/TrainingsTrackingParameters" component={TrainingsTrackingSuperAdminView} />    
      <Route path="/superadmin/allprofiles" component={AllProfilesV2View} />
      <Route path="/superadmin/allprofiles/page/:pageNumber" component={AllProfilesV2View} />
      <Route path="/superadmin/inFranceprofiles" component={InFranceProfilesV2View} />
      <Route path="/superadmin/inFranceprofiles/page/:pageNumber" component={InFranceProfilesV2View} />
      
      
      

      {/* <Route path="/candidate/home" component={CandidateHomeViewV2} /> */}
      <PrivateRoute path="/candidate/parameters" component={CandidateParametersViewV1} />
      <Route path="/candidate/myprofile" component={CandidateMyProfile} />
      <Route path="/candidate/jobs" component={CandidateJobsView} />
      <PrivateRoute path='/candidate/trainings/:type' component={TrainingCandidates} />
      <PrivateRoute path='/candidate/trainingDetails/:id' component={TrainingForCandidatesDetails} />
      <PrivateRoute exact path='/MyProfile/:id' component={CandidateProfileView} />
      <PrivateRoute path='/candidate/changeCountry/parameters' component={ChangeCountryParametersView} />
      <PrivateRoute path="/candidate/job-offers" component={CandidateJobOffersView} />
      <PrivateRoute path="/candidate/freelance/missions" component={CandidateFreelanceMissionsView} />
      <PrivateRoute path="/candidate/freelance/parameters" component={InFreelanceProfileView} />
      <PrivateRoute path='/candidate/checkoutTraining/success' component={PaymentSuccess} />
      <PrivateRoute path='/candidate/home' component={WelcomeCandidateView} />
      <PrivateRoute path='/candidate/subscriptions' component={TrainingSubscriptionsView} />
      <PrivateRoute path='/candidate/trainingOverview/:id' component={TrainingOverView} />
      <PrivateRoute path='/candidate/trainingV1' component={TrainingForCandidatesV1} />


      {/* <PrivateRoute path="/infrancecandidate/home" component={InFranceCandidateHomeViewV4} /> */}
      <Route path="/infrancecandidate/jobs/page/:pageNumber" component={CandidateJobOffersView} />
      <Route path="/infrancecandidate/freelancemissions/page/:pageNumber" component={CandidateFreelanceMissionsView} />
      <PrivateRoute exact path="/infrancecandidate/createMyprofile" component={InFranceCreateProfile} />
      <Route path="/infrancecandidate/mycompetencies" component={InFranceCandidateCompetencies} />
      
      <Route path="/infrancecandidate/apply" component={InFranceCandidateApplyJob} />
{/*       <PrivateRoute path="/infrancecandidate/parameters" component={InFranceCandidateParametersView} />
 */}      <PrivateRoute path="/infrancecandidate/contract/generalinterviews" component={InFranceGeneralInterviewsView} />
      <Route path="/infrancecandidate/contract/passPresentationInterview/apply" component={InFranceCandidatePassPresentationInterview} />
      <Route path="/jobs/apply" component={ApplyToOneJob} />
      <PrivateRoute path="/jobApplication/oneJobApplication/:jobApplicationId" component={OneJobApplicationView} />
      <PrivateRoute path="/Infrapplypermanentfr/preferences" component={InFranceCandidatePreferencesView} />
      {/* <PrivateRoute exact path='/infrancecandidate/MyProfile/:id' component={InFrCandidateProfile} /> */}
      
      <Route path='/infrancecandidate/Jobs50k+' component={InFranceCandidateJobsWithPayGreaterThen50kView} />
      <Route path='/infrancecandidate/MissionsWithTJMG500+' component={InFranceCandidateJobsWithTJMGreaterThen500View} />
      <Route path="/infrancecandidate/internshipOffers" component={InternShipOffersForInFrCandidateView} />


      {/*
      {user && (user.role === "candidat") && user.location && user.location === "France" &&
        <Route path="/jobs/apply" component={ApplyToOneJob} />
      }
      {user && (user.role === "candidat") && user.location && user.location !== "France" &&
        <Route path="/jobs/apply" component={EtrangerCDIenFranceHomeViewV2} />
      }

    */}
      


      

         

      <Route path="/lp" component={Landing_Page_Layout_One} />
      <Route path='/home/offers50K+' component={JobOffersWithPayMoreThen50KView} />
      <Route path='/home/Missions500+' component={JobOffersWithTJMGreaterThen500View} />
      <Route path="/home/SearchTalentCandidate" component={SearchTalentCandidate} />
      <Route path="/home/SearchTalentCandidateV1" component={SearchTalentCandidateV1} />
      <Route path='/home/talentedCandidatesLists' component={TalentedCandidatesList} />
      <Route path='/home/talentedCandidatesListsV1' component={TalentedCandidatesListV1} />
      <Route path='/home/talentedCandidatesListsV2' component={TalentedCandidatesListV2} />
      <Route path='/home/TalentedCandidatesIframev1' component={TalentedCandidatesIframeV1} />

      <AuthRoutes path="/home" component={HomeLayout} />
      
      {/* <Redirect from="/" to="/candidat/tab1" /> */}
          {
            // console.log("4444444444444444444444444444444: user: ", user)

          }


      <Redirect from="/" to={homeRoute} />



        
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
