import axios from "axios";
import ReactPaginate from "react-paginate";
//import Card from "components/Card";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import ViewJob from "components/recruteur/ViewJob";
import { ExclamationCircleIcon, SearchIcon } from "@heroicons/react/outline";
import config from "config";
import "../app.css";
import oneJobCard from "components/recruteur/oneJobCard";
import CardJob from "components/Card";
import Header from "components/Headers/Header";
import HomepageLayout from "layouts/HomepageLayout";
import OneJobCardCandidate from "components/candidat/oneJobCardCandidate";

import {
  PencilAltIcon,
  LocationMarkerIcon,
  BriefcaseIcon,
  MailIcon,
  PhoneIcon,
  LinkIcon,
  CodeIcon,
  ArchiveIcon,
  OfficeBuildingIcon,
  PaperClipIcon,
} from "@heroicons/react/solid";
import {
  Button,
  Form,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormText,
  FormFeedback,
  CardText,
  Alert,
  InputGroup,
} from "reactstrap";
import OneJob from "components/recruteur/oneJob";
import { getProfileProgressForFrCandidate } from "network/ApiAxios";
import ChecklistForApply from "./MyProfile/CheckListForApply";
import { getProfileFrUserByID } from "network/ApiAxios";
import { options_Pays } from "options";
import Select from 'react-select'

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});
function FreelanceMissionsCardsInFranceCandidate() {
  const user = JSON.parse(localStorage.getItem("user"));
  const location = useLocation();
  const [jobs, setJobs] = useState([]);
  const [search, setSearch] = useState("");
  const [searchLaunched, setSearchLaunched] = useState(false);
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState([]);
  const [perPage] = useState(6);
  const [pageCount, setPageCount] = useState(0);
  const [
    candidateProgressProfile,
    setCandidateProgressProfile,
  ] = useState(null);
  const [profileCV, setProfileCV] = useState(null)
  const [isAppliedToGeneralPresentation, setIsAppliedToGeneralPresentation] = useState(null)
  const [country, setCountry] = useState(user.location);

  const history = useHistory();
  const query = qs.parse(location.search);
  // const [totalJobsNumber, setTotalJobsNumber] = useState("");
  var totalJobsNumber;
  var pageNumber = (query.pageNumber) || 1;
  // console.log("pageNumber: ", pageNumber);

  instance.get(`jobs/getActiveWithFreelanceContractJobsForCandidatesNumber/${country}`).then((resp) => {
    // console.log("resp: ", resp);
    // console.log("resp.data.cnt", resp.data.cnt);
    // setTotalJobsNumber(resp.data.cnt);
    totalJobsNumber = resp.data.cnt;
    // console.log("totalJobsNumber: ", totalJobsNumber);
    // console.log("perPage: ", perPage);
    setPageCount(Math.ceil(totalJobsNumber / perPage));
    // console.log("pageCount: ", pageCount);

  });


  const fetchData = () => {
    // console.log("22222222222222: fetchData: ");
    // console.log("22222222222222: fetchData: pageNumber", pageNumber);
    // console.log("22222222222222: fetchData: perPage", perPage);
    // console.log("22222222222222: fetchData: url", `jobs/getJobsPerPage/${pageNumber}/${perPage}`);

    instance.get(`jobs/getActiveWithFreelanceContractJobsForCandidates/${pageNumber}/${perPage}/${country}`).then((resp) => {
      setJobs(resp.data);

    });
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    // console.log("3333333333333333333333: selectedpage: ", selectedPage);
    // setOffset(selectedPage + selectedPage * 5);
    history.push(`/candidate/freelance/missions?pageNumber=${selectedPage + 1}`);
  };

  const handlePageClickForSearching = (e) => {
    const selectedPage = e.selected;
    // console.log("3333333333333333333333: selectedpage: ", selectedPage);
    // setOffset(selectedPage + selectedPage * 5);
    history.push(`/candidate/freelance/missions?pageNumber=${selectedPage + 1}`);
  };

  const launchTheSearchFunction = () => {
    if (search !== "") {
      setSearchLaunched(true);

      instance.get(`jobs/searchActiveJobsForCandidatesJobsByKeywordsNumber/Freelance/${search}/${country}`).then((resp) => {
        // console.log("resp: ", resp);
        // console.log("resp.data.cnt", resp.data.cnt);
        // setTotalJobsNumber(resp.data.cnt);
        totalJobsNumber = resp.data.cnt;
        // console.log("totalJobsNumber: ", totalJobsNumber);
        // console.log("perPage: ", perPage);
        setPageCount(Math.ceil(totalJobsNumber / perPage));
        // console.log("pageCount: ", pageCount);

      });
      instance.get(`jobs/searchActiveJobsForCandidatesByKeywords/Freelance/${search}/${country}/${pageNumber}/${perPage}`).then((resp) => {
        setJobs(resp.data);

      });
    }


  };

  const getCVProfileFrCandidate = async () => {
    try {
      const { data } = await getProfileFrUserByID(user?._id)
      data?.CV && setProfileCV(data?.CV)
    } catch (error) {
      console.log(error);
    }
  }

  const CheckProgressForFrCandidate = async () => {
    try {
      const { data } = await getProfileProgressForFrCandidate(user?._id)
      data && setCandidateProgressProfile(((user?.CV || (data.profileExistence && profileCV)) && 50) + (data.IsAppliedToGeneralPresentation && 50))
      setIsAppliedToGeneralPresentation(data.IsAppliedToGeneralPresentation)
      //console.log(((user?.CV || data.profileExistence) && 50) + (data.IsAppliedToGeneralPresentation && 50))
      //console.log('progress', data)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getCVProfileFrCandidate()
    CheckProgressForFrCandidate()
  }, [])


  useEffect(() => {
    fetchData();
  }, [pageNumber]);




  return (
    <>
      <Container className="container-fluid ">
        <Row>
          <Col lg="2">
          </Col>
          <Col lg="10">
            <Card className="bg-transparent w-full">
              <CardHeader>
                {/*(candidateProgressProfile !== 100 && candidateProgressProfile !== null) && <Card className='mt-1 mb-4'>
                  <CardHeader>
                    <ChecklistForApply
                      profileCV={profileCV}
                      userCV={user?.CV}
                      IsAppliedToGeneralPresentation={isAppliedToGeneralPresentation}
                      isOpen
                    />
                  </CardHeader>
                </Card>*/}
                <Row>
                  <Col sm='1'></Col>
                  <Col lg="4">
                    <div
                      className="homePageStyle"
                      style={{
                        //backgroundColor: "#f7fafc",
                        //objectFit: "cover",
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: "100%",
                        height: "75%",
                        paddingRight: 0,
                        paddingLeft: 0,
                      }}


                    >
                      <FormGroup>
                        <Input
                          className="form-control-alternative"
                          id="input-Recherche"
                          placeholder="Rechercher"
                          onChange={(e) =>
                            setSearch(e.target.value)
                          }
                          type="text"
                        />
                      </FormGroup>

                    </div>
                  </Col>

                  <Col sm='3' className='mt-1'>
                    <FormGroup>
                      <InputGroup className="input-group-alternative mb-3">
                        <Select
                          name='location'
                          className="w-full "
                          options={options_Pays}
                          placeholder={options_Pays.find(opt => opt.value === country)?.value}
                          //value={options_Pays.filter(opt => opt.value === country)}
                          onChange={(opt) => setCountry(opt.value)}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>


                  <Col lg="3">
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      // sx={{ mt: "25px" }}
                      onClick={() => {
                        // console.log("bouton cliqué 0000000000000000000000 ")

                        launchTheSearchFunction()
                      }}
                    >
                      Lancez la recherche
                    </Button>
                  </Col>
                  <Col sm="1"></Col>
                </Row>
              </CardHeader>

              {searchLaunched && (
                <>
                  <CardBody>
                    {jobs
                      .map((job) => (
                        <OneJobCardCandidate
                          key={job._id}
                          id={job._id}
                          title={job.title}
                          description={job.description}
                          technologies={job.technologies}
                          contract={job.contract}
                          entreprise={job.entreprise}
                          location={job.location}
                          createdAt={job.createdAt}
                        />

                      ))}
                  </CardBody>
                  <CardFooter className="bg-transparent w-full">
                    <Row>

                      <ReactPaginate
                        previousLabel={"<<"}
                        nextLabel={">>"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClickForSearching}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    </Row>
                  </CardFooter>
                </>
              )}
              {!searchLaunched && (
                <>
                  <CardBody>

                    {jobs
                      .map((job) => (
                        <OneJobCardCandidate
                          key={job._id}
                          id={job._id}
                          title={job.title}
                          description={job.description}
                          technologies={job.technologies}
                          contract={job.contract}
                          entreprise={job.entreprise}
                          location={job.location}
                          createdAt={job.createdAt}
                        />
                      ))}

                  </CardBody>
                  <CardFooter className="bg-transparent w-full">
                    <Row>

                      <ReactPaginate
                        previousLabel={"<<"}
                        nextLabel={">>"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />

                    </Row>
                  </CardFooter>
                </>
              )}


            </Card>
          </Col>

        </Row>
      </Container>
    </>
  );
}

export default FreelanceMissionsCardsInFranceCandidate;
