import axios from "axios";
import Questions from "components/recruteur/Questions";
import UpdateForm from "components/UpdateForm";
import React, { useEffect, useState } from "react";
import { NavLink as NavLinkRRD, useHistory, Link } from "react-router-dom";
import {
  PencilAltIcon,
  LocationMarkerIcon,
  BriefcaseIcon,
  MailIcon,
  PhoneIcon,
  LinkIcon,
  CodeIcon,
  ArchiveIcon,
  OfficeBuildingIcon,
  PaperClipIcon,
  AcademicCapIcon,
  UserIcon,
  LightBulbIcon,
} from "@heroicons/react/solid";
import {
  DropdownMenu,
  Media,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormText,
  FormFeedback,
  CardText,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Table,
} from "reactstrap";
import JobCandidats from "components/recruteur/JobCandidats";
import config from "config";
import LoginPostul from "components/loginPostul";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});
function CandidatOneJob({ jobId }) {
  const history = useHistory();
  const [job, setJob] = useState({});
  const [created, setCreated] = useState("");
  const [endDate, setEndDate] = useState("");
  const [interviewUrl, setInterviewUrl] = useState("");
  const [affiche, setAffiche] = useState({
    update: false,
    questions: false,
  });

  const [openTab, setOpenTab] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalPostul, setModalPostul] = useState(false);
  const [CVinput, setCVinput] = useState(false);
  const [CV, setCV] = useState("");
  const [candidatID, setCandidatID] = useState("");
  const [motivLttr, setMotivLttr] = useState("");
  const [loginPostul, setLoginPostul] = useState(false);
  const [applied, setApplied] = useState(false);


  const [completedProfile, setCompletedProfile] = useState(false);


  const [url, setUrl] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const toggleOpen = () => setDropdown(!dropdown);
  var user;
  if (localStorage.getItem("user")) {
    user = JSON.parse(localStorage.getItem("user"));
  } else {
    user = { role: "none" };
  }

  useEffect(() => {
    instance.get(`jobs/${jobId}`).then((resp) => {
      setJob(resp.data.job);
      setCreated(resp.data.job.createdAt);
      setInterviewUrl(resp.data.job.interview.url);
      // setEndDate(resp.data.job.endDate.slice(0, 10));
      if (localStorage.getItem("user")) {
        if (user.jobs.includes(config.General_Presentation_Job_ID)) {
          //console.log("77777777777777777777777777777777777");
          //console.log(user.jobs);
          setCompletedProfile(true);
        }      
        resp.data.job.participations.forEach((e) => {
          if (JSON.parse(localStorage.getItem("user"))._id == e.candidat._id) {
            setApplied(true);
          }
        });
        if (JSON.parse(localStorage.getItem("user")).jobs) {
          JSON.parse(localStorage.getItem("user")).jobs.forEach((e) => {
            if (e == jobId) {
              setUrl(true);
            }
          });
        }
        if (JSON.parse(localStorage.getItem("user")).role == "employer") {
          setUrl(true);
        }
      }
    });
  }, [jobId]);

  const participate = () => {
    const formData = new FormData();
    formData.append("CV", CV);
    formData.append("candidatID", user._id);
    formData.append("motivLttr", motivLttr);
    instance.put(`jobs/participate/${jobId}`, formData).then((resp) => {
      localStorage.setItem("user", JSON.stringify(resp.data.user));
      history.push(`/interview/${job?.interview?._id}`)
    });
  };

  const deleteJob = () => {
    instance.delete(`jobs/${jobId}`).then(() => {
      history.push("/admin/tab2");
    });
  };

  return (
    <>
      <Container>
        <Row className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"></Row>
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <Card className="card-profile shadow">
              <Row>
                <div className="col">
                  <div className="card-profile-stats d-flex justify-content-center mt-md-5"></div>
                </div>
              </Row>
              <div className="text-center">
                <h3>{job.title}</h3>
                <div
                  className="h5 mt-4 col-12 "
                  style={{ display: "inline-flex" }}
                >
                  <LocationMarkerIcon className="h-6 pr-2" />
                  {job.location}
                </div>
                <div
                  className="h5 mt-4 col-12 "
                  style={{ display: "inline-flex" }}
                >
                  <AcademicCapIcon className="h-6 pr-2" />
                  {job.studyLevel}
                </div>
                <div
                  className="h5 mt-4 col-12 "
                  style={{ display: "inline-flex" }}
                >
                  <LightBulbIcon className="h-6 pr-2" />
                  Experience: {job.yearsOfExperience}
                </div>
                <div
                  className="h5 mt-4 col-12 "
                  style={{ display: "inline-flex" }}
                >
                  <PaperClipIcon className="h-6 pr-2" />
                  Type de contrat: {job.contract}
                </div>
                <div
                  className="h5 mt-4 col-12 "
                  style={{ display: "inline-flex" }}
                >
                  <UserIcon className="h-6 pr-2" />
                  Genre {job.gender}
                </div>
                <hr className="my-2" />
                <p></p>
              </div>
            </Card>
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Table className="GatTable">
                  <tbody>
                    <tr>
                      <td
                        className={openTab === 1 ? "tdActive " : "td "}
                        //activeClassName={openTab === 1 ? "active" : {}}
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab(1);
                        }}
                        //data-toggle="tab"
                        to="#link1"
                        //role="tablist"
                        //onClick={() => history.push('/admin/edit-profile')}
                        // size="sm"
                      >
                        <i className="fa fa-book" aria-hidden="true" />{" "}
                        Description
                      </td>

                      {user.role == "employer" && user._id == job.userID && (
                        <td
                          className={openTab === 2 ? "tdActive " : "td "}
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(2);
                          }}
                          data-toggle="tab"
                          href="#link2"
                          role="tablist"
                          //onClick={() => history.push('/admin/edit-profile')}
                          size="sm"
                        >
                          <i class="fa fa-question-circle" aria-hidden="true" />{" "}
                          Questions
                        </td>
                      )}
                      {user.role == "employer" && user._id == job.userID && (
                        <>
                          <td
                            className={
                              (openTab === 3) | (openTab === 4)
                                ? "tdActive "
                                : "td "
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(3);}}
                            data-toggle="tab"
                            href="#link1"
                            role="tablist"
                            //onClick={() => history.push('/admin/edit-profile')}
                            size="sm"
                          >
                            <i className="fa fa-users" aria-hidden="true" />{" "}
                            Candidats
                          </td>

                          <DropdownMenu
                            classname=""
                            className={` ${dropdown ? "show" : ""}`}
                          >
                            {/* <DropdownItem className="noti-title" header tag="div">
                                    <h6 className="text-overflow m-0">Welcome!</h6>
                                </DropdownItem> */}
                            <DropdownItem
                              onClick={(e) => {
                                e.preventDefault();
                                setOpenTab(3);
                                toggleOpen();
                              }}
                            >
                              <i className="fa fa-check" />
                              <span>Terminé</span>
                            </DropdownItem>

                            <DropdownItem
                              onClick={(e) => {
                                e.preventDefault();
                                setOpenTab(4);
                                toggleOpen();
                              }}
                            >
                              <i className="fa fa-times" />
                              <span>Non terminé</span>
                            </DropdownItem>
                          </DropdownMenu>
                        </>
                      )}
                      {/* {user.role == "employer" && user._id == job.userID && (
                       <Col className="text-right" xs="2">
                          <Button
                        style={
                          openTab === 4
                            ? {
                                color: "#455266",
                                backgroundColor: "#cecece",
                                borderColor: "#455266",
                              }
                            : { color: "white", backgroundColor: "#455266" }
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab(4);
                        }}
                        data-toggle="tab"
                        href="#link1"
                        role="tablist"
                        //onClick={() => history.push('/admin/edit-profile')}
                        size="sm"
                      >
                        Modifier
                      </Button> 
                        </Col>
                      )}*/}
                      {user.role != "employer" && config.Candidature_Method != "postuler_directement_one_step" &&(
                        <>
                          <td style={{ width: "100%" }} />
                          <td
                            className="text-right"
                            style={{ float: "right" }}
                            lg="8"
                          >
                              {!completedProfile && (
                              <Button
                                color="primary"
                                type="button"
                                href={`/candidat/monprofil`}
                                target="_blank"
                                //size="sm"
                              >
                                Terminez votre profil avant
                              </Button>
                            )}
                            {completedProfile && !applied && (
                                
                                <Button
                                color="primary"
                                type="button"
                                onClick={() => {
                                  setModalPostul(!modalPostul);
                                }}
                                //size="sm"
                              >
                                Postuler
                              </Button>                              
                            )}
                            {completedProfile && applied && (
                                <Button
                                  color="secondary"
                                  type="button"
                                  size="sm"
                                 // href={`/interview/${job?.interview?._id}`}
                                 disabled
                                  //target="blank"
                                >
                                  Vous avez déjà postulé à cette offre
                                </Button>
                            )}


                            {user.role == "candidat" && (
                              <Modal
                                toggle={() => setModalPostul(!modalPostul)}
                                isOpen={modalPostul}
                                size="lg"
                              >
                                <div className=" modal-header">
                                  <h4
                                    className=" modal-title"
                                    id="exampleModalLabel"
                                  >
                                    {job.title}
                                  </h4>
                                  <button
                                    aria-label="Close"
                                    className=" close"
                                    type="button"
                                    onClick={() => setModalPostul(!modalPostul)}
                                  >
                                    <span aria-hidden={true}>×</span>
                                  </button>
                                </div>
                                <ModalBody>
                                  <Form>
                                    <Row>
                                      <Col lg="6">
                                        <FormGroup>
                                          <label
                                            className="form-control-label"
                                            htmlFor="input-username"
                                          >
                                            Nom
                                          </label>
                                          <Input
                                            className="form-control-alternative"
                                            value={user.name}
                                            id="input-username"
                                            placeholder="Username"
                                            // onChange={(e) => setName(e.target.value)}
                                            type="text"
                                            readOnly
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col lg="6">
                                        <FormGroup>
                                          <label
                                            className="form-control-label"
                                            htmlFor="input-username"
                                          >
                                            Prénom
                                          </label>
                                          <Input
                                            className="form-control-alternative"
                                            value={user.lastName}
                                            id="input-username"
                                            placeholder="Username"
                                            // onChange={(e) => setLastName(e.target.value)}
                                            type="text"
                                            readOnly
                                          />
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg="6">
                                        <FormGroup>
                                          <label
                                            className="form-control-label"
                                            htmlFor="input-email"
                                          >
                                            Email
                                          </label>
                                          <Input
                                            className="form-control-alternative"
                                            id="input-email"
                                            value={user.email}
                                            // onChange={(e) => setAdress(e.target.value)}
                                            type="email"
                                            readOnly
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col lg="6">
                                        <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-email"
                                          >
                                            CV du candidat
                                          </label>
                                          <Input
                                          className="form-control-alternative"
                                          type="file"
                                          accept=".pdf"
                                          onChange={(e) =>
                                            setCV(e.target.files[0])
                                          }
                                          CVinput
                                        />
                                          {/* </div> */}
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    <Row>
                                      
                                    </Row>

                                    

                                  </Form>
                                </ModalBody>
                                <ModalFooter>
                                  <Button
                                    color="secondary"
                                    type="button"
                                    onClick={() => setModalPostul(!modalPostul)}
                                    size="sm"
                                  >
                                    Annuler
                                  </Button>
                                  <Button
                                    color="primary"
                                    type="button"
                                    size="sm"
                                    onClick={() => {
                                      participate();
                                      //setModalPostul(!modalPostul);
                                    }}
                                  >
                                    Suivant 
                                  </Button>
                                </ModalFooter>
                              </Modal>
                            )}
                            {user.role == "none" && (
                              <Modal
                                toggle={() => {
                                  setModalPostul(!modalPostul);
                                  setLoginPostul(false);
                                }}
                                isOpen={modalPostul}
                                size="lg"
                              >
                                <div className=" modal-header">
                                  <h4
                                    className=" modal-title"
                                    id="exampleModalLabel"
                                  >
                                    {job.title}
                                  </h4>
                                  <button
                                    aria-label="Close"
                                    className=" close"
                                    type="button"
                                    onClick={() => {
                                      setModalPostul(!modalPostul);
                                      setLoginPostul(false);
                                    }}
                                  >
                                    <span aria-hidden={true}>×</span>
                                  </button>
                                </div>
                                <ModalBody>
                                  {!loginPostul && (
                                    <Row>
                                      <Col lg="12">
                                        <h4 className="text-center">
                                          {" "}
                                          Vous devez être connecté pour pouvoir
                                          postuler !
                                        </h4>
                                      </Col>
                                    </Row>
                                  )}
                                  {loginPostul && (
                                    <LoginPostul id={jobId} redirect={"job"} />
                                  )}
                                </ModalBody>
                                {!loginPostul && (
                                  <ModalFooter>
                                    <Col lg="6">
                                      <Button
                                       
                                       className="btn-secondary"
                                        onClick={() => setLoginPostul(true)}
                                      >
                                        vous avez déjà un compte,
                                        connectez-vous!
                                      </Button>
                                    </Col>
                                    <Col lg="6">
                                      <Button
                                        color="primary"
                                        
                                        to="/home/register"
                                        tag={Link}
                                      >
                                        Créez un nouveau compte!
                                      </Button>
                                    </Col>
                                  </ModalFooter>
                                )}
                              </Modal>
                            )}
                          </td>
                        </>
                      )}
                      {user.role != "employer" && config.Candidature_Method == "postuler_directement_one_step" &&(
                        <>
                          <td style={{ width: "100%" }} />
                          <td
                            className="text-right"
                            style={{ float: "right" }}
                            lg="8"
                          >
                            {!applied && (
                                
                                <Button
                                color="primary"
                                type="button"
                                onClick={() => {
                                  setModalPostul(!modalPostul);
                                }}
                                //size="sm"
                              >
                                Postuler
                              </Button>                              
                            )}
                            {applied && (
                                <Button
                                  color="secondary"
                                  type="button"
                                  size="sm"
                                 // href={`/interview/${job?.interview?._id}`}
                                 disabled
                                  //target="blank"
                                >
                                  Vous avez déjà postulé à cette offre
                                </Button>
                            )}


                            {user.role == "candidat" && (
                              <Modal
                                toggle={() => setModalPostul(!modalPostul)}
                                isOpen={modalPostul}
                                size="lg"
                              >
                                <div className=" modal-header">
                                  <h4
                                    className=" modal-title"
                                    id="exampleModalLabel"
                                  >
                                    {job.title}
                                  </h4>
                                  <button
                                    aria-label="Close"
                                    className=" close"
                                    type="button"
                                    onClick={() => setModalPostul(!modalPostul)}
                                  >
                                    <span aria-hidden={true}>×</span>
                                  </button>
                                </div>
                                <ModalBody>
                                  <Form>
                                    <Row>
                                      <Col lg="6">
                                        <FormGroup>
                                          <label
                                            className="form-control-label"
                                            htmlFor="input-username"
                                          >
                                            Nom
                                          </label>
                                          <Input
                                            className="form-control-alternative"
                                            value={user.name}
                                            id="input-username"
                                            placeholder="Username"
                                            // onChange={(e) => setName(e.target.value)}
                                            type="text"
                                            readOnly
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col lg="6">
                                        <FormGroup>
                                          <label
                                            className="form-control-label"
                                            htmlFor="input-username"
                                          >
                                            Prénom
                                          </label>
                                          <Input
                                            className="form-control-alternative"
                                            value={user.lastName}
                                            id="input-username"
                                            placeholder="Username"
                                            // onChange={(e) => setLastName(e.target.value)}
                                            type="text"
                                            readOnly
                                          />
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg="6">
                                        <FormGroup>
                                          <label
                                            className="form-control-label"
                                            htmlFor="input-email"
                                          >
                                            Email
                                          </label>
                                          <Input
                                            className="form-control-alternative"
                                            id="input-email"
                                            value={user.email}
                                            // onChange={(e) => setAdress(e.target.value)}
                                            type="email"
                                            readOnly
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col lg="6">
                                        <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-email"
                                          >
                                            CV du candidat
                                          </label>
                                          <Input
                                          className="form-control-alternative"
                                          type="file"
                                          accept=".pdf"
                                          onChange={(e) =>
                                            setCV(e.target.files[0])
                                          }
                                          CVinput
                                        />
                                          {/* </div> */}
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    <Row>
                                      
                                    </Row>
                                  </Form>
                                </ModalBody>
                                <ModalFooter>
                                  <Button
                                    color="secondary"
                                    type="button"
                                    onClick={() => setModalPostul(!modalPostul)}
                                    size="sm"
                                  >
                                    Annuler
                                  </Button>
                                  <Button
                                    color="primary"
                                    type="button"
                                    size="sm"
                                    onClick={() => {
                                      participate();
                                      //setModalPostul(!modalPostul);
                                    }}
                                  >
                                    Suivant 
                                  </Button>
                                </ModalFooter>
                              </Modal>
                            )}
                            {user.role == "none" && (
                              <Modal
                                toggle={() => {
                                  setModalPostul(!modalPostul);
                                  setLoginPostul(false);
                                }}
                                isOpen={modalPostul}
                                size="lg"
                              >
                                <div className=" modal-header">
                                  <h4
                                    className=" modal-title"
                                    id="exampleModalLabel"
                                  >
                                    {job.title}
                                  </h4>
                                  <button
                                    aria-label="Close"
                                    className=" close"
                                    type="button"
                                    onClick={() => {
                                      setModalPostul(!modalPostul);
                                      setLoginPostul(false);
                                    }}
                                  >
                                    <span aria-hidden={true}>×</span>
                                  </button>
                                </div>
                                <ModalBody>
                                  {!loginPostul && (
                                    <Row>
                                      <Col lg="12">
                                        <h4 className="text-center">
                                          {" "}
                                          Vous devez être connecté pour pouvoir
                                          postuler !
                                        </h4>
                                      </Col>
                                    </Row>
                                  )}
                                  {loginPostul && (
                                    <LoginPostul id={jobId} redirect={"job"} />
                                  )}
                                </ModalBody>
                                {!loginPostul && (
                                  <ModalFooter>
                                    <Col lg="6">
                                      <Button
                                       
                                       className="btn-secondary"
                                        onClick={() => setLoginPostul(true)}
                                      >
                                        vous avez déjà un compte,
                                        connectez-vous!
                                      </Button>
                                    </Col>
                                    <Col lg="6">
                                      <Button
                                        color="primary"
                                        
                                        to="/home/register"
                                        tag={Link}
                                      >
                                        Créez un nouveau compte!
                                      </Button>
                                    </Col>
                                  </ModalFooter>
                                )}
                              </Modal>
                            )}
                          </td>
                        </>
                      )}
                    </tr>
                  </tbody>
                </Table>
              </CardHeader>
              <CardBody
                className={openTab === 1 ? "block" : "hidden"}
                id="link1"
              >
                <Form>
                  <h4 className=" mb-4">Description de l'emploi</h4>
                  <div className="pl-lg-4">
                    <Row>
                      <div className="text-sm">{job.description}</div>
                    </Row>
                    {/*<Row>*/}
                    {/*    <Col lg="6">*/}
                    {/*        <FormGroup>*/}
                    {/*            <label*/}
                    {/*                className="form-control-label"*/}
                    {/*                htmlFor="input-first-name"*/}
                    {/*            >*/}
                    {/*                First name*/}
                    {/*            </label>*/}
                    {/*            <Input*/}
                    {/*                className="form-control-alternative"*/}
                    {/*                defaultValue="Lucky"*/}
                    {/*                id="input-first-name"*/}
                    {/*                placeholder="First name"*/}
                    {/*                type="text"*/}
                    {/*            />*/}
                    {/*        </FormGroup>*/}
                    {/*    </Col>*/}
                    {/*    <Col lg="6">*/}
                    {/*        <FormGroup>*/}
                    {/*            <label*/}
                    {/*                className="form-control-label"*/}
                    {/*                htmlFor="input-last-name"*/}
                    {/*            >*/}
                    {/*                Last name*/}
                    {/*            </label>*/}
                    {/*            <Input*/}
                    {/*                className="form-control-alternative"*/}
                    {/*                defaultValue="Jesse"*/}
                    {/*                id="input-last-name"*/}
                    {/*                placeholder="Last name"*/}
                    {/*                type="text"*/}
                    {/*            />*/}
                    {/*        </FormGroup>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                  </div>
                  <hr className="my-4" />
                  {/* Address */}
                  <h4 className=" mb-4">Données supplémentaires</h4>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <div
                          className="h5 mt-4 col-12 "
                          style={{ display: "inline-flex" }}
                        >
                          Technologies/Compétences: {job.technologies}
                        </div>
                      </Col>
                      <Col lg="6">
                        <div
                          className="h5 mt-4 col-12 "
                          style={{ display: "inline-flex" }}
                        >
                          Langues: {job.language}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div
                          className="h5 mt-4 col-12 "
                          style={{ display: "inline-flex" }}
                        >
                          Rémunération: {job.pay}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {user.role == "employer" && user._id == job.userID && (
                    <Col className="text-right ml-4" xs="4">
                      <Button
                        color="secondary"
                        style={{ float: "left" }}
                        type="button"
                        onClick={() => {
                          setModalOpen(!modalOpen);
                        }}
                        size="sm"
                      >
                        Supprimer
                      </Button>
                      <Modal
                        toggle={() => setModalOpen(!modalOpen)}
                        isOpen={modalOpen}
                      >
                        <div className=" modal-header">
                          <h4 className=" modal-title" id="exampleModalLabel">
                            Êtes-vous sûr de vouloir supprimer cet offre ?
                          </h4>
                          <button
                            aria-label="Close"
                            className=" close"
                            type="button"
                            onClick={() => setModalOpen(!modalOpen)}
                          >
                            <span aria-hidden={true}>×</span>
                          </button>
                        </div>

                        <ModalFooter>
                          <Button
                            color="secondary"
                            type="button"
                            onClick={() => setModalOpen(!modalOpen)}
                          >
                            ANNULER
                          </Button>
                          <Button
                            color="danger"
                            type="button"
                            onClick={() => {
                              deleteJob();
                              setModalOpen(!modalOpen);
                            }}
                          >
                            SUPPRIMER
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </Col>
                  )}
                  {/* Description */}
                  {/* <h6 className="heading-small text-muted mb-4">
                     About me (placeholder)
                   </h6>
                   <div className="pl-lg-4">
                     <FormGroup>
                       <label>About Me</label>
                       <Input
                         className="form-control-alternative"
                         placeholder="A few words about you ..."
                         rows="4"
                         defaultValue="A beautiful Dashboard for Bootstrap 4. It is Free and
                           Open Source."
                         type="textarea"
                         disabled
                       />
                     </FormGroup>
                   </div> */}
                </Form>
              </CardBody>
              <CardBody
                className={openTab === 2 ? "block" : "hidden"}
                id="link2"
              >
                <h4 className=" mb-4">Questions de l'offre</h4>
                <div className="pl-lg-4">
                  <Row>
                    <Questions jobId={jobId} />
                  </Row>
                </div>
              </CardBody>
              <CardBody
                className={openTab === 3 ? "block" : "hidden"}
                id="link2"
              >
                <h4 className=" mb-1">Candidats ayant postulé</h4>
                <div className="pl-lg-4">
                  <Row>
                    <JobCandidats participants={job} completed={true} />
                  </Row>
                </div>
              </CardBody>
              <CardBody
                className={openTab === 4 ? "block" : "hidden"}
                id="link2"
              >
                <h4 className=" mb-1">Candidats ayant postulé</h4>
                <div className="pl-lg-4">
                  <Row>
                    <JobCandidats participants={job}  />
                  </Row>
                </div>
                {/* <h4 className=" mb-4">Modifier l'offre</h4>
                <div className="pl-lg-4">
                  <Row>
                    <UpdateForm jobId={jobId} />
                  </Row>
                </div> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CandidatOneJob;
