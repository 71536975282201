import OutFrCandidateProfileComp from 'components/candidat/OutFrCandidateProfileComp'
import SharedProfileCandidateComp from 'components/candidat/SharefProfileCandidateComp'
import PageSpace from 'components/styles/pageSpace'
import HomepageLayout from 'layouts/HomepageLayout'
import React from 'react'

const OutFrSharedProfileV1 = ({match}) => {
    const {id} = match.params
  return (
    <>
    <HomepageLayout />
    <PageSpace />
    {/* <OutFrCandidateProfileComp id={id} /> */}
    <SharedProfileCandidateComp id={id} isInterviewNotShown />
    </>
  )
}

export default OutFrSharedProfileV1