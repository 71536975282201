import {
  ViewListIcon,
  ArrowCircleDownIcon,
  PencilAltIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/outline";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import UserManagementActionIntroduction from "components/employer/userManagementActionIntroduction";

import { Row, Button, Col, Card, CardImg, FormGroup, Input, InputGroup, InputGroupAddon, CardHeader, Table, CardBody, Form, Label } from "reactstrap";

import "../custom.css";
import config from "config";

// import 'assets/css/react_pdf_css.css';

// import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

// pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;




const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});
function DisplayUserManagementAction(props) {
  const history = useHistory();



  const [userManagementAction, setUserManagementAction] = useState({});
  const [createdAt, setCreatedAt] = useState("");



const getAction = () => {
    instance.get(`userManagementActions/getActionByID/${props.actionID}`).then((resp) => {
      if (resp.data !== null) {
            // console.log("8888888888888888888888: geNote: step 2:");
            // console.log("8888888888888888888888: geNote: step 3 : resp: ", resp);
            // console.log("8888888888888888888888: geNote: step 4 : resp.data: ", resp.data);
            setUserManagementAction(resp.data);
            if (resp.data.createdAt)
            {
              setCreatedAt(resp.data.createdAt);

            }
            
          
        
      }
    });
};





   useEffect(() => {
    getAction();

  
  }, []);






  return (
    <>
      <Row key={props.actionID}>
        <Col lg="12">
          <FormGroup row>
          {userManagementAction && 
            <>

              <UserManagementActionIntroduction
                recruiterUserID={userManagementAction.recruiterUserID}
              />
              <br />
              {userManagementAction && userManagementAction.actionType === "Update User Pipeline" &&
                <Input
                  type="textarea"
                  rows="1"
                  readOnly
                  value={`${userManagementAction.actionType} : de ${userManagementAction.oldPipeline} à ${userManagementAction.newPipeline}` || ""}
                >
                </Input>
              }
              {userManagementAction && userManagementAction.actionType === "Update job Application Pipeline" &&
                <Input
                  type="textarea"
                  rows="1"
                  readOnly
                  value={`Changement de pipeline d'une candidature: de ${userManagementAction.oldPipeline} à ${userManagementAction.newPipeline}` || ""}
                >
                </Input>
              }
              



              {/*
                <Label>
                  {jobApplicationNote.note}
                </Label>
            */}

              <br />
              <Label>
                  {createdAt.slice(0, 10)}
              </Label>
            </>
            }

          <hr className="my-4" />

          </FormGroup>
        </Col>
      </Row>
    </>
  );
}

export default DisplayUserManagementAction;
