import {
  ViewListIcon,
  ArrowCircleDownIcon,
  PencilAltIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/outline";
import JobApplicationNote from "components/employer/jobApplicationNote";
import SendEmailInvitForOneJobApp from "components/employer/sendEmailInvitForOneJobApp";
import DisplayUserManagementAction from "components/employer/displayUserManagementAction";

import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { 
  Container, 
  Button, 
  Col, 
  Card, 
  CardBody, 
  Input,
} from "reactstrap";
import Select from "react-select";
import { Grid, TextField } from "@mui/material";

import "../custom.css";
import Row from "reactstrap/lib/Row";
import config from "config";
import { useTranslation } from "react-i18next";


const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});
function UserManagementActions(props) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  // console.log("11111111111111111111111111111: ShowApplicationEmployerV4");
  // console.log("11111111111111111111111111111: ShowApplicationEmployerV4: candidatId: ", candidatId);
  

  const [candidateUserActions, setCandidateUserActions] = useState([]);

  
  
  const user = JSON.parse(localStorage.getItem("user"));




const fetchUserManagementActions = async () => {
    console.log("333333333333333333: jobAppNotes v5: step 0: ");
  
  instance.get(`/userManagementActions/getActionsByUserID/${props.candidateUserID}`).then((resp) => {
    if ( resp.data && resp.data.length && resp.data.length !== null && resp.data.length !== 0  && resp.data[0]._id && resp.data[0]._id !== null) {
      // console.log("333333333333333333: jobAppNotes v5: step 1: resp.data.length:", resp.data.length);
      setCandidateUserActions(resp.data);
    }
    
    // console.log("333333333333333333: jobAppNotes: step 2:");
    // console.log("333333333333333333: jobAppNotes: step 3 : resp: ", resp);
    // console.log("333333333333333333: jobAppNotes: step 4 : resp.data: ", resp.data);
    });
};




   useEffect(() => {
    fetchUserManagementActions();
  }, []);



  return (
    <>

        <Row>
          <Col className="order-xl-1">
            <Card className="bg-secondary shadow">
              <CardBody>
                <h4 className="mt-4 mb-4">{t('NoteKEY5')}:</h4>

                <Row>
          <Col className="order-xl-1">
            <Card className="bg-secondary shadow">
              <CardBody>
                <h4 className="mt-4 mb-4">{t('Remarks')}:</h4>


                {candidateUserActions && candidateUserActions.length !== 0 && (
                <>
                    {candidateUserActions
                    .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
                    .map((candidateUserActionItem) => (
          

                        <DisplayUserManagementAction
                          actionID = {candidateUserActionItem._id}
                          key={candidateUserActionItem._id}
                        />
                      
 
                    ))}
                </>
                )}

              </CardBody>
            </Card>
          </Col>
        </Row>

              </CardBody>
            </Card>
          </Col>
        </Row>
     
    </>
  );
}

export default UserManagementActions;
