import axios from "axios";
import Questions from "components/recruteur/Questions";
import UpdateForm from "components/UpdateForm";
import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  PencilAltIcon,
  LocationMarkerIcon,
  BriefcaseIcon,
  MailIcon,
  PhoneIcon,
  LinkIcon,
  CodeIcon,
  ArchiveIcon,
  OfficeBuildingIcon,
  PaperClipIcon,
  AcademicCapIcon,
  UserIcon,
  LightBulbIcon,
} from "@heroicons/react/solid";
import {
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormText,
  FormFeedback,
  CardText,
} from "reactstrap";
import config from "config";



import { Box } from "@mui/material";
import CandidateLayoutV3 from "layouts/CandidateLayoutV3";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});
function InFranceCandidateHomeViewV4() {




  return (
    <>
      <CandidateLayoutV3 />

      <Box sx={
        { 
          ml: 32,
          p: "30px" 
        }
        }>
        
       
        <Container fluid>
          <Row className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"></Row>
          <Row>
          <Col>
            <div
              className="homePageStyle"
            style={{
                  //backgroundColor: "#f7fafc",
                  //objectFit: "cover",
                  justifyContent:'center',
                  alignItems:'center',
                  width: "100%",
                  height: "75%",
                  paddingRight: 0,
                  paddingLeft: 0,
              }}
            
            
            >
              <h2>Trouvez le job qui vous convient</h2>           
            </div>
            </Col>
            </Row>

            <Row className="align-items-center "
                  
                  style={{
                    //width: "",
                    justifyContent:'center',
                    alignItems:'center',
                  }}

                >
                  <Button
                    className="mx-auto"
                    //color="danger"
                    color="primary"
                    type="button"
                    to={"/infrancecandidate/jobs"}
                    tag={Link}
                  >
                    Voir toutes les offres
                  </Button>
                </Row>




        
        </Container>

      </Box> 

    </>
  );
}

export default InFranceCandidateHomeViewV4;
