import axios from "axios";
import { 
  getCompanyUsersForTraningsTracking,
 } from "network/ApiAxios";

import EmployerLayout from "layouts/EmployerLayout";
import React, { useEffect, useState } from "react";

import { Box } from "@mui/material";
import JobApplicationsEmployerV3 from "components/employer/jobApplicationsEmployerV3";
// import JobApplicationsCardsEmployerV2 from "components/employer/jobApplicationsCardsEmployerV2";
import JobApplicationsCardsEmployerV3 from "components/employer/jobApplicationsCardsEmployerV3";
import JobApplicationCardEmployer from "components/employer/jobApplicationCardEmployer";
import JobApplicationOneCardEmployerV2 from "components/employer/jobApplicationOneCardEmployerV2";
import PageSpace from "components/styles/pageSpace";
import config from "config";
import {
  DropdownMenu,
  Media,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormText,
  FormFeedback,
  CardText,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Table,
} from "reactstrap";
import Select from "react-select";



function RecruiterTrainingsTrackingView () {
  // let jobs_length = config.Jobs_for_outfrSearchPermanentFr.length;
  const [jobID, setJobID] = useState(config.Default_Job_for_employer_profiles);

  const [jobApplications, setJobApplications] = useState([]);
  const [candidateUserID, setCandidateUserID] = useState("");
  const [employeesForSelectCandidates, setEmployeesForSelectCandidates] = useState([]);
  

  var user;
  if (localStorage.getItem("user")) {
  user = JSON.parse(localStorage.getItem("user"));
   } else {
   user = { role: "none" };
   };

   const instance = axios.create({
    baseURL: config.WS_BASE_URL,
  });
  
  instance.interceptors.request.use(async (config) => {
    const token = localStorage.getItem("token");
    config.headers.Authorization = token ? token : "";
    config.headers['Content-Type'] = "application/json";
    return config;
  });

  const fetchEmployees = async () => {
    console.log("11111111111111: recruiterTrainingsTrackingView: fetchEmployees: ");
    // console.log("11111111111111: recruiterTrainingsTrackingView: user: ", user);
    // console.log("11111111111111: recruiterTrainingsTrackingView: user.employerCampanyID: ", user.employerCampanyID);
    if ((user.employerCampanyID !== null) && (user.employerCampanyID !== ""))
    {
      const response = await getCompanyUsersForTraningsTracking(user.employerCampanyID);
      const { data } = response;
      // console.log("11111111111111: recruiterTrainingsTrackingView: ");
      // console.log("11111111111111: recruiterTrainingsTrackingView: data: ", data);
      if (data && data.length && data.length !== null && data.length !== 0) {
        console.log("11111111111111: recruiterTrainingsTrackingView: data: ", data);
        let newArray;
        newArray = data;
        setEmployeesForSelectCandidates(newArray.map(item => ({value: item._id, label: item.email})));
  

        console.log("11111111111111: recruiterTrainingsTrackingView: employeesForSelectCandidates: ", employeesForSelectCandidates);
      }

    }
    
  };


  const fetchJobApplicationsData = () => {
    setJobApplications([]);
    instance.get(`trainingsTracking/getUserTrainingsTracking/${candidateUserID}`).then((resp) => {
      console.log("yyyyyyyyyyyyyyyyyyyyyyyyyy:, fetchJobApplicationsData: resp: ", resp);
      if (candidateUserID && candidateUserID !== "" && resp.data !== null && resp.data.length !== 0)
      {
        console.log("yyyyyyyyyyyyyyyyyyyyyyyyyy:, fetchJobApplicationsData: resp.data: ", resp.data);
        console.log("yyyyyyyyyyyyyyyyyyyyyyyyyy:, fetchJobApplicationsData: resp.data.length: ", (resp.data).length);
        var JobApplicationsArray = [];
        // var Response = resp.data;
        for (var i = 0; i < (resp.data).length; i++) {
          JobApplicationsArray.push(resp.data[i]);
        }
        setJobApplications(JobApplicationsArray);

      }


    });

  };
  const fetchJobApplicationsV2 = () => {
    setJobApplications([]);
    instance.get(`jobApplication/getJobApplicationsByUserID/${candidateUserID}`).then((resp) => {
      console.log("yyyyyyyyyyyyyyyyyyyyyyyyyy:, fetchJobApplicationsData: resp: ", resp);
      if (candidateUserID && candidateUserID !== "" && resp.data !== null && resp.data.length !== 0)
      {
        console.log("yyyyyyyyyyyyyyyyyyyyyyyyyy:, fetchJobApplicationsData: resp.data: ", resp.data);
        console.log("yyyyyyyyyyyyyyyyyyyyyyyyyy:, fetchJobApplicationsData: resp.data.length: ", (resp.data).length);
        var JobApplicationsArray = [];
        // var Response = resp.data;
        for (var i = 0; i < (resp.data).length; i++) {
          JobApplicationsArray.push(resp.data[i]);
        }
        setJobApplications(JobApplicationsArray);

      }


    });

  };



  useEffect(() => {
    // getProfileTypesFunction();
    // getAccessRuleFunction();
    fetchEmployees();
    // fetchJobApplicationsData();
    fetchJobApplicationsV2();

  }, [candidateUserID]);
  

  return (
    <>
      <EmployerLayout />
      {/*
      <JobApplicationsCardsEmployer key="627d34016543d2ab235e8126" jobID="627d34016543d2ab235e8126" />
       */}
        <Container>
        <Row>
          <Col lg="2">
          </Col>
          <Col lg="10">
            <Row className="justify-content-center border-0 py-2" style={{width:"100%" }}>
              <label>Merci de choisir le collaborateur</label>
            </Row>
            <Row>
            <Col lg="1">
            </Col>
            <Col lg="10">
            <Select 
                className="w-full"
                // lg="10"
                // options={config.CandidatesUserIds_for_training}
                options={employeesForSelectCandidates}
                onChange={(e) => { 
                  setCandidateUserID(e.value) 
                }} 
    
              />
            </Col>
            <Col lg="1">
            </Col>

            </Row>
            <PageSpace />
            {(candidateUserID !== "" && candidateUserID !== null && jobApplications.length !== 0) && (
              <>
                { /*console.log("00000000000000000 jobApplications: ", jobApplications) */}
                { /* console.log("00000000000000000 jobApplications.length: ", jobApplications.length) */}
                {jobApplications && jobApplications.map((jobApplicationItem) => (
                    <>
                      { console.log("00000000000000000 jobApplicationItem: ", jobApplicationItem) }
                        <Row
                        className="mb-3 flex w-full hover:black hover:border hover:border-black cursor-pointer duration-75 hover:z-20 bg-white shadow-lg rounded-sm"
                        // key={participant._id}
                        // key={`"${jobApplicationItem._id}_Row"`}

                        >


                            <Col lg="8">
                              <JobApplicationOneCardEmployerV2
                                // key={jobApplicationItem._id}
                                job_Application_ID={jobApplicationItem._id}
                              />
      

                            </Col>
                            <Col lg="4" className="flex items-center">
                              <Container>
                              <Row>
                                <Col lg="12" className="mt-4">
                                <div
                              className="text-sm"
                              style={{ display: "inline-flex" }}
                            >
                                

                                <Button
                                  color="primary"
                                  size="sm"
                                  target="_blank"
                                  href={`/jobApplication/oneJobApplication/${jobApplicationItem?._id}`}
                                >
                                  Ouvrir à côté
                                </Button>
                                </div>
                                </Col>
                            </Row>

                              </Container>


                            </Col>
                        </Row>
                    </>
                  ))
                }
              </> 
            )
            }
          </Col>
        </Row>

      </Container>
       
      

      

      
    </>
  );
}

export default RecruiterTrainingsTrackingView;