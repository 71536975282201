import axios from "axios";
import React, { useEffect, useState } from "react";
// import ShowCandidat from "components/recruteur/ShowCandidat";
import ShowApplicationEmployerV4 from "components/employer/showApplicationEmployerV4";
//import OneInterview from "components/recruteur/OneInterview";
import { useHistory, useLocation, Link } from "react-router-dom";
import qs from "query-string";
//import ShowCandidat from "./ShowCandidat";
import {
  PencilAltIcon,
  LocationMarkerIcon,
  BriefcaseIcon,
  MailIcon,
  PhoneIcon,
  LinkIcon,
  CodeIcon,
  ArchiveIcon,
  OfficeBuildingIcon,
  PaperClipIcon,
  AcademicCapIcon,
  UserIcon,
  LightBulbIcon,
} from "@heroicons/react/solid";
import { SearchIcon } from "@heroicons/react/outline";
import {
  DropdownMenu,
  Media,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormText,
  FormFeedback,
  CardText,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Table,
} from "reactstrap";
//import {getAll} from "../../network/ApiAxios";
import config from "config";
import { set } from "react-hook-form";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});
function JobApplicationsEmployerV3({ jobID }) {
  const [candidats, setCandidats] = useState([]);
  
  const [users, setUsers] = useState([]);
  const [participants, setParticipants] = useState();
  const [job, setJob] = useState({});
  const [jobApplications, setJobApplications] = useState();
  const history = useHistory();
  const location = useLocation();
  const query = qs.parse(location.search);
  const [search, setSearch] = useState("");
  const [candidat, setCandidat] = useState("");
  const [checkbox, setCheckbox] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalDelOpen, setModalDelOpen] = useState(false);
  const [candidatID, setCandidatID] = useState("");
  const [participantId, setParticipantId] = useState("");
  const [candidatModal, setCandidatModal] = useState();
  const [check, setCheck] = useState(1);

  const [createdAt, setCreatedAt] = useState("");
  


  const removeCandidat = () => {
    instance
      .delete(`jobs/candidat/${candidatID}/${participantId}`)
      .then((res) => {
        getJob(participants._id);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getJob = () => {
    instance.get(`jobs/${jobID}`).then((resp) => {
      // setParticipants(resp.data.job);
      // console.log("00000000000000000000000000 JobApplicationsEmployerV3 getJob: resp.data : ", resp.data);
      setJob(resp.data.job);
    });
  };

  const getJobApplications = () => {
    // console.log("1111111111111111111111111111 JobApplicationsEmployerV3");
    // console.log("1111111111111111111111111111 JobApplicationsEmployerV3 jobID", jobID);
    instance.get(`jobApplication/getJobApplicationsByJobID/${jobID}`).then((resp) => {
      // console.log("getJobApplication: ", resp);
      // setCreatedAt(resp.data.createdAt);
      // console.log("1111111111111111111111111111 JobApplicationsEmployerV3: createdAt", createdAt);
      setJobApplications(resp.data);
      // console.log("1111111111111111111111111111 JobApplicationsEmployerV3: resp : ", resp);
      // console.log("1111111111111111111111111111 JobApplicationsEmployerV3: resp.data : ", resp.data);
      
    });
  };

  useEffect(() => {
    getJobApplications();
    getJob();
  }, [jobID]);

  return (
    <>
      <Col lg="4" className="mt-3">
        <FormGroup check>
          <Label check>
            <Input type="radio"  onChange={() => setCheck(1)} value={1} checked={check===1? true:false} />{" "}
            En cours
          </Label>
        </FormGroup>
      </Col>
      <Col lg="4" className="mt-3">
        <FormGroup check>
          <Label check>
            <Input type="radio"  onChange={() => setCheck(2)} value={2} checked={check===2? true:false} />{" "}
            Validé
          </Label>
        </FormGroup>
      </Col>
      <Col lg="4" className="mt-3">
        <FormGroup check>
          <Label check>
            <Input type="radio"  onChange={() => setCheck(3)} value={3} checked={check===3? true:false}/>{" "}
            Rejeté
          </Label>
        </FormGroup>
      </Col>
      <div
        className="flex items-center my-3 px-2 py-2 border-2 mx-auto rounded-full border-gray-400 max-w-md"
        style={{ float: "right" }}
      >
        <input
          type="text"
          className="bg-transparent flex-grow text-black px-2"
          placeholder="Search..."
          onChange={(e) => setSearch(e.target.value)}
        />
        <SearchIcon className="h-6 text-blue-500" />
      </div>

      <Card className="bg-transparent w-full">
        <Row>
          {/* console.log("00000000000000000 JobApplicationsEmployerV3 participants: ", jobApplications) */}
          {jobApplications
            ?.filter((participant) => {
             
              if (search === "") {
                return participant;
              } else if (
                participant.candidat.name
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                participant.candidat.lastName
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                participant.candidat.email
                  .toLowerCase()
                  .includes(search.toLowerCase())
              ) {
                return participant;
              }
            })
            .map((participant) =>
              check === 1 ? (
                <>
                  {/* console.log("000000000000000000") */}
                  {/* console.log("111111111111111111", participant) */}
                  {/* console.log("111111111111111111 bbbbbbbbbbbbb", participant._id) */}
                  {/* console.log("222222222222222222", participant.interviewCandidat) */}
                  {/* console.log("33333333333333333333", participant?.interviewCandidat?._id) */}
                  {/* console.log("44444444444444444444", participant?.interviewCandidat?.createdAt) */}
                  {/* console.log("11111111111111111111: participant.interviewCandidat._id : ", participant?.interviewCandidat?._id) */}
                  {/* getJobApplication(participant?.interviewCandidat?._id) */}
                  {/* console.log("999999999999999999", createdAt) */}
                  {participant && participant.pipeline !== "Validé" && participant.pipeline !== "Rejeté" &&
                    <Row
                      className="  mb-3 flex w-full hover:black hover:border hover:border-black cursor-pointer duration-75 hover:z-20 bg-white shadow-lg rounded-sm"
                      key={participant._id}
                      onClick={() => {
                        {/*
                          setModalOpen(!modalOpen);
                          console.log("55555555555555555555555555 jobApplicationsEmployerV3 bouton voir la candidature");
                          console.log("55555555555555555555555555 jobApplicationsEmployerV3: bouton voir la candidature participant", participant);

                          setCandidatModal(participant);
                          history.push(
                            // `/admin/tab2?jobId=${participants._id}&candidat=${participant?.interviewCandidat?._id}`
                            `/employer/${jobID}?jobId=${jobID}&candidat=${participant._id}`
                            // `/jobApplication/oneJobApplication/candidat=${participant._id}`
                          );
                        */}

                      }}
                    >
                      <Col lg="2">
                        <img
                          src={`${config.Data_URL}${participant.userID.image}`}
                          className="rounded-circle"
                          onError={(e) => {
                            e.target.src =
                              require("assets/img/brand/profile-icon-3.png").default;
                          }}
                          style={{
                            width: "100%",
                            height: "100%",

                            //objectFit: "cover",
                          }}
                          //alt="Responsive image"
                        />
                      </Col>
                      <Col lg="10">
                        <Col lg="12" className="mb-2">
                          <div>
                            <span className="card-t">
                              {participant.prenom}{" "}
                              {participant.nom}{" "}
                            </span>
                            <button
                              aria-label="Close"
                              className=" close"
                              type="button"
                              onClick={() => {
                                setCandidatID(
                                  participant?._id
                                );
                                setParticipantId(participant._id);
                                setModalDelOpen(!modalDelOpen);
                              }}
                            >
                              <span aria-hidden={true}>×</span>
                            </button>
                          </div>
                        </Col>
                        <Row>
                          <Col lg="8">
                            <Col lg="12">
                              <div
                                className="text-sm"
                                style={{ display: "inline-flex" }}
                              >
                                {" "}
                                <MailIcon
                                  className=""
                                  style={{ height: "1.2rem" }}
                                />{" "}
                                &nbsp; <span>{participant.email}</span>{" "}
                              </div>
                            </Col>
                            <Col lg="12">
                              <div
                                className="text-sm"
                                style={{ display: "inline-flex" }}
                              >
                                {" "}
                                <LightBulbIcon
                                  className=""
                                  style={{ height: "1.2rem" }}
                                />{" "}
                                &nbsp;{" "}
                                <span>
                                  {" "}
                                  Date:{participant.createdAt.slice(0, 10)}
                                </span>{" "}
                              </div>
                            </Col>
                            <Col lg="12">
                              <div
                                className="text-sm"
                                style={{ display: "inline-flex" }}
                              >
                                {" "}
                                <LightBulbIcon
                                  className=""
                                  style={{ height: "1.2rem" }}
                                />{" "}
                                &nbsp;{" "}
                                <span>
                                  {" "}
                                  {/* console.log("55555555555555555555555555 jobApplicationsEmployerV3") */}
                                  {/* console.log("55555555555555555555555555 jobApplicationsEmployerV3: participant?.interviewCandidat", participant?.interviewCandidat?.videos?.length) */}
                                  Nombre de vidéo: {participant?.videos?.length}
                                </span>{" "}
                              </div>
                            </Col>

                          </Col>
                          <Col lg="4" className=" flex items-center">
                            <div>
                              <Button
                                color="primary"
                                size="sm"
                                target="_blank"
                                href={`/jobApplication/oneJobApplication/${participant._id}`}

                                // href={`http://localhost:5100/${participant.CV}`}
                                //   target="blank"
                              >
                                Voir la Candidature
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  }
                </>
              ) : check === 2  ? (
                <>
                  {participant && participant.pipeline === "Validé" &&

                  <Row
                    className="  mb-3 flex w-full hover:black hover:border hover:border-black cursor-pointer duration-75 hover:z-20 bg-white shadow-lg rounded-sm"
                    key={participant._id}
                    onClick={() => {
                      {/*
                        setModalOpen(!modalOpen);
                        setCandidatModal(participant);
                        history.push(
                          // `/admin/tab2?jobId=${participants._id}&candidat=${participant?.interviewCandidat?._id}`
                          `/employer/${jobID}?jobId=${jobID}&candidat=${participant._id}`
                        );
                      */}

                    }}
                  >
                    <Col lg="2">
                      <img
                        src={`${config.Data_URL}${participant.userID.image}`}
                        className="rounded-circle"
                        onError={(e) => {
                          e.target.src =
                            require("assets/img/brand/profile-icon-3.png").default;
                        }}
                        style={{
                          width: "100%",
                          height: "100%",

                          //objectFit: "cover",
                        }}
                        //alt="Responsive image"
                      />
                    </Col>
                    <Col lg="10">
                      <Col lg="12" className="mb-2">
                        <div>
                          <span className="card-t">
                            {participant.prenom}{" "}
                            {participant.nom}
                          </span>
                          <button
                            aria-label="Close"
                            className=" close"
                            type="button"
                            onClick={() => {
                              setCandidatID(
                                participant?._id
                              );
                              setParticipantId(participant._id);
                              setModalDelOpen(!modalDelOpen);
                            }}
                          >
                            <span aria-hidden={true}>×</span>
                          </button>
                        </div>
                      </Col>
                      <Row>
                        <Col lg="8">
                          <Col lg="12">
                            <div
                              className="text-sm"
                              style={{ display: "inline-flex" }}
                            >
                              {" "}
                              <MailIcon
                                className=""
                                style={{ height: "1.2rem" }}
                              />{" "}
                              &nbsp; <span>{participant.email}</span>{" "}
                            </div>
                          </Col>


                          <Col lg="12">
                            <div
                              className="text-sm"
                              style={{ display: "inline-flex" }}
                            >
                              {" "}
                              <LightBulbIcon
                                className=""
                                style={{ height: "1.2rem" }}
                              />{" "}
                              &nbsp;{" "}
                              <span>
                                {" "}
                                Date:{participant.createdAt.slice(0, 10)}
                              </span>{" "}
                            </div>
                          </Col>
                          <Col lg="12">
                            <div
                              className="text-sm"
                              style={{ display: "inline-flex" }}
                            >
                              {" "}
                              <LightBulbIcon
                                className=""
                                style={{ height: "1.2rem" }}
                              />{" "}
                              &nbsp;{" "}
                              <span>
                                {" "}
                                {/* console.log("55555555555555555555555555 jobApplicationsEmployerV3") */}
                                {/* console.log("55555555555555555555555555 jobApplicationsEmployerV3: participant?.interviewCandidat", participant?.interviewCandidat?.videos?.length) */}
                                Nombre de vidéo: {participant?.videos?.length}
                              </span>{" "}
                            </div>
                          </Col>
                        </Col>
                        <Col lg="4" className=" flex items-center">
                          <div>
                            <Button
                              color="primary"
                              size="sm"
                              target="_blank"
                              href={`/jobApplication/oneJobApplication/${participant._id}`}

                              // href={`http://localhost:5100/${participant.CV}`}
                              //   target="blank"
                            >
                              Voir la Candidature
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  }

                </>

              ) :(
                <>
                  {participant && participant.pipeline === "Rejeté" &&

                    <Row
                      className="  mb-3 flex w-full hover:black hover:border hover:border-black cursor-pointer duration-75 hover:z-20 bg-white shadow-lg rounded-sm"
                      key={participant._id}
                        onClick={() => {
                          {/*
                            setModalOpen(!modalOpen);
                            setCandidatModal(participant);
                            history.push(
                              // `/admin/tab2?jobId=${participants._id}&candidat=${participant?.interviewCandidat?._id}`
                              `/employer/${jobID}?jobId=${jobID}&candidat=${participant._id}`
                            );
                        */}

                      }}

                    >
                      <Col lg="2">
                        <img
                          src={`${config.Data_URL}${participant.userID.image}`}
                          className="rounded-circle"
                          onError={(e) => {
                            e.target.src =
                              require("assets/img/brand/profile-icon-3.png").default;
                          }}
                          style={{
                            width: "100%",
                            height: "100%",

                            //objectFit: "cover",
                          }}
                          //alt="Responsive image"
                        />
                      </Col>
                      <Col lg="10">
                        <Col lg="12" className="mb-2">
                          <div>
                            <span className="card-t">
                              {participant.prenom}{" "}
                              {participant.nom}{" "}
                            </span>
                            <button
                              aria-label="Close"
                              className=" close"
                              type="button"
                              onClick={() => {
                                setCandidatID(
                                  participant?._id
                                );
                                setParticipantId(participant._id);
                                setModalDelOpen(!modalDelOpen);
                              }}
                            >
                              <span aria-hidden={true}>×</span>
                            </button>
                          </div>
                        </Col>
                        <Row>
                          <Col lg="8">
                            <Col lg="12">
                              <div
                                className="text-sm"
                                style={{ display: "inline-flex" }}
                              >
                                {" "}
                                <MailIcon
                                  className=""
                                  style={{ height: "1.2rem" }}
                                />{" "}
                                &nbsp; <span>{participant.email}</span>{" "}
                              </div>
                            </Col>


                            <Col lg="12">
                              <div
                                className="text-sm"
                                style={{ display: "inline-flex" }}
                              >
                                {" "}
                                <LightBulbIcon
                                  className=""
                                  style={{ height: "1.2rem" }}
                                />{" "}
                                &nbsp;{" "}
                                <span>
                                  {" "}
                                  Date:{participant.createdAt.slice(0, 10)}
                                </span>{" "}
                              </div>
                            </Col>
                            <Col lg="12">
                              <div
                                className="text-sm"
                                style={{ display: "inline-flex" }}
                              >
                                {" "}
                                <LightBulbIcon
                                  className=""
                                  style={{ height: "1.2rem" }}
                                />{" "}
                                &nbsp;{" "}
                                <span>
                                  {" "}
                                  {/* console.log("55555555555555555555555555 jobApplicationsEmployerV3") */}
                                  {/* console.log("55555555555555555555555555 jobApplicationsEmployerV3: participant?.interviewCandidat", participant?.interviewCandidat?.videos?.length) */}
                                  Nombre de vidéo: {participant?.videos?.length}
                                </span>{" "}
                              </div>
                            </Col>
                          </Col>
                          <Col lg="4" className=" flex items-center">
                            <div>
                              <Button
                                color="primary"
                                size="sm"
                                target="_blank"
                                href={`/jobApplication/oneJobApplication/${participant._id}`}

                                // href={`http://localhost:5100/${participant.CV}`}
                                //   target="blank"
                              >
                                Voir la Candidature
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  }

                </>
                )
            )}{" "}
        </Row>

        {query.candidat && (
          <Modal
            toggle={() => setModalOpen(!modalOpen)}
            isOpen={modalOpen}
            size="lg"
            style={{ maxWidth: "1100px", width: "80%" }}
          >
            <div className=" modal-header">
              <h4 className=" modal-title" id="exampleModalLabel">
                {job.title}{" "}
              </h4>
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => setModalOpen(!modalOpen)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
              <ShowApplicationEmployerV4
                candidatId={query.candidat}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                type="button"
                onClick={() => setModalOpen(!modalOpen)}
                size="sm"
              >
                Fermer
              </Button>
            </ModalFooter>
          </Modal>
        )}
        <Modal
          toggle={() => setModalDelOpen(!modalDelOpen)}
          isOpen={modalDelOpen}
        >
          {candidatID && (
            <div className=" modal-header">
              <h4 className=" modal-title" id="exampleModalLabel">
                Êtes-vous sûr de vouloir supprimer cette interview ?
              </h4>
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => setModalDelOpen(!modalDelOpen)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
          )}
          {!candidatID && (
            <div className=" modal-header">
              <h4 className=" modal-title" id="exampleModalLabel">
                Ce candidat n'a pas encore passé l'entretien!
              </h4>
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => setModalDelOpen(!modalDelOpen)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
          )}

          <ModalFooter>
            <Button
              color="secondary"
              type="button"
              size="sm"
              onClick={() => setModalDelOpen(!modalDelOpen)}
            >
              ANNULER
            </Button>
            {candidatID && (
              <Button
                color="danger"
                type="button"
                size="sm"
                onClick={() => {
                  removeCandidat();
                  setModalDelOpen(!modalDelOpen);
                }}
              >
                SUPPRIMER
              </Button>
            )}
          </ModalFooter>
        </Modal>
      </Card>
    </>
  );
}

export default JobApplicationsEmployerV3;
