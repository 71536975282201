import axios from "axios";

import EmployerLayout from "layouts/EmployerLayout";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useHistory, useLocation } from "react-router-dom";

import { Box } from "@mui/material";
import OneJobApplicationCardSuperAdminV2 from "components/superAdmin/oneJobApplicationCardSuperAdminV2";
import InFranceOneJobApplicationCardSuperAdmin from "components/superAdmin/inFranceOneJobApplicationCardSuperAdmin";


import qs from "query-string";

import config from "config";
import {
  DropdownMenu,
  Media,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormText,
  FormFeedback,
  CardText,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Table,
} from "reactstrap";
import SuperAdminLayout from "layouts/SuperAdminLayout";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers['Content-Type'] = "application/json";
  return config;
});


function InFranceProfilesV2View() {
  const [jobApplications, setJobApplications] = useState([]);
  var totalJobApplicationsNumber;
  const [pageCount, setPageCount] = useState(0);
  const [perPage] = useState(10);
  const location = useLocation();
  const query = qs.parse(location.search);
  var pageNumber = (query.pageNumber) || 1;

  const history = useHistory();

  instance.get("jobApplication/getAllJobApplicationsNumber").then((resp) => {
    // console.log("resp: ", resp);
    // console.log("0000000000000000000000000: resp.data.cnt", resp.data.cnt);
    // setTotalJobsNumber(resp.data.cnt);
    totalJobApplicationsNumber = resp.data.cnt;
    // console.log("totalJobsNumber: ", totalJobsNumber);
    // console.log("perPage: ", perPage);
    setPageCount(Math.ceil(totalJobApplicationsNumber / perPage));
    // console.log("pageCount: ", pageCount);

  });
  

  const getJobApplications = () => {
    // console.log("1111111111111111111111111111 JobApplicationsEmployerV3");
    // console.log("1111111111111111111111111111 JobApplicationsEmployerV3 jobID", jobID);

    // console.log("1111111111111111111111111111 oneJobApplicationCardSuperAdmin getJobApplication");
    instance.get(`jobApplication/getJobApplicationsPerPage/${pageNumber}/${perPage}`).then((resp) => {
      // console.log("getJobApplication: ", resp);
      // setCreatedAt(resp.data.createdAt);
      // console.log("1111111111111111111111111111 JobApplicationsEmployerV3: createdAt", createdAt);
      setJobApplications(resp.data);
      
    });
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    // console.log("3333333333333333333333: selectedpage: ", selectedPage);
    // console.log("3333333333333333333333: next page url: ", `/superadmin/profiles/page/?pageNumber=${selectedPage+1}`);
    // setOffset(selectedPage + selectedPage * 5);
    // history.push(`/superadmin/profiles/page/?pageNumber=${selectedPage+1}`);
    // history.push(`/superadmin/profiles/page/?pageNumber=${selectedPage+1}`);
    history.push(`/superadmin/inFranceprofiles/page/?pageNumber=${selectedPage+1}`);
    // history.push(`/superadmin/home`);
  };

  useEffect(() => {
    getJobApplications();
  }, [pageNumber]);

  return (
    <>
      <SuperAdminLayout />
      {/*
      <JobApplicationsCardsEmployer key="627d34016543d2ab235e8126" jobID="627d34016543d2ab235e8126" />
       */}
        <Container>
        <Row>
        <Col lg="2">
        </Col>
        <Col lg="10">
        <Card className="bg-transparent w-full">
          <CardBody>
            {jobApplications
              .map((jobApplication) => (
                <InFranceOneJobApplicationCardSuperAdmin 
                  key={jobApplication._id}
                  jobApplicationID={jobApplication._id} 
                />
              ))}
            </CardBody>
          <CardFooter className="bg-transparent w-full">
          <Row>
            <ReactPaginate
                previousLabel={"<<"}
                nextLabel={">>"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={3}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
            />
            </Row>
          </CardFooter>
        </Card>
        </Col>

        </Row>

      </Container>
            
    </>
  );
}

export default InFranceProfilesV2View;